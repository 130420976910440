import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Alert,
    Button,
    Divider,
    Grid,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import logo from '../../assets/logo_dark.png';
import logoBrand from '../../assets/logo_brand.png';
import logoIcon from '../../assets/logo.png';
import { Container, Image, Page, Text, theme } from '../../components';
import { forgotPassword as forgotPasswordService } from '../../services/auth';
import { useResponsive } from '../../hooks/responsive';


const ForgotPassword: React.FC = () => {
    var version = require('../../../package.json').version;

    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { mdDown } = useResponsive();
    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState<string>(searchParams.get('ref') || '');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    const onForgotPassword = async () => {
        if (success)
            return;

        setLoading(true);
        setSuccess(null);
        setErrorMessage(null);

        setTimeout(async () => {
            if (loading) {
                setLoading(false);
                setSuccess(null);
                setErrorMessage('Falha ao enviar email');
            };
        }, 20000);

        try {
            const authResponse: boolean = await forgotPasswordService(email);
            setLoading(false);
            if (authResponse) {
                setSuccess(email);
            } else {
                setErrorMessage(
                    'Ocorreu um erro ao enviar email. Confirme que o endereço está correto, caso necessário contate o suporte em suporte@redsoft.ai'
                )
            }
        } catch (_err) {
            if (_err instanceof Error)
                setErrorMessage(_err.message)
            else
                setErrorMessage('Ocorreu um erro ao enviar email')

            setLoading(false);
        }
    };

    return (
        <Page>
            <Container fluid flex padded color={theme.palette.background.default}>
                <Container inline horizontalCentered>
                    <Image source={logoIcon} height={40} width={40 * 1.666} />
                    <Image source={logoBrand} height={35} width={35 * 4.16} />
                </Container>
                <Divider />
                <Container fluid flex centered padded width="100%">
                    <Container fluid flex flexGrow centered padded maxWidth={mdDown ? "90%" : "500px"}>
                        <form onSubmit={handleSubmit(onForgotPassword)}>
                            <Grid container rowSpacing={2} padding={2}
                                display="flex" alignItems="center" justifyContent="center">
                                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                    <Image source={logo} height={200} width={200 * 1.4857} />
                                </Grid>
                                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                    <Text color={theme.palette.primary.main} size={17}>I.A. para a indústria da Carne.</Text>
                                </Grid>
                                <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <Text semiBold size={15}>E-mail:</Text>
                                    <TextField
                                        id="email"
                                        placeholder="E-mail"
                                        value={email}
                                        error={errors.email != undefined}
                                        disabled={!!success}
                                        sx={{ width: "100%" }}
                                        {...register('email', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event),
                                            required: 'Informe seu e-mail',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Informe um endereço de e-mail válido',
                                            },
                                        })}
                                    />
                                    {errors.email &&
                                        <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.email as any).message}</Text>
                                    }
                                </Grid>
                                {errorMessage &&
                                    <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" alignContent="center" sx={{ width: "100%" }}>
                                        <Text size={15} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{errorMessage || ''}</Text>
                                    </Grid>
                                }
                                {success && !errorMessage &&
                                    <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" alignContent="center" sx={{ width: "100%" }}>
                                        <Alert severity="success" style={{ marginBottom: 3 }}>
                                            <span>Email para recuperação de senha enviado com sucesso para</span>
                                            {' '}
                                            <span style={{ textDecoration: 'underline' }}>
                                                {success}
                                            </span>.
                                            <br />
                                            <br />
                                            O email pode levar alguns minutos para chegar, por favor aguarde. Caso necessário, contate suporte@redsoft.ai.
                                        </Alert>
                                    </Grid>
                                }
                                {!success &&
                                    <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            loading={loading}
                                            disabled={loading}
                                            style={{ opacity: loading ? "70%" : "100%" }}
                                        >
                                            Enviar email de recuperação
                                        </LoadingButton>
                                    </Grid>
                                }
                                <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        onClick={() => navigate('/')}
                                    >
                                        {success ? 'Voltar' : 'Cancelar'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </Container>
            </Container>
            <Container fluid centered padded color={theme.palette.background.default}>
                <Text semiBold size={12} color={theme.palette.primary.main} style={{ opacity: 0.9 }}                    >
                    v{version}
                </Text>
            </Container>
        </Page>
    )
}

export default ForgotPassword