import React, { FC, useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Fab, Grid, IconButton, Grow } from '@mui/material'
import { FilterAltOutlined as FilterOutlined, FilterAltOffOutlined as FilterOffOutlined, Check as CheckIcon } from '@mui/icons-material';

import { AnnotationsAnomaliesFilter, AnnotationsAnomaliesDownloadModal, AnnotationsAnomaliesReinsertModal, Container, Page, AnnotationsAnomaliesContinuousGrid, Spacing, theme} from '../../components'
import { useAnomalies } from '../../hooks/anomalies';
import { useAnnotationsAnomalies } from '../../hooks/annotationsAnomalies';
import { useAnnotators } from '../../hooks/annotators';
import { useAuth } from '../../hooks/auth';
import { useInformation } from '../../hooks/information';
import { IAnnotator, IAnomaly } from '../../types';

import { IAnomalyFilter, IAnnotationAnomaly } from '../../types';

import { PADDING } from '../../utils/consts';
import { Title1, BodyText } from '../../components/atoms/text';


const AnnotationsAnomalies: FC = () => {
    const { hideInformation, showInformation } = useInformation();

    const {
        anomaliesForAnnotation, 
        fetchAnomaliesForAnnotation, 
        anomaliesCount,
        fetchAnomaliesCount,
        loadingAnomaliesForAnnotation, 
        filter, 
        setFilter 
    } = useAnomalies();

    const { createNewAnnotation } = useAnnotationsAnomalies();

    const { fetchAnnotatorByUserId, fetchAnnotatorAnnotates } = useAnnotators();
    const { user } = useAuth();

    const [selectedAnomalies, setSelectedAnomalies] = useState<IAnomaly[]>([]);
    const [filterIsOpen, setFilterIsOpen] = useState<boolean>(true);
    const [anomaliesDownloadIsOpen, setAnomaliesDownloadIsOpen] = useState<boolean>(false);
    const [reinsertAnomaliesIsOpen, setReinsertAnomaliesIsOpen] = useState<boolean>(false);

    const [annotator, setAnnotator] = useState<IAnnotator | undefined>(undefined);
    
    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        fetchAnnotatorData();
        fetchAnomaliesForAnnotation(filter);
        fetchAnomaliesCount(filter);
    }, []);

    const onFilterIconClick = () => {
        setFilterIsOpen(!filterIsOpen);
    }

    const fetchAnnotatorData = async () => {
        if (user) {
            const userId: string = user.id;
            const auxAnnotator: IAnnotator | undefined = await fetchAnnotatorByUserId(userId);
            if (auxAnnotator) setAnnotator(auxAnnotator);
        }
    }
    const applyFilter = async (_filter: IAnomalyFilter) => {
        setFilter(_filter);
        setSelectedAnomalies([]);
        fetchAnomaliesForAnnotation(_filter);
        fetchAnomaliesCount(_filter);
    }

    const renderAnomaliesStats = () => {
        return (
            <Grid container item width="100%">
                <Grid container item xs={12} sm={12} md={8} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }} >
                    <Grid item xs={12} sm={4} md={4}>
                        <BodyText bold >Anotadas: {anomaliesCount.countAnnotated}</BodyText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <BodyText bold >Não anotadas: {anomaliesCount.countNonAnnotated} </BodyText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <BodyText bold >Total: {anomaliesCount.countAnnotated + anomaliesCount.countNonAnnotated}</BodyText>
                    </Grid>
                </Grid>
                <Spacing top={PADDING * 3} />

                <Grid item xs={0} md={12} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}></Grid>

                <Grid container item xs={12} sm={12} md={8} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                    <Grid item xs={12} sm={4} md={4}>
                        <BodyText semiBold >Anomalias: {anomaliesCount.countTrue}</BodyText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <BodyText semiBold >Falsas anomalias: {anomaliesCount.countFalse} </BodyText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <BodyText semiBold >Reinseridas: {anomaliesCount.countReinserted}</BodyText>
                    </Grid>   
                </Grid>
            </Grid>
        );
    }

    const onSelectAnomaly = async (_anomaly: IAnomaly) => {
        if (!selectedAnomalies.find(item => item.id == _anomaly.id)) {
            setSelectedAnomalies([...selectedAnomalies, _anomaly]);
        }else {
            const aux: IAnomaly[] = [];
            for (let i = 0; i < selectedAnomalies.length; i++) {
                if (selectedAnomalies[i].id == _anomaly.id) {
                    if (selectedAnomalies[i].isAnomaly != _anomaly.isAnomaly)
                        aux.push(_anomaly);
                } else
                    aux.push(selectedAnomalies[i]);
            }
            setSelectedAnomalies(aux);
        } 
    }
    
    const selectAllAnomalies = async () => {
        const aux: IAnomaly[] = [...selectedAnomalies];
        const limit = anomaliesForAnnotation.length < filter.limit ? anomaliesForAnnotation.length : filter.limit
        for (let i = 0; i < limit; i++) {
            const anomaly: IAnomaly = anomaliesForAnnotation[i];
            if (selectedAnomalies.find(item => item.id == anomaly.id))
                continue;
            aux.push(anomaly);
        }
        setSelectedAnomalies(aux);
    }

    const sendAnomaliesAnnotations = async () => {
        if (!annotator) return;
        if (selectedAnomalies.length == 0) {
            showInformation('Selecione pelo menos uma anomalia.', 'Aviso', undefined, () => {
                hideInformation();
            })
            return;
        }

        for (let index = 0; index < selectedAnomalies.length; index++) {
            if (!selectedAnomalies[index].id) continue;

            let anomaly = selectedAnomalies[index];
            const newAnnotation: IAnnotationAnomaly = {
                annotatorId: annotator?.id,
                anomalyId: anomaly.id,
                isAnomaly: anomaly.isAnomaly,
                isExternal: anomaly.isExternal,
                annotator: annotator,
            }
            await createNewAnnotation(newAnnotation, false);
        }
        showInformation('Anotações criadas com sucesso.', 'Aviso', undefined, () => {
            hideInformation();
            fetchAnomaliesForAnnotation(filter);
            fetchAnomaliesCount(filter);
            setSelectedAnomalies([]);
        });
    }
    return (
        <Page width='100%' height='87%' veryPadded>
            <Grid container color={theme.palette.background.default} rowSpacing={0}>
                <Grid item xs={11} sx={{ marginBottom: PADDING/2 }}>
                    <Title1 style={{ whiteSpace: 'nowrap' }}>Anotações - Anomalias</Title1>
                    <BodyText muted>Treinamento / Anotações / Anomalias</BodyText>
                </Grid>
                <Grid item xs={1} sx={{ marginBottom: PADDING/2 }}>
                    <IconButton size='medium' style={{}} onClick={onFilterIconClick}>
                        {
                            filterIsOpen ? <FilterOffOutlined style={{ color: theme.palette.primary.dark }}/>:<FilterOutlined style={{ color: theme.palette.primary.dark }}/>
                        }
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    {
                        filterIsOpen &&
                        <AnnotationsAnomaliesFilter
                            filter={filter}
                            onApplyFilter={applyFilter}
                            onCleanFilter={() => setFilter({ initialDate: new Date(), finalDate: new Date(), branches: [], limit: 10 })}
                            onDownloadData={() => setAnomaliesDownloadIsOpen(true)}
                            onReinsertAnomalies={() => setReinsertAnomaliesIsOpen(true)}
                        />
                    }
                </Grid>

            </Grid>
            <Spacing top={PADDING}/>
            {renderAnomaliesStats()}
            <Spacing top={PADDING}/>
            {anomaliesForAnnotation.length === 0 &&
                <Container fluid centered height="450px">
                    <Container fluid centered width="500px" height="450px">
                        <BodyText bold center size={20}>Nenhuma anotação foi encontrada no filtro selecionado.</BodyText>
                    </Container>
                </Container>
            }
            {anomaliesForAnnotation.length > 0 &&
                <Container fluid flex horizontalCentered  width="100%" height="100%">
                    <Container fluid flex width="99%">
                        <Container inline centered spacedBetween width="100%" sx={{ marginTop: PADDING }}>
                            <Container inline>
                                <Spacing left={PADDING} />
                                <BodyText bold size={13} color={theme.palette.primary.dark}>Anotações selecionadas: {selectedAnomalies.length}</BodyText>
                            </Container>
                            <Container inline>
                                <Button size="small" variant="contained" color="primary" onClick={() => selectAllAnomalies()}>
                                    <BodyText medium size={13} color={theme.palette.background.paper}>Selecionar todas</BodyText>
                                </Button>
                                <Spacing left={PADDING} />
                                <Button size="small" variant="contained" color="primary" onClick={() => setSelectedAnomalies([])}>
                                    <BodyText medium size={13} color={theme.palette.background.paper}>Selecionar nenhuma</BodyText>
                                </Button>
                            </Container>
                        </Container>
                        <AnnotationsAnomaliesContinuousGrid
                            anomalies={anomaliesForAnnotation}
                            selectedAnomalies={selectedAnomalies}
                            onSelectAnomaly={(auxAnomaly: IAnomaly) => onSelectAnomaly(auxAnomaly)}
                            limit={filter.limit}
                        />
                    </Container>
                </Container>
            }
            <Grow in={!fabHover} >
                <Fab
                variant="circular"
                size="large"
                color="primary"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    zIndex: 100,
                }}
                onMouseEnter={() => setFabHover(true)}
                onMouseLeave={() => setFabHover(false)}
                >
                <CheckIcon sx={{ color: theme.palette.background.paper }} />
                </Fab>
            </Grow>
            <Grow in={fabHover} >
                <Fab
                variant="extended"
                size="large"
                color="primary"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    height: 56,
                    borderRadius: '28px',
                    zIndex: 100,
                    transition: '0.3s',
                }}
                onMouseEnter={() => setFabHover(true)}
                onMouseLeave={() => setFabHover(false)}
                onClick={() => sendAnomaliesAnnotations()}
                >
                
                <Container inline>
                    <BodyText style={{ color: theme.palette.background.paper }}>
                        Enviar anotações
                    </BodyText>
                </Container>
                
                <CheckIcon sx={{ color: theme.palette.background.paper }} />
                    
                </Fab>
            </Grow>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingAnomaliesForAnnotation}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AnnotationsAnomaliesDownloadModal
                open={anomaliesDownloadIsOpen}
                onClose={() => setAnomaliesDownloadIsOpen(!anomaliesDownloadIsOpen)}
            />
            <AnnotationsAnomaliesReinsertModal
                open={reinsertAnomaliesIsOpen}
                onClose={() => {
                    setReinsertAnomaliesIsOpen(!reinsertAnomaliesIsOpen);
                    fetchAnomaliesCount(filter);
                }}
            />
        </Page>
    )
}

export default AnnotationsAnomalies;