import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';

import ButtonMenu from '../../../atoms/buttonMenu';
import { IVerdictContextMenu } from "../../../../types";
import { BodyText } from '../../../atoms/text';


const VerdictContextMenu: FC<IVerdictContextMenu> = (params: IVerdictContextMenu) => {
    if (params.loading)
        return <CircularProgress style={{ padding: '8px', alignSelf: 'center' }} />

    return (
    <ButtonMenu open={(
            params.open &&
            !params.loading &&
            !params.downloadVerdictSheetIsOpen &&
            !params.downloadVerdictPhotosIsOpen
        )}
        style={{ alignSelf: 'center' }}
        onOpen={() => params.setOpen(true)}
    >
        <MenuItem
            onClick={() => params.setDownloadVerdictSheetIsOpen(true)}
            style={{ justifyContent: 'right' }}
        >
            <BodyText muted={params.loading || params.downloadVerdictSheetIsOpen}>
                Baixar Planilha de Arbitragem&ensp;
                {params.loading || params.downloadVerdictSheetIsOpen ?
                    <CircularProgress style={{ padding: '12px', margin: '-8px auto -4px' }} />
                    : <DownloadIcon />
                }
            </BodyText>
        </MenuItem>
        <MenuItem
            onClick={() => params.setDownloadVerdictPhotosIsOpen(true)}
            style={{ justifyContent: 'right' }}
        >
            <BodyText muted={params.loading || params.downloadVerdictPhotosIsOpen}>
                Baixar Fotos Arbitradas&ensp;
                {params.loading || params.downloadVerdictPhotosIsOpen ?
                    <CircularProgress style={{ padding: '12px', margin: '-8px auto -4px' }} />
                    : <DownloadIcon />
                }
            </BodyText>
        </MenuItem>
    </ButtonMenu>
    )
}

export default VerdictContextMenu;
