import React, { FC, useState } from 'react';
import moment from 'moment';
import { ptBR } from "date-fns/locale";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';

import { ITimePicker } from '../../../types';


const DateTimePicker: FC<ITimePicker> = (params: ITimePicker) => {
    const [open, setOpen] = useState<boolean>(false)
    const inputFormat = params.inputFormat || "yyyy-MM-dd HH:mm";
    let views: CalendarOrClockPickerView[] = [];
    if (inputFormat.includes('yy'))
        views.push('year');
    if (inputFormat.includes('MM'))
        views.push('month');
    if (inputFormat.includes('dd'))
        views.push('day');
    if (inputFormat.includes('HH'))
        views.push('hours');
    if (inputFormat.includes('mm'))
        views.push('minutes');
    if (inputFormat.includes('ss'))
        views.push('seconds');

    return (
        <LocalizationProvider
            locale={ptBR} dateAdapter={AdapterDateFns}
        >
            <MuiDateTimePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                label={params.label}
                views={views}
                mask={params.mask}
                value={params.value || null}
                inputFormat={inputFormat}
                onChange={(newValue: any) => {
                    if (moment(newValue, inputFormat).isValid())
                        params.setValue(newValue);
                    else
                        params.setValue(null);
                }}
                disabled={params.disabled}
                disablePast={params.disablePast}
                renderInput={
                    (parameters) => <TextField
                        {...parameters}
                        sx={{ margin: 0, zIndex: 0, ...params.sx }}
                    />
                }
            />
        </LocalizationProvider>
    )
}

export default DateTimePicker
