import { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { CarcassesFilter, Container, Page, Spacing, theme } from '../../../components';
import { useCarcass } from '../../../hooks/carcasses';
import { usePopVisualizationDownload } from '../../../hooks/popVisualizationDownload';
import { PADDING } from '../../../utils/consts'
import { ICarcassFilterParams, ICarcass } from '../../../types';
import CarcassesPopList from '../../../components/molecules/carcassesPops/visualization/list';
import CarcassPopDetailedItem from '../../../components/molecules/carcassesPops/visualization/detailedItem';
import { Title1, BodyText } from '../../../components/atoms/text';
import { PopGroupIdPermissionRelation } from '../../../utils/pops';
import { useAuth } from '../../../hooks/auth';


const PopVisualization: FC = () => {
    const { fetchCarcasses, loading, carcasses } = useCarcass();
    const { clear } = usePopVisualizationDownload();
    const { roles } = useAuth();

    const [currCarcass, setCurrCarcass] = useState<string>('');  // Current carcass on detail
    const [filteredCarcasses, setFilteredCarcasses] = useState<ICarcass[]>([]);

    useEffect(() => {
        // Filter the carcases POPs that the user has access to
        const updatedCarcasses = carcasses.map(carcass => {
            const popsA = carcass.popsA?.filter(pop => roles.includes(PopGroupIdPermissionRelation[String(pop.popGroup?.id)]) || pop.popGroup?.id === 3) || [];
            const popsB = carcass.popsB?.filter(pop => roles.includes(PopGroupIdPermissionRelation[String(pop.popGroup?.id)]) || pop.popGroup?.id === 3) || [];
            return { ...carcass, popsA, popsB };
        });
        // We cant reasign carcasses because its a const
        // But we can javascript it and reasign its internals
        for (let i = 0; i < carcasses.length; i++) {
            carcasses[i] = updatedCarcasses[i];
        }

        setCurrCarcass(carcasses.length > 0 ? carcasses[0].id : '');
    }, [carcasses]);

    const carcassesIds = filteredCarcasses.map((item) => {
        let value: string | number = '';
        if (item.partnerId && !item.partnerId.includes('SV'))
            value = item.partnerId;
        else if (item.sequence)
            value = item.sequence;
        else if (item.partnerId) {
            value = item.partnerId;
        }
        return { key: item.id, value: value }
    });

    if (currCarcass && !filteredCarcasses.map(item => item.id).includes(currCarcass)) {
        setCurrCarcass(filteredCarcasses.length > 0 ? filteredCarcasses[0].id : '');
    }

    const onApplyFilter = async (filter: ICarcassFilterParams) => {
        clear();
        fetchCarcasses(filter);
    }

    return (
        <Page width='auto'>
            <Container fluid flex color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline style={{
                    flex: '0',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Visualização de POPs</Title1>
                        <BodyText muted>Pop-Engine / Visualização</BodyText>
                    </Container>
                    <Container inline fluid style={{ flexGrow: '0.2' }}>
                        <CarcassesFilter onApply={onApplyFilter} loading={loading} />
                    </Container>
                </Container>
                <Spacing top={PADDING * 2} />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} xl={2}>
                        <CarcassesPopList
                            carcasses={carcasses}
                            currCarcass={currCarcass}
                            setCurrCarcass={setCurrCarcass}
                            loading={loading}
                            filteredCarcasses={filteredCarcasses}
                            setFilteredCarcasses={setFilteredCarcasses}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={10} sx={{ md: { minHeight: '100%' } }}>
                        <CarcassPopDetailedItem
                            carcass={carcasses.find(item => item.id === currCarcass)}
                            loading={loading}
                            carcassesIds={carcassesIds}
                            setCurrCarcass={setCurrCarcass}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page >
    )
}

export default PopVisualization;
