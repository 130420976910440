export enum PopRelatedPhoto {
    EXTERNAL = 0,
    INTERNAL = 1,
    OCR = 2,
    OCR_HASH_1 = 3
}

export const PopGroupIdPermissionRelation: Record<string, string> = {
    "0": "view_pop_visualization__group_redutores_de_desembolso",
    "1": "view_pop_visualization__group_agregadores_de_receita",
    "2": "view_pop_visualization__group_maximizadores_de_produtos",
}

export const PopGroups: Record<number, number[]> = {
    // Redutores de desembolso
    0: [1, 6, 2, 3, 4, 16],
    // Agregadores de receita
    1: [5, 7, 19, 18, 20, 8],
    // Maximizadores de produtos
    2: [9, 10, 11, 12, 13, 14, 15, 17],
}
