import React, { FC } from 'react';
import Link from '@mui/material/Link';

import { Container, Page, Spacing, theme } from '../../components';
import { VERY_PADDING } from '../../utils/consts'
import { Title1, Title2, BodyText, LargeText } from '../../components/atoms/text';


const Help: FC = () => {
    const videos: {
        src: string,
        title: string,
        videoTitle: string,
    }[] = [
        {
            src: "https://www.youtube.com/embed/vnjt7s4_GoI",
            title: "Acesso e Tela Inicial",
            videoTitle: "Onboarding Dashboard Redsoft - Acesso e Tela Inicial",
        },
        {
            src: "https://www.youtube.com/embed/LCwM4yscSHM",
            title: "Cards Iniciais e Filtros",
            videoTitle: "Onboarding Dashboard Redsoft - Cards iniciais e filtros",
        },
        {
            src: "https://www.youtube.com/embed/bX29dKyx2vI",
            title: "Gráficos e Percentuais",
            videoTitle: "Onboarding Dashboard Redsoft - Gráficos e Percentuais",
        },
        {
            src: "https://www.youtube.com/embed/1FjDkm_VKgY",
            title: "Animais: Carcaças e Lotes",
            videoTitle: "Onboarding Dashboard Redsoft - Animais: Carcaças e Lotes",
        },
        {
            src: "https://www.youtube.com/embed/wGjkYaZuZa8",
            title: "Carcaças e Lotes",
            videoTitle: "Onboarding Dashboard Redsoft - Carcaças e Lotes",
        },
        {
            src: "https://www.youtube.com/embed/Nctm9sb9_MU",
            title: "Relatório Técnico de Abate",
            videoTitle: "Onboarding Dashboard Redsoft - Relatório Técnico de Abate",
        },
    ]

    return (
        <Page width='auto' height='auto' color={theme.palette.background.default} style={{minHeight: "100%"}}>
            <Container fluid flex height='auto' width="100%" veryPadded>
                <Container inline width="100%" spacedBetween> 
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Ajuda</Title1>
                    </Container>
                </Container>
                <Spacing top={VERY_PADDING} />
                <BodyText>
                    Nessa página, você encontra uma série de vídeos instruindo 
                    sobre o uso das principais ferramentas da plataforma.
                </BodyText>
                <BodyText style={{ marginBottom: '1em' }}>
                    Caso ainda necessite de ajuda, é possível se comunicar
                    através dos contatos no canto inferior esquerdo do menu
                    lateral.
                </BodyText>
                <LargeText bold color={theme.palette.primary.main}>Sumário</LargeText>
                {videos.map((video, index) =>
                    <Link
                        href={`#video-${index}`}
                        key={`videos-${index}`}
                    >
                        {video.title}
                    </Link>
                )}
                <Spacing top={VERY_PADDING} />
                {videos.map((video, index) => <React.Fragment key={`videos-${index}`}>
                    <Title2 id={`video-${index}`}>{video.title}</Title2>
                    <iframe width={1274} height={679}
                        src={`${video.src}?rel=0`}
                        title={video.videoTitle}
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            border: 'none',
                            marginBottom: '2em'
                        }}
                    />
                </React.Fragment>)}
            </Container>
        </Page>
    )
}

export default Help;
