import { IShift } from '../types'
import { convertApiResponseToIShift, baseURL } from "../services/shifts";
import CRUDHook from "./crud";

export const { crudProvider: ShiftsProvider, crudHook: useShifts } = CRUDHook<IShift>(
    {
        baseURL,
        name: 'turno',
        convertResponseToModel: convertApiResponseToIShift,
    }
)
