import { AxiosResponse } from "axios";

import api from ".";
import { ICarcassLocalClassification } from "../types";

import CRUDService from "./crud";

export const baseURL = '/api_redsoft/v1_0/carcasses_local_classifications';

export const convertApiResponseToICarcassLocalClassification = (_data: any) => {
  const entry: ICarcassLocalClassification = {
    id: _data.id,
    branchId: _data.branchId,
    localClassification: _data.localClassification,
    name: _data.name,
    classificationValue: _data.classificationValue,
    jbsClassification: _data.jbsClassification,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return entry;
};

const defaultService = CRUDService({
  baseURL,
  name: 'classificação local',
  convertResponseToModel: convertApiResponseToICarcassLocalClassification
});

export const getCarcassesLocalClassificationsByBranch: (branch?: string) => Promise<ICarcassLocalClassification[]> =
  async (branch?: string) => {
    const url = !!branch ? `${baseURL}/by_branch_id/${branch}` : baseURL;
    const response: AxiosResponse = await api.get(url);
    return defaultService.crudCheckResponse(response, 'Falha ao buscar acabamentos locais');
  };
