import React, { FC, createContext, useState, useContext } from "react";

import {
    IAlertConstantEntry,
    IAlertConstants,
    IAlertConstantsContext,
} from "../types";
import {
    getAlertConstants,
    getComparisonDescription,
    getLevelDescription,
    getStatusDescription,
    getWeekDayDescription,
} from "../services/alerts";


const AlertConstantsContext = createContext<IAlertConstantsContext>(
    {} as IAlertConstantsContext
);


export const AlertConstantsProvider: FC<any> = ({ children }) => {
    const [comparisons, setComparisons] = useState<IAlertConstantEntry[]>(
        [] as IAlertConstantEntry[]);
    const [levels, setLevels] = useState<IAlertConstantEntry[]>(
        [] as IAlertConstantEntry[]);
    const [sources, setSources] = useState<IAlertConstantEntry[]>(
        [] as IAlertConstantEntry[]);
    const [statuses, setStatuses] = useState<IAlertConstantEntry[]>(
        [] as IAlertConstantEntry[]);
    const [weekDays, setWeekDays] = useState<IAlertConstantEntry[]>(
        [] as IAlertConstantEntry[]);
    const [loading, setLoading] = useState<boolean>(false);

    const clearAll = () => {
        setComparisons([]);
        setLevels([]);
        setSources([]);
        setStatuses([]);
        setWeekDays([]);
    }

    const fetchAlertConstants = async () => {
        clearAll();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 6000);

        const response: IAlertConstants = await getAlertConstants();
        setComparisons(response.comparisons.map((item) => ({
            ...item,
            description: getComparisonDescription(item.name)
        })) || []);
        setLevels(response.levels.map((item) => ({
            ...item,
            description: getLevelDescription(item.name)
        })) || []);
        setSources(response.sources || []);
        setStatuses(response.statuses.map((item) => ({
            ...item,
            description: getStatusDescription(item.name)
        })) || []);
        setWeekDays(response.weekDays.map((item) => ({
            ...item,
            description: getWeekDayDescription(item.name)
        })) || []);

        return response;
    };

    return (
        <AlertConstantsContext.Provider
            value={{
                loading,
                comparisons,
                levels,
                sources,
                statuses,
                weekDays,
                fetchAlertConstants,
            }}
        >
            {children}
        </AlertConstantsContext.Provider>
    );
};

export function useAlertConstants() {
    const context = useContext(AlertConstantsContext);

    if (!context) {
        throw new Error(
            "useAlertConstants must be used within an AlertConstantsContextProvider",
        );
    }
    return context;
}
