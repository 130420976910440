import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fab, Grow, TextField } from '@mui/material';
import { AssignmentInd as AnnotatorIcon, Search as SearchIcon } from '@mui/icons-material';

import { AnnotatorAccessModal, AnnotatorRegisterModal, Container, Page, Spacing, Text, theme } from '../../components';
import { useAnnotators } from '../../hooks/annotators';
import { useAuth } from '../../hooks/auth';
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information';
import { useUsers } from '../../hooks/users';
import { PADDING, VERY_PADDING } from '../../utils/consts'
import { IAnnotator } from '../../types';
import AnnotatorsList from '../../components/molecules/annotators/list';
import { Title1, BodyText } from '../../components/atoms/text';

const Annotators: FC = () => {
    const { fetchAnnotators, filteredAnnotators,
        loading, paginate, annotators, annotatorsPerRow,
        setPaginate, deleteAnnotator, searchAnnotator, setSearchAnnotator } = useAnnotators();
    const { user } = useAuth();
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const navigate = useNavigate();
    const { fetchUsers } = useUsers();

    const [search, setSearch] = useState<string>('')
    const [newAnnotatorModalVisible, setNewAnnotatorModalVisible] = useState<boolean>(false);
    const [accessAnnotatorModalVisible, setAccessAnnotatorModalVisible] = useState<boolean>(false);
    const [annotatorToEdit, setAnnotatorToEdit] = useState<IAnnotator | undefined>(undefined);
    const [selectedAnnotatorId, setSelectedAnnotatorId] = useState<string | undefined>(undefined);

    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        if (!loading) {
            fetchAnnotators();
            fetchUsers();
        }
    }, []);

    useEffect(() => {
        if (!newAnnotatorModalVisible)
            setAnnotatorToEdit(undefined);
    }, [newAnnotatorModalVisible]);


    useEffect(() => {
        if (!accessAnnotatorModalVisible)
            setSelectedAnnotatorId(undefined);
    }, [accessAnnotatorModalVisible]);

    const editAnnotator = (_annotatorId: string) => {
        navigate(`/annotators/${_annotatorId}`);
    }

    const onDeleteAnnotator = async (_annotatorId: string) => {
        showConfirmation("Ao excluir o anotador não será possível recuperá-lo.", "Excluir anotador", "Confirma", "Cancelar",
            async () => {
                await deleteAnnotator(_annotatorId);
                hideConfirmation();
                showInformation('Anotador excluído com sucesso.', 'Exclusão de anotador');
            },
            () => {
                hideConfirmation();
            });
    }

    const openPermissions = (_annotatorId: string) => {
        fetchUsers();
        setSelectedAnnotatorId(_annotatorId);
        setTimeout(() => {
            setAccessAnnotatorModalVisible(true);
        }, 1000);
    }

    return (
        <Page width='auto' height='87%' veryPadded>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%">
                <Container style={{ flexGrow: '1' }}>
                    <Title1 style={{ whiteSpace: 'nowrap' }}>Anotadores</Title1>
                    <BodyText muted>Treinamento / Anotadores</BodyText>
                </Container>
                <Spacing top={PADDING} />
                <TextField id="search-annotators" autoFocus placeholder="Pesquisar anotadores" value={searchAnnotator}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchAnnotator(event.target.value)}
                    InputProps={{ startAdornment: <SearchIcon color='primary' /> }} sx={{ width: "100%" }} />
                <Spacing top={VERY_PADDING} />
                <AnnotatorsList
                    onOpenPermissions={(_id: string) => openPermissions(_id)}
                    onEditAnnotator={(_id: string) => editAnnotator(_id)}
                    onDeleteAnnotator={(_id: string) => onDeleteAnnotator(_id)} />
            </Container>
            <Grow in={!fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/annotators/register`)}
                    variant="circular"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    <AnnotatorIcon />
                </Fab>
            </Grow>
            <Grow in={fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/annotators/register`)}
                    variant="extended"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        height: 56,
                        borderRadius: '28px'
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    {fabHover &&
                        <Container inline>
                            <Text size={14} color={theme.palette.background.paper}>Novo anotador</Text>
                            <Spacing left={PADDING} />
                        </Container>}
                    <AnnotatorIcon />
                </Fab>
            </Grow>

            <AnnotatorRegisterModal
                annotatorToEdit={annotatorToEdit}
                open={newAnnotatorModalVisible}
                onClose={() => setNewAnnotatorModalVisible(false)}
                onCancel={() => setNewAnnotatorModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    showInformation(_informationMessage, _informationTitle);
                }} />
            <AnnotatorAccessModal
                annotatorId={selectedAnnotatorId}
                annotator={annotators.find(item => item.id == selectedAnnotatorId)}
                open={accessAnnotatorModalVisible}
                onClose={() => setAccessAnnotatorModalVisible(false)}
                onCancel={() => setAccessAnnotatorModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    showInformation(_informationMessage, _informationTitle);
                }} />
        </Page >
    )
}

export default Annotators;