import api from '../services';
import { AxiosResponse } from 'axios';
import { IAlertSchedule } from '../types'
import { baseURL } from "../services/alertGroupSchedules";
import CRUDHook from "./crud";

export const { crudProvider: AlertGroupSchedulesProvider, crudHook: useAlertGroupSchedules } = CRUDHook<IAlertSchedule>(
    {
        baseURL,
        name: 'horário de grupo de alerta',
        fetchAllMethod: async () => {
            let res: IAlertSchedule[];
            const branchesRepsonse = await api.get('/api_redsoft/v1_0/branches');
            const branches = branchesRepsonse.data.map((item: any) => item.id).join(',');
            const response: AxiosResponse = await api.get(baseURL + `?branches=${branches}`);
            return response.data;
        }
    }
)
