import React, { FC, useEffect } from 'react';

import { Grid } from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { ReactComponent as CowIcon } from "../../../../assets/icons/animal_cow.svg";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CloudIcon from '@mui/icons-material/Cloud';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DashboardNumberCard } from '../../..';
import { useHashCount } from '../../../../hooks/hashCount';
import { useAnomalies } from '../../../../hooks/anomalies';
import { useBranches } from '../../../../hooks/branches';
import HashCountFilter from './filter';


const HashCount: FC = () => {
    const {
        loading, filter, setFilter, hashCount, fetchHashCount, setLoading
    } = useHashCount();

    const {
        branches,
        fetchBranches,
    } = useBranches();

    const { anomaliesCount, fetchAnomaliesCount } = useAnomalies();

    useEffect(() => {
        fetchBranches();
    }, []);
    
    useEffect(() => {
        if (filter.branchId) {
            fetchHashCount();
        }
    }, [filter]);

    useEffect(() => {
        if (filter.branchId && branches.length > 0) {
            setLoading(true);
            fetchAnomaliesCount({
                initialDate: filter.date as Date,
                finalDate: filter.date  as Date,
                branches: [branches.find(a => a.id === filter.branchId)!],
                limit: 0
            });
            setLoading(false);
        }
    }, [hashCount]);

    return (
      <Grid container>
            <Grid item container xs={12} sx={{ marginBottom: 2 }}>
                <HashCountFilter
                    date={filter.date}
                    branchId={filter.branchId}
                    loading={loading}
                    onApply={(_filter) => setFilter(_filter)}
                />
            </Grid>
            <Grid container spacing={4} alignItems="center" minHeight='100%'>
                <Grid item xs={12} md={3}>
                    <DashboardNumberCard
                        label={'Meias-Carcaças Planejadas na Escala de Abate'}
                        value={hashCount?.slaughterSchedule || 0}
                        size='medium'
                        icon={<FormatListNumberedIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardNumberCard
                        label={'Meias-Carcaças Capturadas no #1'}
                        value={hashCount?.hash1 || 0}
                        size='medium'
                        icon={<QrCode2Icon style={{ width: '35px', height: '35px', color: 'white'}} />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardNumberCard
                        label={'Meias-Carcaças Capturadas no #2'}
                        value={hashCount?.hash2 || 0}
                        size='medium'
                        icon={<CowIcon style={{scale: '0.85', color: 'white'}} />}
                    />
                </Grid>
                <Grid item xs={12} md={3} container alignItems='center' columnSpacing={4} rowSpacing={2}>
                    <Grid item xs={12}>
                        <DashboardNumberCard
                            label={'Meias-Carcaças Integradas com ERP'}
                            value={hashCount?.erpSync || 0}
                            size='small'
                            icon={<CloudIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DashboardNumberCard
                            label={'Meias-Carcaças Bem Posicionadas'}
                            value={hashCount?.wellPlaced || 0}
                            size='small'
                            icon={<CheckBoxIcon style={{ width: '25px', height: '25px', color: 'white'}} />}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} container alignItems='center' columnSpacing={4} rowSpacing={2}>
                    <Grid item xs={12}>
                        <DashboardNumberCard
                            label={'Anomalias Anotadas'}
                            value={anomaliesCount.countAnnotated || 0}
                            size='small'
                            icon={<BookmarkIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DashboardNumberCard
                            label={'Anomalias Não-Anotadas'}
                            value={anomaliesCount.countNonAnnotated || 0}
                            size='small'
                            icon={<BookmarkBorderIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardNumberCard
                        label={'Anomalias Reinseridas'}
                        value={anomaliesCount?.countReinserted || 0}
                        size='medium'
                        icon={<BookmarkAddedIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardNumberCard
                        label={'Anomalias Falsas'}
                        value={anomaliesCount?.countFalse || 0}
                        size='medium'
                        icon={<BookmarkRemoveIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DashboardNumberCard
                        label={'Anomalias Verdadeiras'}
                        value={anomaliesCount?.countTrue || 0}
                        size='medium'
                        icon={<BookmarkAddIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                    />
                </Grid>
            </Grid>
    </Grid>
    )
}

export default HashCount;