import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Divider, IconButton, Menu } from "@mui/material";
import {
    Close as CloseIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    DoneAll as DoneAllIcon,
    Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Text from "../../../atoms/text";
import { useCarcassesLocalClassifications } from "../../../../hooks/carcassesLocalClassifications";
import { ICarcassesLocalClassificationsMultiSelect, ICarcassLocalClassification } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import theme from "../../../theme";
import Spacing from "../../../atoms/spacing";
import { normalizeString } from "../../../../utils/stringFormatters";

const CarcassesLocalClassificationsMultiSelect: FC<ICarcassesLocalClassificationsMultiSelect> = (params: ICarcassesLocalClassificationsMultiSelect) => {
    const optionsId = "options-button";
    const selectionLength = params.selectedClassifications.reduce((res: string[], item) => {
        if (item.localClassification && !res.includes(item.localClassification))
            res.push(item.localClassification);
        return res;
    }, []).length;

    const { entries: carcassesLocalClassifications, fetchAllEntries: fetchCarcassesLocalClassifications } = useCarcassesLocalClassifications();

    const [search, setSearch] = useState<string>("")
    const [groupedClassifications, setGroupedClassifications] = useState<{[key: string]: ICarcassLocalClassification[]}>({});
    const [possibleClassifications, setPossibleClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [filteredClassifications, setFilteredClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
    const isOptionsOpen = Boolean(anchorOptions)

    useEffect(() => {
        fetchCarcassesLocalClassifications();
    }, []);

    useEffect(() => {
        if (params.selectedBranchesId.length == 0) {
            setPossibleClassifications([]);
            setFilteredClassifications([]);
            params.setSelectedClassifications([]);
            setGroupedClassifications({});
        } else {
            if (carcassesLocalClassifications.length > 0) {
                let auxGroupedClassifications: {[key: string]: ICarcassLocalClassification[]} = {};
                let auxClassifications = [...carcassesLocalClassifications.filter(
                    item => params.selectedBranchesId.includes(item.branchId) && item.localClassification !== null
                )].reduce((res: ICarcassLocalClassification[], item) => {
                    const localClassifications = res.map(item => item.localClassification);
                    if (!localClassifications.includes(item.localClassification)) {
                        auxGroupedClassifications[item.localClassification!] = [item];
                        res.push(item);
                    } else {
                        auxGroupedClassifications[item.localClassification!].push(item)
                    }
                    return res
                }, []);
                auxClassifications.sort((a, b) => a.classificationValue! - b.classificationValue!);
                setPossibleClassifications(auxClassifications);
                setFilteredClassifications(auxClassifications);
                setGroupedClassifications(auxGroupedClassifications);
            } else {
                setPossibleClassifications([]);
                setFilteredClassifications([]);
                params.setSelectedClassifications([]);
                setGroupedClassifications({});
            }
        }
    }, [
        params.selectedBranchesId.length,
        carcassesLocalClassifications.length,
        params.selectedBranchesId.length > 0 ? params.selectedBranchesId[0] : '',
        carcassesLocalClassifications.length > 0 ? carcassesLocalClassifications[0] : '',
    ]);

    useEffect(() => {
        setFilteredClassifications([...possibleClassifications]);
    }, [
        possibleClassifications.length,
        possibleClassifications.length > 0 ? possibleClassifications[0] : ''
    ]);

    useEffect(() => {
        filterClassifications(search);
    }, [search]);

    const handleOptionsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorOptions(event.currentTarget);
    };

    const handleOptionsMenuClose = () => {
        setAnchorOptions(null);
    };

    const filterClassifications = (_search: string) => {
        setFilteredClassifications([])
        let auxClassifications = [...possibleClassifications]

        setTimeout(() => {
            if (_search != "") {
                auxClassifications = auxClassifications.filter((item: ICarcassLocalClassification) =>
                    (item.name && normalizeString(item.name).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredClassifications([...auxClassifications])
        }, 0)
    }

    const getGroupedClassifications = (_classification: ICarcassLocalClassification) => {
        // Returns all the ICarcassLocalClassification[] with the same localClassification
        return groupedClassifications[_classification.localClassification!] || [_classification];
    }

    const handleSelectClassification = (_classification: ICarcassLocalClassification, _isChecked: boolean) => {
        if (params.singleSelect) {
            params.setSelectedClassifications(getGroupedClassifications(_classification));
            return;
        };

        const auxClassifications: ICarcassLocalClassification[] = [];
        for (let i = 0; i < params.selectedClassifications.length; i++) {
            const auxClassification: ICarcassLocalClassification = params.selectedClassifications[i];
            if (auxClassification.name === _classification.name && !_isChecked) continue;
            for (const auxClass of getGroupedClassifications(auxClassification)) {
                const auxClassIds = auxClassifications.map(item => item.id!);
                if (!auxClassIds.includes(auxClass.id!))
                    auxClassifications.push(auxClass);
            }
        };
        if (_isChecked) {
            for (const auxClass of getGroupedClassifications(_classification)) {
                const auxClassIds = auxClassifications.map(item => item.id!);
                if (!auxClassIds.includes(auxClass.id!))
                    auxClassifications.push(auxClass);
            }
        };

        params.setSelectedClassifications([...auxClassifications]);
    }

    const renderClassificationItem = (_classification: ICarcassLocalClassification, key: number) => {
        const isChecked: boolean = params.selectedClassifications.find(item => item.id == _classification.id) != undefined;

        return (
            <Container key={key} inline spacedBetween horizontalCentered width="100%"
                onClick={() => {
                    if (!params.disabled)
                        handleSelectClassification(_classification, !isChecked)
                }}
                sx={{ cursor: "pointer" }}
            >
                <Checkbox size="small" disabled={params.disabled} checked={isChecked} />
                <Container fluid flexStart flex>
                    <Text muted={params.disabled}>
                        {_classification.name}{
                            _classification.localClassification ?
                            ` (${_classification.localClassification})` : ''
                        }
                    </Text>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth={"170px"} height="30px" width='100%'>
            <Container inline horizontalCentered spacedBetween minWidth="130px" height="30px" onClick={handleOptionsMenuOpen}
                style={{ cursor: "pointer", paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedClassifications.length == 0 && <Text size={15} muted={params.disabled}>{params.placeholder}</Text>}
                {params.selectedClassifications.length > 0 &&
                    <Text bold size={14} muted={params.disabled}>
                        {`${params.selectedClassifications[0].name} ${!params.singleSelect && selectionLength > 1 ? " +" + (selectionLength - 1) : ""}`}
                    </Text>
                }
                <IconButton disabled={params.disabled} style={{ backgroundColor: "transparent" }}>
                    {isOptionsOpen && <ExpandLessIcon />}
                    {!isOptionsOpen && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Menu
                id={optionsId}
                anchorEl={anchorOptions}
                anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                transformOrigin={{ vertical: "top", horizontal: "right", }}
                open={isOptionsOpen}
                onClose={handleOptionsMenuClose}>
                <Container fluid padded maxWidth="250px" borderRadius={BORDER_RADIUS} >
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder="Pesquisar acabamento"
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color="primary" />}
                        sx={{ height: "35px" }}
                        disabled={params.disabled}
                    />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: "auto" }}>
                        {params.selectedBranchesId.length == 0 && filteredClassifications.length == 0 &&
                            <Text muted={params.disabled}>Por favor, selecione pelo menos uma empresa.</Text>
                        }
                        {params.selectedBranchesId.length > 0 && filteredClassifications.length == 0 &&
                            <Text muted={params.disabled}>Não foi encontrado nenhum acabamento para as empresas selecionadas.</Text>
                        }
                        {filteredClassifications.map((item, index) => renderClassificationItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button
                            disabled={params.disabled}
                            startIcon={<DoneAllIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => {
                                if (!params.disabled)
                                    params.setSelectedClassifications(
                                        Object.values(groupedClassifications)
                                        .reduce((res, item) => res.concat(item), [])
                            )}}>
                            <Text size={15} muted={params.disabled}>Marcar todos</Text>
                        </Button>
                        <Button
                            disabled={params.disabled}
                            startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => {
                                if (!params.disabled)
                                    params.setSelectedClassifications([])
                            }}>
                            <Text size={15} muted={params.disabled}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Menu>
        </Container>
    );
}

export default CarcassesLocalClassificationsMultiSelect;