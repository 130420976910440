import React, { FC, useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import { LargeText, BodyText } from '../../components/atoms/text';
import {
    getCarcassClassification,
    getCarcassClassificationClassName,
    getCarcassConformationName,
} from '../../hooks/carcasses';

import { useDownloadPage } from '../../hooks/downloadPage';
import { getPopClassificationName } from '../../services/carcassesPops';


const DownloadPageCounts: FC<any> = () => {
    const { counts, filterProperty } = useDownloadPage();

    const [groupBy, setGroupBy] = useState<'pop' | 'classification' | 'conformation'>(filterProperty);

    useEffect(() => {
        setGroupBy(filterProperty);
    }, [counts])

    if (!counts || !counts.total)
        return <></>

    return <Box sx={{ paddingLeft: '20px' }}>
        <LargeText bold style={{ marginBottom: '20px' }}>
            Total: {counts.total}
        </LargeText>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: 2,
                rowGap: 4,
                columnGap: 10,
            }}
        >
            {Object.keys(counts.counts).map((item) =>
                <Stack spacing={1} key={item}>
                    <LargeText bold>
                        {groupBy === 'pop' ? item :
                            groupBy === 'classification' ? getCarcassClassificationClassName(parseInt(item))
                            : getCarcassConformationName(parseInt(item))
                        }: {counts.counts[item].total} ({
                            (counts.counts[item].percent * 100).toFixed(2)
                        }%{counts.counts[item].undefinedTotal !== undefined &&
                        ` - ${counts.counts[item].undefinedTotal} indef.`
                        })
                    </LargeText>
                    {Object.keys(counts.counts[item].groups).map((group) => {
                        const groupData = counts.counts[item].groups[group];
                        return <Box key={group}>
                            <BodyText
                                style={{
                                    fontStyle: 'italic',
                                    marginBottom: '-2px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {groupBy === 'pop' ? getPopClassificationName(parseInt(group)) : group
                                }: {groupData.total} ({(groupData.percent * 100).toFixed(2)}%{
                                    groupData.undefinedTotal !== undefined &&
                                        ` - ${groupData.undefinedTotal} indef.`
                                    })
                            </BodyText>
                        </Box>})}
                </Stack>
            )}
        </Box>
    </Box>;
};

export default DownloadPageCounts;
