import React from 'react'
import { Routes, Route } from 'react-router-dom';

import {
    Login,
    PasswordRecovery,
    PasswordRecoveryRealmsRedirect,
    ForgotPassword,
} from '../pages';

const AuthRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password/" element={<ForgotPassword />} />
            <Route path="/password-recovery/:token" element={<PasswordRecovery />} />
            <Route path="/password-recovery/key/:key" element={<PasswordRecovery />} />
            <Route path="/realms/pix_docs/login-actions/action-token" element={<PasswordRecoveryRealmsRedirect />} />

            <Route path="*" element={<Login />} />
        </Routes>
    )
}

export default AuthRoutes;
