import React, { FC, useRef, useState } from "react";
import { Button, ButtonGroup, Checkbox, ClickAwayListener, Grid, Grow, Paper, Popper, MenuList, MenuItem, IconButton } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon, ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';

import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { IAnnotationAnomalyCard, IAnomaly } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import CarcassPhoto from "../../carcasses/carcassPhoto";
import { useAnnotations } from "../../../../hooks/annotations";

const AnnotationAnomaliesCard: FC<IAnnotationAnomalyCard> = (params: IAnnotationAnomalyCard) => {
    const { handleMagnifierSize } = useAnnotations();

    const [openButton, setOpenButton] = useState(false);
    const anchorButton = useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<string>('Anomalia');
    const [openPhoto, setOpenPhoto] = useState<boolean>(false);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        choice: string,
    ) => {
        if(choice === 'is_anomaly')
            setSelectedOption('Anomalia');
        else if(choice === 'external')
            setSelectedOption('Externa');
        else if(choice === 'internal')
            setSelectedOption('Interna');
        setOpenButton(false);

        let auxAnomaly: IAnomaly = params.anomaly;
        
        params.onSelectAnomaly({
            ...auxAnomaly,
            isAnomaly: choice === 'is_anomaly' ? true : false,
            isExternal: choice !== 'is_anomaly' ? (choice === 'external' ? true : false) : true 
        });
    };

    const handleToggle = () => {
        setOpenButton((prevOpenButton) => !prevOpenButton);
    };

    const handleCorrect = () => {
        params.onSelectAnomaly(params.anomaly);
    };

    const handleClose = (event: Event) => {
        if (
            anchorButton.current &&
            anchorButton.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenButton(false);
    };

    return (
        <Grid container width="300px" height="auto" color='#000'>
            <Grid container item width="100%" sx={{ backgroundColor: theme.palette.primary.dark }} borderRadius='6px 6px 0 0' alignItems='center' justifyContent='center'>
                <Grid container item xs={2} alignItems='center' justifyContent='center'>
                    <Checkbox checked={params.isSelected} style={{ color: theme.palette.background.paper }} onClick={handleCorrect} />
                </Grid>
                <Grid container item xs={8} alignItems='center' justifyContent='center'>
                    <Text medium color={theme.palette.background.paper}>Anomalia</Text>
                </Grid>
                <Grid container item xs={1} alignItems='center' justifyContent='end' >
                    <IconButton size="medium" onClick={() => handleMagnifierSize(true)} style={{ color: theme.palette.background.paper }} >
                        <ZoomInIcon />
                    </IconButton>
                </Grid>
                <Grid container item xs={1} alignItems='center' justifyContent='end'>
                    <IconButton size="medium" onClick={() => handleMagnifierSize(false)} style={{ color: theme.palette.background.paper }} >
                        <ZoomOutIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container item xs={12} boxShadow='5px 5px 15px 0px rgba(0,0,0,0.25)'>
                <CarcassPhoto hideZoomButtons photoUri={params.anomaly.photoUri || ""} />
            </Grid>
            <Grid container item xs={12} width="100%" height="35px" borderRadius='0 0 6px 6px' sx={{backgroundColor: theme.palette.primary.dark }}  alignItems='center' justifyContent='center'>
                <Text medium size={14} color={theme.palette.background.paper}>{moment(params.anomaly.date).format('DD/MM/YYYY HH:mm:ss')} </Text>
            </Grid>
            <Spacing top={PADDING} />

            <Grid container item >
                <Grid container item justifyContent='center' alignItems='center'  sx={{paddingTop: '8px'}}>
                    <ButtonGroup variant="contained" ref={anchorButton} aria-label="split button" sx={{paddingTop: '1px'}}>
                        <Button onClick={handleToggle} color={params.isSelected ? 'success' : 'primary'}> 
                            <Text color={theme.palette.background.paper} size={13} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {selectedOption}
                            </Text>
                        </Button>
                        <Button
                            size="small"
                            aria-controls={openButton ? 'split-button-menu' : undefined}
                            aria-expanded={openButton ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Spacing left={PADDING} />
                    <Button variant="contained" onClick={() => setOpenPhoto(true)} sx={{ backgroundColor: theme.palette.info.dark, width: '100px' }}>
                        <Text color={theme.palette.background.paper} size={13}>Detalhar</Text>
                    </Button>
                </Grid>
            </Grid>
            <Popper
                open={openButton}
                anchorEl={anchorButton.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 100 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem >
                                    <MenuItem
                                        key={'is_anomaly'}
                                        selected={'is_anomaly' === selectedOption}
                                        onClick={(event) => handleMenuItemClick(event, 'is_anomaly')}>
                                        <Text size={13} color={theme.palette.primary.main}>
                                            Anomalia
                                        </Text>
                                    </MenuItem>
                                    <MenuItem
                                        key={'external'}
                                        selected={'external' === selectedOption}
                                        onClick={(event) => handleMenuItemClick(event, 'external')}>
                                        <Text size={13} color={theme.palette.primary.main}>
                                            Externa
                                        </Text>
                                    </MenuItem>
                                    <MenuItem
                                        key={'internal'}
                                        selected={'internal' === selectedOption}
                                        onClick={(event) => handleMenuItemClick(event, 'internal')}>
                                        <Text size={13} color={theme.palette.primary.main}>
                                            Interna
                                        </Text>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {openPhoto && params.anomaly.photoUri &&
                <Lightbox
                    mainSrc={params.anomaly.photoUri}
                    onCloseRequest={() => setOpenPhoto(false)}
                    zoomInLabel="Mais zoom"
                    zoomOutLabel="Menos zoom" />
            }
        </Grid>
    );
}

export default AnnotationAnomaliesCard;