import React from 'react';
import { Modal as MuiModal } from '@mui/material';

const Modal = React.forwardRef<unknown, any>((props: unknown, ref: any) => {
    const handleClose = (event: any, reason: any) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown")
            return;
        (props as any).onClose();
    }

    return (
        <MuiModal
            ref={ref}
            open={(props as any).isOpened}
            onClose={handleClose}
            closeAfterTransition
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 15,
                overflowY: 'auto',
                ...(props as any).style
            }}
        >
            <>
                {(props as any).children}
            </>
        </MuiModal>
    );
});

export default Modal;
