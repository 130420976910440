import React, { FC, useState, useEffect } from "react";
import { Button, Grid, } from "@mui/material";

import BranchesMultiSelect from "../../branches/multiSelect";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import Input from "../../../atoms/input";
import { useInformation } from "../../../../hooks/information";
import { IAnomalyFilter, IBranch } from "../../../../types";
import { IAnomaliesFilter } from "../../../../types/components/molecules/anomalies";
import { PADDING } from "../../../../utils/consts";
import DateRangePicker from "../../../atoms/dateRangePicker";

const AnnotationsAnomaliesFilter: FC<IAnomaliesFilter> = (params: IAnomaliesFilter) => {
    const { hideInformation, showInformation } = useInformation();

    const [initialDate, setInitialDate] = useState<Date | null>(params.filter.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(params.filter.finalDate);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(params.filter.branches);
    const [limit, setLimit] = useState<number>(params.filter.limit);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);

    useEffect(() => {
        setInitialDate(params.filter.initialDate)
        setFinalDate(params.filter.finalDate)
        setSelectedBranches(params.filter.branches)
        setLimit(params.filter.limit)
    }, [params.filter]);

    const validate = () => {
        let isValid: boolean = true;
    
        if (!initialDate || !finalDate) {
            showInformation('Selecione um intervalo de data.', 'Aviso', undefined, () => {
                hideInformation();
            })
            isValid = false;
        }
    
        if (selectedBranches.length === 0) {
            showInformation('Selecione uma filial', 'Aviso', undefined, () => {
                hideInformation();
            })
          isValid = false;
        }

        return isValid;
    };
    
    const onApplyFilter = () => {
        setBranchesExpanded(false);

        if (validate() == false) return;

        let auxFilter: IAnomalyFilter = {
            initialDate,
            finalDate,
            branches: selectedBranches,
            limit: limit,
        }
        
        params.onApplyFilter(auxFilter);
    }

    const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        let limit: number = parseInt(event.target.value);
        if(limit > 100){
            event.target.value = '99';
            setLimit(99);
        }else{
            setLimit(limit); 
        }
    }

    return (
        <Container fluid flex verticalCentered minHeight="50px">
            <Text size={15} medium>Filtros</Text>
            <Spacing top={PADDING} />
            <Container inline flex flexGrow horizontalCentered>
                <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                    <Grid item xs={4} sm={5} md={3} lg={4} xl={2.5}>
                        <DateRangePicker
                            initialDate={initialDate}
                            finalDate={finalDate}
                            placeholder={"Escolha o intervalo de data"}
                            setInitialDate={setInitialDate}
                            setFinalDate={setFinalDate}
                            disabled={false}
                            maxDaysSelect={0}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} lg={4} xl={2}>
                        <BranchesMultiSelect
                            selectedBranches={selectedBranches}
                            setSelectedBranches={setSelectedBranches}
                            expanded={branchesExpanded}
                            setExpanded={setBranchesExpanded}
                            singleSelect
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '40px',
                                marginTop: -2,
                                alignItems:'center',
                                
                            }}
                        />
                    </Grid>
                    <Grid item lg={4} xl={2}>
                        <Input
                            id="limit"
                            autoFocus
                            variant="outlined"
                            onChange={handleChangeLimit}
                            placeholder='Limite de Exibição'
                            backgroundColor={theme.palette.background.default}
                            sx={{
                                height: '37px',
                                backgroundColor: '#FFF',                                        
                                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                                borderRadius: '4px',
                                width: '100%',
                                minWidth: '50px'
                            }} />
                    </Grid>
                    <Grid container item xs={1} sm={3} lg={4} xl={2}>
                        <Button size="small" variant="contained" onClick={onApplyFilter} sx={{ backgroundColor: theme.palette.primary.dark }}>
                            <Text size={13} color={theme.palette.background.paper}>Aplicar</Text>
                        </Button>
                        <Spacing left={PADDING}></Spacing>
                        <Button size="small" variant="contained" onClick={params.onCleanFilter} sx={{ backgroundColor: theme.palette.primary.dark }}>
                            <Text size={13} color={theme.palette.background.paper}>Limpar</Text>
                        </Button>
                    </Grid>
                    
                    <Grid container item xs={12} sm={12} lg={4} xl={3} >
                        <Button size="small" variant="outlined" color="primary" onClick={params.onDownloadData}>
                            <Text medium size={13} color={theme.palette.primary.main}>Baixar imagens</Text>
                        </Button>
                        <Spacing right={PADDING}></Spacing>
                        <Button size="small" variant="outlined" color="primary" onClick={params.onReinsertAnomalies}>
                            <Text medium size={13} color={theme.palette.primary.main}>Reinserir falsas anomalias</Text>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default AnnotationsAnomaliesFilter;