

import React, { FC, useEffect, useState } from 'react';

import { Bar, BarChart, CartesianGrid, Label, Legend, Tooltip, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { IDashboardCharts } from '../../../../types';
import { theme } from '../../../../components'

const DashboardDentitionBarChart: FC<IDashboardCharts> = (params: IDashboardCharts) => {
    const [data, setData] = useState<any[]>([]);
    const [colors, setColors] = useState<string[]>([theme.palette.primary.main, '#875352', '#FFAE00', '#FF8042', '#E58A00', '#4D5A80']);

    useEffect(() => {
        if(params.data !== undefined){
            setData(params.data)
        }
    }, [params.data])

    useEffect(() => {
        if(params.colors !== undefined){
            setColors(params.colors)
        }
    }, [params.colors])

    return (
        <ResponsiveContainer height={220}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Label fill='white'/>
                <Bar name='Quantidade de carcaças' dataKey="value" fill={colors[0]} legendType='circle' isAnimationActive={true}/>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default DashboardDentitionBarChart;

