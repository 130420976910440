import moment from 'moment';
import { createContext, FC, useContext, useState } from 'react';

import {
  getCarcassesCountByClassification,
  getCarcassesCountByDentition,
  getCarcassesCountByFrigobomTypification,
  getCarcassesCountBySex,
  getCarcassesCountByWeight,
  getDashboardCounts,
} from '../services/dashboard';

import {
    IDashboardBarChartData,
    IDashboardContext,
    IDashboardFilterData,
    IDashboardPieChartData,
} from '../types';
import { useLoading } from './loading';

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

export const DashboardProvider: FC<any> = ({ children }) => {
    const [carcassAllTimeCount, setCarcassAllTimeCount] = useState<number>(0);
    const [carcassAllTimeBestPlacedCount, setCarcassAllTimeBestPlacedCount] = useState<number>(0);
    const [carcassAverageWeight, setCarcassAverageWeight] = useState<number>(0.0);
    const [carcassIntegratedWithERPCount, setCarcassIntegratedWithERPCount] = useState<number>(0);
    const [carcassesCountByClassification, setCarcassesCountByClassification] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByDentition, setCarcassesCountByDentition] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByWeight, setCarcassesCountByWeight] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountBySex, setCarcassesCountBySex] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByTypification, setCarcassesCountByTypification] = useState<IDashboardBarChartData[]>([]);
    const [annotationsThisWeekCount, setAnnotationsThisWeekCount] = useState<number>(0);
    const [revisionsThisWeekCount, setRevisionsThisWeekCount] = useState<number>(0);
    const [carcassesSlaughterScheduleCount, setCarcassesSlaughterScheduleCount] = useState<number | undefined>(undefined);

    const [carcassesCountByFrigobomTypification, setCarcassesCountByFrigobomTypification] = useState<IDashboardBarChartData[]>([]);

    const { hideLoading } = useLoading();
    const [filter, setFilter] = useState<IDashboardFilterData>(
        {
            initialDate: new Date(),
            finalDate: new Date(),
            branches: [],
            farms: [],
            lotNums: [],
            dentitions: [],
            weightIntervals: [],
            sexes: [],
            pops: [],
        }
    );

    const [loadingFetchingDashboardData, setLoadingFetchingDashboardData] = useState<boolean>(false);

    const fetchDashboardCounts = async (_query: string) => {
        let data = await getDashboardCounts(_query);
        setCarcassAllTimeCount(data?.carcassCountAllTime?.count || 0);
        setCarcassAllTimeBestPlacedCount(data?.carcassCountAllTimeBestPlaced?.count || 0);
        setCarcassAverageWeight(data?.carcassAverageWeight?.avgWeight || 0);
        setCarcassIntegratedWithERPCount(data?.carcassCountIntegratedWithErp?.count || 0);

        // For slaughter schedules, we do not have data about each carcass, so
        // set it as undefined if any special filter is selected
        if ((!filter.farms || filter.farms.length === 0) &&
            (!filter.lotNums || filter.lotNums.length === 0) &&
            (!filter.dentitions || filter.dentitions.length === 0) &&
            (!filter.weightIntervals || filter.weightIntervals.length === 0) &&
            (!filter.sexes || filter.sexes.length === 0)
        )
            setCarcassesSlaughterScheduleCount(data?.carcassCountSlaughterSchedule || 0);
        else
            setCarcassesSlaughterScheduleCount(undefined);
    }

    const fetchCarcassesCountByClassification = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountByClassification(_query);
        setCarcassesCountByClassification(data);
    }
    const fetchCarcassesCountByDentition = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountByDentition(_query);
        setCarcassesCountByDentition(data);
    }
    const fetchCarcassesCountByWeight = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountByWeight(_query);
        setCarcassesCountByWeight(data);
    }
    const fetchCarcassesCountBySex = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountBySex(_query);
        setCarcassesCountBySex(data);
    }
    const fetchCarcassesCountByFrigobomTypification = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountByFrigobomTypification(_query);
        setCarcassesCountByFrigobomTypification(data);
    }

    const fetchDashboardData = async (_filter: IDashboardFilterData) => {
        if (loadingFetchingDashboardData)
            return;
        setLoadingFetchingDashboardData(true);
        let query: string = '';
        if (_filter.initialDate)
            query += ((query == '') ? '?' : '&') + `initial_date=${moment(_filter.initialDate).format('YYYY-MM-DD')}`;
        if (_filter.finalDate)
            query += ((query == '') ? '?' : '&') + `final_date=${moment(_filter.finalDate).format('YYYY-MM-DD')}`;
        if (_filter.branches.length > 0)
            query += ((query == '') ? '?' : '&') + `branches=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
        if (_filter.farms != null && _filter.farms.length > 0)
            query += ((query == '') ? '?' : '&') + `farms=${_filter.farms.map(item => `'${item}'`).join(',')}`;
        if (_filter.lotNums != null && _filter.lotNums.length > 0)
            query += ((query == '') ? '?' : '&') + `lot_nums=${_filter.lotNums.map(item => `'${item}'`).join(',')}`;
        if (_filter.dentitions != null && _filter.dentitions.length > 0)
            query += ((query == '') ? '?' : '&') + `dentitions=${_filter.dentitions.map(item => `${item}`).join(',')}`;
        if (_filter.weightIntervals != null && _filter.weightIntervals.length > 0)
            query += ((query == '') ? '?' : '&') + `weight_intervals=${_filter.weightIntervals.map(item => `${item}`).join(',')}`;
        if (_filter.sexes != null && _filter.sexes.length > 0)
            query += ((query == '') ? '?' : '&') + `sexes=${_filter.sexes.map(item => `${item}`).join(',')}`;

        setCarcassAllTimeCount(0);
        setCarcassAllTimeBestPlacedCount(0);
        setCarcassAverageWeight(0.0);
        setCarcassIntegratedWithERPCount(0);
        setAnnotationsThisWeekCount(0);
        setRevisionsThisWeekCount(0);
        setCarcassesSlaughterScheduleCount(undefined);

        setCarcassesCountByClassification([]);
        setCarcassesCountByDentition([]);
        setCarcassesCountByWeight([]);
        setCarcassesCountBySex([]);
        setCarcassesCountByTypification([]);
        setCarcassesCountByFrigobomTypification([]);

        const promiseDashboardCounts = new Promise(async function (resolve, reject) {
            try {
                fetchDashboardCounts(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByClassification = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassesCountByClassification(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByDentition = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassesCountByDentition(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByWeight = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassesCountByWeight(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountBySex = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassesCountBySex(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByFrigobomTypification = new Promise(async function (resolve, reject) {
            try {
                fetchCarcassesCountByFrigobomTypification(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });

        Promise.all([
            promiseDashboardCounts,
            promiseCarcassesCountByClassification,
            promiseCarcassesCountByDentition,
            promiseCarcassesCountByWeight,
            promiseCarcassesCountBySex,
            promiseCarcassesCountByFrigobomTypification,
        ]).then(res => {
            hideLoading();
            setLoadingFetchingDashboardData(false);
        }).catch(err => {
            hideLoading();
            setLoadingFetchingDashboardData(false);
            console.error(err);
        })
    }

    return (
        <DashboardContext.Provider value={{
            carcassAllTimeCount,
            carcassAllTimeBestPlacedCount,
            carcassAverageWeight,
            carcassIntegratedWithERPCount,
            carcassesCountByClassification,
            carcassesCountByDentition,
            carcassesCountByWeight,
            carcassesCountBySex,
            carcassesCountByTypification,
            carcassesCountByFrigobomTypification,
            carcassesSlaughterScheduleCount,
            annotationsThisWeekCount,
            revisionsThisWeekCount,

            loading: loadingFetchingDashboardData,

            fetchDashboardData,

            filter,
            setFilter
        }} >
            {children}
        </DashboardContext.Provider>
    )
}

export function useDashboard() {
    const context = useContext(DashboardContext);

    if (!context) {
        throw new Error('useDashboard must be used within an DashboardProvider');
    }

    return context
}
