import React, { FC } from "react";
import {
  Button,
  Divider,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import Text from "../../atoms/text";
import theme from "../../theme";
import { ICheckboxMultiselect, ILabelId } from "../../../types";


const CheckboxMultiselect: FC<ICheckboxMultiselect> = (params: ICheckboxMultiselect) => {
    const onChange = (event: SelectChangeEvent<number[]>) => {
        const { value } = event.target;
        params.setValue((value as number[]).sort());
    };
    return (
        <Select
            multiple
            displayEmpty={!!params.placeholder}
            disabled={params.disabled}
            value={params.value}
            onChange={onChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (params.placeholder && (!selected || selected.length === 0))
                    return <Text muted>{params.placeholder}</Text>;
                return params.options!.filter(
                    item => selected.includes(item.id)
                ).map(item => item.label).join(', ');
            }}
            sx={{
                minWidth:"100%",
                height: '37px',
                backgroundColor: '#FFF',
                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                borderRadius: '4px',
                marginTop: '0px',
                ...params.sx,
            }}
        >   
            <MenuItem disabled value="" sx={{ fontStyle: 'italic' }}>
                {params.placeholder}
            </MenuItem>
            {params.options && params.options.map((item) => (
                <MenuItem key={item.id} value={item.id} disabled={params.disabled}>
                    <Checkbox
                        disabled={params.disabled}
                        checked={params.value.indexOf(item.id) > -1}
                    />
                    <ListItemText primary={item.label} />
                </MenuItem>
            ))}
            {params.options && (
                <div>
                    <Divider sx={{ width: "100%" }} />
                    <MenuItem value="">
                        <Button startIcon={<DoneAllIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setValue(params.options!.map(item => item.id))}
                            sx={{width: '100%'}}
                        >
                            <Text size={15}>Marcar todos</Text>
                        </Button>
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setValue([])}
                            sx={{width: '100%'}}
                        >
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </MenuItem>
                </div>
            )}
        </Select>
    )
}

export default CheckboxMultiselect;
