import React, { FC } from 'react';
import Grid from '@mui/material/Grid';

import { Container, Page, theme } from '../../../components';
import { Title1, BodyText } from '../../../components/atoms/text';
import AlertsCRUDTable from './table';


const AlertSetup: FC = () => {
    return (
        <Page width='auto'>
            <Container fluid flex color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline style={{
                    flex: '0',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Configuração de Alertas</Title1>
                        <BodyText muted>Alertas / Configuração de Alertas</BodyText>
                    </Container>
                </Container>
                <Grid container>
                    <Grid item xs={12}>
                        <AlertsCRUDTable />
                    </Grid>
                </Grid>
            </Container>
        </Page >
    )
}

export default AlertSetup;
