export enum ISocketHookStatus {
  // Possible SocketHook statuses

  IDLE,         // not connected; initial state
  CONNECTING,   // attempting to connect/reconnect
  OPEN,         // connected
  LOADING,      // processing message received
  ERROR,        // after a onerror event
  CLOSE         // after a onclose event
}


interface ISocketHookContext<T> {
  // What is provided by the SocketHook

  data: T;
  setData: React.Dispatch<React.SetStateAction<T>>;
  socket?: WebSocket;
  hookStatus: ISocketHookStatus;
  setHookStatus: React.Dispatch<React.SetStateAction<ISocketHookStatus>>;
  send: (payload: any) => void;

  // recreates socket
  reconnect: () => void;

  /* close socket and do NOT attempt to reconnect. This is the only way to
   * prevent a reconnection apart from using "noReconnect" (see below).
   */
  closeForcibly: () => void;
}

interface ISocketHookOnOpenCallback<T> extends ISocketHookContext<T> {};
interface ISocketHookOnReconnectCallback<T> extends ISocketHookContext<T> {};
interface ISocketHookOnMessageCallback<T> extends ISocketHookContext<T> {
  event: MessageEvent<any>;
  eventData: any;
};
interface ISocketHookOnErrorCallback<T> extends ISocketHookContext<T> {
  error: Event;
};
interface ISocketHookOnCloseCallback<T> extends ISocketHookContext<T> {
  event: CloseEvent;
};

interface ISocketHook<T> {
  // Interface for createSocketHook()

  // Socket URL (back-end)
  url: string;

  /* Initial/reset data state. In case of error/closed connection, data is
   * resetted
   */
  defaultData: T;

  /* Methods called after each state change.
   * NOTE: onOpenCallback will be called on reconnect event if
   * onReconnectCallback is not specified. Otherwise, onOpenCallback will NOT
   * be called when reconnecting, only the onReconnectCallback method will be
   * executed.
   */
  onMessageCallback?: (params: ISocketHookOnMessageCallback<T>) => void;
  onErrorCallback?: (params: ISocketHookOnErrorCallback<T>) => void;
  onOpenCallback?: (params: ISocketHookOnOpenCallback<T>) => void;
  onCloseCallback?: (params: ISocketHookOnCloseCallback<T>) => void;
  onReconnectCallback?: (params: ISocketHookOnReconnectCallback<T>) => void;

  // Removes the reconnection behaviour. That means the connection will remain
  // closed until a new connection operation is performed.
  noReconnect?: boolean;

  // Interval (in ms) to attempt a failed reconnection
  reconnectAttemptInterval?: number;

  // max attempts to (re)create a connection
  maxReconnectRetries?: number;
}

export type {
  ISocketHook,
  ISocketHookContext,
  ISocketHookOnOpenCallback,
  ISocketHookOnReconnectCallback,
  ISocketHookOnMessageCallback,
  ISocketHookOnErrorCallback,
  ISocketHookOnCloseCallback,
}
