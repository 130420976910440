import { FC } from 'react';

import { ISlaughterDelaysMainCausesEntry } from '../../../../types/hooks/slaughterDelays';
import { useSlaughterDelays } from '../../../../hooks/slaughterDelays';
import { useSlaughterDelaysFilter } from '../../../../hooks/slaughterDelaysFilter';
import SlaughterDelayMainCausesChart from './mainCausesChart';


const SlaughterDelayMainLocationsChart: FC = () => {
    const {
        groupByDuration,
        mainLocations,
        fetchDashboard,
    } = useSlaughterDelays();
    const { setLocations, branch } = useSlaughterDelaysFilter();

    const data = (groupByDuration ?
        mainLocations.duration : mainLocations.occurrences
    );

    function handleOnClick(value: ISlaughterDelaysMainCausesEntry) {
        const newLocations = [{
            branchId: branch?.id || '',
            id: value.id,
            code: value.code,
            description: value.description,
        }];
        setLocations(newLocations);
        fetchDashboard({locations: newLocations});
    };

    return <SlaughterDelayMainCausesChart
        data={data}
        onClick={(val) => handleOnClick(val)}
    />;
}

export default SlaughterDelayMainLocationsChart;
