import api from "./";
import {
  IDashboardBarChartData,
  IDashboardPieChartData,
  IDashboardChartData,
} from "../types";

const baseUrl: string = "/api_redsoft/v1_0/dashboard";

export const getDashboardCounts = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/dashboard_counts${_query}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count = response.data;
      return count;
    }
  }
  return 0;
};

export const getCarcassesCountByClassification = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_classifications_count${_query}`
  );

  const data: IDashboardPieChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardPieChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountByDentition = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_dentition_count${_query}`
  );

  const data: IDashboardBarChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardBarChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountByWeight = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_weight_count${_query}`
  );

  const data: IDashboardPieChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardPieChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountBySex = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_sex_count${_query}`
  );

  const data: IDashboardPieChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardPieChart(response.data[i]));
      }
    }
  }
  return data;
};

export const getCarcassesCountByFrigobomTypification = async (_query: string) => {
  const response: any = await api.get(
    `${baseUrl}/carcasses_frigobom_typification_count${_query}`
  );

  const data: IDashboardPieChartData[] = [];
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      for (let i = 0; i < response.data.length; i++) {
        data.push(convertApiResponseToIDashboardPieChart(response.data[i]));
      }
    }
  }
  return data;
};

export const convertApiResponseToIDashboardBarChart = (_data: any) => {
  const data: IDashboardBarChartData = {
    name: _data.name,
    value: _data.count,
    type: _data.type,
  };
  return data;
};

export const convertApiResponseToIDashboardPieChart = (_data: any) => {
  const data: IDashboardPieChartData = {
    name: _data.name,
    name2: _data.name2,
    value: _data.count,
    type: _data.type,
  };
  return data;
};

export const convertApiResponseToIDashboardChart = (_data: any) => {
  const data: IDashboardChartData = {
    name: _data.name,
    value: _data.count,
    valueB: _data.countB,
  };
  return data;
};
