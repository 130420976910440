/* CRUDHook
Used to create CRUD hooks.
Note that some methods can be overriden.

Params:
- baseURL: base URL to use for fetch purposes. Usually will start as
'/api_redsoft/v1_0/';
- name: used for error messages. Right now, the messages are in Portuguese;
- convertResponseToModel: method to convert API response;
- createMethod: overrides the CRUDService's create method;
- fetchMethod: overrides the CRUDService's fetch method;
- fetchAllMethod: overrides the CRUDService's fetchAll method;
- updateMethod: overrides the CRUDService's update method;
- deleteMethod: overrides the CRUDService's delete method;
- loadingTimeout: milliseconds for loading timeout. Prevents hook from keeping
stuck in 'loading'.
*/

import React, { FC, createContext, useContext, useState } from 'react'
import CRUDService from '../services/crud';

import { ICRUDContext } from '../types'
import { IEntity } from '../types';

interface ICRUDHook<IEntryType extends IEntity> {
    baseURL: string;
    name?: string,
    convertResponseToModel?: (data: any) => any;
    createMethod?: (_payload: IEntryType) => Promise<IEntryType>;
    fetchMethod?: (_id: string) => Promise<IEntryType>;
    fetchAllMethod?: () => Promise<IEntryType[]>;
    updateMethod?: (_id: string, _payload: IEntryType) => Promise<IEntryType>;
    deleteMethod?: (_id: string) => Promise<IEntryType>;
    loadingTimeout?: number;
}

export default function CRUDHook<IEntryType extends IEntity>({
    baseURL,
    name,
    convertResponseToModel,
    createMethod,
    fetchMethod,
    fetchAllMethod,
    updateMethod,
    deleteMethod,
    loadingTimeout=6000,
}: ICRUDHook<IEntryType>) {
    const crudContext = createContext<ICRUDContext<IEntryType>>({} as ICRUDContext<IEntryType>);

    const crudProvider: FC<any> = ({ children }) => {
        const [entries, setEntries] = useState<IEntryType[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [search, setSearch] = useState<string>('');
        const [perRow, setPerRow] = useState<string>('15');
        const [paginate, setPaginate] = useState<number>(0);

        const {
            crudCreate,
            crudFetch,
            crudFetchAll,
            crudUpdate,
            crudDelete
        } = CRUDService({baseURL, name, convertResponseToModel});

        const executeLoading = async (_func: (params?: any) => any, params?: any) => {
            // Encapsulates function in a loading state
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, loadingTimeout);
            try {
                const res = await _func(params);
                setLoading(false);
                return res;
            } finally {
                setLoading(false);
            };
        }

        const fetchAllEntries = async () => {
            return executeLoading(async () => {
                let res: IEntryType[] = [];
                if (fetchAllMethod)
                    res = await fetchAllMethod();
                else
                    res = await crudFetchAll();
                if (res) {
                    setEntries([...res]);
                    return res;
                }
            });
        };

        const fetchEntry = async (_id: string) => {
            return executeLoading(async () => {
                let res: IEntryType | null = null;
                if (fetchMethod)
                    res = await fetchMethod(_id);
                else
                    res = await crudFetch(_id);
                if (res)
                    return res;
            });
        };

        const createEntry = async (_payload: IEntryType) => {
            return executeLoading(async () => {
                let res: IEntryType | null = null;
                if (createMethod)
                    res = await createMethod(_payload);
                else
                    res = await crudCreate(_payload);
                if (res)
                    return res;
            });
        };

        const updateEntry = async (_id: string, _payload: IEntryType) => {
            return executeLoading(async () => {
                let res: IEntryType | null = null;
                if (updateMethod)
                    res = await updateMethod(_id, _payload);
                else
                    res = await crudUpdate(_id, _payload);
                if (res)
                    return res;
            });
        };

        const deleteEntry = async (_id: string) => {
            return executeLoading(async () => {
                let res: IEntryType | null = null;
                if (deleteMethod)
                    res = await deleteMethod(_id);
                else
                    res = await crudDelete(_id);
                if (res)
                    return res;
            });
        }

        return (
            <crudContext.Provider value={{
                entries,
                setEntries,
                loading,
                setLoading,
                executeLoading,
                fetchAllEntries,
                createEntry,
                updateEntry,
                deleteEntry,
                fetchEntry,
                search,
                setSearch,
                perRow,
                setPerRow,
                paginate,
                setPaginate,
            }}>{children}</crudContext.Provider>
        )
    }

    function crudHook() {
        const context = useContext(crudContext);

        if (!context) {
            throw new Error('CRUD Context must be used within an CRUDProvider')
        }

        return context;
    }

    return { crudProvider, crudHook }
};
