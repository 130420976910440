import React, { FC } from "react";

import { ICarcass } from "../../../../../types";
import { ICarcassListItem } from "../../../../../types";
import { BodyText, Title3 } from "../../../../atoms/text";
import { colors } from "../../../../theme/styles";
import styled from "@emotion/styled/macro";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { usePopVisualizationDownload } from '../../../../../hooks/popVisualizationDownload';


const CarcassListItemCard = styled(Card)(({}) => ({
    borderRadius: '8px',
    background: 'none',
}));


const CarcassListItemBox = styled(CardContent)(({}) => ({
    cursor: 'pointer',
    "&:hover": {
        background: colors.grayLight,
    },
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px !important',
}));


const CarcassListActiveItemBox = styled(CarcassListItemBox)(({}) => ({
    background: colors.primary,
    cursor: 'default',
    "&:hover": {
        background: colors.primary,
    },
}));

const renderSequenceItemTitle = (carcass: ICarcass) => {
    if(!carcass.partnerId && carcass.sequence)
        return `Ordinal ${carcass.sequence}`;
    return `Sequencial Local ${carcass.partnerId}`;
}

const CarcassPopListItem: FC<ICarcassListItem> = (params: ICarcassListItem) => {
    const { data } = usePopVisualizationDownload();
    const isSelectedToDownload = !!(data.find((entry) => entry.id === params.carcass!.id));
    let cardStyle: any = {};
    if (isSelectedToDownload)
        cardStyle.borderLeft = '5px solid #FFAE00';

    const children = (<>
        <Title3
            semiBold={params.active}
            color={params.active ? 'white' : colors.textColor}
            style={{ whiteSpace: 'nowrap' }}
        >
            {renderSequenceItemTitle(params.carcass)}
        </Title3>
    </>);

    if (params.active)
        return (
            <CarcassListItemCard variant="outlined" sx={cardStyle}>
                <CarcassListActiveItemBox>
                    {children}
                </CarcassListActiveItemBox>
            </CarcassListItemCard>
        )

    return (
        <CarcassListItemCard variant="outlined" sx={cardStyle}>
            <CarcassListItemBox onClick={() => params.setCurrCarcass()}>
                {children}
            </CarcassListItemBox>
        </CarcassListItemCard>
    );
}

export default CarcassPopListItem;
