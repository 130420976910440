import { ITypification } from '../types';
import { baseURL } from "../services/typifications";
import CRUDHook from "./crud";

export const { crudProvider: TypificationsProvider, crudHook: useTypifications } = CRUDHook<ITypification>(
    {
        baseURL,
        name: 'tipificação',
    }
)
