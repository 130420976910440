import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { FC, useState } from 'react';
import 'react-image-lightbox/style.css';

import Container from '../../../../atoms/container';
import theme from '../../../../theme';
import CarcassPopsImages from './CarcassPopsImages';

import logoIcon from '../../../../../assets/logo.png';
import { Image } from '../../../../../components/';

import { ICarcassDetailedItem, ICarcassesPop } from '../../../../../types';
import { BodyText, Title1, Title3, Title4 } from '../../../../atoms/text';

import {
    usePopVisualizationBoxes
} from '../../../../../hooks/popVisualizationBoxes';
import { PopGroups, PopGroupIdPermissionRelation } from '../../../../../utils/pops';
import { useAuth } from '../../../../../hooks/auth';


// Represents a generic state for each POP Group (i.e. group0, group1, group2 ... groupN) for each carcass side (A, B);
// If we add more groups we will not need to change here (I hope).
type ExpandedState = {
    [side in 'A' | 'B']: {
        [groupId: string]: boolean
    }
}

/* Card displaying exibition options for POPs (i.e. checkboxes toggles and individual states) */
const CarcassPopDetailedItem: FC<ICarcassDetailedItem> = (params: ICarcassDetailedItem) => {
    const { roles } = useAuth();
    // Initialize the state with no groups defined.
    // Every time an accordion is expanded the first time we will add the groupId to the state.
    // Before that the value will be undefined, that stands for "False", thus the accordion starts collapsed.
    const [expanded, setExpanded] = useState<ExpandedState>({
        A: {},
        B: {},
    });

    const handleExpand = (side: 'A' | 'B', groupId: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [side]: {
                ...prevExpanded[side],
                [groupId]: isExpanded,
            },
        }));
    };

    const {
        isPopDisabled,
        isPopGroupDisabled,
        clear,
        togglePopBoxes,
        togglePopBoxesByGroup,
        setDisabledBoundingBoxes,
        disabledBoundingBoxes,
        isLabelActive,
        setIsLabelActive,
    } = usePopVisualizationBoxes();

    function disableAllBoundingBoxes() {
        let disabledPops: number[] = [];
        if (params.carcass?.popsA) {
            disabledPops = disabledPops.concat(
                params.carcass.popsA.filter(
                    pop => !disabledPops.includes(pop.popType!)
                ).map(pop => pop.popType!));
        }
        if (params.carcass?.popsB) {
            disabledPops = disabledPops.concat(
                params.carcass.popsB.filter(
                    pop => !disabledPops.includes(pop.popType!)
                ).map(pop => pop.popType!));
        }
        setDisabledBoundingBoxes(disabledPops);
        return;
    }

    function toggleEnableAllBoundingBoxes() {
        if (disabledBoundingBoxes.length === 0) {
            disableAllBoundingBoxes();
            return;
        }
        clear();
        return;
    }

    function togglePopBoxesByGroupId(popGroupId: number) {
        togglePopBoxesByGroup(PopGroups[popGroupId]);
    }

    type GroupedPops = Record<string, ICarcassesPop[]>;


    // Group POPs based on their groupId
    const groupPops = (popsA?: ICarcassesPop[], popsB?: ICarcassesPop[]) => {
        const pops = [...(popsA ?? []), ...(popsB ?? [])];
        const groupedPops = pops.reduce((res: GroupedPops, pop: ICarcassesPop) => {
            const key = pop.popGroup && pop.popGroup.id !== null && pop.popGroup.name !== null ? pop.popGroup.id : "Outros";
            if (!res[key]) {
                res[key] = [];
            }
            res[key].push(pop);

            res[key] = res[key].sort((a, b) => {
                const aPopType = a.popType !== undefined ? Number(a.popType) : -Infinity;
                const bPopType = b.popType !== undefined ? Number(b.popType) : -Infinity;
                return aPopType - bPopType;
            });
            return res;
        }, {});
        return groupedPops;
    }


    function renderCheckboxClassificationColor(pop?: ICarcassesPop) {
        if (!pop) {
            return theme.palette.grey[600]
        }

        if (typeof pop.count === 'number' || pop.classification === 0) {
            return theme.palette.success.dark
        }
        else if (pop.classification === 1) {
            return theme.palette.error.light
        }

        return theme.palette.grey[600]

    }

    function renderCheckboxClassification(pop: ICarcassesPop) {
        if (typeof pop.count === 'number') {
            return pop.count.toString()
        }
        else {
            if (pop.classification === 0) {
                return "Conforme"
            }
            else if (pop.classification === 1) {
                return "Não Conforme"
            }

        }
        return "Não Avaliado"
    }

    const renderCheckBoxes = (pops?: ICarcassesPop[], popSide?: 'A' | 'B') => {
        if (!pops || pops.length === 0) {
            return null
        }

        if (popSide === undefined) {
            return null
        }

        const groupedPops = groupPops(pops);
        return (
            <>
                {Object.entries(groupedPops).map(([groupId, popsInGroup]: [string, ICarcassesPop[]]) => {
                    const displayName = groupId === "Outros" ? "Outros" : popsInGroup[0].popGroup?.name ?? "Outros";

                    groupId = groupId + popSide;
                    return (
                        <Accordion
                            key={`${groupId}-${popSide}`}
                            expanded={expanded[popSide][groupId]}
                            onChange={handleExpand(popSide, groupId)}
                            disableGutters={true}
                            sx={{
                                boxShadow: 'none',
                                transition: 'background-color 0.2s ease-in-out',
                                ':hover': {
                                    backgroundColor: expanded[popSide][groupId] ? theme.palette.gray.light : theme.palette.gray.main,
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls={`panel${groupId}-content`}
                                id={`panel${groupId}-header`}
                            >
                                <Typography>{displayName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                {popsInGroup.map((pop) => (
                                    <Grid item container key={pop.id}>
                                        <Grid key={pop.id} item xl={8} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                            <Checkbox
                                                color={pop.classification === 0 || pop.classification === 1 || pop.count ? "primary" : "default"}
                                                checked={!isPopDisabled(pop.popType!)}
                                                onChange={() => togglePopBoxes(pop.popType!)}
                                            />
                                            <span title={pop.description}>{pop.description}</span>
                                        </Grid>
                                        <Grid item xl={4} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: 0 }}>
                                            <BodyText style={{
                                                color: renderCheckboxClassificationColor(pop)
                                            }}>
                                                {renderCheckboxClassification(pop)}
                                            </BodyText>
                                        </Grid>
                                    </Grid>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </>
        );
    };

    return (<>
        {params.carcass && <>
            <Container inline>
                <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} md={8} sx={{ md: { height: '100%' } }}>
                        <CarcassPopsImages
                            carcass={params.carcass}
                            loading={params.loading}
                            carcassesIds={params.carcassesIds}
                            setCurrCarcass={params.setCurrCarcass}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} height='100%' justifyContent='center' sx={{ padding: 2 }}>
                        <div style={{ marginBottom: 16 }}>
                            <Grid container >
                                <Grid item xs={10}>
                                    <Title1 style={{ fontSize: "x-large", marginBottom: 14 }}>Avaliação IA Redsoft</Title1>
                                </Grid>
                                <Grid item xs={2}>
                                    <Image source={logoIcon} height={40} width={40 * 1.666} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: '8px auto 16px', borderColor: theme.palette.primary.light, opacity: 0.7 }} />
                            <Grid container>
                                <Grid item xs={7}>
                                    <Title3 style={{ marginTop: 8 }}> Opções de Exibição: </Title3>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormGroup style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                                        <FormControlLabel
                                            control={
                                                <Switch checked={isLabelActive}
                                                    size="small"
                                                    onChange={() => setIsLabelActive(!isLabelActive)}
                                                />
                                            }
                                            label="Exibir legendas"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: '8px auto 16px', borderColor: theme.palette.gray.main, opacity: 0.7 }} />
                            <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                                <FormControlLabel
                                    label="Selecionar Todos"
                                    control={
                                        <Checkbox
                                            checked={disabledBoundingBoxes.length === 0}
                                            onChange={() => toggleEnableAllBoundingBoxes()}
                                        />
                                    }
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                                    {
                                        roles.includes(PopGroupIdPermissionRelation["0"]) &&
                                        <FormControlLabel
                                            label="Redutores de Desembolso"
                                            control={
                                                <Checkbox
                                                    checked={!isPopGroupDisabled(0)}
                                                    onChange={() => togglePopBoxesByGroupId(0)}
                                                />
                                            }
                                        />
                                    }
                                    {
                                        roles.includes(PopGroupIdPermissionRelation["1"]) &&
                                        <FormControlLabel
                                            label="Agregadores de Receita"
                                            control={
                                                <Checkbox
                                                    checked={!isPopGroupDisabled(1)}
                                                    onChange={() => togglePopBoxesByGroupId(1)}
                                                />
                                            }
                                        />
                                    }
                                    {
                                        roles.includes(PopGroupIdPermissionRelation["2"]) &&
                                        <FormControlLabel
                                            label="Maximizadores de Produtos"
                                            control={
                                                <Checkbox
                                                    checked={!isPopGroupDisabled(2)}
                                                    onChange={() => togglePopBoxesByGroupId(2)}
                                                />
                                            }
                                        />
                                    }
                                </Box>
                            </Box>
                            <Title3 style={{ marginBottom: 8, marginTop: 8 }}>Meia Carcaça A </Title3>
                            {renderCheckBoxes(params.carcass.popsA, "A")}
                            <Title3 style={{ marginBottom: 8, marginTop: 12 }}>Meia Carcaça B </Title3>
                            {renderCheckBoxes(params.carcass.popsB, "B")}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>}
        {params.carcass === undefined &&
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Title4 center style={{ paddingTop: '40px', paddingBottom: '32px' }}>
                    Nenhuma carcaça selecionada, utilize a listagem à esquerda e os filtros para visualizar os detalhes de uma carcaça.
                </Title4>
            </Box>
        }
    </>);
}

export default CarcassPopDetailedItem;
