import { IAlertGroup } from '../types'
import { baseURL } from "../services/alertGroup";
import CRUDHook from "./crud";

export const { crudProvider: AlertGroupsProvider, crudHook: useAlertGroups } = CRUDHook<IAlertGroup>(
    {
        baseURL,
        name: 'grupo de alerta',
    }
)
