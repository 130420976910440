import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { useSlaughterDelays } from '../../../hooks/slaughterDelays';


const SlaughterDelaysDownloadSpreadsheetButton: FC<any> = () => {
    const {
        loading,
        dashboardStats,
        downloadSpreadsheet,
    } = useSlaughterDelays();


    return (
        <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={() => {
                if (!loading && dashboardStats?.total)
                    downloadSpreadsheet();
            }}
            disabled={loading || !dashboardStats?.total}
        >
            Baixar Planilha
        </Button>
    );
}

export default SlaughterDelaysDownloadSpreadsheetButton;
