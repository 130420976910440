import { FC, createContext, useContext, useEffect, useState } from "react";

import {
    createBranch,
    updateBranch,
    getBranchs,
    destroyBranch,
    getBranch,
    getBranchesByCompanyId,
} from "../services/branches";
import { IBranch, IBranchContext, IBranchSystemHealth } from "../types";

const BranchesContext = createContext<IBranchContext>({} as IBranchContext);

export const BranchesProvider: FC<any> = ({ children }) => {
    const [branches, setBranches] = useState<IBranch[]>([]);
    const [filteredBranches, setFilteredBranches] = useState<IBranch[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchBranch, setSearchBranch] = useState<string>("");
    const [branchesPerRow, setBranchesPerRow] = useState<string>("25");
    const [paginate, setPaginate] = useState<number>(0);

    const [healths, setHealths] = useState<IBranchSystemHealth[]>([]);

    useEffect(() => {
        filterBranches(searchBranch);
    }, [searchBranch, branches]);

    const filterBranches = (_searchBranch: string) => {
        let auxBranches = [...branches];
        if (_searchBranch !== "") auxBranches = auxBranches.filter(item => item.name.toLowerCase().indexOf(_searchBranch.toLowerCase()) >= 0 ||
            (item.email && item.email.toLowerCase().indexOf(_searchBranch.toLowerCase()) >= 0));
        setFilteredBranches(auxBranches);
    }

    const fetchBranches = async () => {
        setLoading(true);
        const interval = setTimeout(() => {
            setLoading(false);
        }, 2000);
        try {
            const branches: IBranch[] = await getBranchs();
            setBranches([...branches]);
            updateHealdStatus(branches);
            setLoading(false);
            if (interval)
                clearInterval(interval);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
            if (interval)
                clearInterval(interval);
        }
    };

    const fetchBranch = async (_id: string) => {
        const branch: IBranch = await getBranch(_id);
        return branch;
    };

    const fetchBranchesByCompanyId = async (_companyId: string) => {
        setLoading(true);
        const interval = setTimeout(() => {
            setLoading(false);
        }, 2000);
        try {
            const branches: IBranch[] = await getBranchesByCompanyId(_companyId);
            setBranches([...branches]);
            updateHealdStatus(branches);
            setLoading(false);
            if (interval)
                clearInterval(interval);
            return branches;
        } catch (_err) {
            console.log(_err);
            setLoading(false);
            if (interval)
                clearInterval(interval);
            return [];
        }
    };

    const createNewBranch = async (
        _branch: IBranch,
        _reload: boolean = true,
    ) => {
        const branch = await createBranch(_branch);
        if (!branch.id)
            throw new Error("Não foi possível criar filial.");
        if (_reload) fetchBranches();
        return branch;
    };

    const editBranch = async (_id: string, _payload: IBranch) => {
        try {
            const branch = await updateBranch(_id, _payload);

            fetchBranches();
            return branch;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    };

    const deleteBranch = async (_id: string) => {
        const branch = await destroyBranch(_id);
        fetchBranches();
        return branch;
    };

    const updateHealdStatus = (_branches: IBranch[]) => {
        setHealths([]);
        const auxHealths: IBranchSystemHealth[] = [];
        for (let i = 0; i < _branches.length; i++) {
            const itemHealths: IBranchSystemHealth[] | undefined = _branches[i].healths;
            if (itemHealths)
                for (let j = 0; j < itemHealths.length; j++) {
                    auxHealths.push(itemHealths[j]);
                }
        }

        setHealths([...auxHealths]);
    }

    const reloadHealthStatus = async () => {
        try {
            const auxBranches: IBranch[] = await getBranchs();
            updateHealdStatus(auxBranches);
        } catch (_err) {
            console.log(_err);
        }
    };

    return (
        <BranchesContext.Provider
            value={{
                branches,
                filteredBranches,
                loading,
                fetchBranches,
                fetchBranch,
                fetchBranchesByCompanyId,
                createNewBranch,
                editBranch,
                deleteBranch,
                searchBranch,
                setSearchBranch,
                branchesPerRow,
                setBranchesPerRow,
                paginate,
                setPaginate,

                healths, reloadHealthStatus
            }}
        >
            {children}
        </BranchesContext.Provider>
    );
};

export function useBranches() {
    const context = useContext(BranchesContext);

    if (!context) {
        throw new Error(
            "useBranches must be used within an BranchesProvider",
        );
    }

    return context;
}
