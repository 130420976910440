import { FC } from 'react';

import {
    AreaChart,
    Area,
    CartesianGrid,
    Legend,
    Tooltip,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { theme } from '../../..'

import { useSlaughterDelays } from '../../../../hooks/slaughterDelays';


const SlaughterDelayOcurrencesChart: FC = () => {
    const { occurrencesData, groupByDuration } = useSlaughterDelays();

    if (!occurrencesData || occurrencesData.length <= 1)
        return <></>;

    const dateStringToBrazilian = (dateString: string) =>
        dateString.split('-').reverse().join('/');

    const data = (groupByDuration ?
        occurrencesData.map(entry => ({
            name: dateStringToBrazilian(entry.label),
            value: parseFloat(entry.duration.toFixed(1))
        }))
        : occurrencesData.map(entry => ({
            name: dateStringToBrazilian(entry.label),
            value: entry.occurrences
        }))
    );

    return (
        <ResponsiveContainer height={220}>
             <AreaChart
                width={200}
                data={data}
                margin={{
                    top: 5,
                    right: 60,
                    left: 30,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" hide />
                <YAxis color="black"/>
                <Tooltip />
                <Legend iconSize={15} wrapperStyle={{ bottom: 0 }} />
                <Area
                    dot
                    name={groupByDuration ?
                        'Tempo total de atraso/parada [min]'
                        : 'Número de ocorrências'
                    }
                    dataKey="value"
                    fill={theme.palette.primary.main}
                    stroke={theme.palette.primary.main}
                    strokeWidth={2}
                    isAnimationActive={true}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default SlaughterDelayOcurrencesChart;

