import React, { FC, createContext, useContext, useState } from "react";

import {
    getDownloadPage
} from "../services/downloadPage";
import {
    IBranch,
    ICarcassLocalClassification,
    ICarcassLocalConformation,
    IDownloadPageContext,
    IDownloadPageData,
    ILabelId,
} from "../types";

const DownloadPageContext = createContext<IDownloadPageContext>({} as IDownloadPageContext);

export const DownloadPageProvider: FC<any> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const [initialDate, setInitialDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>([]);
    const [sexes, setSexes] = useState<string[]>([]);
    const [selectedPops, setSelectedPops] = useState<string[]>([]);
    const [popClassifications, setPopClassifications] = useState<number[]>([]);
    const [selectedClassifications, setSelectedClassifications] = useState<ILabelId[]>([]);
    const [selectedLocalClassifications, setSelectedLocalClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [selectedConformations, setSelectedConformations] = useState<ILabelId[]>([]);
    const [selectedLocalConformations, setSelectedLocalConformations] = useState<ICarcassLocalConformation[]>([]);
    const [photoTypes, setPhotoTypes] = useState<number[]>([]);

    const [accuracyTreshold, setAccuracyTreshold] = useState<number| null>(null);
    const [undefinedThreshold, setUndefinedThreshold] = useState<number| null>(null);
    const [filterProperty, setFilterProperty] = useState<'classification' | 'conformation' | 'pop'>('pop');
    const [onlyBestPlaced, setOnlyBestPlaced] = useState<boolean>(false);
    const [randomize, setRandomize] = useState<boolean>(false);
    const [limit, setLimit] = useState<number | null>(null);

    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const [localClassificationsExpanded, setLocalClassificationsExpanded] = useState<boolean>(false);
    const [localConformationsExpanded, setLocalConformationsExpanded] = useState<boolean>(false);
    const [conformationsExpanded, setConformationsExpanded] = useState<boolean>(false);

    const [data, setData] = useState<IDownloadPageData[]>([]);
    const [counts, setCounts] = useState<any>(null);

    function clear() {
        setInitialDate(null);
        setFinalDate(null);
        setSelectedBranches([]);
        setBranchesExpanded(false);
        setSexes([]);
        setSelectedPops([]);
        setSelectedClassifications([]);
        setSelectedLocalClassifications([]);
        setSelectedConformations([]);
        setSelectedLocalConformations([]);
        setPhotoTypes([]);
        setClassificationsExpanded(false);
        setLocalClassificationsExpanded(false);
        setLocalConformationsExpanded(false);
        setConformationsExpanded(false);
        setAccuracyTreshold(null);
        setUndefinedThreshold(null);
        setFilterProperty('pop');
        setLimit(null);
        setRandomize(false);
    };

    function onApply() {
        setLoading(true);
        setData([]);
        setCounts(null);
        const timeout = setTimeout(() => setLoading(false), 10000);

        getDownloadPage({
            initialDate,
            finalDate,
            selectedBranches,
            sexes,
            selectedPops,
            popClassifications,
            selectedClassifications,
            selectedLocalClassifications,
            selectedConformations,
            selectedLocalConformations,
            photoTypes,
            accuracyTreshold,
            filterProperty,
            limit,
            onlyBestPlaced,
            undefinedThreshold,
            randomize,
        }).then((res) => {
            setData(res.data);
            setCounts(res.counts);
            clearInterval(timeout);
            setLoading(false);
        });
    }

    return (
        <DownloadPageContext.Provider
            value={{
                clear,
                onApply,
                loading,
                initialDate,
                finalDate,
                selectedBranches,
                sexes,
                selectedPops,
                popClassifications,
                selectedClassifications,
                selectedLocalClassifications,
                selectedConformations,
                selectedLocalConformations,
                photoTypes,
                accuracyTreshold,
                filterProperty,
                onlyBestPlaced,
                randomize,
                limit,
                branchesExpanded,
                classificationsExpanded,
                localClassificationsExpanded,
                localConformationsExpanded,
                conformationsExpanded,
                data,
                counts,
                undefinedThreshold,
                setLoading,
                setInitialDate,
                setFinalDate,
                setSelectedBranches,
                setSexes,
                setSelectedPops,
                setPopClassifications,
                setSelectedClassifications,
                setSelectedLocalClassifications,
                setSelectedConformations,
                setSelectedLocalConformations,
                setPhotoTypes,
                setAccuracyTreshold,
                setFilterProperty,
                setOnlyBestPlaced,
                setRandomize,
                setLimit,
                setBranchesExpanded,
                setClassificationsExpanded,
                setLocalClassificationsExpanded,
                setLocalConformationsExpanded,
                setConformationsExpanded,
                setData,
                setCounts,
                setUndefinedThreshold,
            }}
        >
            {children}
        </DownloadPageContext.Provider>
    );
};

export function useDownloadPage() {
    const context = useContext(DownloadPageContext);

    if (!context) {
        throw new Error(
            "useDownloadPage must be used within an DownloadPageProvider",
        );
    }

    return context;
}
