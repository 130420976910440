import React, { FC, createContext, useContext, useState } from 'react'

import { sendSupportEmail } from '../services/sendEmails';
import { ISupportEmail, ISendEmailsContext } from '../types'

const SendEmailsContext = createContext<ISendEmailsContext>({} as ISendEmailsContext);

export const SendEmailsProvider: FC<any> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const sendEmailToSupport = async (_email: ISupportEmail) => {
        setLoading(true);
        try {
            const emailReturn: any = await sendSupportEmail(_email);
            return emailReturn;
        } catch (_err) {
            console.log(_err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <SendEmailsContext.Provider value={{
            loading, sendEmailToSupport
        }}>{children}</SendEmailsContext.Provider>
    )
}

export function useSendEmails() {
    const context = useContext(SendEmailsContext);

    if (!context) {
        throw new Error('useSendEmails must be used within an SendEmailsProvider')
    }

    return context;
}
