import React, { FC, useEffect, useState } from "react";
import { Box, Divider, LinearProgress } from "@mui/material";
import {
    Search as SearchIcon,
} from "@mui/icons-material";

import Container from "../../../atoms/container";
import theme from "../../../theme";
import { PADDING, VERY_PADDING } from "../../../../utils/consts";
import MonitoringRecipientListItem from "./item";
import { IMonitoringRecipientList } from "../../../../types";

const MonitoringRecipientList: FC<IMonitoringRecipientList> = (params: IMonitoringRecipientList) => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        setData(groupBy([...params.data],'userId'));
    }, [params.data]);

    function groupBy (array: any[], key: string) {
        let dataObject: any = array.reduce((acc, item) => {
            if (!acc[item[key]]) acc[item[key]] = {
                userId: item.userId,
                user: item.user.firstName,
                email: item.user.email,
                monitoringItems: []
            }
            acc[item[key]].monitoringItems.push(item)
            return acc
        }, {})
        return Object.values<any>(dataObject).reduce((newData,value)=>{
            newData.push(value);
            return newData;
        },[])
    }

    return (
        <Container
            fluid
            flex
            relative
            width="100%"
            centered
            sx={{ marginTop: PADDING, paddingBottom: VERY_PADDING }}
        >
            <LinearProgress color="primary" />
            {params.loading && (
                <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                </Box>
            )}

            <Container horizontalCentered fluid flex width="100%">
                {params.headers.find((item) => item.hideLabel) != undefined &&
                    !params.loading && (
                        <Divider
                            sx={{ borderColor: theme.palette.grayLight.main }}
                        />
                    )}

                {!params.loading &&
                    data.map((_data: any, index: number) => {
                        return (
                            <MonitoringRecipientListItem
                                key={index}
                                index={index}
                                headers={params.headers}
                                data={_data}
                                dataToItem={params.dataToItem}
                            />
                        );
                    })}
            </Container>
        </Container>
    );
};

export default MonitoringRecipientList;
