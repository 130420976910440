import React, { FC, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { reasonToAutocompleteOption } from "../../../services/slaughterDelays";
import { IAutocompleteOption } from "../../../types/components/atoms/autocomplete";
import { ISlaughterDelaysReasonSelect } from "../../../types/hooks/slaughterDelays";
import { useSlaughterDelayReasons } from "../../../hooks/slaughterDelayReasons";


/* MultiSelect for branches, used in CarcassesFilter */
const SlaughterDelayReasonSelect: FC<ISlaughterDelaysReasonSelect> = (params: ISlaughterDelaysReasonSelect) => {
    const { entries, fetchAllEntries } = useSlaughterDelayReasons();

    const options = entries?.reduce((res, entry) => {
        if (!entry.isDeleted &&
            (!params.branches || params.branches.includes(entry.branchId)))
                res.push(reasonToAutocompleteOption(entry));
        return res;
    }, [] as IAutocompleteOption[]);

    useEffect(() => {
        if (!entries || entries.length === 0)
            fetchAllEntries();
    }, []);

    return (
        <Autocomplete
            multiple={params.multiple}
            filterSelectedOptions
            disableCloseOnSelect
            id={params.id || 'slaughter-delays-reasons-select'}
            options={options || []}
            renderInput={(renderParams) => (
                <TextField
                    label={params.label || 'Motivos'}
                    placeholder={params.placeholder || "Selecionar motivos"}
                    style={{ margin: 0, width: '100%' }}
                    {...renderParams}
                />
            )}
            value={params.value}
            onChange={(event: any, newValue: any) => {
                params.setValue(newValue);
            }}
            disabled={params.disabled}
            sx={{
                width: '100%',
                ...params.sx
            }}
        />
    );
}

export default SlaughterDelayReasonSelect;
