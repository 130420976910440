import React from "react";
import { Routes, Route } from "react-router-dom";

import { AppDrawer } from "../components";
import HooksProvider from "../hooks";
import { useAuth } from "../hooks/auth";
import {
    ActiveAlerts,
    AlertGroups,
    AlertHistory,
    AlertSetup,
    AnalysisAnnotations,
    Annotations,
    AnnotationsAnomalies,
    AnnotationsSelect,
    AnnotatorRegister,
    Annotators,
    Branches,
    BranchesHasUsers,
    BranchHasUserRegister,
    BranchRegister,
    CarcassDetailed,
    Carcasses,
    Companies,
    CompanyRegister,
    ControlPanel,
    Dashboard,
    DownloadPage,
    Help,
    Lots,
    PopDashboard,
    Revisions,
    Settings,
    SlaughterDelays,
    UserRegister,
    Users,
    Verdicts,
} from "../pages";
import PopVisualization from "../pages/pops/visualization";

const AuthRoutes: React.FC = () => {
    const { hasRights } = useAuth();

    return (
        <HooksProvider>
            <AppDrawer>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    {hasRights!("view_annotations") && <Route path="/annotations" element={<Annotations />} />}
                    {hasRights!("view_annotations") && <Route path="/annotations_anomalies" element={<AnnotationsAnomalies />} />}
                    {hasRights!("view_annotations") && <Route path="/annotations_select" element={<AnnotationsSelect />} />}
                    {hasRights!("view_annotators") && <Route path="/annotators" element={<Annotators />} />}
                    {hasRights!("manage_annotators") && <Route path="/annotators/register" element={<AnnotatorRegister />} />}
                    {hasRights!("manage_annotators") && <Route path="/annotators/:id" element={<AnnotatorRegister />} />}
                    {hasRights!("view_carcasses") && <Route path="/carcasses" element={<Carcasses />} />}
                    {hasRights!("view_carcasses__detailed") && <Route path="/carcasses/:id" element={<CarcassDetailed />} />}
                    {hasRights!("view_lotes") && <Route path="/lotes" element={<Lots />} />}
                    {hasRights!("view_analysis_annotations") && <Route path="/analysis-annotations" element={<AnalysisAnnotations />} />}
                    {hasRights!("view_alert_system_alerts") && <Route path="/alert/setup" element={<AlertSetup />} />}
                    {hasRights!("view_alert_system_groups") && <Route path="/alert/groups" element={<AlertGroups />} />}
                    {hasRights!("view_alert_system_history") && <Route path="/alert/history" element={<AlertHistory />} />}
                    {hasRights!("view_alert_system_active") && <Route path="/alert/active-alerts" element={<ActiveAlerts />} />}
                    {hasRights!("view_branches") && <Route path="/companies/:companyId/branches" element={<Branches />} />}
                    {hasRights!("manage_branches") && <Route path="/companies/:companyId/branches/register" element={<BranchRegister />} />}
                    {hasRights!("manage_branches") && <Route path="/companies/:companyId/branches/:id" element={<BranchRegister />} />}
                    {hasRights!("view_branches_has_users") && <Route path="/branches-has-users" element={<BranchesHasUsers />} />}
                    {hasRights!("manage_branches_has_users") && <Route path="/branches-has-users/register" element={<BranchHasUserRegister />} />}
                    {hasRights!("manage_branches_has_users") && <Route path="/branches-has-users/:id" element={<BranchHasUserRegister />} />}
                    {hasRights!("view_companies") && <Route path="/companies" element={<Companies />} />}
                    {hasRights!("manage_companies") && <Route path="/companies/register" element={<CompanyRegister />} />}
                    {hasRights!("manage_companies") && <Route path="/companies/:id" element={<CompanyRegister />} />}
                    {hasRights!("view_control_panel") && <Route path="/control_panel" element={<ControlPanel />} />}
                    {hasRights!("view_tutorial") && <Route path="/help" element={<Help />} />}
                    {hasRights!("view_pop_dashboard") && <Route path="/pops/dashboard" element={<PopDashboard />} />}
                    {hasRights!("view_pop_visualization") && <Route path="/pops/visualization" element={<PopVisualization/>} />}
                    {hasRights!("view_revisions") && <Route path="/revisions" element={<Revisions />} />}
                    {hasRights!("view_download_page") && <Route path="/download-page" element={<DownloadPage />} />}
                    {hasRights!("view_settings") && <Route path="/settings" element={<Settings />} />}
                    {hasRights!("view_slaughter_delays") && <Route path="/telenoria" element={<SlaughterDelays />} />}
                    {hasRights!("view_users") && <Route path="/companies/:companyId/branches/:branchId/users" element={<Users />} />}
                    {hasRights!("manage_users") && <Route path="/companies/:companyId/branches/:branchId/users/register" element={<UserRegister />} />}
                    {hasRights!("manage_users") && <Route path="/companies/:companyId/branches/:branchId/users/:id" element={<UserRegister />} />}
                    {hasRights!("view_verdicts") && <Route path="/verdicts" element={<Verdicts />} />}
                    <Route path="*" element={<Dashboard />} />
                </Routes>
            </AppDrawer>
        </HooksProvider>
    );
};

export default AuthRoutes;
