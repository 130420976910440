import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
} from '@mui/material';

import BranchesMultiSelect from '../branches/multiSelect';
import Container from '../../atoms/container';
import {
    IAlertActiveFilterParams,
    IAlertActiveFilter,
    IBranch,
} from '../../../types';
import { PADDING } from '../../../utils/consts';
import DateTimePicker from '../../atoms/dateTimePicker';


const AlertActiveFilter: FC<IAlertActiveFilter> = (params: IAlertActiveFilter) => {
    const [initialTime, setInitialTime] = useState<Date | null>(null);
    const [finalTime, setFinalTime] = useState<Date | null>(null);
    const [branches, setBranches] = useState<IBranch[]>([]);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<number[]>([]);
    const [levels, setLevels] = useState<number[]>([]);
    const [sources, setSources] = useState<number[]>([]);

    useEffect(() => {
        // load initial data
        onApplyFilter();
    }, []);

    const onApplyFilter = () => {
        setBranchesExpanded(false);

        let auxFilter: IAlertActiveFilterParams = {
            initialTime,
            finalTime,
            branches,
            statuses,
            levels,
            sources,
        }
        params.onApply(auxFilter);
    };

    const cleanFilter = () => {
        setInitialTime(null);
        setFinalTime(null);
        setBranches([]);
        setBranchesExpanded(false);
        setStatuses([]);
        setLevels([]);
        setSources([]);
    };

    const renderAutocompleteMultiselect = (
        title: string,
        placeholder: string,
        options: { id: number | string, label: string }[],
        value: (number | string)[],
        setValue: (value: (number | string)[]) => void
    ) => {
        return <Autocomplete
            disabled={params.loading}
            multiple={true}
            options={options}
            renderInput={(params) => <TextField
                {...params}
                placeholder={placeholder}
                title={title}
                sx={{ background: '#FFF', margin: 0 }}
            />}
            onChange={(e, newValue: {id: number | string, label: string}[]) => {
                const val: (number | string)[] = newValue.map((i) => i.id) || [];
                setValue(val);
            }}
            value={ options.filter((opt) => value.includes(opt.id)) }
        />
    };

    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING }}>
            <Grid container spacing={1} alignItems="center" sx={{ marginBottom: { xs: 2, lg: 0 } }}>
                <Grid item xs={12} lg={4}>
                    <DateTimePicker
                        value={initialTime}
                        setValue={setInitialTime}
                        label='Data/Hora Inicial'
                        placeholder='Data/Hora Inicial'
                        inputFormat='yyyy-MM-dd HH:mm:ss'
                        mask={'__/__/____ __:__:__'}
                        sx={{ background: '#FFF', width: '100%' }}
                        disabled={params.loading}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DateTimePicker
                        value={finalTime}
                        setValue={setFinalTime}
                        label='Data/Hora Final'
                        placeholder='Data/Hora Final'
                        inputFormat='yyyy-MM-dd HH:mm:ss'
                        mask={'__/__/____ __:__:__'}
                        sx={{ background: '#FFF', width: '100%' }}
                        disabled={params.loading}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Parâmetros', 'Selecione os parâmetros...',
                        params.sources ? params.sources.map(
                            (item) => ({ id: item.value, label: item.description })) : [],
                        sources,
                        (value: (number | string)[]) => setSources(value as number[])
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Níveis', 'Selecione os níveis...',
                        params.levels ? params.levels.map(
                            (item) => ({ id: item.value, label: item.description })) : [],
                        levels,
                        (value: (number | string)[]) => setLevels(value as number[])
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Estado', 'Selecione os estados...',
                        params.statuses ? params.statuses.map(
                            (item) => ({ id: item.value, label: item.description })): [],
                        statuses,
                        (value: (number | string)[]) => setStatuses(value as number[])
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <BranchesMultiSelect
                        selectedBranches={branches}
                        setSelectedBranches={setBranches}
                        expanded={branchesExpanded}
                        setExpanded={setBranchesExpanded}
                        minWidth='100%'
                        height='40px'
                        disabled={params.loading}
                        sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                        }} />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: {xs: 1, lg: 0} }}>
                    <Button
                        sx={{ marginRight: 2 }}
                        size="large"
                        variant="text"
                        color="primary"
                        onClick={cleanFilter}
                        disabled={params.loading}
                    >
                        Limpar
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onApplyFilter}
                        disabled={params.loading}
                    >
                        Filtrar
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AlertActiveFilter;