import React, { FC, useEffect, useState } from "react";
import Hidden from "@mui/material/Hidden";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import CarcassListItem from "../listItem";
import { ICarcass, ICarcassList } from "../../../../types";
import { BodyText, Title2 } from "../../../atoms/text";
import Pagination from "../../table/pagination";
import { parseIntegerFromString } from "../../../../utils/parses"
import CarcassesListFilter from "./filter";

import { ICarcassAutocompleteData } from "../../../../types";


const CarcassesList: FC<ICarcassList> = (params: ICarcassList) => {
    // Filters
    const [farms, setFarms] = useState<string[]>([]);
    const [classification, setClassification] = useState<string[]>([]);
    const [conformation, setConformation] = useState<string[]>([]);
    const [docNums, setDocumentNumbers] = useState<number[]>([]);
    const [lotNums, setLotNumbers] = useState<number[]>([]);
    const [sequences, setSequences] = useState<string[]>([]);
    const [genders, setGenders] = useState<string[]>([]);
    const [typifications, setTypifications] = useState<string[]>([]);
    const [localClassificationsRedsoft, setLocalClassificationsRedsoft] = useState<string[]>([]);
    const [bestPlaced, setBestPlaced] = useState<boolean[]>([]);
    const [currPage, setCurrPage] = useState<number>(0);

    const pageCount = 10;   // Number of items per page
    const totalCount = params.filteredCarcasses.length;
    const carcasses = params.filteredCarcasses.slice((currPage * pageCount), ((currPage * pageCount) + pageCount));

    // Helpers
    function sortAutocompleteDataString(data: Array<ICarcassAutocompleteData<string>>,
            includeLength: boolean = false) {
        if (!data || !data.length || typeof data[0].key !== 'string') return [];
        if (includeLength)
            return data.sort((a, b) => {
                if (a.key.length > b.key.length) return 1
                else if (b.key.length > a.key.length) return -1
                else if (a.key > b.key) return 1
                else if (b.key > a.key) return -1
                return 0
            });
        return data.sort((a, b) => {
            if (a.key > b.key) return 1
            else if (b.key > a.key) return -1
            return 0
        });
    }

    // Filters options
    const farmList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.farm && res.findIndex((rItem) => item.farm.toUpperCase() === rItem.key) === -1)
                res.push({key: item.farm.toUpperCase(), value: item.farm.toUpperCase()});
            return res;
        }, [])
    );

    const classificationList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.classification && res.findIndex((rItem) => item.classification.toUpperCase() === rItem.key) === -1)
                res.push({key: item.classification.toUpperCase(), value: item.classification.toUpperCase()});
            return res;
        }, [])
    );

    const conformationList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.conformationName && res.findIndex((rItem) => item.conformationName.toUpperCase() === rItem.key) === -1)
                res.push({key: item.conformationName.toUpperCase(), value: item.conformationName.toUpperCase()});
            return res;
        }, [])
    );

    const docList = params.carcasses.reduce((res: Array<ICarcassAutocompleteData<number>>, item: any) => {
        if (item.docNum && res.findIndex((rItem) => item.docNum === rItem.key) === -1)
            res.push({key: item.docNum, value: item.docNum});
        return res;
    }, []).sort((a, b) => a.key - b.key);

    const lotList = params.carcasses.reduce((res: Array<ICarcassAutocompleteData<number>>, item: any) => {
        if (item.lotNum && res.findIndex((rItem) => item.lotNum === rItem.key) === -1)
            res.push({key: item.lotNum, value: item.lotNum});
        return res;
    }, []).sort((a, b) => a.key - b.key);

    const sequenceList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.partnerId && res.findIndex((rItem) => String(item.partnerId).toUpperCase() === rItem.key) === -1)
                res.push({key: String(item.partnerId).toUpperCase(), value: String(item.partnerId)});
            return res;
        }, []), true);

    const genderList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.sex && res.findIndex((rItem) => String(item.sex) === rItem.key) === -1) {
                let value = String(item.sex);
                if (value === 'F') value = 'Fêmea'
                else if (value === 'M') value = 'Macho';
                res.push({key: String(item.sex), value: value});
            }
            return res;
        }, [])
    );

    const typificationList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.typification && res.findIndex((rItem) => String(item.typification).toUpperCase() === rItem.key) === -1)
                res.push({key: String(item.typification).toUpperCase(), value: String(item.typification).toUpperCase()});
            return res;
        }, []), true);

    const localClassificationsRedsoftList = sortAutocompleteDataString(
        params.carcasses.reduce((res: Array<ICarcassAutocompleteData<string>>, item: any) => {
            if (item.localClassificationRedsoft &&
                res.findIndex((rItem) => String(item.localClassificationRedsoft).toUpperCase() === rItem.key) === -1
            )
                res.push({
                    key: String(item.localClassificationRedsoft).toUpperCase(),
                    value: String(item.localClassificationRedsoft).toUpperCase()
                });
            return res;
        }, []), true);

    useEffect(() => {
        recalculateFilteredCarcasses();
    }, [params.carcasses, farms, classification, docNums, lotNums, sequences,
        genders, typifications, localClassificationsRedsoft, bestPlaced]);

    useEffect(() => {
        if (!params.filteredCarcasses.length || !params.currCarcass)
            setCurrPage(0)
        else setCurrPage(Math.floor(
            params.filteredCarcasses.findIndex((item) => item.id === params.currCarcass) / 10));

        if (!params.currCarcass && params.filteredCarcasses.length)
            params.setCurrCarcass(params.filteredCarcasses[0].id)
    }, [params.filteredCarcasses, params.currCarcass]);

    const recalculateFilteredCarcasses = () => {
        const newFilteredCarcasses = params.carcasses.filter((item) => {
            if (farms && farms.length > 0 &&
                    (!item.farm || !farms.includes(item.farm!.toUpperCase())))
                return false;
            if (classification && classification.length > 0 &&
                    (!item.classification ||
                    !classification.includes(item.classification!.toUpperCase())))
                return false;
            if (conformation && conformation.length > 0 &&
                    (!item.conformationName ||
                    !conformation.includes(item.conformationName!.toUpperCase())))
                return false;
            if (docNums && docNums.length > 0 &&
                    (!item.docNum || !docNums.includes(item.docNum!)))
                return false;
            if (lotNums && lotNums.length > 0 &&
                    (!item.lotNum || !lotNums.includes(item.lotNum!)))
                return false;
            if (sequences && sequences.length > 0 &&
                    (!sequences.includes(String(item.partnerId).toUpperCase())))
                return false;
            if (genders && genders.length > 0 &&
                    (!item.sex || !genders.includes(item.sex!)))
                return false;
            if (typifications && typifications.length > 0 &&
                    (!item.typification ||
                    !typifications.includes(item.typification!.toUpperCase())))
                return false;
            if (localClassificationsRedsoft && localClassificationsRedsoft.length > 0 &&
                    (!item.localClassificationRedsoft ||
                    !localClassificationsRedsoft.includes(item.localClassificationRedsoft!.toUpperCase())))
                return false;
            if (Array.isArray(bestPlaced) && bestPlaced.length > 0 &&
                    (item.bestPlaced === undefined || !bestPlaced.includes(item.bestPlaced!)))
                return false;
            return true
        }).sort((a: ICarcass, b: ICarcass) => {
            try {
                if(a.partnerId && b.partnerId && typeof a.partnerId === 'string' && typeof b.partnerId === 'string'){
                    const partnerIdA = parseIntegerFromString(a.partnerId)
                    const partnerIdB = parseIntegerFromString(b.partnerId)

                    if(partnerIdA != null && partnerIdB != null){
                        if (partnerIdA > partnerIdB)
                            return 1;
                        else if (partnerIdA < partnerIdB)
                            return -1;
                        else
                            return 0;
                    }
                }
                if (a.sequence && b.sequence && a.sequence > b.sequence)
                    return 1;
                else if (a.sequence && b.sequence && a.sequence < b.sequence)
                    return -1;
                else if (a.sequence && b.sequence)
                    return 0;

                // Handling when no sequence is defined
                if ((!a.sequence && b.sequence) || typeof a.partnerId !== 'string')
                    return 1;
                if ((a.sequence && !b.sequence) || typeof b.partnerId !== 'string')
                    return -1;

                if ((a.partnerId as string).length > (b.partnerId as string).length) return 1;
                else if ((b.partnerId as string).length > (a.partnerId as string).length) return -1;
                else if (a.partnerId > b.partnerId) return 1;
                else if (b.partnerId > a.partnerId) return -1;
                return 0;
            }
            catch (err) {
                return -1;
            }
        });

        params.setFilteredCarcasses(newFilteredCarcasses);
    };

    return (
        <Card variant='outlined' sx={{
                borderRadius: '16px', background: 'white',
                opacity: params.loading ? 0.5 : 1,
                cursor: params.loading ? 'not-allowed' : 'default'
            }}
        >
            <CardContent>
                <Title2 style={{ width: '100%', alignItems: 'center', marginBottom: '8px' }}>
                    Listagem
                    <span style={{ width: '100%', textAlign: 'right' }}>
                        <CarcassesListFilter
                            setFarms={setFarms}
                            setClassification={setClassification}
                            setConformation={setConformation}
                            setDocumentNumbers={setDocumentNumbers}
                            setLotNumbers={setLotNumbers}
                            setSequences={setSequences}
                            setGenders={setGenders}
                            setTypifications={setTypifications}
                            setBestPlaced={setBestPlaced}
                            carcasses={params.filteredCarcasses}
                            farmList={farmList}
                            classificationList={classificationList}
                            conformationList={conformationList}
                            docList={docList}
                            lotList={lotList}
                            sequenceList={sequenceList}
                            genderList={genderList}
                            typificationList={typificationList}
                            localClassificationsRedsoftList={localClassificationsRedsoftList}
                            setLocalClassificationsRedsoft={setLocalClassificationsRedsoft}
                            recalculateFilteredCarcasses={recalculateFilteredCarcasses}
                            loading={params.loading}
                        />
                    </span>
                </Title2>
                {carcasses.length > 0 && <BodyText muted style={{ marginBottom: '24px' }}>
                    Selecione a sequência para ver os detalhes do animal
                </BodyText>}
                {carcasses.length > 0 && pageCount > 1 &&
                    <Hidden smUp>
                        <Pagination page={currPage} setPage={setCurrPage}
                            pageCount={Math.ceil(totalCount / pageCount)}
                        />
                    </Hidden>
                }
                <Stack marginBottom={1} spacing={1}>
                    {carcasses.length ? carcasses.map((item) =>
                        <CarcassListItem
                            key={`carcass-list-item-${item.id}`}
                            carcass={item}
                            loading={params.loading}
                            setCurrCarcass={() => params.setCurrCarcass(item.id)}
                            active={params.currCarcass === item.id}
                    />)
                    : <BodyText muted>Nenhuma carcaça encontrada com os filtros descritos.</BodyText>
                    }
                </Stack>
                {carcasses.length > 0 && pageCount > 1 &&
                    <Pagination page={currPage} setPage={setCurrPage}
                        pageCount={Math.ceil(totalCount / pageCount)}
                    />
                }
                {carcasses.length > 0 && <BodyText muted style={{ width: '100%', justifyContent: 'right', marginTop: '8px' }}>
                    Exibindo {currPage * pageCount + 1} - {Math.min((currPage + 1) * pageCount, totalCount)} de {totalCount}
                </BodyText>}
            </CardContent>
        </Card>
    );
};

export default CarcassesList;