import React, { FC } from 'react';
import {
    ICRUDTableByBranch,
    ICarcassLocalClassification,
    ICRUDContext,
} from '../../../../types';

import { getCarcassesLocalClassificationsByBranch } from "../../../../services/carcassesLocalClassifications";
import { useCarcassesLocalClassifications } from '../../../../hooks/carcassesLocalClassifications';
import generateCRUDTable from '../../crudTable';


const CarcassesLocalClassificationsCRUDTable: FC<ICRUDTableByBranch> = (params: ICRUDTableByBranch) => {
    const crudTable = generateCRUDTable<ICarcassLocalClassification, ICRUDTableByBranch>(
        {
            columns: [
                {
                    name: 'name',
                    title: 'Nome',
                    required: true,
                },
                {
                    name: 'localClassification',
                    title: 'Local',
                    required: true,
                },
                {
                    name: 'classificationValue',
                    title: 'RedSoft',
                    type: 'integer',
                    required: true,
                    max: 5,
                    min: 0,
                },
                {
                    name: 'jbsClassification',
                    title: 'JBS',
                    type: 'integer',
                    required: true,
                    max: 9,
                    min: 0,
                },
            ],
            name: 'acabamento local',
            defaultSort: 'name',
            entryDescriptor: 'name',
            defaultNewEntry: {
                branchId: params.branchId,
                localClassification: null,
                name: '',
            },
            getHook: useCarcassesLocalClassifications,
            fetchAllEntries: async (hook?: ICRUDContext<ICarcassLocalClassification>, _params?: ICRUDTableByBranch) => {
                return await getCarcassesLocalClassificationsByBranch(params.branchId);
            }
        }
    )

    return crudTable(params);
};

export default React.memo(CarcassesLocalClassificationsCRUDTable);