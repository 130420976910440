import { AxiosResponse } from "axios";

import api from ".";

const baseUrl: string = "carcasses/hash_count";

export const getHashCount = async (branchId: string, date: string | Date) => {
  if (typeof date !== 'string') {
    date = date.toISOString().split('T')[0];
  };

  const url = `/api_redsoft/v1_0/${baseUrl}?branchId=${branchId}&date=${date}`;
  const response: AxiosResponse = await api.get(url);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data
    }
  }

  throw new Error("Não foi possível buscar os dados");
};
