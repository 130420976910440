import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import theme from '../../theme';
import { IAlertStatus, IAlertHistoryTable } from '../../../types';
import { useAlertConstants } from '../../../hooks/alertConstants';
import { useAlerts } from '../../../hooks/alert';


interface Column {
  id: keyof IAlertStatus;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}


const AlertHistoryTable: FC<IAlertHistoryTable> = (params: IAlertHistoryTable) => {
  const { entries: alerts, fetchAllEntries: fetchAlerts } = useAlerts();
  const { statuses, fetchAlertConstants } = useAlertConstants();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const columns: readonly Column[] = [
    {
      id: 'alertTitle',
      label: 'Alerta',
      minWidth: 170,
      format: (val: string) => alerts.find((item) => item.id === val)?.title || val,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 170,
      format: (val: number) => statuses.find((item) => item.value === val)?.description || String(val),
    },
    {
      id: 'eventAt',
      label: 'Data/Hora',
      minWidth: 170,
      format: (datetime) => moment(datetime).format('DD/MM/YYYY HH:mm:ss')
    },
  ];

  useEffect(() => {
    if (!alerts || (params.data.length > 0 && alerts.length === 0))
      fetchAlerts();
    if (!statuses || statuses.length === 0)
      fetchAlertConstants();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getRowColor(status: number) {
    const statusName = statuses.find(s => s.value === status)?.name;
    switch (statusName) {
      case 'SILENT': return '#fbf3d7';
      case 'ACTIVE': return '#fbd7d7';
      default: return '#FFFFFF';
    }
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{
              
            }}>
              {params.loading &&
                <TableCell
                  align='center'
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  Carregando dados...
                </TableCell>
              }
              {!params.loading && columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.primary.main,
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {params.loading &&
              <TableRow hover tabIndex={-1}>
                <TableCell align='center' sx={{ paddingTop: 10, paddingBottom: 10 }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            }
            {!params.loading && params.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const rowColor = getRowColor(row['status']);
                return (
                  <TableRow hover tabIndex={-1} key={`r${index}`} sx={{ background: rowColor }}>
                    {columns.map((column) => {
                      let value = (row[column.id] as string | number | Date);
                      if (!value && column.id === 'alertTitle')
                        value = (row['alertId'] as string | number | Date);
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format
                            ? column.format(value)
                            : value as string | number}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={params.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default AlertHistoryTable;
