import styled from "styled-components";

import { IContainer } from "../../../types";
import { PADDING, VERY_PADDING } from "../../../utils/consts";

const fluid = "display: flex; flex-direction: column;";
const inline = "display: flex; flex-direction: row;";
const flexStart = "align-items: flex-start; justify-content: flex-start";
const flexEnd = "align-items: flex-end; justify-content: flex-end";
const centered = "align-items: center; justify-content: center";

export const ContainerStyled = styled.div`
  ${(props: IContainer) => props.fluid && fluid};
  ${(props: IContainer) => props.inline && inline};

  width: ${(props: IContainer) =>
    typeof props.width === "number" ? `${props.width}px` : props.width};
  min-width: ${(props: IContainer) =>
    typeof props.minWidth === "number"
      ? `${props.minWidth}px`
      : props.minWidth};
  max-width: ${(props: IContainer) =>
    typeof props.maxWidth === "number"
      ? `${props.maxWidth}px`
      : props.maxWidth};
  height: ${(props: IContainer) =>
    typeof props.height === "number" ? `${props.height}px` : props.height};
  min-height: ${(props: IContainer) =>
    typeof props.minHeight === "number"
      ? `${props.minHeight}px`
      : props.minHeight};
  max-height: ${(props: IContainer) =>
    typeof props.maxHeight === "number"
      ? `${props.maxHeight}px`
      : props.maxHeight};

  ${(props: IContainer) => props.color && `background: ${props.color}`};

  ${(props: IContainer) => props.flexStart && flexStart};
  ${(props: IContainer) => props.flexEnd && flexEnd};
  ${(props: IContainer) => props.flex && "flex: 1"};
  ${(props: IContainer) => props.flexGrow && "flex-grow: 1"};

  ${(props: IContainer) => props.centered && centered};
  ${(props: IContainer) => props.horizontalCentered && "align-items: center"};
  ${(props: IContainer) => props.verticalCentered && "justify-content: center"};
  ${(props: IContainer) => props.contentCentered && "justify-content: center"};
  ${(props: IContainer) => props.baselineAlignment && "align-items: baseline"};

  ${(props: IContainer) =>
    props.spacedAround && "justify-content: space-around"};
  ${(props: IContainer) =>
    props.spacedBetween && "justify-content: space-between"};
  ${(props: IContainer) =>
    props.spacedEvenly && "justify-content: space-evenly"};

  border: ${(props: IContainer) =>
    props.border &&
    (typeof props.border === "string" ? `solid ${props.border}` : "solid")};
  ${(props: IContainer) =>
    props.borderRadius && `border-radius: ${props.borderRadius}px`};
  ${(props: IContainer) =>
    props.borderTopRadius &&
    `border-top-left-radius: ${props.borderTopRadius}px; border-top-right-radius: ${props.borderTopRadius}px;`};
  ${(props: IContainer) =>
    props.borderBottomRadius &&
    `border-bottom-left-radius: ${props.borderBottomRadius}px; border-bottom-right-radius: ${props.borderBottomRadius}px;`};

  ${(props: IContainer) => props.padded && `padding: ${PADDING}px`};
  ${(props: IContainer) => props.veryPadded && `padding: ${VERY_PADDING}px`};
  ${(props: IContainer) => props.verticalPadded && `padding: ${PADDING}px 0`};
  ${(props: IContainer) =>
    props.veryVerticalPadded && `padding: ${VERY_PADDING}px 0`};
  ${(props: IContainer) => props.horizontalPadded && `padding: 0 ${PADDING}px`};
  ${(props: IContainer) =>
    props.veryHorizontalPadded && `padding: 0 ${VERY_PADDING}px`};

  box-shadow: ${(props: IContainer) =>
    props.hasShadow && "rgba(0, 0, 0, 0.15) 0px 3px 8px;"};

  ${(props: IContainer) => props.relative && `position: relative`};
  ${(props: IContainer) => props.absolute && `position: absolute`};
`;
