import { ICarcassLocalConformation } from '../types'
import { convertApiResponseToICarcassLocalConformation, baseURL } from "../services/carcassesLocalConformations";
import CRUDHook from "./crud";

export const { crudProvider: CarcassesLocalConformationsProvider, crudHook: useCarcassesLocalConformations } = CRUDHook<ICarcassLocalConformation>(
    {
        baseURL,
        name: 'acabamento local',
        convertResponseToModel: convertApiResponseToICarcassLocalConformation,
    }
)
