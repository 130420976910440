/* generateCRUDTable
Method to create CRUD tables where you can perform creation, update, deletion
and listing all entries of a certain data.

If you want a straight-forward example, just check
CarcassesLocalClassificationsCRUDTable, ShiftsCRUDTable or
TypificationsCRUDTable and its dependencies. All of them are slightly
customized to use a different endpoint for the fetchAll functionality, as they
are listed based on the current branch.

To create a table from scratch you will need:
- Interface for your base data type inheriting from IEntity (it assumes we
will have an id). "IMyData" will be that in our examples;

- A hook and a provider created with CRUDHook. Remember to include the provider
in the App.
    Example:
        export const { crudProvider: MyDataProvider, crudHook: useMyData } = CRUDHook<IMyData>(
            {
                baseURL: '/api_redsoft/v1_0/typifications',
                name: 'meu dado',
            }
        )

- A component using this generateCRUDTable method.
    Example:
        const MyCRUDTable: FC<IMyCRUDTableParams> = (params: IMyCRUDTableParams) => {
            const crudTable = generateCRUDTable<IMyData, IMyCRUDTableParams>(
                {
                    columns: [...],         // check the parameters session
                    name: 'meu dado',       // used for messages
                    defaultSort: 'name',    // or any column name
                    getHook: useMyData,     // the hook we have created
                    defaultNewEntry: {...}  // object containing your start data
                    ...
                }
            )
            return crudTable(params);
        };
        export default React.memo(MyCRUDTable);

- Optionally, you can create a service or use some of their functionality with
CRUDService. Here's an example from typification where we use the crudCheckResponse
method created with the CRUDService:
    export const baseURL = '/api_redsoft/v1_0/typifications';
    const defaultService = CRUDService({
        baseURL,
        name: 'tipificação',
    });
    export const getTypificationsByBranch: (branch?: string) => Promise<ITypification[]> = async (branch?: string) => {
        const url = !!branch ? `${baseURL}/by_branch_id/${branch}` : baseURL;
        const response: AxiosResponse = await api.get(url);
        return defaultService.crudCheckResponse(response, 'Falha ao buscar tipificações');
    };

*******************************************************
Parameters:
- columns: the list of column of that table. Each parameter is described below
    title: shown on UI;
    name: actual column name used on CRUD methods;
    type?: input field type;
    hideColumn?: if true, this column will appear only on create/edit modals;
    inputFormat?: for time picker (e.g. "HH:mm:ss");
    max?: field maximum, used for validation;
    min?: field minimum, used for validation;
    placeholder?: field placeholder;
    required?: whether the value is required or not, used for validation;
    validate?: you can provide a custom validation method;
}>;
- getHook: method that returns the hook with the CRUD methods. Has to implement
the ICRUDContext, even though some methods can be overwritten by
fetchAllEntries, createEntry, updateEntry and deleteEntry;
- name: data name, used on messages. Example "turno";
- defaultNewEntry: start value for eah field when creating a new entry.
    NOTE: you can include values that are not explicitly declared in 'columns';
- defaultSort: default sorting column;
- entryDescriptor?: column that differentiates each entry for the user. Used in
    titles and messages;
- excludeColumns?: array of columns that should be removed when editing an entry;
- defaultSortOrder?: default sorting 'asc' (default) or 'desc';
- defaultPerPage?: default number of lines per page;
- fetchAllEntries?: custom fetch all method;
- createEntry?: custom create method;
- updateEntry?: custom update method;
- deleteEntry?: custom delete method;
*/

import moment from "moment";
import React, { FC, useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { styled } from '@mui/material/styles';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';

import Spacing from "../../atoms/spacing";
import Modal from '../../atoms/modal';
import Container from '../../atoms/container';
import Text, { BodyText, Title4 } from '../../atoms/text';
import TimePicker from '../../atoms/timePicker';
import Theme from '../../theme';
import { BORDER_RADIUS, PADDING } from '../../../utils/consts';
import {
    ICRUDContext,
    IEntity
} from '../../../types';
import { useInformation } from '../../../hooks/information';


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


interface ArrowIconProps {
    className: string;
}

interface IEditModal {
    errors: Array<string | null>
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ className }) => {
    return (
        <div className={className} style={{display: 'inline-block', height: '24px', width: '24px'}}>
            {className === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
    );
};

type Order = 'asc' | 'desc';


export interface CRUDParams<IEntryType extends IEntity, IParams> {
    columns: Array<{
        title: string,
        name: keyof IEntryType,
        type?: 'string' | 'integer' | 'float' | 'time',
        multiselect?: boolean,
        options?: Array<{id: string | number, label: string}>,
        hideColumn?: boolean,
        hidden?: boolean,
        max?: number,
        min?: number,
        placeholder?: string,
        required?: boolean,
        inputFormat?: string,
        validate?: (value: any, entry?: any) => {error: string, value: any},
    }>;
    getHook: (params: IParams) => ICRUDContext<IEntryType>;
    name: string;
    defaultNewEntry: IEntryType;
    defaultSort: keyof IEntryType;
    entryDescriptor?: keyof IEntryType;
    excludeColumns?: (keyof IEntryType)[];
    defaultSortOrder?: Order;
    defaultPerPage?: number;
    fetchAllEntries?: (
        hook?: ICRUDContext<IEntryType>,
        params?: IParams
    ) => Promise<IEntryType[] | null>;
    createEntry?: (
        _payload: IEntryType,
        hook?: ICRUDContext<IEntryType>,
        params?: IParams
    ) => Promise<IEntryType | null>;
    updateEntry?: (
        _id: string,
        _payload: IEntryType,
        hook?: ICRUDContext<IEntryType>,
        params?: IParams
    ) => Promise<IEntryType | null>;
    deleteEntry?: (
        _id: string,
        hook?: ICRUDContext<IEntryType>,
        params?: IParams
    ) => Promise<IEntryType | null>;
}

export default function generateCRUDTable<IEntryType extends IEntity, IParams>({
    name,
    getHook,
    defaultNewEntry,
    columns,
    defaultSort,
    defaultSortOrder = 'asc',
    defaultPerPage = 5,
    entryDescriptor = 'id',
    excludeColumns,
    fetchAllEntries,
    createEntry,
    updateEntry,
    deleteEntry,
}: CRUDParams<IEntryType, IParams>) {
    const CRUDTable: FC<IParams> = (params: IParams) => {
        const [data, setData] = useState<IEntryType[]>([]);     // sorted data
        const [perTablePage, setPerTablePage] = useState<number>(defaultPerPage); // number of lines per page
        const [currentPage, setCurrentPage] = useState<number>(0);  // current page number
        const [orderBy, setOrderBy] = useState<keyof IEntryType>(defaultSort);  // column used for ordering
        const [order, setOrder] = useState<Order>(defaultSortOrder);  // order direction
        const [deleteModal, setDeleteModal] = useState<IEntryType | null>(null);  // delete modal data
        const [editModal, setEditModal] = useState<IEditModal & IEntryType | null>(null);  // edit/create modal data
        const [editModalTitle, setEditModalTitle] = useState<string>('');   // edit/create modal title

        const { showInformation } = useInformation();
        const hook = getHook(params);

        // Use either provided CRUD methods or hook methods
        const customFetchAll = fetchAllEntries ?
            async () => {
                return await hook.executeLoading(async (_params) => {
                    const res = await fetchAllEntries(hook, params);
                    if (res)
                        hook.setEntries(res);
                    else
                        hook.setEntries([]);
                    return res;
                });
            } :
            () => hook.fetchAllEntries();

        const customCreate = createEntry ?
            async (_payload: IEntryType) => {
                return await hook.executeLoading(async (_params) => 
                    await createEntry(_payload, hook, params)
                );
            } :
            (_payload: IEntryType) => hook.createEntry(_payload);
        
        const customUpdate = updateEntry ?
            async (_id: string, _payload: IEntryType) => {
                return await hook.executeLoading(async (_params) => 
                    await updateEntry(_id, _payload, hook, params)
                );
            } :
            (_id: string, _payload: IEntryType) => hook.updateEntry(_id, _payload);

        const customDelete = deleteEntry ?
            async (_id: string) => {
                return await hook.executeLoading(async (_params) => 
                    await deleteEntry(_id, hook, params)
                );
            } :
            (_id: string) => hook.deleteEntry(_id);

        useEffect(() => {
            // Start by fetching data for the table
            customFetchAll();
        }, [])

        useEffect(() => {
            // If hook data changes, sort and set component's data
            if(hook.entries !== null && hook.entries !== undefined){
                const sortedData = stableSort(hook.entries);
                setData(sortedData);
                if (currentPage * perTablePage >= sortedData.length)
                    setCurrentPage(0);
            }
        }, [hook.entries]);

        useEffect(() => {
            // Sort data again when ordering or pagination changes
            const sortedData = stableSort(data);
            setData(sortedData);
        }, [order, orderBy, currentPage, perTablePage])

        // Pagination and sorting onChange handlers
        const handleChangePage = (event: unknown, newPage: number) => {
            setCurrentPage(newPage);
        };
    
        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPerTablePage(parseInt(event.target.value, 10));
            setCurrentPage(0);
        };
        
        const handleSort = (property: keyof IEntryType) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        };

        function getComparator() {
            // basic comparator method using 'orderBy' and 'order' states
            return (a: IEntryType, b: IEntryType) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];
            
                if (aValue === undefined || bValue === undefined) {
                    return 0;
                }
            
                if (aValue! < bValue!) {
                    return order === 'asc' ? -1 : 1;
                }
                if (aValue! > bValue!) {
                    return order === 'asc' ? 1 : -1;
                }
                return 0;
            };
        };

        function stableSort(array: IEntryType[]) {
            // Sorting utility, as JS is not very polite when sorting things.
            // It simply uses the comparator based on the state and sorts the
            // data.
            if (!array) return array;
            const comparator = getComparator();

            const stabilizedThis = array.map((entry, index) => [entry, index]);
            stabilizedThis.sort((a, b) => {
              const order = comparator(a[0] as IEntryType, b[0] as IEntryType);
              if (order !== 0) return order;
              return (a[1] as number) - (b[1] as number);
            });
            return stabilizedThis.map((entry) => entry[0] as IEntryType);
        };

        function paginate(data: IEntryType[], perPage: number = perTablePage, page: number = currentPage) {
            // returns just the data for the current page
            return data.slice(
                page * perPage,
                Math.min((page + 1) * perPage, data.length)
            );
        };

        const emptyRows = Array.from({ length:  perTablePage }, (_, index) => (
            // When there's no data, display 'perPage' empty rows as empty state
            <StyledTableRow key={`empty-${index}`}>
                {columns.filter(item => item.hideColumn !== true && item.hidden !== true).map((item, index) => {
                    if (index === 0)
                        return <StyledTableCell key={index} component="th" scope="row" align='center'>-</StyledTableCell>
                    return <StyledTableCell align="center" key={index}>-</StyledTableCell>
                })}
                <StyledTableCell align="center" key='actions'>-</StyledTableCell>
            </StyledTableRow>
        ));

        function validateValue(columnIndex: number, value: any, entry?: any) {
            // Validator utility used to validate each column. If a custom
            // validation method has been provided, use it, otherwise use
            // default methods.
            let error = '';
            const column = columns[columnIndex]!;
            if (column.validate)
                return column.validate!(value, entry);
            else if (column.multiselect) // Not evaluating multiselects
                return { error, value }
            else {
                if (value === '' || value === undefined || value === null) {
                    value = '';
                    if (column.required)
                        error = 'Campo obrigatório';
                    else
                        value = null;
                } else if (column.type === 'integer') {
                    if (typeof value !== 'number') {
                        try {
                            value = parseInt(value);
                            if (isNaN(value))
                                throw new Error()
                        } catch (e) {
                            error = 'Apenas números são aceitos';
                            value = editModal![column.name];
                        }
                    }
                    if (!error) {
                        if (column.min !== undefined && value < column.min)
                            error = `Mínimo: ${column.min}`;
                        else if (column.max !== undefined && value > column.max)
                            error = `Máximo: ${column.max}`;
                    }
                } else if (column.type === 'string' || !column.type) {
                    if (typeof value !== 'string')
                        value = String(value);
                    if (column.min !== undefined && value.length < column.min)
                        error = `Mínimo de ${column.min} caracteres`;
                    else if (column.max !== undefined && value.length > column.max)
                        error = `Máximo de ${column.max} caracteres`;
                } else if (column.type === 'float') {
                    if (typeof value !== 'number') {
                        try {
                            value = parseFloat(value.replace(',', '.').replace(' ', ''));
                            if (isNaN(value))
                                throw new Error()
                        } catch (e) {
                            error = 'Apenas números são aceitos';
                            value = editModal![column.name];
                        }
                    }
                    if (!error) {
                        if (column.min !== undefined && value < column.min)
                            error = `Mínimo: ${column.min}`;
                        else if (column.max !== undefined && value > column.max)
                            error = `Máximo: ${column.max}`;
                    }
                }
            }
            return { error, value }
        };

        function handleFormInputChange(
            newValue: any, index: number, validate: boolean=false,
            editModalData?: IEditModal & IEntryType
        ) {
            // Create/edit modal fields onChange handler
            const column = columns[index]!;
            let newData: IEditModal & IEntryType = editModalData ?
                {...editModalData!} : {...editModal!};
            if (validate) {
                const { error, value } = validateValue(index, newValue, editModalData);
                newData.errors![index] = error;
                (newData[column.name] as any) = value;
            } else {
                (newData[column.name] as any) = newValue;
            }
            setEditModal(newData);
            return newData
        };

        // create each form input based on 'editModal' data
        const formInputs = (entry: IEntryType) => {
            if (!editModal)
                return <></>
            return (
                <>
                    {columns.filter((item) => item.hidden !== true).map((item, index) =>
                        <Container fluid width={'100%'} key={index}>
                            <Text semiBold size={15}>{item.title}</Text>
                            {!item.options && (!item.type || ['string', 'integer', 'float'].includes(item.type)) &&
                                <TextField id={item.name as string}
                                    required={item.required !== true}
                                    value={editModal[item.name]}
                                    onChange={(e) => handleFormInputChange(e.target.value, index)}
                                    placeholder={item.placeholder || item.title}
                                    error={!!editModal.errors[index]} />
                            }
                            {item.options && (!item.type || ['string', 'integer', 'float'].includes(item.type)) &&
                                <Autocomplete
                                    multiple={item.multiselect}
                                    options={item.options}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        placeholder={item.placeholder || item.title}
                                        title={item.title}
                                    />}
                                    onChange={(e, newValue) => {
                                        let val: null | string | number | (string | number)[] = null;
                                        if (newValue)
                                            if (Array.isArray(newValue))
                                                val = (newValue as ({id: string | number, label: string}[])).map!((i) => i.id)
                                            else
                                                val = newValue.id;
                                        handleFormInputChange(val, index)
                                    }}
                                    value={
                                        !editModal[item.name] ? []
                                        : item.multiselect ?
                                        item.options.filter((opt) => (editModal[item.name] as (string | number)[]).includes(opt.id))
                                        : item.options.find((opt) => (editModal[item.name] as (string | number)) === opt.id)
                                    }
                                />
                            }
                            {item.type === 'time' &&
                                <TimePicker
                                    inputFormat={item.inputFormat || "HH:mm:ss"}
                                    label={item.title}
                                    placeholder={item.placeholder || item.title}
                                    value={editModal[item.name] ? moment(editModal[item.name] as string, "HH:mm:ss").toDate() : null}
                                    setValue={(val) => {
                                        const newValue = moment(val, item.inputFormat || "HH:mm:ss").isValid() ?
                                            moment(val).format(item.inputFormat || "HH:mm:ss") : null;
                                        handleFormInputChange(newValue, index);
                                    }}
                                />
                            }
                            {editModal.errors[index] &&
                                <Text size={13} color={Theme.palette.error.main}>
                                    {editModal.errors[index]}
                                </Text>
                            }
                        </Container>
                    )}
                </>
            )
        };

        const onFormSubmit = async () => {
            // Create/edit modal onSubmit handler
            if (!editModal)
                return

            let currEditModal = undefined;  // Required to handle react's state lifecycle
            for (const columnIndex in columns) {
                // revalidate all fields
                const index = parseInt(columnIndex);
                currEditModal = handleFormInputChange(
                    editModal![columns[index].name],
                    index,
                    true,
                    currEditModal
                );
            }

            if (currEditModal && !currEditModal.errors.some(item => !!item)) {
                const {errors, ...entry} = currEditModal;
                try {
                    if (!entry.id) {
                        await customCreate((entry as unknown) as IEntryType);
                    } else {
                        const id = entry.id;
                        delete entry.id;
                        await customUpdate(id, (entry as unknown) as IEntryType);
                    }
                } catch (e) {
                    console.error(e);
                    showInformation((e as any).message, 'Erro ao enviar dados');
                    return
                }
                setEditModal(null);
                setEditModalTitle('');
                await customFetchAll();
            }
        }

        const filledRow = (entry: IEntryType) => {
            // build each row with data based on 'columns' parameters
            return (
                <StyledTableRow key={`row-${entry.id}`}>
                    {columns.filter(item => item.hideColumn !== true && item.hidden !== true).map((item, index) => {
                        let value: string = '';
                        if (!item.options || item.options.length === 0) {
                            if (!Array.isArray(entry[item.name]))
                                value = entry[item.name] as string;
                            else
                                value = (entry[item.name] as string[]).join(', ');
                        } else if (!item.multiselect || !Array.isArray(entry[item.name])) {
                            value = item.options!.find(
                                (option) => option.id === entry[item.name]
                            )?.label || entry[item.name] as string;
                        } else {
                            value = (entry[item.name] as string[]).reduce((res, val) => {
                                let curr = item.options!.find((option) => option.id === val)?.label;
                                if (curr)
                                    res.push(curr);
                                else
                                    res.push(val);
                                return res
                            }, [] as string[]).join(', ')
                        }
                        if (index === 0)
                            return <StyledTableCell key={index} component="th" scope="row" align='center'>
                                {value}
                            </StyledTableCell>
                        return <StyledTableCell align="center" key={`col-${index}`}>
                            {value}
                        </StyledTableCell>
                    })}
                    <StyledTableCell align="center" key='actions'>
                        <Box sx={{ display: 'inline-flex' }}>
                            <Tooltip title={`Editar ${name}`}>
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => {
                                        const options = columns.find((c) => c.name === entryDescriptor)?.options;
                                        if (!options || !Array.isArray(options) || options.length === 0)
                                            setEditModalTitle(`Editar ${name} ${entry[entryDescriptor]}`);
                                        else {
                                            const label = options.find((o) => o.id === entry[entryDescriptor])?.label
                                            setEditModalTitle(`Editar ${name} ${label || entry[entryDescriptor]}`);
                                        }
                                        let editEntry: any = {
                                            ...entry
                                        };
                                        if (excludeColumns)
                                            for (const col of excludeColumns)
                                                delete editEntry[col];
                                        setEditModal({...editEntry, errors: columns.map((item) => '')});
                                    }}
                                    disableFocusRipple
                                >
                                    <EditIcon color="info" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Spacing left={PADDING} />
                            <Tooltip title={`Excluir ${name}`}>
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => setDeleteModal(entry)}
                                >
                                    <DeleteIcon color="error" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </StyledTableCell>
                </StyledTableRow>
            )
        }

        const onDelete = async (entry: IEntryType) => {
            // Delete modal confirmation handler
            await customDelete(entry.id as string);
            await customFetchAll();
            setDeleteModal(null);
        }
    
        return (<>
            <Container fluid inline sx={{ justifyContent: 'flex-end', marginBottom: '12px' }}>
                <IconButton color='primary'
                    onClick={() => {
                        setEditModalTitle(`Novo(a) ${name}`);
                        setEditModal({
                            ...defaultNewEntry,
                            errors: columns.map((item) => '')
                        });
                    }}
                    sx={{ fontSize: '1.8em', marginRight: '8px' }}
                >
                    <AddIcon fontSize='inherit'/>
                </IconButton>
                <IconButton color='primary'
                    onClick={() => customFetchAll()}
                    sx={{ fontSize: '1.8em' }}
                >
                    <RefreshIcon fontSize='inherit'/>
                </IconButton>
            </Container>
            <TableContainer component={Paper} sx={{borderRadius: BORDER_RADIUS*4,  maxHeight: '602px', overflow: 'auto'}}>
                <Table stickyHeader sx={{ minWidth: '100%', minHeight: '550px'}} aria-label="customized table">
                    <TableHead >
                        <TableRow sx={{height: '6em'}}>
                            {(!hook.loading && columns) && columns.filter(item => item.hideColumn !== true && item.hidden !== true).map((item, index) =>
                            <StyledTableCell key={index} align="center" onClick={() => handleSort(item.name)} sx={{ cursor: 'pointer' }}>
                                <span style={{display: 'flex', justifyContent: 'center'}}>
                                    {item.title}
                                    {orderBy === item.name && <ArrowIcon className={order === 'asc' ? 'asc' : 'desc'}/>}
                                </span>
                            </StyledTableCell>
                            )}
                            <StyledTableCell key='actions-column' align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {hook.loading &&
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row" align='center'>
                                    <CircularProgress />
                                </StyledTableCell>
                            </StyledTableRow>
                        }
                        {!hook.loading && (
                            data.length > 0 ?
                            (
                                paginate(data, perTablePage, currentPage).map((row) => (filledRow(row)))
                            ) : (
                                emptyRows.map( (emptyRow, index) => (
                                    <React.Fragment key={index}>{emptyRow}</React.Fragment>
                                ))
                            ))
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    showFirstButton
                    showLastButton
                    component="div"
                    count={hook.entries ? hook.entries.length : 0}
                    rowsPerPage={perTablePage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Modal key='delete-modal' isOpened={deleteModal !== null} onClose={() => setDeleteModal(null)}>
                <Container fluid color={Theme.palette.background.default} hasShadow centered sx={{ maxWidth: 400 }} veryPadded>
                    <Spacing top={10} />
                    {deleteModal &&
                        <Title4 center>
                            Tem certeza que deseja excluir {name} "{deleteModal[entryDescriptor] as string}"?
                        </Title4>
                    }
                    <Spacing top={30} />
                    <Container fluid inline centered>
                        <Button color="primary" variant="contained" size="large" key='delete-confirm'
                            style={{ minWidth: '130px', marginRight: '8px' }}
                            onClick={() => onDelete(deleteModal!)}
                        >
                            Excluir
                        </Button>
                        <Button color='primary' variant="text" style={{ minWidth: '130px' }} size="large"
                            key='delete-cancel'
                            onClick={() => setDeleteModal(null)}>
                            Cancelar
                        </Button>
                    </Container>
                    <Spacing top={10} />
                </Container>
            </Modal>
            <Modal key={`create-edit-modal-${name}`} isOpened={editModal !== null}
                onClose={() => {
                    setEditModalTitle('');
                    setEditModal(null);
                }}
            >
                <Container fluid color={Theme.palette.background.default} hasShadow veryPadded
                        sx={{ alignItems: 'center', minWidth: '30vw', maxWidth: '95vw', overflow: 'auto', maxHeight: '95vh' }}>
                    <Spacing top={10} />
                    {editModal && <Title4 center>{editModalTitle}</Title4>}
                    <Spacing top={10} />
                    {editModal && formInputs(editModal)}
                    <Spacing top={30} />
                    <Container fluid inline centered>
                        <Button color="primary" variant="contained" size="large" key='edit-confirm'
                            style={{ minWidth: '130px', marginRight: '8px' }}
                            onClick={() => onFormSubmit()}
                        >
                            Salvar
                        </Button>
                        <Button color='primary' variant="text" style={{ minWidth: '130px' }} size="large"
                            key='edit-cancel'
                            onClick={() => {
                                setEditModalTitle('');
                                setEditModal(null);
                            }}
                        >
                            Cancelar
                        </Button>
                    </Container>
                    <Spacing top={10} />
                </Container>
            </Modal>
        </>);
    };
    return CRUDTable;
};
