import React, { FC, createContext, useState, useContext } from 'react';
import moment from 'moment';
import csvToXlsx from '../utils/csvToXlsx';

import {
    ISlaughterDelaysContext,
    ISlaughterDelaysDashboardStats,
    ISlaughterDelaysFilter,
    ISlaughterDelaysMainCausesData,
    ISlaughterDelaysOccurrencesData,
} from '../types/hooks/slaughterDelays';
import {
    getDashboardStats,
    getMainCauses,
    getOccurrencesData,
    getSpreadsheetCSV,
 } from '../services/slaughterDelays';
import { useInformation } from './information';
import { useSlaughterDelaysFilter } from './slaughterDelaysFilter';


const SlaughterDelaysContext = createContext<ISlaughterDelaysContext>(
    {} as ISlaughterDelaysContext);


export const SlaughterDelaysProvider: FC<any> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [groupByDuration, setGroupByDuration] = useState<boolean>(false);
    const [dashboardStats, setDashboardStats] = useState<ISlaughterDelaysDashboardStats | null>(null);
    const [occurrencesData, setOccurrencesData] = useState<ISlaughterDelaysOccurrencesData[]>([]);
    const [mainReasons, setMainReasons] = useState<ISlaughterDelaysMainCausesData>(
        {duration: [], occurrences: []});
    const [mainLocations, setMainLocations] = useState<ISlaughterDelaysMainCausesData>(
        {duration: [], occurrences: []});

    const filter = useSlaughterDelaysFilter();
    const { showInformation } = useInformation();

    const executeLoading = async (_func: () => any, timeout: number = 8000) => {
        setLoading(true);
        const interval = setInterval(() => {
            setLoading(false);
        }, timeout);

        const res: any = await _func();

        setLoading(false);
        clearInterval(interval);
        return res;
    };

    const getFilename = () => {
        const formattedInitialDate = (filter.initialDate?.toISOString().slice(0,10)
            || moment().format('YYYY-MM-DD'));
        let filename = `${filter.branch!.name}_${formattedInitialDate}`;
        if (filter.finalDate && filter.finalDate.toISOString().slice(0,10) !== formattedInitialDate)
            filename += `_${filter.finalDate.toISOString().slice(0,10)}`;
        if (filter.delayType)
            filename += `_tipo${filter.delayType}`;
        return filename;
    };

    const downloadSpreadsheet = async () => {
        filter.setBranchesExpanded(false);

        if (filter.validate()) {
            await executeLoading(async () => {
                const query = filter.buildQuery();
                try {
                    const csvData = await getSpreadsheetCSV(filter.branch!.id!, query);
                    const filename = getFilename();
                    csvToXlsx(csvData, filename);
                } catch (e) {
                    console.error(`Failed to generate slaughter delays XLSX: ${e}`);
                    showInformation(
                        'Falha ao gerar planilha de atrasos e paradas.',
                        'Erro'
                    );
                };
            });
        };
    };

    const fetchDashboard = async (filterParams?: Partial<ISlaughterDelaysFilter>) => {
        filter.setBranchesExpanded(false);
        setDashboardStats(null);
        setOccurrencesData([]);
        setMainLocations({duration: [], occurrences: []});
        setMainReasons({duration: [], occurrences: []});

        if (filter.validate()) {
            await executeLoading(async () => {
                const query = filter.buildQuery(filterParams);
                try {
                    await Promise.all([
                        getDashboardStats(filter.branch!.id!, query).then(
                            res => setDashboardStats(res)),
                        getOccurrencesData(filter.branch!.id!, query).then(
                            res => setOccurrencesData(res)),
                        getMainCauses(filter.branch!.id!, query).then(res => {
                            setMainLocations(res.locations);
                            setMainReasons(res.reasons);
                        })
                    ]);
                } catch (e) {
                    console.error(`Failed to get slaughter delays dashboard: ${e}`);
                    showInformation(
                        'Falha ao buscar dados de atrasos e paradas.',
                        'Erro'
                    );
                };
            });
        };
    };

    return (
        <SlaughterDelaysContext.Provider value={{
            dashboardStats,
            groupByDuration,
            loading,
            mainLocations,
            mainReasons,
            occurrencesData,
            downloadSpreadsheet,
            fetchDashboard,
            setGroupByDuration,
            setLoading,
        }}>
            {children}
        </SlaughterDelaysContext.Provider>
    );
};

export function useSlaughterDelays() {
    const context = useContext(SlaughterDelaysContext);

    if (!context) {
        throw new Error(
            'useSlaughterDelays must be used within an SlaughterDelaysProvider');
    }

    return context;
};
