import React, { FC, useEffect, useState } from "react";

import CompanyListItem from "../listItem";
import Container from "../../../atoms/container";
import Text from "../../../atoms/text";
import { Pagination } from "../../../../components";
import { useCompanies } from "../../../../hooks/companies";
import { useResponsive } from "../../../../hooks/responsive";
import { ICompany, ICompanyList } from "../../../../types";
import { LinearProgress } from "@mui/material";

const CompaniesList: FC<ICompanyList> = (params: ICompanyList) => {
    const companiesHooks = useCompanies();
    const { screenHeight } = useResponsive();

    const pageCount = Math.ceil((screenHeight * 0.6) / 120);
    const companiesCount = companiesHooks.filteredCompanies.length;

    const [currPage, setCurrPage] = useState<number>(0);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setCurrPage(0);
    }, [companiesHooks.searchCompany]);

    const fetchData = () => {
        companiesHooks.fetchCompanies();
    };

    return (
        <Container fluid flex width="100%" minHeight="73vh">
            <Container fluid width="100%" minHeight="73vh">
                {companiesHooks.loading ? <LinearProgress color="primary" /> :
                    companiesHooks.filteredCompanies.length == 0 && <Text>Nenhuma empresa encontrada.</Text>}
                {companiesHooks.filteredCompanies.sort((a: ICompany, b: ICompany) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                }).slice((currPage * pageCount), ((currPage * pageCount) + pageCount))
                    .map((item, index) =>
                        <Container key={index} fluid verticalPadded width="100%">
                            <CompanyListItem
                                key={index}
                                company={item}
                                loading={companiesHooks.loading}
                                onOpenBranches={params.onOpenBranches}
                                onEditCompany={params.onEditCompany}
                                onDeleteCompany={params.onDeleteCompany} />
                        </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(companiesCount / pageCount)} setPage={setCurrPage} />
        </Container>
    );
};

export default CompaniesList;
