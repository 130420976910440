import React, { FC, useState } from 'react'
import {
    Backdrop,
    Button,
    CircularProgress,
    Fab,
    Grid,
    Grow,
    Pagination,
} from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material';
import {
    Container,
    Page,
    Spacing,
    Text,
    theme,
    VerdictContinuosGrid,
    VerdictFilter,
    VerdictCountList,
} from '../../components';
import { useAuth } from '../../hooks/auth';
import { useCarcass } from '../../hooks/carcasses';
import { useInformation } from '../../hooks/information';
import { useVerdicts } from "../../hooks/verdicts";
import { ICarcass, IVerdict, IVerdictFilter } from '../../types';
import { PADDING } from '../../utils/consts';
import { Title1, BodyText } from '../../components/atoms/text';
import VerdictDownloadSheetModal from '../../components/molecules/verdicts/downloadSheetModal';
import VerdictDownloadModal from '../../components/molecules/verdicts/downloadModal';


const VerdictsFlow: FC = () => {
    const { user } = useAuth();
    const { fetchCarcassesForVerdicts, loadingCarcassesForVerdicts, carcassesForVerdicts } = useCarcass();
    const { hideInformation, showInformation } = useInformation();
    const { filter, setFilter, batchCreateNewVerdicts, loading } = useVerdicts();

    const [selectedCarcass, setSelectedCarcass] = useState<ICarcass[]>([]);
    const [contextMenuIsOpen, setContextMenuIsOpen] = useState<boolean>(false);
    const [downloadVerdictSheetIsOpen, setDownloadVerdictSheetIsOpen] = useState<boolean>(false);
    const [downloadVerdictPhotosIsOpen, setDownloadVerdictPhotosIsOpen] = useState<boolean>(false);

    const [fabHover, setFabHover] = useState<boolean>(false);
    const [fabLoading, setFabLoading] = useState<boolean>(false);

    const totalNoVerdictClass = (carcassesForVerdicts && carcassesForVerdicts.countForVerdictClass ?
        Object.keys(carcassesForVerdicts.countForVerdictClass).reduce((res: number, key: any) => {
            return res + carcassesForVerdicts.countForVerdictClass[key];
        }, 0)
        : 0
    );
    const totalVerdictClass = (carcassesForVerdicts &&
                               carcassesForVerdicts.countForVerdictClass &&
                               carcassesForVerdicts.countVerdicts.classification ?
        Object.keys(carcassesForVerdicts.countVerdicts.classification).reduce((res: number, key: any) => {
            return res + Object.keys(carcassesForVerdicts.countVerdicts.classification[key]).reduce(
                (innerRes: number, innerKey: any) => {
                    return innerRes + carcassesForVerdicts.countVerdicts.classification[key][innerKey];
                }, 0
            );
        }, 0)
        : 0
    );

    const totalNoVerdictConf = (carcassesForVerdicts &&
                                carcassesForVerdicts.countForVerdictConf ?
        Object.keys(carcassesForVerdicts.countForVerdictConf).reduce((res: number, key: any) => {
            return res + carcassesForVerdicts.countForVerdictConf[key];
        }, 0)
        : 0
    );
    const totalVerdictConf = (carcassesForVerdicts &&
                              carcassesForVerdicts.countForVerdictConf &&
                              carcassesForVerdicts.countVerdicts.conformation ?
        Object.keys(carcassesForVerdicts.countVerdicts.conformation).reduce((res: number, key: any) => {
            return res + Object.keys(carcassesForVerdicts.countVerdicts.conformation[key]).reduce(
                (innerRes: number, innerKey: any) => {
                    return innerRes + carcassesForVerdicts.countVerdicts.conformation[key][innerKey];
                }, 0
            );
        }, 0)
        : 0
    );

    const onSelectCarcass = async (carcass: ICarcass) => {
        // if (typeof carcass.classificationId !== 'number') return;
        const carcassIndex = carcassesForVerdicts?.carcasses?.findIndex(
            item => item.id === carcass.id);
        if ([-1, undefined].includes(carcassIndex))
            // carcass not for verdict
            return;

        // update carcasses for verdict list for "re-selection" of a carcass
        carcassesForVerdicts!.carcasses[carcassIndex!] = {
            ...carcassesForVerdicts!.carcasses[carcassIndex!],
            ...carcass
        };

        const index = selectedCarcass.findIndex(
            item => item.id === carcass.id);
        if (index === -1) {
            setSelectedCarcass([...selectedCarcass, carcass]);
        } else {
            const aux: ICarcass[] = [...selectedCarcass];
            if (selectedCarcass[index].bestPlaced !== carcass.bestPlaced ||
                (selectedCarcass[index].bestPlacedB !== carcass.bestPlacedB) ||
                (selectedCarcass[index].classificationId !== carcass.classificationId &&
                    carcass.classificationId !== -1) ||
                (selectedCarcass[index].conformationId !== carcass.conformationId &&
                    carcass.conformationId !== -1)
            )
                aux[index] = carcass;
            else
                aux.splice(index, 1);
            setSelectedCarcass(aux);
        }
    }

    const applyFilter = async (_filter: IVerdictFilter) => {
        setFilter(_filter);
        setSelectedCarcass([]);
        fetchCarcassesForVerdicts(_filter);
    }

    const selectAllCarcass = async () => {
        const aux: ICarcass[] = [...selectedCarcass];

        if (carcassesForVerdicts) {
            for (let i = 0; i < carcassesForVerdicts!.carcasses.length; i++) {
                const carcass: ICarcass = carcassesForVerdicts!.carcasses[i];
                if (selectedCarcass.find(item => item.id === carcass.id))
                    continue;
                if (carcass.bestPlaced === undefined)
                    carcass.bestPlaced = true;
                if (carcass.bestPlacedB === undefined)
                    carcass.bestPlacedB = true;
                if (filter.property !== 'conformation' &&
                        !carcass.classificationId)
                {
                    showInformation(
                        `Animal "${carcass.sequence || carcass.partnerId!}" `
                        + `sem classificação para acabamento. Não foi possível`
                        + ` selecionar todos.`,
                        'Erro'
                    )
                    break;
                }
                if (filter.property !== 'classification' &&
                        !carcass.conformationId)
                {
                    showInformation(
                        `Animal "${carcass.sequence || carcass.partnerId!}" `
                        + `sem classificação para conformação. Não foi possível`
                        + ` selecionar todos.`,
                        'Erro'
                    )
                    break;
                }
                aux.push(carcass);
            }
        }
        setSelectedCarcass(aux);
    }

    const sendCarcasses = async () => {
        if (!user || fabLoading) return;
        if (selectedCarcass.length === 0) {
            showInformation('Selecione pelo menos uma anotação.', 'Aviso', undefined, () => {
                hideInformation();
            })
            return;
        }

        setFabLoading(true);
        setTimeout(() => {
            setFabLoading(false);
        }, 10000);
        const _verdicts = selectedCarcass.reduce((res, carcass) => {
            if (carcass.id)
                res.push({
                    carcassId: carcass.id,
                    bestPlaced: carcass.bestPlaced,
                    classification: filter.property !== 'conformation' ? carcass.classificationId : undefined,
                    conformation: filter.property !== 'classification' ? carcass.conformationId : undefined,
                });
            if (carcass.idB)
                res.push({
                    carcassId: carcass.idB,
                    bestPlaced: carcass.bestPlacedB,
                    classification: filter.property !== 'conformation' ? carcass.classificationId : undefined,
                    conformation: filter.property !== 'classification' ? carcass.conformationId : undefined,
                });
            return res

        }, [] as IVerdict[]);
        let successfulVerdicts = 0;
        try{
            successfulVerdicts = await batchCreateNewVerdicts(_verdicts, false);
            showInformation(`${successfulVerdicts} veredito(s) criados com sucesso.`, 'Aviso', undefined, () => {
                hideInformation();
                fetchCarcassesForVerdicts(filter);
                setSelectedCarcass([]);
            });
        } catch (error){
            setFabLoading(false);
            const errorMessage = `Apenas ${successfulVerdicts} veredito(s) criado(s) com sucesso. Erro: ${error}`
            showInformation(errorMessage, 'Erro', undefined, ()=>{
                hideInformation();
                fetchCarcassesForVerdicts(filter);
                setSelectedCarcass([]);
            });
        } finally {
            setFabLoading(false);
        }
    };


    const onChangePage = (event: any, newPage: number) => {
        const newFilter = {
            ...filter,
            offset: filter.limit * (newPage - 1),
        };
        applyFilter(newFilter);
    };

    return (
        <Page width='100%' height='auto' style={{ minHeight: '95vh', }}>
            <Container fluid color={theme.palette.background.default} width="100%" minHeight="95vh">
                <Container veryPadded>
                    <Title1 style={{ whiteSpace: 'nowrap' }}>Arbitragem</Title1>
                    <BodyText muted>Treinamento / Arbitragem</BodyText>
                </Container>

                <Container inline width="100%" height="auto">
                    <VerdictFilter
                        filter={filter}
                        onApplyFilter={applyFilter}
                        onCleanFilter={
                            () => setFilter({
                                initialDate: new Date(), finalDate: new Date(),
                                annotators: [], branches: [],
                                classificationsId: [],
                                conformationsId: [],
                                limit: 10,
                                accuracy: 0,
                                localClassifications: [],
                                localConformations: [],
                                option: 'all',
                                property: 'all',
                            })
                        }
                        loading={(loading || loadingCarcassesForVerdicts)}
                        open={contextMenuIsOpen}
                        setOpen={setContextMenuIsOpen}
                        downloadVerdictSheetIsOpen={downloadVerdictSheetIsOpen}
                        setDownloadVerdictSheetIsOpen={setDownloadVerdictSheetIsOpen}
                        downloadVerdictPhotosIsOpen={downloadVerdictPhotosIsOpen}
                        setDownloadVerdictPhotosIsOpen={setDownloadVerdictPhotosIsOpen}
                    />
                </Container>

                {carcassesForVerdicts &&
                    <VerdictCountList
                        carcasses={carcassesForVerdicts.carcasses}
                        countVerdicts={carcassesForVerdicts.countVerdicts}
                        countForVerdictClass={
                            filter.property !== 'conformation' ?
                            carcassesForVerdicts.countForVerdictClass : null
                        }
                        countForVerdictConf={
                            filter.property !== 'classification' ?
                            carcassesForVerdicts.countForVerdictConf : null
                        }
                        totalNoVerdictClass={totalNoVerdictClass}
                        totalVerdictClass={totalVerdictClass}
                        totalNoVerdictConf={totalNoVerdictConf}
                        totalVerdictConf={totalVerdictConf}
                    />
                }

                {carcassesForVerdicts && carcassesForVerdicts.carcasses.length == 0 &&
                    <Container fluid centered height="450px">
                        <Container fluid centered width="500px" height="450px">
                            <Text bold center size={20}>Nenhuma carcaça foi encontrada para arbitragem com o filtro selecionado.</Text>
                        </Container>
                    </Container>
                }

                {carcassesForVerdicts && carcassesForVerdicts.carcasses.length > 0 &&
                <>
                    <Grid container justifyContent='right' sx={{ marginBottom: 1 }}>
                        <Grid item>
                            <Pagination
                                count={Math.ceil(Math.max(totalNoVerdictClass, totalNoVerdictConf) / filter.limit)}
                                page={Math.floor((filter.offset || 0) / filter.limit) + 1}
                                size="large"
                                sx={{
                                    '& 	.MuiPagination-ul > li > .Mui-selected':{
                                        'backgroundColor': `${theme.palette.primary.main}`,
                                        'color': `${theme.palette.grey[100]}`
                                    },
                                    '& 	.MuiPagination-ul > li > button:hover':{
                                        'backgroundColor': `${theme.palette.primary.main}`,
                                        'color': `${theme.palette.grey[100]}`
                                    }
                                }}
                                onChange={(event: any, newPage: number) => onChangePage(event, newPage)}
                            />
                        </Grid>
                    </Grid>
                    <Container fluid flex horizontalCentered color='#000' width="100%" height="100%">
                        <Container fluid flex width="99%">
                            <Container inline centered spacedBetween width="100%" sx={{ marginTop: PADDING }}>
                                <Container inline>
                                    <Spacing left={PADDING} />
                                    <Text size={13} color={theme.palette.background.paper}>Acabamentos selecionados: {selectedCarcass.length}</Text>
                                </Container>
                                <Container inline>
                                    <Button size="small" variant="contained" color="info" onClick={() => selectAllCarcass()}>
                                        <Text medium size={13} color={theme.palette.background.paper}>Selecionar todas</Text>
                                    </Button>
                                    <Spacing left={PADDING} />
                                    <Button size="small" variant="contained" color="info" onClick={() => setSelectedCarcass([])}>
                                        <Text medium size={13} color={theme.palette.background.paper}>Selecionar nenhuma</Text>
                                    </Button>
                                </Container>
                            </Container>
                            <VerdictContinuosGrid
                                carcasses={carcassesForVerdicts.carcasses}
                                selectedCarcasses={selectedCarcass}
                                property={filter.property || 'all'}
                                onSelectCarcass={(auxCarcass: ICarcass) => onSelectCarcass(auxCarcass)}
                            />
                        </Container>
                    </Container>
                </>}

                <Grow in={!fabHover}>
                    <Fab
                        disabled={fabLoading || loadingCarcassesForVerdicts}
                        onClick={() => sendCarcasses()}
                        variant="circular"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}
                    >
                        {fabLoading || loadingCarcassesForVerdicts ?
                            <CircularProgress sx={{ color: theme.palette.background.paper }} />
                            : <CheckIcon sx={{ color: theme.palette.background.paper }} />
                        }
                    </Fab>
                </Grow>
                <Grow in={fabHover}>
                    <Fab
                        disabled={fabLoading || loadingCarcassesForVerdicts}
                        onClick={() => sendCarcasses()}
                        variant="extended"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            height: 56,
                            borderRadius: '28px',
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        {fabHover &&
                            <Container inline>
                                <Text size={14} color={theme.palette.background.paper}>Enviar veredito</Text>
                                <Spacing left={PADDING} />
                            </Container>
                        }
                        {fabLoading || loadingCarcassesForVerdicts ?
                            <CircularProgress sx={{ color: theme.palette.background.paper }} />
                            : <CheckIcon sx={{ color: theme.palette.background.paper }} />
                        }
                    </Fab>
                </Grow>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingCarcassesForVerdicts}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <VerdictDownloadSheetModal
                open={downloadVerdictSheetIsOpen}
                onClose={() => {
                    setContextMenuIsOpen(false);
                    setDownloadVerdictSheetIsOpen(false);
                }}
                initialDate={filter.initialDate}
                finalDate={filter.finalDate}
                branches={filter.branches}
                classificationsId={filter.classificationsId}
                localClassifications={filter.localClassifications}
                conformationsId={filter.conformationsId}
                localConformations={filter.localConformations}
                includeClassification={filter.property !== 'conformation'}
                includeConformation={filter.property !== 'classification'}
            />
            <VerdictDownloadModal
                open={downloadVerdictPhotosIsOpen}
                onClose={() => {
                    setContextMenuIsOpen(false);
                    setDownloadVerdictPhotosIsOpen(false);
                }}
            />
        </Page >
    )
}

export default VerdictsFlow;
