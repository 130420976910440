import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material'
import { styled } from '@mui/material/styles';

import { BORDER_RADIUS } from '../../../../utils/consts';
import { convertNumberToFixedAndLocale } from '../../../../utils/stringFormatters';
import { IDashboardFilterData, IDashboardLotsTable, ILot, ILotsFilterParams } from '../../../../types';
import { useLots } from '../../../../hooks/lots';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


interface ArrowIconProps {
    className: string;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ className }) => {
    return (
        <div className={className} style={{display: 'inline-block', height: '24px', width: '24px'}}>
            {className === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
    );
};

type Order = 'asc' | 'desc';

const DashboardLotsTable: FC<IDashboardLotsTable> = (params: IDashboardLotsTable) => {
    const { lots, totalNumberOfLots, fetchLots } = useLots();
    const [data, setData] = useState<ILot[]>([]);
    const [filter, setFilter] = useState<IDashboardFilterData>({
        initialDate: moment().toDate(),
        finalDate: moment().toDate(),
        branches: [],
        farms: [],
        lotNums: [],
        dentitions: [],
        weightIntervals: [],
        sexes: [],
    });

    const [lotsPerTablePage, setLotsPerTablePage] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<keyof ILot>('lotNum');
    const [order, setOrder] = useState<Order>('asc');

    useEffect(() => {
        if(lots != null && lots != undefined){
            const sortedData = stableSort(lots, orderBy, order);
            setData(sortedData);
        }
    }, [lots])
    
    useEffect(() => {
        const lotsFilterParams: ILotsFilterParams = {
            initialDate: filter.initialDate,
            finalDate: filter.finalDate,
            branches: filter.branches,
            docNums: null,
            farms: filter.farms,
            lotNums: filter.lotNums,
            dentitions: filter.dentitions,
            weightIntervals: filter.weightIntervals,
            sexes: filter.sexes,
          };
        fetchLots(lotsFilterParams, currentPage+1, lotsPerTablePage)
    }, [])

    useEffect(() => {
        const lotsFilterParams: ILotsFilterParams = {
            initialDate: filter.initialDate,
            finalDate: filter.finalDate,
            branches: filter.branches,
            docNums: null,
            farms: filter.farms,
            lotNums: filter.lotNums,
            dentitions: filter.dentitions,
            weightIntervals: filter.weightIntervals,
            sexes: filter.sexes,
          };
        fetchLots(lotsFilterParams, currentPage+1, lotsPerTablePage)
    }, [filter, currentPage, lotsPerTablePage])
    
    useEffect(() => {
        if(params.filter !== undefined){
            setFilter(params.filter)
        }
    }, [params.filter])

    useEffect(() => {
        const sortedData = stableSort(data, orderBy, order);
        setData(sortedData);
    }, [order, orderBy])
    
    const handleChangePage = (event: unknown, newPage: number) => {
        //setData([]);
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLotsPerTablePage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };
    
    const handleSort = (property: keyof ILot) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array: ILot[], orderBy: keyof ILot, order: Order) {
        const comparator = getComparator(order, orderBy);
      
        const stabilizedThis = array.map((lot, index) => [lot, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0] as ILot, b[0] as ILot);
          if (order !== 0) return order;
          return (a[1] as number) - (b[1] as number);
        });
        return stabilizedThis.map((lot) => lot[0] as ILot);
    }

    function getComparator(order: Order, orderBy: keyof ILot) {
        return (a: ILot, b: ILot) => {
            const aValue = a[orderBy];
            const bValue = b[orderBy];
        
            if (aValue === undefined || bValue === undefined) {
                return 0;
            }
        
            if (aValue < bValue) {
                return order === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        };
    }
    const emptyRows = Array.from({ length:  lotsPerTablePage}, (_, index) => (
        <StyledTableRow>
            <StyledTableCell component="th" scope="row" align='center'>-</StyledTableCell>
            <StyledTableCell align="center">-</StyledTableCell>
            <StyledTableCell align="center">-</StyledTableCell>
            <StyledTableCell align="center">-</StyledTableCell>
            <StyledTableCell align="center">-</StyledTableCell>
            <StyledTableCell align="center">-</StyledTableCell>
        </StyledTableRow>
    ));

    const filledRow = (lot: ILot) => {
        return (
            <StyledTableRow key={`${lot.lotNum}-${lot.id}`}>
                <StyledTableCell component="th" scope="row" align='center'>
                    {lot.lotNum}
                </StyledTableCell>
                {/* <StyledTableCell align="center">{lot.farm}</StyledTableCell> */}
                <StyledTableCell align="center">{params.isFullCarcassCountChecked ? Math.floor(lot.carcassCount ? lot.carcassCount/2 : 0) : lot.carcassCount}</StyledTableCell>
                <StyledTableCell align="center">
                    {(lot.averageWeight !== undefined && lot.averageWeight !== null) ? 
                        (
                            params.isKgChecked ? convertNumberToFixedAndLocale(lot.averageWeight) : convertNumberToFixedAndLocale(lot.averageWeight/15)
                        ): 
                        '-'
                    }
                </StyledTableCell>
                <StyledTableCell align="center">
                    {(lot.totalWeight !== undefined && lot.totalWeight !== null) ? 
                        (
                            params.isKgChecked ? convertNumberToFixedAndLocale(lot.totalWeight) : convertNumberToFixedAndLocale(lot.totalWeight/15)
                        ): 
                        '-'
                    }
                </StyledTableCell>
                <StyledTableCell align="center">{lot.uniqueDocNums}</StyledTableCell>
                <StyledTableCell align="center">{moment(lot.classificationDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}</StyledTableCell>
            </StyledTableRow>
        )
    }

    return (
        <TableContainer component={Paper} sx={{borderRadius: BORDER_RADIUS*4,  maxHeight: '602px', overflow: 'auto'}}>
            <Table sx={{ minWidth: '100%', minHeight: '550px'}} aria-label="customized table">
                <TableHead >
                    <TableRow sx={{height: '6em'}}>
                        <StyledTableCell align="center" onClick={() => handleSort('lotNum')} sx={{ cursor: 'pointer' }}>
                            <span style={{display: 'flex', justifyContent: 'center'}}>
                                Número do Lote
                                {orderBy === 'lotNum' && <ArrowIcon className={order === 'asc' ? 'asc' : 'desc'}/>}
                            </span>
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleSort('carcassCount')} sx={{ cursor: 'pointer' }}>
                            <span style={{display: 'flex', justifyContent: 'center'}}>
                                Quantidade de carcaças
                                {orderBy === 'carcassCount' && <ArrowIcon className={order === 'asc' ? 'asc' : 'desc'}/>}
                            </span> 
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleSort('averageWeight')} sx={{ cursor: 'pointer' }}>
                            <span style={{display: 'flex', justifyContent: 'center'}}>
                                {params.isKgChecked ? 'Peso médio (kg)' : 'Peso médio (@)'}
                                {orderBy === 'averageWeight' && <ArrowIcon className={order === 'asc' ? 'asc' : 'desc'}/>}
                            </span>
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleSort('totalWeight')} sx={{ cursor: 'pointer' }}>
                            <span style={{display: 'flex', justifyContent: 'center'}}>
                                {params.isKgChecked ? 'Peso total (kg)' : 'Peso total (@)'}
                                {orderBy === 'totalWeight' && <ArrowIcon className={order === 'asc' ? 'asc' : 'desc'}/>}
                            </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            Contratos
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => handleSort('classificationDate')} sx={{ cursor: 'pointer' }}>
                            <span style={{display: 'flex', justifyContent: 'center'}}>
                                Data
                                {orderBy === 'classificationDate' && <ArrowIcon className={order === 'asc' ? 'asc' : 'desc'}/>}
                            </span>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                
                <TableBody >
                    {   
                        data.length > 0 ?
                        (
                            data.map((row) => (filledRow(row)))
                        ) : (
                            emptyRows.map( (emptyRow, index) => (
                                <React.Fragment key={index}>{emptyRow}</React.Fragment>
                            )) 
                        )
                    }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                showFirstButton
                showLastButton
                component="div"
                count={totalNumberOfLots}
                rowsPerPage={lotsPerTablePage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
       
    );
}

export default React.memo(DashboardLotsTable);