import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { IAnomaly, IAnomalyCount, IAnomalyFilter, IBranch } from "../types";
import { convertApiResponseToIBranch } from "./branches";

const baseUrl: string = "/api_redsoft/v1_0/anomalies";

export const fetchAnomalies = async (_params: IAnomalyFilter) => {
  let filter = "";
  if (_params.initialDate) filter += `?initial_date=${moment(_params.initialDate).format("YYYY-MM-DD")}`;
  if (_params.finalDate) filter += (filter != "" ? "&" : "?") + `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
  if (_params.branches && _params.branches.length > 0) filter += (filter != "" ? "&" : "?") + `branch_id=${_params.branches.map((item) => `${item.id}`).join(",")}`;

  const response: any = await api.get(`${baseUrl}/filter${filter}`);
  const anomalies: IAnomaly[] = [];

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data)
        for (let i = 0; i < data.length; i++) {
          anomalies.push(convertApiResponseToIAnomaly(data[i]));
        }
    }
  }
  return anomalies;
};


export const fetchAnomalyById = async (_id: string) => {
  const response: any = await api.get(`${baseUrl}/${_id}`);
  let anomaly: IAnomaly | null = null;

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data) anomaly = convertApiResponseToIAnomaly(data);
    }
  }

  return anomaly;
};

export const getAnomaliesForAnnotation = async (_filter: IAnomalyFilter) => {
    let query: string = `?initial_date=${moment(_filter.initialDate).format("YYYY-MM-DD")}`;
    query += `&final_date=${moment(_filter.finalDate).format("YYYY-MM-DD")}`;
    if (_filter.branches && _filter.branches.length > 0)
        query += `&branches_id=${_filter.branches.map((item) => `'${item.id}'`).join(",")}`;

    const response: any = await api.get(`${baseUrl}/for_annotation${query}`);
    const anomalies: IAnomaly[] = [];
    if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
        const data = response.data;
        if (data)
            for (let i = 0; i < data.length; i++) {
            anomalies.push(convertApiResponseToIAnomaly(data[i]));
        }
    }
    }
    return anomalies;
};

export const getAnomaliesAnnotationCount = async(_query: string) =>{
    const response: AxiosResponse = await api.get(`${baseUrl}/count_annotated${_query}`);
    let anomalyCount: IAnomalyCount = {
        countAnnotated: 0,
        countNonAnnotated: 0,
        countFalse: 0,
        countTrue: 0,
        countReinserted: 0,
    };
    if (response && response.request) {
        const status = response.request.status;
        if (status == 200 && response.data) {
            anomalyCount.countAnnotated = response.data['countAnnotated'];
            anomalyCount.countNonAnnotated=response.data['countNonAnnotated'];
            anomalyCount.countFalse = response.data['countFalse'];
            anomalyCount.countTrue=response.data['countTrue'];
            anomalyCount.countReinserted=response.data['countReinserted'];
        } 
        else if (status == 204) {
            throw new Error("Não foi encontrada nenhuma anomalia na data selecionada");
        }
    }
    return anomalyCount
};

export const convertApiResponseToIAnomaly = (_anomaly: any) => {
  let branch: IBranch | undefined = undefined;
  if (_anomaly.branch) branch = convertApiResponseToIBranch(_anomaly.branch);
  else {
    branch = {
      id: _anomaly.branch_id,
      name: "",
      token: "",
      prefix: "",
    };

    if (_anomaly.branchName) branch.name = _anomaly.branchName;
    else if (_anomaly.companyName) {
      branch.name = _anomaly.companyName;
      if (_anomaly.companyFridge) branch.name += " - " + _anomaly.companyFridge;
    }
  }

  const anomaly: IAnomaly = {
    id: _anomaly.id,
    index: _anomaly.index,
    date:  new Date(_anomaly.date),
    photoUri: _anomaly.photoUri,
    classification: _anomaly.classification,
    accuracy: _anomaly.accuracy,
    branchId: _anomaly.branchId,
    branch: branch,
    status: _anomaly.status,
    createdAt: new Date(_anomaly.createdAt),
    updatedAt: new Date(_anomaly.updatedAt),

    isAnomaly: true,
    isExternal: true,
  };

  return anomaly;
};
