import React, { FC, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Fab, Grow, IconButton, TextField } from '@mui/material';
import { ChevronLeft as BackIcon, Search as SearchIcon, Store as StoreIcon } from '@mui/icons-material';

import { BranchList, Container, Page, Spacing, Text, theme } from '../../components';
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information';
import { useBranches } from '../../hooks/branches';
import { PADDING, VERY_PADDING } from '../../utils/consts'
import { IBranch, ICompany } from '../../types';
import { useCompanies } from '../../hooks/companies';

const Branches: FC = () => {
    const branchesHooks = useBranches();
    const { companies, fetchCompanies } = useCompanies();
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { companyId } = useParams();

    const [company, setCompany] = useState<ICompany | undefined>(undefined);
    const [branches, setBranches] = useState<IBranch[]>([]);
    const [filteredBranches, setFilteredBranches] = useState<IBranch[]>([]);
    const [fabHover, setFabHover] = useState<boolean>(false);
    const [intervalToRefresh, setIntervalToRefresh] = useState<number>(25);
    const [isIntervalToRefreshActive, setIsIntervalToRefreshActive] = useState<Boolean>(false);

    let intervalToRefreshRef: any = useRef();

    useEffect(() => {
        if (companyId)
            loadData(companyId);
    }, []);

    useEffect(() => {
        loadData(companyId);
    }, [companyId]);

    useEffect(() => {
        setCompany(companies.find(item => item.id == companyId));
    }, [companies]);

    useEffect(() => {
        startInterval();
    }, [isIntervalToRefreshActive]);

    const loadData = async (_companyId: string | undefined) => {
        if (companyId) {
            if (!branchesHooks.loading)
                fetchCompanies();
            const auxBranches: IBranch[] = await branchesHooks.fetchBranchesByCompanyId(companyId);
            setBranches(auxBranches);
            setFilteredBranches(auxBranches);
            setIsIntervalToRefreshActive(true);
        }
    }

    const filterBranches = (_searchBranch: string) => {
        let auxBranches = [...branches];
        if (_searchBranch !== "") auxBranches = auxBranches.filter(item => item.name.toLowerCase().indexOf(_searchBranch.toLowerCase()) >= 0 ||
            (item.email && item.email.toLowerCase().indexOf(_searchBranch.toLowerCase()) >= 0));
        setFilteredBranches(auxBranches);
    }

    const startInterval = () => {
        if (isIntervalToRefreshActive) {
            intervalToRefreshRef.current = setInterval(decreaseInterval, 1000);
        }
        return () => clearInterval(intervalToRefreshRef.current);
    }

    const decreaseInterval = () => {
        setIntervalToRefresh((prev) => {
            if (prev <= 0) {
                branchesHooks.reloadHealthStatus();

                setTimeout(() => {

                    setIntervalToRefresh(25);
                    setIsIntervalToRefreshActive(true);
                }, 100);
            }
            return prev - 1;
        });
    };

    const editBranch = (_branchId: string) => {
        navigate(`/companies/${companyId}/branches/${_branchId}`)
    }

    function refresh() {
        loadData(companyId).then(() => hideInformation());
    }

    const onDeleteBranch = async (_branchId: string) => {
        showConfirmation("Deseja realmente exluir o frigorífico?", "Atenção", "Confirmar", "Cancelar",
            async () => {
                await branchesHooks.deleteBranch(_branchId);

                hideConfirmation();
                showInformation(
                    'Frigorífico excluído com sucesso.',
                    'Exclusão de frigorífico',
                    undefined,
                    refresh
                );
            }, () => {
                hideConfirmation();
            });
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <IconButton onClick={() => company && navigate(`/companies`)}>
                        <BackIcon color="primary" />
                    </IconButton>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{company && `${company.name} - `} Frigoríficos</Text>
                </Container>
                <Spacing top={VERY_PADDING} />
                <TextField id="search-branches" autoFocus placeholder="Pesquisar frigoríficos" value={branchesHooks.searchBranch}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => filterBranches(event.target.value)}
                    InputProps={{ startAdornment: <SearchIcon color='primary' /> }} sx={{ width: "100%" }} />
                <Spacing top={VERY_PADDING} />
                {companyId &&
                    <BranchList
                        branches={filteredBranches}
                        companyId={companyId}
                        onOpenUsers={(_id: string) => navigate(`/companies/${companyId}/branches/${_id}/users`)}
                        onEditBranch={(_id: string) => editBranch(_id)}
                        onDeleteBranch={(_id: string) => onDeleteBranch(_id)} />
                }
            </Container>

            <Grow in={!fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/companies/${companyId}/branches/register`)}
                    variant="circular"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    <StoreIcon />
                </Fab>
            </Grow>
            <Grow in={fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/companies/${companyId}/branches/register`)}
                    variant="extended"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        height: 56,
                        borderRadius: '28px'
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    {fabHover &&
                        <Container inline>
                            <Text size={14} color={theme.palette.background.paper}>Novo frigorífico</Text>
                            <Spacing left={PADDING} />
                        </Container>}
                    <StoreIcon />
                </Fab>
            </Grow>
        </Page >
    )
}

export default Branches;