import React, { FC } from 'react';

import Container from '../../../atoms/container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { IDashboardChartCard } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';
import HelperIcon from '../../../atoms/helperIcon';


// GENERAL DIMENSIONS
const topPadding = PADDING*2;
const bottomPadding = PADDING*2;
const leftPadding = PADDING*3;
const rightPadding = PADDING*3;

// MEDIUM SIZE CARD DIMENSIONS
const cardHeight = 250;

const labelTextSize = 22;
const labelValueSpacing = 1;

const DashboardChartCard: FC<IDashboardChartCard> = (params: IDashboardChartCard) => {
    return (
        <Container 
                flex
                hasShadow 
                padded  
                color="white" 
                borderRadius={BORDER_RADIUS*10}
                sx={{
                    paddingRight: rightPadding,
                    paddingLeft: leftPadding,
                    paddingTop: topPadding,
                    paddingBottom: bottomPadding,
                    overflowX: 'auto',
                    opacity: params.loading ? 0.75 : 1,
                    ...params.style
                }}
        >
            {/* IF size="medium" */}
            {
                (
                    (params.size && params.size === "medium") 
                    || !params.size 
                ) &&
                <Grid 
                    container
                    direction="column" 
                    justifyContent="space-between" 
                    alignItems="flex-start" 
                    minHeight={cardHeight}
                    rowSpacing={2}
                    width='300'
                    height='300'
                    >
                    <Grid item xs={12} display='flex' width='100%'>
                        <Text
                            semiBold
                            size={labelTextSize}
                            color={theme.palette.primary.main}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {params.label}
                        </Text>
                        {params.help &&
                            <Box sx={{ textAlign: 'right' }}>
                                <HelperIcon text={params.help} sx={{ marginLeft: '8px' }} />
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={12} alignSelf="center" width="100%"
                        sx={{
                            marginTop: { xs: -1, sm: labelValueSpacing },
                            textAlign: 'center',
                        }}
                    >
                        {params.loading ? <CircularProgress /> : params.chart}
                    </Grid>
                    {params.loading && <Grid item xs={12} />}
                </Grid>
            }
        </Container>
    );
}

export default React.memo(DashboardChartCard);