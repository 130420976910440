import { AxiosResponse } from "axios";

import api from ".";
import { IShift } from "../types";

import CRUDService from "./crud";

export const baseURL = '/api_redsoft/v1_0/shifts';

export const convertApiResponseToIShift = (_data: any) => {
  const shift: IShift = {
    id: _data.id,
    branchId: _data.branchId,
    shiftType: _data.shiftType,
    startTime: _data.startTime,
    endTime: _data.endTime,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return shift;
};

const defaultService = CRUDService({
  baseURL,
  name: 'turno',
  convertResponseToModel: convertApiResponseToIShift
});

export const getShiftsByBranch: (branch?: string) => Promise<IShift[]> =
  async (branch?: string) => {
    const url = !!branch ? `${baseURL}/by_branch_id/${branch}` : baseURL;
    const response: AxiosResponse = await api.get(url);
    return defaultService.crudCheckResponse(response, 'Falha ao buscar turnos');
  };
