import { AxiosResponse } from "axios";
import moment from "moment";

import api from ".";
import { useAuth } from "../hooks/auth";

import {
    IBranch,
    ICarcassesPop,
    IPopDashboardData,
    IPopDashboardFilter,
} from "../types";
import { ICarcassesPopBoundingBox } from "../types/hooks/carcassesPops";
import { PopGroupIdPermissionRelation } from "../utils/pops";
const baseUrl: string = "carcasses_pops";

export const PopClassificationsType = {
    adequate: {
        value: 0,
        label: 'Adequado'
    },
    inadequate: {
        value: 1,
        label: 'Inadequado/Falha Leve'
    },
    severe: {
        value: 2,
        label: 'Falha Grave'
    },
};

export const getPopClassificationName = (value: number) => {
    if (value === PopClassificationsType.adequate.value)
        return PopClassificationsType.adequate.label;
    if (value === PopClassificationsType.severe.value)
        return PopClassificationsType.severe.label;
    return PopClassificationsType.inadequate.label;
}

export const getCarcassesPops = async (branchIds: string[]) => {
    let url = `/api_redsoft/v1_0/${baseUrl}`;
    if (branchIds.length > 0) {
        const branchIdsString = branchIds.join(',');
        url = url + `?branches='${branchIdsString}'`
    }

    const response: AxiosResponse = await api.get(url);

    if (response && response.request) {
        const status = response.request.status;
        if (status == 200 && response.data) {
            return response.data;
        }
    }

    throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToICarcassesPop = (data: any) => {
    const boundingBoxesTemp: ICarcassesPopBoundingBox[] = [];

    if (!data) return null;
    if (!data.boundingBoxes) return null;

    data.boundingBoxes.map((box: any) => {
        let boxTemp: ICarcassesPopBoundingBox = {
            x1: box.x1,
            x2: box.x2,
            y1: box.y1,
            y2: box.y2,

        }
        boundingBoxesTemp.push(boxTemp)
    })


    const pop: ICarcassesPop = {
        'id': data.id,
        'carcassId': data.carcassId,
        'name': data.name,
        'description': data.description,
        'popType': data.popType,
        'popGroup': data.popGroup,
        'classification': data.classification,
        'count': data.count,
        'boundingBoxes': boundingBoxesTemp,
        'accuracy': data.accuracy,
        'relatedPhoto': data.relatedPhoto,
        'createdAt': data.createdAt,
        'updatedAt': data.updatedAt,
        'isDeleted': data.isDeleted,
    }
    return pop
}

export const getPopDashboard = async (query: IPopDashboardFilter) => {
    const url = `/api_redsoft/v1_0/${baseUrl}/dashboard`;
    let filters = '?';
    if (query.initialDate)
        filters += `initialDate=${moment(query.initialDate).format("YYYY-MM-DD")}&`;
    if (query.finalDate)
        filters += `finalDate=${moment(query.finalDate).format("YYYY-MM-DD")}&`;
    if (query.selectedBranches.length > 0)
        filters += `branchesId=${query.selectedBranches.map((item: IBranch) => item.id).join(',')}&`;
    if (query.selectedPops.length > 0)
        filters += `pops=${query.selectedPops.join(',')}&`;

    const response: AxiosResponse = await api.get(url + filters);
    if (response && response.request) {
        const status = response.request.status;
        if (status == 200 && response.data) {
            return response.data as IPopDashboardData;
        } else if (status == 400 && (response as any).response && (response as any).response.data) {
            throw new Error((response as any).response.data as string);
        }
    }

    throw new Error("Não foi possível buscar dados para o dashboard de POPs");
};
