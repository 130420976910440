import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { ICarcass, ICarcassesPop, ICarcassFilterParams, IBranch } from "../types";

import { convertApiResponseToIBranch } from "./branches";
import { getCarcassClassification, getCarcassTypification, getCarcassConformationName } from "../hooks/carcasses";
import { convertApiResponseToICarcassesPop } from "./carcassesPops";

const baseUrl: string = "/api_redsoft/v1_0/carcasses";

export const fetchCarcasses = async (_params: ICarcassFilterParams) => {
    let filter = "";
    if (_params.initialDate) filter += `?initial_date=${moment(_params.initialDate).format("YYYY-MM-DD")}`;
    if (_params.finalDate) filter += (filter != "" ? "&" : "?") + `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    if (_params.branchId) filter += (filter != "" ? "&" : "?") + `branches_id=${_params.branchId}`;

    const response: any = await api.get(baseUrl + filter);
    const carcasses: ICarcass[] = [];

    if (response && response.request) {
        const status = response.request.status;
        if (status === 200) {
            const data = response.data;
            if (data)
                for (let i = 0; i < data.length; i++) {
                    carcasses.push(convertApiResponseToICarcass(data[i]));
                }
        }
    }

    return carcasses;
};

export const deleteCarcasses = async (_query: string) => {
    const response: AxiosResponse = await api.delete(`${baseUrl}${_query}`);

    if (response && response.request) {
        const status = response.request.status;

        if (status === 200 && response.data) {
            return response.data;
        } else if (status === 204) {
            return response.statusText
        }
    }

    throw new Error("Não foi possível buscar os dados");
};

export const fetchCarcassesGroupSides = async (_params: ICarcassFilterParams) => {
    let filter = "";
    if (_params.initialDate) filter += `?initial_date=${moment(_params.initialDate).format("YYYY-MM-DD")}`;
    if (_params.finalDate) filter += (filter != "" ? "&" : "?") + `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    if (_params.branchId) filter += (filter != "" ? "&" : "?") + `branch_id=${_params.branchId}`;
    if (_params.docNums && _params.docNums.length > 0) filter += (filter != "" ? "&" : "?") + `doc_num=${_params.docNums.map((item) => `${item}`).join(",")}`;
    if (_params.lotNums && _params.lotNums.length > 0) filter += (filter != "" ? "&" : "?") + `lot_num=${_params.lotNums.map((item) => `${item}`).join(",")}`;
    if (_params.farms && _params.farms.length > 0) filter += (filter != "" ? "&" : "?") + `farm=${_params.farms.map((item) => `${item}`).join(",")}`;

    const response: any = await api.get(`${baseUrl}/group_sides${filter}`);
    const carcasses: ICarcass[] = [];

    if (response && response.request) {
        const status = response.request.status;
        if (status === 200) {
            const data = response.data;
            if (data)
                for (let i = 0; i < data.length; i++) {
                    carcasses.push(convertApiResponseToICarcass(data[i]));
                }
        }
    }

    return carcasses;
};

export const fetchCarcassById = async (_id: string) => {
    const response: any = await api.get(`${baseUrl}/${_id}`);
    let carcass: ICarcass | null = null;

    if (response && response.request) {
        const status = response.request.status;
        if (status === 200) {
            const data = response.data;
            if (data) carcass = convertApiResponseToICarcass(data);
        }
    }

    return carcass;
};

export const getCarcassForAnnotation = async (_query: string) => {
    const response: any = await api.get(`${baseUrl}/for_annotation${_query}`);

    if (response && response.request) {
        const status = response.request.status;
        if (status === 200) {
            const data = response.data;
            if (data) return convertApiResponseToICarcass(data);
        }
    }

    return null;
};

export const importXlsFileMarfrig = async (_doc: any) => {
    const formData = new FormData();
    formData.append("file", _doc);

    const response: any = await api.post(`${baseUrl}/import/xls/marfrig`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    if (response && response.request) {
        const status = response.request.status;
        if (status === 200) {
            const data = response.data;
            return data;
        }
    }

    return null;
};

export const getCarcassesDownload = async (_query: string) => {
    const response: AxiosResponse = await api.get(`${baseUrl}/download_photos${_query}`, { responseType: "blob" });
    if (response && response.request) {
        const status = response.request.status;
        if (status === 200 && response.data) {
            // const revision: IRevision = convertApiResponseToIRevision(response.data);
            return response;
        } else if (status === 204) {
            throw new Error("Não foi encontrada nenhuma carcaça na data selecionada");
        }
    }

    throw new Error("Não foi possível buscar os dados");
};

export const getCarcassesDownloadFilePath = async (_filePath: string) => {
    _filePath = _filePath.substring(_filePath.lastIndexOf('/'));
    const response = await api.get('/download/carcasses' + _filePath, { responseType: "blob" });
    if (response && response.request) {
        const status = response.request.status;
        if (status === 200 && response.data) {
            return response;
        } else if (status === 204) {
            throw new Error("Não foi encontrada nenhuma carcaça na data selecionada");
        }
    }
    throw new Error("Não foi possível buscar os dados");
};

export const getCarcassesForVerdicts = async (accuracy: number, _query: string) => {
    const response: AxiosResponse = await api.get(`${baseUrl}/for_verdict/${accuracy}${_query}`);
    if (response && response.request) {
        const status = response.request.status;
        if (status === 200 && response.data) {
            const carcasses: ICarcass[] = [];

            for (let i = 0; i < response.data.carcasses.length; i++) {
                const data = response.data.carcasses[i];
                const carcass: ICarcass = convertApiResponseToICarcass(data);
                carcasses.push(carcass);
            }
            let mergedCarcasses = mergeCarcasses(carcasses);
            let res = response.data;
            res.carcasses = mergedCarcasses;

            return res;
        }
    }

    throw new Error("Não foi possível buscar os dados");
};

export const getCarcassesSheet = async (_query: string) => {
    const response: AxiosResponse = await api.get(`${baseUrl}/download_carcasses_sheet${_query}`);

    if (response && response.request) {
        const status = response.request.status;
        if (status === 200 && response.data) {

            return response.data;
        }
    }

    throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToICarcass = (_carcass: any) => {
    let branch: IBranch | undefined = undefined;
    if (_carcass.branch) branch = convertApiResponseToIBranch(_carcass.branch);
    else {
        branch = {
            id: _carcass.branch_id,
            name: "",
            token: "",
            prefix: "",
        };

        if (_carcass.branchName) branch.name = _carcass.branchName;
        else if (_carcass.companyName) {
            branch.name = _carcass.companyName;
            if (_carcass.companyFridge) branch.name += " - " + _carcass.companyFridge;
        }
    }

    let classification = "Indefinido";
    if (_carcass.classificationId !== undefined)
        classification = getCarcassClassification(_carcass.classificationId);
    else if (_carcass.classification !== undefined)
        classification = getCarcassClassification(_carcass.classification);
    let classificationId = _carcass.classificationId !== undefined ? _carcass.classificationId : _carcass.classification;
    let classificationAccuracy = _carcass.classificationAccuracy;

    let conformationId = (
        _carcass.conformationId !== undefined ?
            _carcass.conformationId : _carcass.conformation !== undefined ?
                _carcass.conformation : _carcass.localConformationId
    );
    let conformationAccuracy = _carcass.conformationAccuracy;

    let classificationDate = _carcass.classificationDate;
    if (_carcass.idB) {
        if (_carcass.classificationAccuracyB > _carcass.classificationAccuracy) {
            classification = getCarcassClassification(_carcass.classificationIdB);
            classificationId = _carcass.classificationIdB;
            classificationAccuracy = _carcass.classificationAccuracyB;
            classificationDate = _carcass.classificationDateB;
        }
        if (_carcass.conformationAccuracyB > _carcass.conformationAccuracy) {
            conformationId = _carcass.conformationIdB;
            conformationAccuracy = _carcass.conformationAccuracyB;
        }
    }

    let localClassification = null;
    if (!localClassification && _carcass.localClassification !== undefined)
        localClassification = _carcass.localClassification;
    if (!localClassification && _carcass.localClassificationId !== undefined)
        localClassification = getCarcassClassification(_carcass.localClassificationId);
    if (!localClassification && _carcass.coverageId !== undefined)
        localClassification = getCarcassClassification(parseInt(_carcass.coverageId));
    if (!localClassification && _carcass.coverage !== undefined)
        localClassification = getCarcassClassification(parseInt(_carcass.coverage));

    if (!localClassification) localClassification = "Indefinido";

    let iaTypification = getCarcassTypification(classificationId);
    let iaTypificationB = getCarcassTypification(_carcass.classificationIdB);
    let verdicTypification = getCarcassTypification(_carcass.classificationIdV);

    let popsA: ICarcassesPop[] = []
    if (_carcass.popsA) {
        popsA = (_carcass.popsA || []).map((pop: any) => convertApiResponseToICarcassesPop(pop)).filter((pop: ICarcassesPop | null) => pop !== null);
    }

    let popsB: ICarcassesPop[] = []
    if (_carcass.popsB)
        popsB = (_carcass.popsB || []).map((pop: any) => convertApiResponseToICarcassesPop(pop)).filter((pop: ICarcassesPop | null) => pop !== null);

    const carcass: ICarcass = {
        id: _carcass.id,
        branchId: _carcass.branchId,
        branch: branch,
        photoUri: _carcass.photoUri,
        internalUri: _carcass.internalUri,
        classification: classification,
        classificationId: classificationId,
        classificationAccuracy: classificationAccuracy,
        conformationId: conformationId,
        conformationAccuracy: conformationAccuracy,
        conformationName: getCarcassConformationName(conformationId),
        classificationDate: classificationDate,
        typification: _carcass.typification,
        ocrUri: _carcass.ocrUri,
        ocrUriHash1: _carcass.ocrUriHash1,
        ocrValueHash1: _carcass.ocrValueHash1,
        partnerId: _carcass.partnerId,
        bestPlaced: _carcass.bestPlaced,
        bestPlacedB: _carcass.bestPlacedB,
        cnpj: _carcass.cnpj,
        date: _carcass.date,
        slaughterDate: _carcass.slaughterDate,
        sequence: _carcass.sequence,
        sideCarcass: _carcass.sideCarcass,
        lotNum: _carcass.lotNum,
        docId: _carcass.docId,
        index: _carcass.index,
        label: _carcass.label,
        docNum: _carcass.docNum,
        cnpjRuralProducer: _carcass.cnpjRuralProducer,
        farm: _carcass.farm,
        city: _carcass.city,
        uf: _carcass.uf,
        productId: _carcass.productId,
        sex: _carcass.sex,
        sibovId: _carcass.sibovId,
        handlingId: _carcass.handlingId,
        maturityId: _carcass.maturityId,
        dentition: _carcass.dentition,
        age: _carcass.age,
        breed: _carcass.breed,
        isTracked: _carcass.isTracked,
        sifStatus: _carcass.sifStatus,
        weight: _carcass.weight,
        species: _carcass.species,
        troop: _carcass.troop,
        localClassificationId: _carcass.localClassificationId,
        localClassification,
        animalType: _carcass.animalType,
        certification: _carcass.certification,
        observation: _carcass.observation,
        producerName: _carcass.producerName,
        producerId: _carcass.producerId,
        farmId: _carcass.farmId,
        complement: _carcass.complement,
        localConformationId: _carcass.localConformationId,
        localConformation: _carcass.localConformation || "Indefinido",
        dif: _carcass.dif,
        destination: _carcass.destination,
        createdAt: _carcass.createdAt,
        updatedAt: _carcass.updatedAt,
        iaTypification,
        metaData: typeof _carcass.metaData === 'string' ? JSON.parse(_carcass.metaData) : _carcass.metaData,

        idB: _carcass.idB,
        photoUriB: _carcass.photoUriB,
        internalUriB: _carcass.internalUriB,
        classificationIdB: _carcass.classificationIdB,
        classificationAccuracyB: _carcass.classificationAccuracyB,
        conformationIdB: _carcass.conformationIdB,
        conformationAccuracyB: _carcass.conformationAccuracyB,
        conformationNameB: getCarcassConformationName(_carcass.conformationIdB),
        classificationDateB: _carcass.classificationDateB,
        dateB: _carcass.dateB,
        slaughterDateB: _carcass.slaughterDateB,
        indexB: _carcass.indexB,
        sequenceB: _carcass.sequenceB,
        labelB: _carcass.labelB,
        ocrUriB: _carcass.ocrUriB,
        ocrUriHash1B: _carcass.ocrUriHash1B,
        ocrValueHash1B: _carcass.ocrValueHash1B,
        partnerIdB: _carcass.partnerIdB,
        metaDataB: typeof _carcass.metaDataB === 'string' ? JSON.parse(_carcass.metaDataB) : _carcass.metaDataB,
        iaTypificationB,
        verdicTypification,
        classificationIdV: _carcass.classificationIdV,

        rancherName: _carcass.rancherName,
        localClassificationRedsoft: _carcass.localClassificationRedsoft,

        popsA: popsA,
        popsB: popsB,
    };

    return carcass;
};

function mergeCarcasses(carcasses: ICarcass[]): ICarcass[] {
    const mergedCarcasses: ICarcass[] = [];

    carcasses.forEach(carcass => {
        const existingCarcassIndex = mergedCarcasses.findIndex(
            c => c.classificationDate.split(' ')[0] === carcass.classificationDate.split(' ')[0] &&
                c.branchId === carcass.branchId &&
                c.partnerId === carcass.partnerId
        );

        if (existingCarcassIndex !== -1) {
            const existingCarcass = mergedCarcasses[existingCarcassIndex];

            // Use new photo_uri
            existingCarcass.photoUriB = carcass.photoUri;
            existingCarcass.idB = carcass.id;

            // Select highest classification accuracy
            if (carcass.classificationAccuracy > existingCarcass.classificationAccuracy) {
                existingCarcass.classificationId = carcass.classificationId;
                existingCarcass.classificationAccuracy = carcass.classificationAccuracy;
            }

            // Select highest conformation accuracy
            if (carcass.conformationAccuracy > existingCarcass.conformationAccuracy) {
                existingCarcass.conformationId = carcass.conformationId;
                existingCarcass.conformationAccuracy = carcass.conformationAccuracy;
            }
        } else {
            mergedCarcasses.push(carcass);
        }
    });
    return mergedCarcasses;
};
