import { AxiosResponse } from "axios";
import moment from "moment";
import api from ".";
import { IAlert, IAlertProvider, IAlertActiveFilterParams } from "../types";

import CRUDService from "./crud";

export const baseURL: string = "/api_alert_system/v1_0/alerts";

export const getAlertConstants = async () => {
  const response: AxiosResponse = await api.get(`${baseURL}/constants`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

const defaultService = CRUDService({
  baseURL,
  name: 'alerta',
});

export const getAllAlertsWithProvidersIds: () => Promise<IAlert[]> = async () => {
  const response: AxiosResponse = await api.get(baseURL);
  defaultService.crudCheckResponse(response, 'Falha ao buscar alertas');
  return response.data.map((item: IAlert) => ({
    ...item,
    providers: item.providers ? item.providers.map(
      (p: string | IAlertProvider) => typeof p === 'string' ? p : p.id
    ) : []
  }));
};


export const getActiveAlerts = async (params: IAlertActiveFilterParams) => {
  let filter = "?";
  if (params) {
    if (params.initialTime)
      filter += `initialTime=${moment(params.initialTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      )}&`;
    if (params.finalTime)
      filter += `finalTime=${moment(params.finalTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      )}&`;
    if (params.branches && params.branches.length > 0)
      filter += `branchesId=${params.branches.map((item) => item.id).join(",")}&`;
    if (params.statuses && params.statuses.length > 0)
      filter += `statuses=${params.statuses.join(",")}&`;
    if (params.levels && params.levels.length > 0)
      filter += `levels=${params.levels.join(",")}&`;
    if (params.sources && params.sources.length > 0)
      filter += `sources=${params.sources.join(",")}&`;
  }

  if (filter.endsWith('&'))
    filter = filter.substring(0, filter.length - 1);
  else if (filter.length === 1)
      filter = '';
  const response: AxiosResponse = await api.get(`${baseURL}/active${filter}`);

  if (response && response.request) {
    const status = response.request.status;
    let res = [];
    if (status == 200 && response.data) {
      for (const entry of response.data) {
        res.push({
          ...entry,
          'status': {
            ...entry.status,
            'eventAt': moment(
              entry.status.eventAt, 'YYYY-MM-DD HH:mm:ss.S'
              ).subtract(3, 'h').format('YYYY-MM-DD HH:mm:ss.S'),
          }
        })
      }
      return res;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};


export const silenceAlert = async (alertId: string, activate: boolean) => {
  const response: AxiosResponse = await api.post(`${baseURL}/silence`, {
    alertId, activate
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status === 200) {
      return true;
    }
  }

  throw new Error("Não foi possível silenciar/reativar alerta");
};


export const getComparisonDescription = (value: string) => {
  const errorMsg = `Indefinido (${value})`;
  if (!value)
    return errorMsg;

  switch (value) {
    case 'LOWER_THAN': return 'Abaixo de';
    case 'HIGHER_THAN': return 'Acima de';
    default: return errorMsg
  }
};


export const getLevelDescription = (value: string) => {
  const errorMsg = `Indefinido (${value})`;
  if (!value)
    return errorMsg;

  switch (value) {
    case 'WARNING': return 'Alerta';
    case 'CRITICAL': return 'Crítico';
    default: return errorMsg
  }
};


export const getStatusDescription = (value: string) => {
  const errorMsg = `Indefinido (${value})`;
  if (!value)
    return errorMsg;

  switch (value) {
    case 'INACTIVE': return 'Inativo';
    case 'ACTIVE': return 'Ativo';
    case 'SILENT': return 'Silenciado';
    default: return errorMsg
  }
};


export const getWeekDayDescription = (value: string) => {
  const errorMsg = `Indefinido (${value})`;
  if (!value)
    return errorMsg;

  switch (value) {
    case 'SUNDAY': return 'Domingo';
    case 'MONDAY': return 'Segunda-feira';
    case 'TUESDAY': return 'Terça-feira';
    case 'WEDNESDAY': return 'Quarta-feira';
    case 'THURSDAY': return 'Quinta-feira';
    case 'FRIDAY': return 'Sexta-feira';
    case 'SATURDAY': return 'Sábado';
    default: return errorMsg
  }
};

