import React, { FC } from 'react';
import {
    ICRUDTableByBranch,
    ITypification,
    ICRUDContext,
} from '../../../../types';

import { getTypificationsByBranch } from '../../../../services/typifications';
import { useTypifications } from '../../../../hooks/typifications';
import generateCRUDTable from '../../crudTable';


const TypificationsCRUDTable: FC<ICRUDTableByBranch> = (params: ICRUDTableByBranch) => {
    const crudTable = generateCRUDTable<ITypification, ICRUDTableByBranch>(
        {
            columns: [
                {
                    name: 'name',
                    title: 'Nome',
                    required: true,
                    max: 60,
                },
                {
                    name: 'priority',
                    title: 'Prioridade',
                    type: 'integer',
                    required: true,
                },
                {
                    name: 'description',
                    title: 'Descrição',
                    required: false,
                    hideColumn: true,
                    max: 100,
                },
                {
                    name: 'minWeight',
                    title: 'Mín. Peso',
                    type: 'float',
                    min: 0,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'maxWeight',
                    title: 'Máx. Peso',
                    type: 'float',
                    min: 0,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'minClassification',
                    title: 'Mín. Acabamento',
                    type: 'integer',
                    min: 0,
                    max: 5,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'maxClassification',
                    title: 'Máx. Acabamento',
                    type: 'integer',
                    min: 0,
                    max: 5,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'minConformation',
                    title: 'Mín. Conformação',
                    type: 'integer',
                    min: 0,
                    max: 5,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'maxConformation',
                    title: 'Máx. Conformação',
                    type: 'integer',
                    min: 0,
                    max: 5,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'minDentition',
                    title: 'Mín. Dentição',
                    type: 'integer',
                    min: 0,
                    max: 10,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'maxDentition',
                    title: 'Máx. Dentição',
                    type: 'integer',
                    min: 0,
                    max: 8,
                    required: false,
                    hideColumn: true,
                },
                {
                    name: 'breed',
                    title: 'Raça',
                    required: false,
                    hideColumn: true,
                    max: 50,
                },
                {
                    name: 'sex',
                    title: 'Sexo (F ou M)',
                    min: 1,
                    max: 1,
                    required: false,
                    hideColumn: true,
                },
            ],
            name: 'tipificação',
            defaultSort: 'priority',
            defaultSortOrder: 'desc',
            entryDescriptor: 'name',
            defaultNewEntry: {
                branchId: params.branchId,
                priority: 0,
                name: ''
            },
            getHook: useTypifications,
            fetchAllEntries: async (hook?: ICRUDContext<ITypification>, _params?: ICRUDTableByBranch) => {
                return await getTypificationsByBranch(params.branchId);
            }
        }
    )

    return crudTable(params);
};

export default React.memo(TypificationsCRUDTable);