import React, { FC } from 'react';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import theme from '../../../theme';
import { useBranches } from '../../../../hooks/branches';
import { IBranchSelect } from '../../../../types';

const BranchSelect: FC<IBranchSelect> = (params: IBranchSelect) => {
    const { branches } = useBranches();

    return (
        <Container fluid sx={{ flexGrow: 1 }} height={40}>
            <Autocomplete
                id="branches"
                required
                variant={params.variant || 'outlined'}
                options={params.options || branches as any[]}
                value={params.value || ''}
                defaultValue={params.valueName || ''}
                onChange={params.handleChangeValue}
                placeholder={params.placeholder || 'Escolha a filial'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists={params.value != ''} 
                sx={{marginTop: '0px'}}
                />
        </Container>
    );
}

export default BranchSelect;