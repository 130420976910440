import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import BranchSelect from '../../branches/select';
import Container from '../../../atoms/container';
import DatePicker from '../../../atoms/datePicker';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { useCarcass } from '../../../../hooks/carcasses';
import { useConfirmation } from '../../../../hooks/confirmation';
import { useInformation } from "../../../../hooks/information";
import { ICarcassDeleteModal } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import { useAuth } from '../../../../hooks/auth';

const CarcassDeleteModal: FC<ICarcassDeleteModal> = (params: ICarcassDeleteModal) => {
    const { user } = useAuth();
    const { deleteCarcassesInfo } = useCarcass();
    const { hideConfirmation, showConfirmation } = useConfirmation();
    const { showInformation } = useInformation();

    const [dateValue, setDateValue] = useState<Date | null>(new Date());
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setBranchError(undefined);
        setError(null);
    }

    const onDeleteCarcasses = async () => {
        const valid = validate();
        setLoading(true);

        if(valid){
            showConfirmation(`Tem certeza que deseja excluir todas as carcaças do dia ${moment(dateValue).format('DD/MM/YYYY')}?`, "Deletar", "Confirma", "Cancelar",
            async () => {
                if(dateValue){
                    const responseDelete: any = await deleteCarcassesInfo(dateValue, branchId); 
                    hideConfirmation();
                    showInformation('Carcaças removidas com sucesso.', 'Sucesso')
                    return
                }
                showInformation('Não foi possível remover as carcaças.', 'Falha')
            },
            () => {
                hideConfirmation();
            });
            setLoading(false);
            return
        }
    }
        

    const validate = () => {
        let isValid: boolean = true;

        if (!dateValue) {
            setError('Favor informar a data');
            isValid = false;
        }
        if (branchId == '') {
            setBranchError('Favor informar o frigorífico')
            isValid = false;
        }

        if (isValid) {
            setError('');
            setBranchError(undefined);
        }
        return isValid;
    }
    const close = () => {
        cleanData();

        params.onClose();
    }

    const cleanData = () => {
        setDateValue(new Date());
        setBranchId('');
        setBranchName('');
        setBranchError(undefined);
        setError(null);
        setLoading(false);
    }

    return (
        <Modal isOpened={params.open} onClose={close}>
            <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={BORDER_RADIUS}
                style={{ maxWidth: 540 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Deletar carcaças do dia</Text>
                    </Container>
                </Container>

                <Container fluid centered spacedAround veryPadded width="90%">
                    <Text>Preencha o filtro para deletar as carcaças de um dia</Text>
                    <Spacing top={PADDING} />
                    <Text light size={13}>Esse processo pode demorar um pouco dependendo da quantidade de carcaças.</Text>
                    <Spacing top={VERY_PADDING} />

                    <Grid container rowSpacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <Text light>Data:</Text>
                        </Grid>
                        <Grid item xs={7}>
                            <DatePicker
                                value={dateValue}
                                label='Data'
                                placeholder={"Escolha a data"}
                                setValue={setDateValue}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Text light>Frigorífico:</Text>
                        </Grid>
                        <Grid item xs={7}>
                            <BranchSelect
                                value={branchId}
                                valueName={branchName}
                                handleChangeValue={handleChangeBranch}
                                variant="outlined"
                                placeholder='Escolha o frigorífico'
                                error={branchError} />
                        </Grid>
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={close}>
                        <Text bold size={12} color={loading ? theme.palette.grey[400] : theme.palette.primary.light}>Fechar</Text>
                    </LoadingButton>
                    <Spacing left={PADDING} />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, borderColor: theme.palette.primary.light, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={() => onDeleteCarcasses()}>
                        <Text bold size={12} color={theme.palette.background.paper}>Confirmar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal >
    )
}

export default CarcassDeleteModal;