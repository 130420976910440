import React, { FC, useEffect, useState } from 'react';
import moment from "moment";
import { Button, Grid, SelectChangeEvent } from '@mui/material';

import BranchesMultiSelect from '../../branches/multiSelect';
import Container from '../../../atoms/container';
import DashboardDentitionMultiSelect  from './dentitionMultiSelect'
import DashboardWeightMultiSelect from './weightMultiSelect';
import DashboardSexMultiSelect from './sexMultiSelect';
import DashboardPopMultiSelect from './popMultiSelect';
import DateRangePicker from '../../../atoms/dateRangePicker';
import Input from '../../../atoms/input';
import Spacing from '../../../atoms/spacing';
import { theme } from '../../../../components'
import { IDashboardFilter, IBranch, IDashboardFilterData } from '../../../../types';
import { PADDING, VERY_PADDING } from '../../../../utils/consts';
import { useBranches } from '../../../../hooks/branches';

const DashboardFilter: FC<IDashboardFilter> = (params: IDashboardFilter) => {
    const { branches } = useBranches();

    const [initialDate, setInitialDate] = useState<Date | null>(params.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(params.finalDate);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(params.branches);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [farms, setFarms] = useState<string[] | null>(params.farms);
    const [lots, setLots] = useState<string[] | null>(params.lots);
    const [dentitions, setDentitions] = useState<number[] | null>(params.dentitions);
    const [weightIntervals, setWeightIntervals] = useState<string[] | null>(params.weightIntervals);
    const [sexes, setSexes] = useState<string[] | null>(params.sexes);
    const [selectedPops, setSelectedPops] = useState<string[] | null >(params.pops ? params.pops : null);

    const [farmsInputValue, setFarmsInputValue] = useState<string>(params.farms !== null ? params.farms.join(',') : '');
    const [lotsInputValue, setLotsInputValue] = useState<string>(params.lots !== null ? params.lots.join(',') : '');

    let farmsTimeout: NodeJS.Timeout;
    let lotsTimeout: NodeJS.Timeout;

    useEffect(() => {
        setWeightIntervals([]);
    }, [params.isUnitKg]);
    
    const onChangeFarms = (event: React.ChangeEvent<HTMLInputElement>) => {
        let farms: string = event.target.value;
        setFarmsInputValue(farms);
        let farmsList: string[] = farms.split(",");
        let trimmedList = farmsList
            .map(item => item.trim())
            .filter(item => item !== '');

        clearTimeout(farmsTimeout);
        farmsTimeout = setTimeout(() => {
            setFarms(trimmedList);
        }, 1000);
    }

    const onChangeLots = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/[^0-9,]/g, '');
        setLotsInputValue(event.target.value);
        let lot: string = event.target.value;
        let lotList: string[] = lot.split(",");
        let trimmedList = lotList
            .map(item => item.trim())
            .filter(item => item !== '');
        
        clearTimeout(lotsTimeout);
        lotsTimeout = setTimeout(() => {
            setLots(trimmedList);
        }, 1000);
    }

    const onChangeDentitions = (event: SelectChangeEvent<typeof dentitions>) => {
        const { value } = event.target;
        setDentitions((value as number[]).sort());
    };

    const onChangeWeightIntervals = (event: SelectChangeEvent<typeof weightIntervals>) => {
        const { value } = event.target;
        setWeightIntervals((value as string[]).sort());
    };

    const onChangeSexes = (event: SelectChangeEvent<typeof sexes>) => {
        const { value } = event.target;
        setSexes((value as string[]).sort());
    };

    const onChangePops = (event: SelectChangeEvent<typeof selectedPops>) => {
        const { value } = event.target;
        setSelectedPops((value as string[]).sort());
    };

    const applyFilters = () => {
        const newFilter: IDashboardFilterData = {
            initialDate,
            finalDate,
            branches: selectedBranches,
            farms: farms,
            lotNums: lots,
            dentitions: dentitions,
            weightIntervals: weightIntervals,
            sexes: sexes,
            pops: selectedPops,
        };
        params.onApplyFilter(newFilter);
    }

    const clearFilters = () => {
        setInitialDate(moment().toDate());
        setFinalDate(moment().toDate());
        setSelectedBranches([]);
        setLots([]);
        setFarms([]);
        setDentitions([]);
        setWeightIntervals([]);
        setSexes([]);
        setSelectedPops([]);

        setFarmsInputValue('');
        setLotsInputValue('');
    }
    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING, paddingLeft: PADDING, paddingBottom: VERY_PADDING }}>
            <Grid container rowSpacing={2} columnSpacing={3} alignItems="center" >
                <Grid container item xs={12} sm={6} lg={3} width="90%">
                    <DateRangePicker
                        disabled={params.loading}
                        initialDate={initialDate}
                        finalDate={finalDate}
                        placeholder={"Escolha o intervalo de data"}
                        setInitialDate={setInitialDate}
                        setFinalDate={setFinalDate}
                        maxDaysSelect={0}
                        useDefaultPredefinedRanges
                        sx={{
                            width: '100%',
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%">
                    <BranchesMultiSelect
                        disabled={params.loading}
                        minWidth="100%"
                        selectedBranches={selectedBranches}
                        setSelectedBranches={setSelectedBranches}
                        expanded={branchesExpanded}
                        setExpanded={setBranchesExpanded}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                </Grid>
                
                <Grid container item xs={12} sm={6} lg={3} width="90%">
                    <Input
                        id="farms"
                        variant="outlined"
                        value={farmsInputValue}
                        onChange={onChangeFarms}
                        placeholder='Nome da fazenda'
                        backgroundColor={theme.palette.background.default}
                        disabled={params.loading}
                        sx={{
                            minWidth:"100%",
                            height: '37px',
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                            borderRadius: '4px',
                            marginTop: '0px'
                        }} />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                    <Input
                        id="lots"
                        variant="outlined"
                        value={lotsInputValue}
                        onChange={onChangeLots}
                        placeholder='Número do Lote'
                        backgroundColor={theme.palette.background.default}
                        disabled={params.loading}
                        sx={{
                            minWidth:"100%",
                            height: '37px',
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                            borderRadius: '4px',
                            marginTop: '0px'
                        }} />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <DashboardDentitionMultiSelect
                        onChangeDentitions={onChangeDentitions}
                        dentitions={dentitions !== null ? dentitions : []}
                        setDentitions={setDentitions}
                        disabled={params.loading}
                   />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <DashboardWeightMultiSelect
                        onChangeWeightIntervals={onChangeWeightIntervals}
                        weightIntervals={weightIntervals !== null ? weightIntervals : []}
                        setWeightIntervals={setWeightIntervals}
                        isUnitKg={params.isUnitKg}
                        disabled={params.loading}
                   />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <DashboardSexMultiSelect
                        sexes={sexes !== null ? sexes : []}
                        setSexes={setSexes}
                        onChangeSexes={onChangeSexes}
                        disabled={params.loading}
                   />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <DashboardPopMultiSelect
                        selectedPops={selectedPops !== null ? selectedPops: []}
                        setSelectedPops={setSelectedPops}
                        onChangePops={onChangePops}
                        branches={branches.map(branch => branch.id!)}
                        disabled={params.loading}
                   />
                </Grid>
                <Grid container item xs={12} width="90%" minWidth="200px" sx={{justifyContent: 'end'}}>
                    <Button
                        disabled={params.loading}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => applyFilters()}
                    >
                        Aplicar
                    </Button>
                    <Spacing left={20}/>
                    <Button
                        disabled={params.loading}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => clearFilters()}
                    >
                        Limpar
                    </Button>
                </Grid> 
            </Grid>
        </Container>
    );
}

export default DashboardFilter;
