import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import {
    FilterAltOffOutlined as FilterOffOutlined,
    FilterAltOutlined as FilterOutlined,
} from '@mui/icons-material';
import { FormControlLabel, FormGroup, Grid, IconButton, Switch, Stack } from '@mui/material';

import './classes.css';

import {
  PhotoCameraBack as PhotoCameraBackIcon,
  ScaleOutlined as ScaleOutlinedIcon,
  WbCloudyOutlined as WbCloudyOutlinedIcon
} from '@mui/icons-material/';
import { ReactComponent as CowIcon } from "../../assets/icons/animal_cow.svg";
import {
  Container, DashboardChartCard, DashboardClassificationPieChart,
  DashboardDentitionBarChart,
  DashboardFrigobomTypificationAreaChart,
  DashboardLotsTable, DashboardNumberCard, DashboardSexPieChart,
  DashboardWeightPieChart,
  Page,
  Spacing,
  Text,
  theme
} from '../../components';
import DashboardFilter from '../../components/molecules/dashboard/filter';
import { useAuth } from '../../hooks/auth';
import { useDashboard } from '../../hooks/dashboard';
import { IDashboardBarChartData, IDashboardFilterData } from '../../types';
import { VERY_PADDING } from '../../utils/consts';
import { COLORS, IDashboardPage } from './consts';
import { useCarcassesPops } from '../../hooks/carcassesPops';


const DashboardBase: FC<IDashboardPage>  = (params: IDashboardPage) => {
    const { carcassAllTimeCount, carcassAllTimeBestPlacedCount,
        carcassAverageWeight, carcassIntegratedWithERPCount,
        carcassesCountByClassification, carcassesCountByDentition,
        carcassesCountByWeight, carcassesCountBySex,
        carcassesCountByTypification, carcassesCountByFrigobomTypification,
        loading,carcassesSlaughterScheduleCount, fetchDashboardData, filter, setFilter
    } = useDashboard();

    const { pops } = useCarcassesPops();

    const { roles } = useAuth();

    const [isFullCarcassCountChecked, setIsFullCarcassCountChecked] = useState<boolean>(true);
    const [fullCarcassLabel, setFullCarcassLabel] = useState<string>("Carcaças inteiras")
    const [carcassAverageWeightAux, setCarcassAverageWeightAux] = useState<number>(carcassAverageWeight);
	
    const [carcassAllTimeCountHalf, setCarcassAllTimeCountHalf] = useState<number>();
    const [carcassAllTimeBestPlacedCountHalf, setCarcassAllTimeBestPlacedCountHalf] = useState<number>();
    const [carcassIntegratedWithERPCountHalf, setCarcassIntegratedWithERPCountHalf] = useState<number>();
    const [carcassesCountByClassificationHalf, setCarcassesCountByClassificationHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByDentitionHalf, setCarcassesCountByDentitionHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByWeightHalf, setCarcassesCountByWeightHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountBySexHalf, setCarcassesCountBySexHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByTypificationHalf, setCarcassesCountByTypificationHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByFrigobomTypificationHalf, setCarcassesCountByFrigobomTypificationHalf] = useState<IDashboardBarChartData[]>();

    function roundCategoriesInHalf(
            categories: IDashboardBarChartData[],
            setterHalf: (values: IDashboardBarChartData[]) => void
    ) {
        const newCount = categories.reduce((res, item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            if (newItem.value > 0) res.push(newItem);
            return res
        }, Array<IDashboardBarChartData>());
        const sum = newCount.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        setterHalf(adjustDifference(newCount, sum, desiredSum));
    }
    
    useEffect(() => {
        if(params.isKgChecked) {
            params.setWeightUnitLabel('kg');
            setCarcassAverageWeightAux(carcassAverageWeight);
        }else{
            params.setWeightUnitLabel('@');
            setCarcassAverageWeightAux(carcassAverageWeight/15);
        }
        
    }, [carcassAverageWeight]);
    
    useEffect(() => {
        setCarcassAllTimeCountHalf(Math.floor(carcassAllTimeCount/2));
    }, [carcassAllTimeCount]);

    useEffect(() => {
        setCarcassAllTimeBestPlacedCountHalf(Math.floor(carcassAllTimeBestPlacedCount/2));
    }, [carcassAllTimeBestPlacedCount]);

    useEffect(() => {
        setCarcassIntegratedWithERPCountHalf(Math.floor(carcassIntegratedWithERPCount/2));
    }, [carcassIntegratedWithERPCount]);

    useEffect(() => {
        roundCategoriesInHalf(carcassesCountByClassification, setCarcassesCountByClassificationHalf);
    }, [carcassesCountByClassification]);

    useEffect(() => {
        roundCategoriesInHalf(carcassesCountByDentition, setCarcassesCountByDentitionHalf);
    }, [carcassesCountByDentition]);
    
    useEffect(() => {
        roundCategoriesInHalf(carcassesCountByWeight, setCarcassesCountByWeightHalf);
    }, [carcassesCountByWeight]);

    useEffect(() => {
        roundCategoriesInHalf(carcassesCountBySex, setCarcassesCountBySexHalf);
    }, [carcassesCountBySex]);

    useEffect(() => {
        roundCategoriesInHalf(carcassesCountByTypification, setCarcassesCountByTypificationHalf);
    }, [carcassesCountByTypification]);

    useEffect(() => {
        const newCount = carcassesCountByFrigobomTypification.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            return newItem
        })
        const sum = newCount.reduce((total, item) => {
            if(item.name == 'Sem cobertura' || item.name == 'Desuniforme' || item.name == 'Padrão'){
                total = total + item.value
            }
            return total
        }, 0);

        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        setCarcassesCountByFrigobomTypificationHalf(adjustDifference(newCount, sum, desiredSum));
    }, [carcassesCountByFrigobomTypification])

    const adjustDifference = (countObject: IDashboardBarChartData[], sum: number, desiredSum: number) => {
        const adjustedObject = countObject.slice()
        if (sum !== desiredSum) {
            const difference = desiredSum - sum;
            const adjustment = Math.floor(difference / adjustedObject.length);
            var leftover = difference % adjustedObject.length;

            for (let i = 0; i < adjustedObject.length; i++) {
                adjustedObject[i].value += Math.max(0, adjustment);
                if (leftover > 0) {
                    adjustedObject[i].value += 1;
                    leftover--;
                }
                else{
                    break;
                }
            }
        }
        return adjustedObject
    }

    const onFilterIconClick = () => {
        params.setFilterExpanded(!params.filterExpanded);
    }

    const handleKgSwitchChange = () => {
        params.setIsKgChecked(!params.isKgChecked);
        if(params.isKgChecked) {
            params.setWeightUnitLabel('@');
            setCarcassAverageWeightAux(carcassAverageWeight/15);
        }else{
            params.setWeightUnitLabel('kg');
            setCarcassAverageWeightAux(carcassAverageWeight);
        }
    }

    const handleFullCarcassCountSwitchChange = () => {
        setIsFullCarcassCountChecked(!isFullCarcassCountChecked);
        if(isFullCarcassCountChecked) {
            setFullCarcassLabel('Meias carcaças');
        }else{
            setFullCarcassLabel('Carcaças inteiras');
        }
    }

    const convertClassificationToFrigobomNomenclature = (classificationData: IDashboardBarChartData[] | undefined) => {
        if(!classificationData)
            return

        var convertedData: IDashboardBarChartData[] = [];

        var padraoValue = 0;
        var desuniformeValue = 0;
        var semCoberturaValue = 0;
        for (let i = 0; i < classificationData.length; i++){
            if(classificationData[i].name == 'Mediana' || classificationData[i].name == 'Uniforme' || classificationData[i].name == 'Excessiva'){
                padraoValue += classificationData[i].value
            }else if(classificationData[i].name == 'Escassa'){
                desuniformeValue += classificationData[i].value
            }else if(classificationData[i].name == 'Ausente'){
                semCoberturaValue += classificationData[i].value
            }
        }
        convertedData.push({
            name: 'Sem Cobertura',
            value: semCoberturaValue
        })
        convertedData.push({
            name: 'Desuniforme',
            value: desuniformeValue
        })
        convertedData.push({
            name: 'Padrão',
            value: padraoValue
        })

        const sum = convertedData.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        convertedData = adjustDifference(convertedData, sum, desiredSum)
        return convertedData;
    }

    const getSelectedPopsDescriptions = () => {
        const popCopy = filter.pops ?? [];
        const descriptions = pops.filter(pop => popCopy.includes(pop.name)).map(pop => pop.description)
        return descriptions
    }

    const printSelectedPops = (truncateN: number) => {
        const descriptions = getSelectedPopsDescriptions().join(', ');

        function truncate(str: string, length: number) {
            if (str.length > length) {
                return str.slice(0, length) + '...';
            } else return str;
        }

        if (truncateN != -1){
            return truncate(descriptions, truncateN)
        }
        return descriptions
    }

    return (
        <Page width='100%' height='auto' style={{ minHeight: '95vh', padding: "0 30px 40px" }}>
            <Container fluid flex color={theme.palette.background.default} width="100%" >
                <Container fluid sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING, paddingTop: VERY_PADDING}}>
                    <Grid container rowSpacing={4} columnSpacing={4} sx={{alignItems: "center"}}>
                        <Grid item lg={2} xs={12}>
                            <Text semiBold color={theme.palette.primary.main} size={25}>Dashboard</Text>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Text semiBold color={theme.palette.primary.main} size={23}>
                                { (filter.initialDate && filter.finalDate) ?
                                    (
                                        filter.initialDate.toString() === filter.finalDate.toString() ?
                                            `${moment(filter.initialDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}` : 
                                            `${moment(filter.initialDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')} - ${moment(filter.finalDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}`
                                    ): ''
                                }
                            </Text>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Stack
                                direction='row' spacing={1} display='flex'
                                sx={{
                                    justifyContent: {
                                        xs: 'center',
                                        md: 'flex-end',
                                    }
                                }}
                            >
                                <IconButton size='medium' onClick={onFilterIconClick}>
                                    {
                                        params.filterExpanded ?
                                        <FilterOffOutlined style={{ color: theme.palette.primary.dark }}/> :
                                        <FilterOutlined style={{ color: theme.palette.primary.dark }}/>
                                    }
                                </IconButton>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Switch checked={params.isKgChecked} onChange={handleKgSwitchChange}/>
                                    } 
                                    label={params.weightUnitLabel} />
                                </FormGroup>
                                {roles.includes('view_dashboard__half_carcasses') &&
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch checked={isFullCarcassCountChecked} onChange={handleFullCarcassCountSwitchChange}/>
                                        } 
                                        label={fullCarcassLabel} />
                                    </FormGroup>
                                }
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                params.filterExpanded &&
                                <DashboardFilter
                                    initialDate={filter.initialDate}
                                    finalDate={filter.finalDate}
                                    branches={filter.branches}
                                    farms={filter.farms}
                                    lots={filter.lotNums}
                                    dentitions={filter.dentitions}
                                    weightIntervals={filter.weightIntervals}
                                    sexes={filter.sexes}
                                    pops={filter.pops}
                                    isUnitKg={params.isKgChecked}
                                    onApplyFilter={(_filter: IDashboardFilterData) => {
                                        fetchDashboardData(_filter);
                                        setFilter(_filter);
                                    }}
                                    loading={loading}
                                />
                            }
                        </Grid>
                    </Grid>
                </Container>
                <Container fluid flex flexGrow flexStart sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING}}>
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} spacing={4} alignItems="center" minHeight='100%'>
                        <Grid
                            container
                            item
                            lg={['view_well_placed',
                                 'view_slaughter_scale'].some(
                                    r => roles.includes(r)
                                ) ? 8 : 6}
                            xs={12} sx={{alignContent: "center"}}
                            spacing={4}
                            height="100%"
                        >
                            <Grid
                                item
                                md={['view_well_placed',
                                     'view_slaughter_scale'].some(
                                        r => roles.includes(r)
                                    ) ? 6 : 12}
                                xs={12}
                            >
                                <DashboardNumberCard
                                    label={'Quantidade de carcaças'}
                                    value={isFullCarcassCountChecked ? carcassAllTimeCountHalf : carcassAllTimeCount}
                                    size='medium'
                                    icon={<CowIcon style={{scale: '0.85', color: 'white'}}></CowIcon>}
                                    help={`Quantidade total de ${isFullCarcassCountChecked ? '' : 'meias-'}carcaças classificadas`}
                                />
                            </Grid>
                            {['view_well_placed', 'view_slaughter_scale'].some(r => roles.includes(r)) &&
                                <Grid container item md={6} xs={12} spacing={2}>
                                    {roles.includes('view_slaughter_scale') &&
                                        <Grid item xs={12}>
                                            <DashboardNumberCard
                                                label={'Carcaças na escala de abate'}
                                                value={
                                                    carcassesSlaughterScheduleCount === undefined ?
                                                    undefined :
                                                    carcassesSlaughterScheduleCount * (Number(isFullCarcassCountChecked) + 1)
                                                }
                                                size={roles.includes('view_well_placed') ? 'small' : 'medium'}
                                                icon={<PhotoCameraBackIcon style={{ width: '32px', height: '32px', color: 'white'}} />}
                                                help={
                                                    `Quantidade total de ${isFullCarcassCountChecked ? '' : 'meias-'}carcaças 
                                                    listadas na escala de abate. Como não temos informações sobre cada animal 
                                                    na escala de abate, nenhum dado é exibido caso algum filtro para animais 
                                                    seja selecionado. Tente retirar alguns filtros caso o valor esteja indefinido.
                                                    `
                                                }
                                            />
                                        </Grid>
                                    }
                                    {roles.includes('view_well_placed') &&
                                        <Grid item xs={12}>
                                            <DashboardNumberCard
                                                label={'Carcaças bem posicionadas'}
                                                value={isFullCarcassCountChecked ? carcassAllTimeBestPlacedCountHalf : carcassAllTimeBestPlacedCount}
                                                size={roles.includes('view_slaughter_scale') ? 'small' : 'medium'}
                                                icon={<PhotoCameraBackIcon style={{ width: '32px', height: '32px', color: 'white'}} />}
                                                help={`Quantidade total de ${isFullCarcassCountChecked ? '' : 'meias-'}carcaças bem-posicionadas`}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid
                            container
                            item
                            lg={['view_well_placed',
                                 'view_slaughter_scale'].some(
                                    r => roles.includes(r)
                                ) ? 4 : 6}
                            xs={12}
                            sx={{alignContent: "center"}}
                            columnSpacing={4}
                            rowSpacing={2}
                            height="100%"
                        >
                            <Grid item xs={12}>
                                <DashboardNumberCard
                                    label={'Peso médio das carcaças'}
                                    value={carcassAverageWeightAux}
                                    valueUnit={params.weightUnitLabel}
                                    size={roles.includes('view_carcasses_erp_integrated') ? 'small' : 'medium'}
                                    icon={<ScaleOutlinedIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                                    help='Peso médio das carcaças classificadas'
                                />
                            </Grid>
                            {roles.includes('view_carcasses_erp_integrated') &&
                                <Grid item xs={12}>
                                    <DashboardNumberCard
                                        label={'Carcaças classificadas e integradas com o ERP'}
                                        value={isFullCarcassCountChecked ? carcassIntegratedWithERPCountHalf : carcassIntegratedWithERPCount}
                                        size='small'
                                        icon={<WbCloudyOutlinedIcon style={{ width: '32px', height: '32px', color: 'white'}} />}
                                        help={`Quantidade total de ${isFullCarcassCountChecked ? '' : 'meias-'}carcaças classificadas e integradas com ERP`}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Spacing top={VERY_PADDING} />
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} spacing={4} alignItems="center" minHeight='100%'>
                        <Grid container item lg={6} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                            <Grid item xs={12}>
                                <DashboardChartCard 
                                    label={'Acabamento Redsoft'}
                                    size='medium'
                                    help='Gráfico da quantidade de carcaças agrupadas por acabamento de gordura (algoritmo IA Redsoft)'
                                    chart={
                                        <DashboardClassificationPieChart
                                            data={isFullCarcassCountChecked ? carcassesCountByClassificationHalf : carcassesCountByClassification}
                                            colors={COLORS}
                                        />

                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item lg={6} xs={12} sx={{alignContent: "center"}} rowSpacing={2} height="100%">
                            <Grid item xs={12}>
                                <DashboardChartCard 
                                    label={'Cronologia dentária'}
                                    size='medium'
                                    help='Gráfico da quantidade de carcaças agrupadas por dentição'
                                    chart={
                                        <DashboardDentitionBarChart
                                            data={isFullCarcassCountChecked ? carcassesCountByDentitionHalf : carcassesCountByDentition} 
                                            colors={COLORS}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Spacing top={VERY_PADDING} />
                    {filter.branches.length === 1 && filter.branches[0].company?.name.toLocaleLowerCase() === 'frigobom' && <>
                        <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                            <Grid container item lg={5} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                                <Grid item xs={12}>
                                    <DashboardChartCard
                                        label={'Acabamento Redsoft - Terminologia Frigobom'}
                                        size='medium'
                                        help='Gráfico da quantidade de carcaças agrupadas por acabamento de gordura (algoritmo IA Redsoft) segundo a terminologia Frigobom'
                                        chart={
                                            <DashboardClassificationPieChart
                                                data={isFullCarcassCountChecked ? convertClassificationToFrigobomNomenclature(carcassesCountByClassificationHalf) : convertClassificationToFrigobomNomenclature(carcassesCountByClassification)}
                                                colors={COLORS}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item lg={7} xs={12} sx={{alignContent: "center"}} rowSpacing={2} height="100%">
                                <Grid item xs={12}>
                                    <DashboardChartCard
                                        label={'Tipificação Frigobom (ERP)'}
                                        size='medium'
                                        help='Gráfico da quantidade de carcaças agrupadas por tipificação segundo ERP Frigobom'
                                        chart={
                                            <DashboardFrigobomTypificationAreaChart
                                                data={isFullCarcassCountChecked ? carcassesCountByFrigobomTypificationHalf : carcassesCountByFrigobomTypification} 
                                                colors={COLORS}
                                                height={220}
                                                isKgChecked={params.isKgChecked}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Spacing top={VERY_PADDING} />
                    </>}
                    {carcassesCountByTypification && carcassesCountByTypification.length > 0 && <>
                        <Grid container columns={{ xs: 1 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                            <Grid container item xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                                <Grid item xs={12}>
                                    <DashboardChartCard
                                        label={'Tipificação Redsoft'}
                                        size='medium'
                                        help='Gráfico da quantidade de carcaças agrupadas por tipificação (algoritmo IA Redsoft)'
                                        chart={
                                            <DashboardClassificationPieChart
                                                data={isFullCarcassCountChecked ? carcassesCountByTypificationHalf : carcassesCountByTypification}
                                                colors={COLORS}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Spacing top={VERY_PADDING} />
                    </>}
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                        <Grid container item  xl={7.5} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                            <Grid item xs={12}>
                                {/* LOTS TABLE */}
                                <DashboardChartCard 
                                        label={'Listagem de lotes'}
                                        size='medium'
                                        help='Tabela dos lotes de animais para o período especificado'
                                        chart={
                                            <DashboardLotsTable    
                                                filter={filter}
                                                isKgChecked={params.isKgChecked}
                                                isFullCarcassCountChecked={isFullCarcassCountChecked}
                                            />
                                        }
                                    />    
                            </Grid>
                        </Grid>
                        <Grid container item xl={4.5} xs={12} sx={{alignContent: "center"}} rowSpacing={6} columnSpacing={4} height="100%">
                            <Grid item xl={12} lg={6} xs={12}>
                                <DashboardChartCard 
                                    label={'Carcaças por peso'}
                                    size='medium'
                                    help='Gráfico da quantidade de carcaças agrupadas por faixa de peso'
                                    chart={
                                        <DashboardWeightPieChart
                                            data={isFullCarcassCountChecked ? carcassesCountByWeightHalf : carcassesCountByWeight}
                                            colors={COLORS}
                                            height={230}
                                            isKgChecked={params.isKgChecked}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xl={12} lg={6} xs={12}>
                                <DashboardChartCard 
                                    label={'Carcaças por sexo e idade'}
                                    size='medium'
                                    help='Gráfico da quantidade de carcaças agrupadas por sexo e idade'
                                    chart={
                                        <DashboardSexPieChart
                                            data={isFullCarcassCountChecked ? carcassesCountBySexHalf : carcassesCountBySex}
                                            colors={COLORS}
                                            height={230}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </Page >
    )
}

export default DashboardBase;
