import React, { FC, useState } from 'react'
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from "date-fns/locale";
import moment from 'moment'

import Theme from '../../theme'
import { IDatePicker } from '../../../types';

const DatePicker: FC<IDatePicker> = (params: IDatePicker) => {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <LocalizationProvider
            locale={ptBR} dateAdapter={AdapterDateFns} >
            <MuiDatePicker
                open={open}
                autoFocus={params.autoFocus}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                label=""
                value={params.value}
                onChange={(newValue: any) => params.setValue(newValue)}
                disabled={params.disabled}
                disablePast={params.disablePast}
                renderInput={(_params: any) => {
                    let auxParams = _params
                    if (auxParams.inputProps) {
                        auxParams.inputProps.label = ""
                        auxParams.inputProps.placeholder = params.placeholder
                    }

                    return (
                        <TextField
                            placeholder={params.placeholder}
                            value={params.value ? moment(params.value).format('DD/MM/YYYY').toString() : ''}
                            required={params.required}
                            variant={params.variant || "standard"}
                            error={params.error}
                            size={params.size || 'small'}
                            style={{
                                ...params.sx,
                            }}
                            {...auxParams}
                            {...params.register}
                            sx={{
                                svg: { color: Theme.palette.primary.dark },
                                margin: 0
                            }}
                            readOnly
                            onClick={() => setOpen(true)} />
                    )
                }}
            />
        </LocalizationProvider>
    )
}

export default DatePicker
