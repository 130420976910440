import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { convertApiResponseToIBranch } from "./branches";

import {
  IBranch,
  IAnalysisAnnotations,
  IAnalysisAnnotationsFilterParams,
} from "../types";

const baseUrl: string = "/api_redsoft/v1_0/carcasses/analysis_annotations";

export const fetchAnalysisAnnotations = async (
  _params: IAnalysisAnnotationsFilterParams
) => {
  let filter = "";
  if (_params.initialDate)
    filter += `?initial_date=${moment(_params.initialDate).format(
      "YYYY-MM-DD"
    )}`;
  if (_params.finalDate)
    filter +=
      (filter != "" ? "&" : "?") +
      `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
  if (_params.branches.length > 0)
    filter +=
      (filter != "" ? "&" : "?") +
      `branches_id=${_params.branches.map((item) => `${item.id}`).join(",")}`;
  if (_params.classifications.length > 0)
    filter +=
      (filter != "" ? "&" : "?") +
      `classifications_id=${_params.classifications.map((item) => `${item.classificationValue!}`).join(",")}`;

  const response: any = await api.get(baseUrl + filter);
  const analysisAnnotations: IAnalysisAnnotations[] = [];

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;

      if (data)
        for (let i = 0; i < data.length; i++) {
          analysisAnnotations.push(
            convertApiResponseToAnalysisAnnotations(data[i])
          );
        }
    }
  }

  return analysisAnnotations;
};

export const fetchAnalysisAnnotationsGroupSides = async (
  _params: IAnalysisAnnotationsFilterParams
) => {
  let filter = "";
  if (_params.initialDate)
    filter += `?initial_date=${moment(_params.initialDate).format(
      "YYYY-MM-DD"
    )}`;
  if (_params.finalDate)
    filter +=
      (filter != "" ? "&" : "?") +
      `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
  if (_params.branches.length > 0)
    filter +=
      (filter != "" ? "&" : "?") +
      `branches_id=${_params.branches.map((item) => `${item.id}`).join(",")}`;
  if (_params.classifications.length > 0)
  filter +=
    (filter != "" ? "&" : "?") +
    `classifications_id=${_params.classifications.map((item) => `${item.classificationValue!}`).join(",")}`;

  const response: any = await api.get(`${baseUrl}/group_sides${filter}`);
  const analysisAnnotations: IAnalysisAnnotations[] = [];

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;

      if (data)
        for (let i = 0; i < data.length; i++) {
          let dataConvert = convertApiResponseToAnalysisAnnotations(data[i]);
          analysisAnnotations.push(dataConvert);
        }
    }
  }

  return analysisAnnotations;
};

export const convertApiResponseToAnalysisAnnotations = (
  _analysis_annotations: any
) => {
  let branch: IBranch | undefined = undefined;
  if (_analysis_annotations.branch)
    branch = convertApiResponseToIBranch(_analysis_annotations.branch);
  else {
    branch = {
      id: _analysis_annotations.branch_id,
      name: "",
      token: "",
      prefix: "",
    };

    if (_analysis_annotations.branch_name)
      branch.name = _analysis_annotations.branch_name;
  }

  const analysisAnnotations: IAnalysisAnnotations = {
    branch,
    classificationDate: _analysis_annotations.classification_date,
    countCarcass: _analysis_annotations.count_carcasses,
    countAnnotations: _analysis_annotations.count_annotations,
    countRevisions: _analysis_annotations.count_revisions,
  };

  return analysisAnnotations;
};
