import { ICarcassLocalClassification } from '../types'
import { convertApiResponseToICarcassLocalClassification, baseURL } from "../services/carcassesLocalClassifications";
import CRUDHook from "./crud";

export const { crudProvider: CarcassesLocalClassificationsProvider, crudHook: useCarcassesLocalClassifications } = CRUDHook<ICarcassLocalClassification>(
    {
        baseURL,
        name: 'acabamento local',
        convertResponseToModel: convertApiResponseToICarcassLocalClassification,
    }
)
