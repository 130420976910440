import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, SelectChangeEvent } from '@mui/material';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import BranchesMultiSelect from '../../branches/multiSelect';
import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import Input from '../../../atoms/input';
import {
    IVerdictsFilter,
    IBranch,
    IAnnotator,
    IVerdictFilter,
    ICarcassLocalClassification,
    ICarcassLocalConformation,
    ILabelId,
} from '../../../../types';
import { PADDING } from '../../../../utils/consts';
import CarcassesLocalClassificationsMultiSelect from '../../carcassesLocalClassifications/multiSelect';
import CarcassesLocalConformationsMultiSelect from '../../carcassesLocalConformations/multiSelect';
import CarcassClassificationsMultiSelect from '../../carcasses/classificationsMultiSelect';
import CarcassConformationsMultiSelect from '../../carcasses/conformationsMultiSelect';
import DateRangePicker from "../../../atoms/dateRangePicker";
import VerdictContextMenu from '../verdictContextMenu';


const VerdictFilter: FC<IVerdictsFilter> = (params: IVerdictsFilter) => {
    const [initialDate, setInitialDate] = useState<Date | null>(params.filter.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(params.filter.finalDate);
    const [selectedAnnotators, setSelectedAnnotators] = useState<IAnnotator[]>(params.filter.annotators);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(params.filter.branches);
    const [selectedClassifications, setSelectedClassifications] = useState<ILabelId[]>(params.filter.classificationsId);
    const [selectedLocalClassifications, setSelectedLocalClassifications] = useState<ICarcassLocalClassification[]>(params.filter.localClassifications);
    const [selectedConformations, setSelectedConformations] = useState<ILabelId[]>(params.filter.conformationsId);
    const [selectedLocalConformations, setSelectedLocalConformations] = useState<ICarcassLocalConformation[]>(params.filter.localConformations);
    const [annotatorsExpanded, setAnnotatorsExpanded] = useState<boolean>(false);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const [localClassificationsExpanded, setLocalClassificationsExpanded] = useState<boolean>(false);
    const [localConformationsExpanded, setLocalConformationsExpanded] = useState<boolean>(false);
    const [conformationsExpanded, setConformationsExpanded] = useState<boolean>(false);
    const [accuracyTreshold, setAccuracyTreshold] = useState<number>(params.filter.accuracy || 0);
    const [filterOption, setFilterOption] = useState<string>(params.filter.option || 'all');
    const [filterProperty, setFilterProperty] = useState<string>(params.filter.property || 'all');
    const [limit, setLimit] = useState<string>(String(params.filter.limit) || '10');

    useEffect(() => {
        setInitialDate(params.filter.initialDate);
        setFinalDate(params.filter.finalDate);
        setSelectedAnnotators(params.filter.annotators);
        setSelectedBranches(params.filter.branches);
        setSelectedClassifications(params.filter.classificationsId);
        setSelectedLocalClassifications(params.filter.localClassifications);
        setSelectedConformations(params.filter.conformationsId);
        setLimit(String(params.filter.limit) || '10');
        setAccuracyTreshold(params.filter.accuracy || 0);
        setFilterOption(params.filter.option || 'all');
        setFilterProperty(params.filter.property || 'all');
    }, [params.filter]);

    useEffect(() => {
        if (annotatorsExpanded) {
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [annotatorsExpanded]);

    useEffect(() => {
        if (branchesExpanded) {
            setAnnotatorsExpanded(false);
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [branchesExpanded]);

    useEffect(() => {
        if (classificationsExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setLocalClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [classificationsExpanded]);

    useEffect(() => {
        if (localClassificationsExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [localClassificationsExpanded]);

    useEffect(() => {
        if (conformationsExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [conformationsExpanded]);

    useEffect(() => {
        if (localConformationsExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
            setConformationsExpanded(false);
        }
    }, [localConformationsExpanded]);

    const handleChangeAccuracy = (event: SelectChangeEvent<string | number | null>) => {
        setAccuracyTreshold(parseFloat((event.target.value as string).replace(/\D/g, '')) || 0);
    }

    const handleChangeLimit = (event: SelectChangeEvent<string | number | null>) => {
        setLimit((event.target.value as string).replace(/\D/g, ''));
    }

    const onApplyFilter = () => {
        setAnnotatorsExpanded(false);
        setBranchesExpanded(false);
        setClassificationsExpanded(false);
        setLocalClassificationsExpanded(false);
        setConformationsExpanded(false);
        setLocalConformationsExpanded(false);

        let auxFilter: IVerdictFilter = {
            initialDate,
            finalDate,
            annotators: selectedAnnotators,
            branches: selectedBranches,
            classificationsId: selectedClassifications,
            conformationsId: selectedConformations,
            limit: parseInt(limit),
            accuracy: accuracyTreshold,
            localClassifications: selectedLocalClassifications,
            localConformations: selectedLocalConformations,
            option: (filterOption as 'all' | 'accuracy_only' | 'conflict_only' | undefined),
            property: (filterProperty as 'all' | 'classification' | 'conformation' | undefined),
            offset: 0,
        }
        params.onApplyFilter(auxFilter);
    }

    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING, paddingLeft: PADDING }}>
            <Text size={15} medium>Filtros</Text>
            <Spacing top={PADDING} />
            <Grid container columnSpacing={4} alignItems="center" sx={{ marginBottom: { xs: 2, lg: 0 } }}>
                <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                    <DateRangePicker
                        initialDate={initialDate}
                        finalDate={finalDate}
                        placeholder={"Escolha o intervalo de data"}
                        setInitialDate={setInitialDate}
                        setFinalDate={setFinalDate}
                        disabled={false}
                        maxDaysSelect={0}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                        }}
                    />
                    <BranchesMultiSelect
                        selectedBranches={selectedBranches}
                        setSelectedBranches={setSelectedBranches}
                        expanded={branchesExpanded}
                        setExpanded={setBranchesExpanded}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: 12,
                        }} />
                </Grid>
                <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                    <CarcassClassificationsMultiSelect
                        useJBS
                        placeholder="Acabamentos IA"
                        selectedItems={selectedClassifications}
                        setSelectedItems={setSelectedClassifications}
                        expanded={classificationsExpanded}
                        setExpanded={setClassificationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                    <CarcassesLocalClassificationsMultiSelect
                        placeholder="Acabamento Local"
                        selectedBranchesId={selectedBranches.map(item => item.id || "")}
                        selectedClassifications={selectedLocalClassifications}
                        setSelectedClassifications={setSelectedLocalClassifications}
                        expanded={localClassificationsExpanded}
                        setExpanded={setLocalClassificationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: 15
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={4} sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                    <CarcassConformationsMultiSelect
                        placeholder="Conformações IA"
                        selectedItems={selectedConformations}
                        setSelectedItems={setSelectedConformations}
                        expanded={conformationsExpanded}
                        setExpanded={setConformationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                    <CarcassesLocalConformationsMultiSelect
                        placeholder="Conformação Local"
                        selectedBranchesId={selectedBranches.map(item => item.id || "")}
                        selectedConformations={selectedLocalConformations}
                        setSelectedConformations={setSelectedLocalConformations}
                        expanded={localConformationsExpanded}
                        setExpanded={setLocalConformationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: 15
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={4} alignItems="center">
                <Grid item xs={12} lg={3}>
                    <RadioGroup value={filterOption || 'all'} onChange={(e) => setFilterOption(
                        (e.target as HTMLInputElement).value)
                    }>
                        <FormControlLabel
                            key='all' value='all'
                            control={<Radio size='small' />}
                            label='Todos os casos' />
                        <FormControlLabel
                            key='accuracy_only' value='accuracy_only'
                            control={<Radio size='small' />}
                            label='Apenas com baixa confiança' />
                        <FormControlLabel
                            key='conflict_only' value='conflict_only'
                            control={<Radio size='small' />}
                            label='Apenas com conflito entre IA e local' />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <RadioGroup
                        value={filterProperty || 'all'}
                        onChange={
                            (e) => setFilterProperty((e.target as HTMLInputElement).value)
                        }
                    >
                        <FormControlLabel
                            key='all' value='all'
                            control={<Radio size='small' />}
                            label='Todos' />
                        <FormControlLabel
                            key='classification' value='classification'
                            control={<Radio size='small' />}
                            label='Apenas acabamento' />
                        <FormControlLabel
                            key='conformation' value='conformation'
                            control={<Radio size='small' />}
                            label='Apenas conformação' />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12} lg={3} sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap'
                }}>
                    <Container inline horizontalCentered style={{ marginLeft: '8px' }}>
                        <Text size={14} medium muted={filterOption === 'conflict_only'}>
                            Confiança:&emsp;
                        </Text>
                        <Input
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={String(accuracyTreshold)}
                            onChange={handleChangeAccuracy}
                            placeholder=''
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '35px',
                                marginTop: 0,
                                minWidth: '5em',
                                maxWidth: '7em',
                            }}
                            disabled={filterOption === 'conflict_only'}
                        />
                    </Container>
                    <Container inline horizontalCentered style={{ marginLeft: '8px' }}>
                        <Text size={14} medium >Exibição:&emsp;</Text>
                        <Input
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={limit}
                            onChange={handleChangeLimit}
                            placeholder=''
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '35px',
                                marginTop: 0,
                                minWidth: '5em',
                                maxWidth: '7em',
                            }}
                        />
                    </Container>
                </Grid>
                <Grid item xs={12} lg={3} sx={{ display: 'flex', justifyContent: { lg: 'flex-end' } }}>
                    <Button sx={{ marginRight: 2 }} size="large" variant="contained" color="primary" onClick={onApplyFilter}>
                        Filtrar
                    </Button>
                    <Button sx={{ marginRight: 2 }} size="large" variant="text" color="primary" onClick={params.onCleanFilter}>
                        Limpar
                    </Button>
                    <VerdictContextMenu
                        open={params.open}
                        setOpen={params.setOpen}
                        loading={params.loading}
                        downloadVerdictSheetIsOpen={params.downloadVerdictSheetIsOpen}
                        setDownloadVerdictSheetIsOpen={params.setDownloadVerdictSheetIsOpen}
                        downloadVerdictPhotosIsOpen={params.downloadVerdictPhotosIsOpen}
                        setDownloadVerdictPhotosIsOpen={params.setDownloadVerdictPhotosIsOpen}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default VerdictFilter;