import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
} from '@mui/material';

import BranchesMultiSelect from '../branches/multiSelect';
import Container from '../../atoms/container';
import {
    IAlertHistoryFilterParams,
    IAlertHistoryFilter,
    IBranch,
    IAlert,
} from '../../../types';
import { PADDING } from '../../../utils/consts';
import DateTimePicker from '../../atoms/dateTimePicker';
import { useAlertConstants } from '../../../hooks/alertConstants';
import { useAlerts } from '../../../hooks/alert';


const AlertHistoryFilter: FC<IAlertHistoryFilter> = (params: IAlertHistoryFilter) => {
    const {
      levels: levelsConstants,
      statuses: statusesConstants,
      sources: sourcesConstants,
      fetchAlertConstants
    } = useAlertConstants();
    const { entries: alertsList, fetchAllEntries: fetchAlerts } = useAlerts();

    const [initialTime, setInitialTime] = useState<Date | null>(null);
    const [finalTime, setFinalTime] = useState<Date | null>(null);
    const [branches, setBranches] = useState<IBranch[]>([]);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<IAlert[]>([]);
    const [statuses, setStatuses] = useState<number[]>([]);
    const [levels, setLevels] = useState<number[]>([]);
    const [sources, setSources] = useState<number[]>([]);

    useEffect(() => {
        if (!alerts || alerts.length === 0)
            fetchAlerts();
        if (
            !levelsConstants || levelsConstants.length === 0 ||
            !sourcesConstants || sourcesConstants.length === 0 ||
            !statusesConstants || statusesConstants.length === 0
        )
            fetchAlertConstants();

        // load initial data
        onApplyFilter();
    }, []);

    const onApplyFilter = () => {
        setBranchesExpanded(false);

        let auxFilter: IAlertHistoryFilterParams = {
            initialTime,
            finalTime,
            branches,
            alerts,
            statuses,
            levels,
            sources,
        }
        params.onApply(auxFilter);
    };

    const cleanFilter = () => {
        setInitialTime(null);
        setFinalTime(null);
        setBranches([]);
        setBranchesExpanded(false);
        setAlerts([]);
        setStatuses([]);
        setLevels([]);
        setSources([]);
    };

    const renderAutocompleteMultiselect = (
        title: string,
        placeholder: string,
        options: { id: number | string, label: string }[],
        value: (number | string)[],
        setValue: (value: (number | string)[]) => void
    ) => {
      return <Autocomplete
            disabled={params.loading}
            multiple={true}
            options={options}
            renderInput={(params) => <TextField
                {...params}
                placeholder={placeholder}
                title={title}
                sx={{ background: '#FFF', margin: 0 }}
            />}
            onChange={(e, newValue: {id: number | string, label: string}[]) => {
                const val: (number | string)[] = newValue.map((i) => i.id) || [];
                setValue(val);
            }}
            value={ options.filter((opt) => value.includes(opt.id)) }
        />
    };

    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING }}>
            <Grid container spacing={1} alignItems="center" sx={{ marginBottom: { xs: 2, lg: 0 } }}>
                <Grid item xs={12} lg={4}>
                    <DateTimePicker
                        value={initialTime}
                        setValue={setInitialTime}
                        label='Data/Hora Inicial'
                        placeholder='Data/Hora Inicial'
                        inputFormat='yyyy-MM-dd HH:mm:ss'
                        mask={'__/__/____ __:__:__'}
                        sx={{ background: '#FFF', width: '100%' }}
                        disabled={params.loading}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DateTimePicker
                        value={finalTime}
                        setValue={setFinalTime}
                        label='Data/Hora Final'
                        placeholder='Data/Hora Final'
                        inputFormat='yyyy-MM-dd HH:mm:ss'
                        mask={'__/__/____ __:__:__'}
                        sx={{ background: '#FFF', width: '100%' }}
                        disabled={params.loading}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Parâmetros', 'Selecione os parâmetros...',
                        sourcesConstants.map((item) => ({ id: item.value, label: item.description })),
                        sources,
                        (value: (number | string)[]) => setSources(value as number[])
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Alertas', 'Selecione os alertas...',
                        alertsList.map((item) => ({ id: item.id!, label: item.title })),
                        alerts.map((item) => item.id!),
                        (value: (number | string)[]) => setAlerts(
                          alertsList.filter((item) => item.id && value.includes(item.id))
                        )
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Níveis', 'Selecione os níveis...',
                        levelsConstants.map((item) => ({ id: item.value, label: item.description })),
                        levels,
                        (value: (number | string)[]) => setLevels(value as number[])
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    {renderAutocompleteMultiselect(
                        'Estado', 'Selecione os estados...',
                        statusesConstants.map((item) => ({ id: item.value, label: item.description })),
                        statuses,
                        (value: (number | string)[]) => setStatuses(value as number[])
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <BranchesMultiSelect
                        selectedBranches={branches}
                        setSelectedBranches={setBranches}
                        expanded={branchesExpanded}
                        setExpanded={setBranchesExpanded}
                        minWidth='100%'
                        height='40px'
                        disabled={params.loading}
                        sx={{
                            backgroundColor: '#FFF',
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                        }} />
                </Grid>
                <Grid item xs={12} lg={8} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: {xs: 1, lg: 0} }}>
                    <Button
                        sx={{ marginRight: 2 }}
                        size="large"
                        variant="text"
                        color="primary"
                        onClick={cleanFilter}
                        disabled={params.loading}
                    >
                        Limpar
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onApplyFilter}
                        disabled={params.loading}
                    >
                        Filtrar
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AlertHistoryFilter;