import React, { FC, useEffect, useState } from "react";

import BranchListItem from "../listItem";
import Container from "../../../atoms/container";
import Text from "../../../atoms/text";
import { Pagination } from "../../../../components";
import { useBranches } from "../../../../hooks/branches";
import { useResponsive } from "../../../../hooks/responsive";
import { IBranch, IBranchList } from "../../../../types";
import { LinearProgress } from "@mui/material";

const BranchesList: FC<IBranchList> = (props: IBranchList) => {
    const branchesHooks = useBranches();
    const { screenHeight } = useResponsive();

    const pageCount = Math.ceil((screenHeight * 0.6) / 120);
    const branchesCount = props.branches.length;

    const [currPage, setCurrPage] = useState<number>(0);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setCurrPage(0);
    }, [branchesHooks.searchBranch]);

    const fetchData = () => {
        branchesHooks.fetchBranchesByCompanyId(props.companyId);
    };

    return (
        <Container fluid flex width="100%" minHeight="73vh">
            <Container fluid width="100%" minHeight="73vh">
                {branchesHooks.loading ?  <LinearProgress color="primary" /> :
                    props.branches.length == 0 && <Text center>Nenhum frigorífico encontrado.</Text>}
                {props.branches.sort((a: IBranch, b: IBranch) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                }).slice((currPage * pageCount), ((currPage * pageCount) + pageCount))
                    .map((item, index) =>
                        <Container key={index} fluid verticalPadded width="100%">
                            <BranchListItem
                                key={index}
                                branch={item}
                                loading={branchesHooks.loading}
                                onOpenUsers={props.onOpenUsers}
                                onEditBranch={props.onEditBranch}
                                onDeleteBranch={props.onDeleteBranch} />
                        </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(branchesCount / pageCount)} setPage={setCurrPage} />
        </Container>
    );
};

export default BranchesList;
