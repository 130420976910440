import { ISlaughterDelaysReason } from '../types/hooks/slaughterDelays';
import { BASE_URL } from "../services/slaughterDelays";
import CRUDHook from "./crud";

export const {
    crudProvider: SlaughterDelayReasonLocationsProvider,
    crudHook: useSlaughterDelayReasonLocations,
} = CRUDHook<ISlaughterDelaysReason>(
    {
        baseURL: `${BASE_URL}/locations`,
        name: 'local de parada',
    }
);
