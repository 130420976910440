import { ISlaughterDelaysReason } from '../types/hooks/slaughterDelays';
import { BASE_URL } from "../services/slaughterDelays";
import CRUDHook from "./crud";

export const {
    crudProvider: SlaughterDelayReasonsProvider,
    crudHook: useSlaughterDelayReasons,
} = CRUDHook<ISlaughterDelaysReason>(
    {
        baseURL: `${BASE_URL}/reasons`,
        name: 'motivo de parada',
    }
);
