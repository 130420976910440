import { AxiosResponse } from "axios";

import api from ".";
import { ISupportEmail } from "../types/hooks/sendEmails";
const baseUrl: string = "emails";

export const sendSupportEmail = async (
    _supportEmail: ISupportEmail
) => {
    const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}/support`, {
        senderName: _supportEmail.senderName,
        senderEmail: _supportEmail.senderEmail,
        subject: _supportEmail.subject,
        message: _supportEmail.message,
    });

    if (response && response.request) {
        const status = response.request.status;
        if (status == 200 && response.data) {
            return response.data;
        } else if (
            status == 400 &&
            (response as any).response &&
            (response as any).response.data
        ) {
            throw new Error((response as any).response.data as string);
        }
    }

    throw new Error("Não foi possível criar um dado");
};

