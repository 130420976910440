import React, { FC, useState } from "react";
import { Box, Button, Grid } from "@mui/material";

import Container from "../../atoms/container";
import DatePicker from "../../atoms/datePicker";
import Text from "../../atoms/text";
import theme from "../../theme";
import { useAnalysisAnnotations } from "../../../hooks/analysisAnnotations";
import {
    IAnalysisAnnotationsFilterParams,
    IAnalysisAnnotationsFilter,
    ICarcassLocalClassification,
    IBranch,
} from "../../../types";

import BranchesMultiSelect from "../branches/multiSelect";
import CarcassesLocalClassificationsMultiSelect from "../carcassesLocalClassifications/multiSelect";

const AnalysisAnnotationsFilterFilter: FC<IAnalysisAnnotationsFilter> = (
    params: IAnalysisAnnotationsFilter,
) => {
    const { filter } = useAnalysisAnnotations();
    const [initialDate, setInitialDate] = useState<Date | null>(
        filter.initialDate,
    );
    const [finalDate, setFinalDate] = useState<Date | null>(filter.finalDate);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(
        filter.branches,
    );
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [selectedClassifications, setSelectedClassifications] = useState<ICarcassLocalClassification[]>(filter.classifications);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const onApply = () => {
        setBranchesExpanded(false);
        const filterParams: IAnalysisAnnotationsFilterParams = {
            initialDate,
            finalDate,
            classifications: selectedClassifications,
            branches: selectedBranches,
        };

        setTimeout(() => {
            params.onApply(filterParams);
        }, 300);
    };

    return (
        <Container fluid flex>
            <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
                alignItems="center"
            >
                <Grid item xs={4} sm={5} md={3} lg={4} xl={2.5}>
                    <DatePicker
                        variant="outlined"
                        value={initialDate}
                        setValue={setInitialDate}
                        label="Data inicial"
                        placeholder="Data inicial"
                        sx={{
                            backgroundColor: "#FFF",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 8px",
                            borderRadius: "4px",
                        }}
                    />
                </Grid>
                <Grid item xs={4} sm={5} md={3} lg={4} xl={2.5}>
                    <DatePicker
                        variant="outlined"
                        value={finalDate}
                        setValue={setFinalDate}
                        label="Data final"
                        placeholder=""
                        sx={{
                            backgroundColor: "#FFF",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 8px",
                            borderRadius: "4px",
                        }}
                    />
                </Grid>

                <Grid item xs={2} sm={3} lg={4} xl={2}>
                    <BranchesMultiSelect
                        selectedBranches={selectedBranches}
                        setSelectedBranches={setSelectedBranches}
                        expanded={branchesExpanded}
                        setExpanded={setBranchesExpanded}
                        sx={{
                            backgroundColor: "#FFF",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 8px",
                            borderRadius: "4px",
                        }}
                    />
                </Grid>
                <Grid item xs={2} sm={3} lg={4} xl={2}>
                        <CarcassesLocalClassificationsMultiSelect
                            placeholder="Acabamento Local"
                            selectedBranchesId={selectedBranches.map(item => item.id || "")}
                            selectedClassifications={selectedClassifications}
                            setSelectedClassifications={setSelectedClassifications}
                            expanded={classificationsExpanded}
                            setExpanded={setClassificationsExpanded}
                            singleSelect={false}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                            }}
                        />
                </Grid>

                <Grid item xs={1} sm={3} lg={4} xl={2}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => onApply()}
                    >
                        <Text size={12} color={theme.palette.background.paper}>
                            Aplicar
                        </Text>
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AnalysisAnnotationsFilterFilter;
