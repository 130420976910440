import React, { FC, useRef, useState } from "react";
import { Button, ButtonGroup, Checkbox, ClickAwayListener, Grow, Paper, Popper, MenuList, MenuItem, IconButton } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon, ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';

import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useCarcass } from "../../../../hooks/carcasses";
import { IAnnotation, IRevisionCard } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import CarcassPhoto from "../../carcasses/carcassPhoto";
import { useAnnotations } from "../../../../hooks/annotations";

const RevisionCard: FC<IRevisionCard> = (params: IRevisionCard) => {
    const { handleMagnifierSize } = useAnnotations();
    const { classificationsJBS } = useCarcass();

    const [openButton, setOpenButton] = useState(false);
    const anchorButton = useRef<HTMLDivElement>(null);
    const [selectedClassification, setSelectedClassification] = useState(params.annotation.classification);
    const [openPhoto, setOpenPhoto] = useState<boolean>(false);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedClassification(index);
        setOpenButton(false);

        let auxAnnotation: IAnnotation = params.annotation;
        params.onSelectAnnotation({
            ...auxAnnotation,
            classification: index
        });
    };

    const handleToggle = () => {
        setOpenButton((prevOpenButton) => !prevOpenButton);
    };

    const handleCorrect = () => {
        if (!params.isSelected)
            params.onSelectAnnotation(params.annotation);
        else {
            params.onSelectAnnotation({
                ...params.annotation,
                classification: -1
            });
        }
    };


    const classificationLabel = (id: number) => {
        return classificationsJBS.find(item => item.id == params.annotation.classification)?.label;
    }

    const handleClose = (event: Event) => {
        if (
            anchorButton.current &&
            anchorButton.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenButton(false);
    };

    if (!params.annotation.annotator || !params.annotation.annotator.user) {
        return (
            <Container>
                <Text>Anotador não foi encontrado.</Text>
            </Container>
        );
    }
    if (!params.annotation.carcass) {
        return (
            <Container>
                <Text>Carcaça não foi encontrada.</Text>
            </Container>
        );
    }



    return (
        <Container fluid centered hasShadow padded width="300px" height="auto">
            <Container inline centered width="100%" height="30px" color={theme.palette.primary.dark} relative>
                <Container fluid absolute sx={{ left: 0 }}>
                    <Checkbox checked={params.isSelected} style={{ color: theme.palette.background.paper }} onClick={handleCorrect} />
                </Container>
                <Text medium color={theme.palette.background.paper}>{ classificationLabel(params.annotation.classification) }</Text>
                <Container inline absolute sx={{ right: 0 }}>
                    <IconButton size="medium" onClick={() => handleMagnifierSize(true)} style={{ color: theme.palette.background.paper }} >
                        <ZoomInIcon />
                    </IconButton>
                    <IconButton size="medium" onClick={() => handleMagnifierSize(false)} style={{ color: theme.palette.background.paper }} >
                        <ZoomOutIcon />
                    </IconButton>
                </Container>
            </Container>
            <Container fluid>
                <CarcassPhoto hideZoomButtons photoUri={params.annotation.carcass.photoUri || ""} minHeight="inherit" minWidth="inherit"/>
            </Container>
            <Container inline centered width="100%" height="30px" color={theme.palette.primary.dark} relative>
                <Text medium size={13} color={theme.palette.background.paper}>{moment(params.annotation.createdAt).format('DD/MM/YYYY HH:mm:ss')} - {params.annotation.annotator.user.firstName}</Text>
            </Container>
            <Spacing top={PADDING} />

            <Container fluid flexStart>
                <Container inline>
                    <ButtonGroup variant="contained" ref={anchorButton} aria-label="split button">
                        <Button onClick={handleToggle} color={params.isSelected ? 'success' : 'primary'}>
                            <Text color={theme.palette.background.paper}
                                size={13}>
                                {selectedClassification >= 0 ? classificationsJBS.find(item => item.id == selectedClassification)?.label : 'Selecione um acabamento'}
                            </Text>
                        </Button>
                        <Button
                            size="small"
                            aria-controls={openButton ? 'split-button-menu' : undefined}
                            aria-expanded={openButton ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Spacing left={PADDING} />
                    <Button variant="contained" onClick={() => setOpenPhoto(true)} sx={{ backgroundColor: theme.palette.info.dark, width: '100px' }}>
                        <Text color={theme.palette.background.paper} size={13}>Detalhar</Text>
                    </Button>
                </Container>
            </Container>
            <Popper
                open={openButton}
                anchorEl={anchorButton.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 100 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {classificationsJBS.map((item, index) => (
                                        <MenuItem
                                            key={item.id}
                                            selected={item.id === selectedClassification}
                                            onClick={(event) => handleMenuItemClick(event, item.id)}>
                                            <Text size={13} color={theme.palette.primary.main}>
                                                {item.label}
                                            </Text>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {openPhoto && params.annotation.carcass.photoUri &&
                <Lightbox
                    mainSrc={params.annotation.carcass.photoUri}
                    onCloseRequest={() => setOpenPhoto(false)}
                    zoomInLabel="Mais zoom"
                    zoomOutLabel="Menos zoom" />
            }
        </Container>
    );
}

export default RevisionCard;