import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { IHelperIcon } from '../../../types';

const HelperIcon: FC<IHelperIcon> = (params: IHelperIcon) => {
    const HelpText = ( text: string ) => {
        // Split the text by newline characters and map each part to a JSX element
        const parts = text.split('\n').map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));

        return <div>{parts}</div>;
    };

    const icon: React.ReactElement = params.icon ||
        <HelpOutlineIcon color="primary" sx={params.sx} fontSize={params.size || 'small'} />;
    if (!params.text)
        return (icon as React.ReactElement);

    return <Tooltip title={HelpText(params.text)} enterTouchDelay={50}>
        {(icon as React.ReactElement)}
    </Tooltip>;
}

export default HelperIcon;
