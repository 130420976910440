import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Grid,
    IconButton
} from '@mui/material';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { useAuth } from '../../../../../hooks/auth';
import { usePopVisualizationDownload } from '../../../../../hooks/popVisualizationDownload';
import { ICarcass } from '../../../../../types';
import { ICarcassPopsImages } from '../../../../../types/components/molecules/carcasses';
import { PADDING } from '../../../../../utils/consts';
import Container from '../../../../atoms/container';
import Spacing from '../../../../atoms/spacing';
import { BodyText, Title2 } from '../../../../atoms/text';
import { getPhotoType, CarcassPopsSingleImage } from './CarcassPopsSingleImage';


const CarcassPopsImages: FC<ICarcassPopsImages> = (params: ICarcassPopsImages) => {
    const { roles } = useAuth();
    const {
        data,
        toggleEntry,
        convertCarcassToEntry,
        downloading,
    } = usePopVisualizationDownload();

    const [openPhoto, setOpenPhoto] = useState<boolean>(false);
    const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
    const [selectedView, setSelectedView] = useState<'sideA' | 'sideB' | 'identifier' | 'identifier_hash_1'>('sideA');
    const [inputCarcass, setInputCarcass] = useState<string>(
        params.carcass ? String(partnerIdOrSequence(params.carcass)) : 'error'
    );

    useEffect(() => {
        setInputCarcass(params.carcass ? String(partnerIdOrSequence(params.carcass)) : 'error');
    }, [params.carcass || '']);

    if (params.carcass === undefined)
        return <></>;

    const photos = {
        external: selectedView === 'identifier' ? params.carcass.ocrUri :
            selectedView === 'identifier_hash_1' ? params.carcass.ocrUriHash1 :
                selectedView === 'sideA' ? params.carcass.photoUri :
                    params.carcass.photoUriB,
        internal: selectedView === 'identifier' ? params.carcass.ocrUriB :
            selectedView === 'identifier_hash_1' ? params.carcass.ocrUriHash1B :
                selectedView === 'sideA' ? params.carcass.internalUri :
                    params.carcass.internalUriB,
    }

    const carcassIndex = partnerIdOrSequence(params.carcass) ? params.carcassesIds.findIndex(
        (item) => String(item.value) === String(partnerIdOrSequence(params.carcass))) : -1;

    const selectPhoto = (_photoUri: string) => {
        setSelectedPhoto(_photoUri);
        setOpenPhoto(true);
    }

    const handleChangeCarcassNumber = () => {
        if (inputCarcass !== String(partnerIdOrSequence(params.carcass))) {
            const newCarcass = params.carcassesIds.find((item) => String(item.value) === inputCarcass);
            if (newCarcass)
                params.setCurrCarcass(newCarcass.key)
            else
                setInputCarcass(String(partnerIdOrSequence(params.carcass)))
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter')
            event.currentTarget.blur();
    };

    function partnerIdOrSequence(carcass: ICarcass | undefined) {
        if (carcass) {
            if (carcass.partnerId && !carcass.partnerId.includes('SV')) {
                return carcass.partnerId;
            } else if (carcass.sequence) {
                return carcass.sequence;
            } else if (carcass.partnerId) {
                return carcass.partnerId;
            }
        }
        return ''
    }

    function isTagImage(selectedView: string) {
        if (selectedView === 'identifier' || selectedView === 'identifier_hash_1') return true;
        return false;
    }

    return (<>
        <Container inline style={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
            <Title2 style={{ margin: '0' }}>
                <IconButton
                    color='primary'
                    onClick={() => params.setCurrCarcass(
                        params.carcassesIds[carcassIndex - 1].key
                    )}
                    disabled={carcassIndex <= 0}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <input
                    value={inputCarcass}
                    onChange={(e) => setInputCarcass(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleChangeCarcassNumber}
                    style={{ width: 'auto', background: 'none', border: 'none', textAlign: 'center' }}
                    size={4}
                />
                <IconButton
                    color='primary'
                    onClick={() => params.setCurrCarcass(
                        params.carcassesIds[carcassIndex + 1].key
                    )}
                    disabled={carcassIndex >= params.carcassesIds.length - 1}
                >
                    <ChevronRightIcon />
                </IconButton>
                {params.carcass.date ?
                    moment(params.carcass.date).format('DD/MM/YYYY')
                    : moment(params.carcass.classificationDate).format('DD/MM/YYYY')
                }
            </Title2>
            <Box sx={{ flexWrap: 'nowrap', textAlign: 'right' }}>
                {params.carcass.photoUri && <Button color='primary'
                    variant={selectedView === 'sideA' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('sideA')}
                >
                    Lado A
                </Button>}
                {params.carcass.photoUriB && <Button color='primary'
                    variant={selectedView === 'sideB' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('sideB')}
                >
                    Lado B
                </Button>}
                {params.carcass.ocrUri && <Button color='primary'
                    variant={selectedView === 'identifier' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('identifier')}
                >
                    ETIQUETA
                </Button>}
                {params.carcass.ocrUriHash1 && <Button color='primary'
                    variant={selectedView === 'identifier_hash_1' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('identifier_hash_1')}
                >
                    #1
                </Button>}
            </Box>
        </Container>
        <Card sx={{
            background: 'white',
            borderRadius: '16px',
            opacity: params.loading ? 0.5 : 1,
            cursor: params.loading ? 'not-allowed' : 'default',
            borderTopRightRadius: '0',
        }}
            elevation={4}
        >
            <CardContent>
                <Grid container direction='row'
                    alignItems='center' justifyContent='center'
                    height='100%' sx={{ xs: { height: 'auto !important', marginTop: '16px' } }}
                    rowSpacing={2} key={`carcasses-image-detail-${selectedView}`}
                >
                    <Grid item container xs alignItems='center'>
                        {photos.external &&
                            <>
                                {roles.includes('pop_can_export_images') && params.carcass &&
                                    <Container inline centered width='100%'>
                                        <Checkbox
                                            disabled={downloading}
                                            size="small"
                                            checked={!!(data?.find(
                                                (entry) => (
                                                    entry.id === params.carcass!.id &&
                                                    entry.photoUri === photos.external
                                                )
                                            ))}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleEntry(convertCarcassToEntry(
                                                    params.carcass!,
                                                    selectedView === 'sideB' ? 'B' : 'A',
                                                    getPhotoType(selectedView, true)
                                                ));
                                            }}
                                        />
                                        <Container>
                                            <BodyText>Selecionar para exportação</BodyText>
                                        </Container>
                                    </Container>
                                }
                                <Container fluid centered maxWidth={isTagImage(selectedView) ? '80%' : '80%'}
                                    style={{ margin: 'auto' }}
                                    onClick={() => selectPhoto(photos.external!)} sx={{ cursor: 'pointer' }} relative
                                >
                                    <CarcassPopsSingleImage
                                        photos={photos}
                                        carcass={params.carcass}
                                        selectedView={selectedView}
                                        isLeft={true}
                                    />
                                    {roles.includes('view_well_placed') &&
                                        <BodyText bold center>
                                            {isTagImage(selectedView) ? 'Lado A' : 'Parte Externa'}
                                            <br />
                                            {selectedView === 'identifier' ?
                                                (
                                                    params.carcass.label && params.carcass.label !== '0' ?
                                                        `Leitura OCR: ${params.carcass.label}` : ''
                                                )
                                                :
                                                selectedView === 'identifier_hash_1' ?
                                                    (
                                                        params.carcass.ocrValueHash1 && params.carcass.ocrValueHash1 !== '0' ?
                                                            `Leitura OCR: ${params.carcass.ocrValueHash1}` : ''
                                                    )
                                                    :
                                                    (
                                                        params.carcass.bestPlaced !== undefined ?
                                                            `${params.carcass.bestPlaced ? 'Bem' : 'Mal'} posicionado` : ''
                                                    )
                                            }
                                        </BodyText>
                                    }
                                </Container>
                            </>}
                    </Grid>
                    <Grid item container xs alignItems='center'>
                        {photos.internal &&
                            <>
                                <Spacing left={PADDING / 2} />
                                {roles.includes('pop_can_export_images') && params.carcass &&
                                    <Container inline centered width='100%'>
                                        <Checkbox
                                            disabled={downloading}
                                            size="small"
                                            checked={!!(data?.find(
                                                (entry) => (
                                                    entry.id === params.carcass!.id &&
                                                    entry.photoUri === photos.internal
                                                )
                                            ))}
                                            onClick={() => toggleEntry(convertCarcassToEntry(
                                                params.carcass!,
                                                selectedView === 'sideA' ? 'A' : 'B',
                                                getPhotoType(selectedView, false)
                                            ))}
                                        />
                                        <Container>
                                            <BodyText>Selecionar para exportação</BodyText>
                                        </Container>
                                    </Container>
                                }
                                <Container fluid centered maxWidth={isTagImage(selectedView) ? '80%' : '80%'}
                                    style={{ margin: 'auto' }}
                                    onClick={() => selectPhoto(photos.internal!)} sx={{ cursor: 'pointer' }} relative>
                                    {/* It can be Internal if its not TAG Image */}
                                    <CarcassPopsSingleImage
                                        photos={photos}
                                        carcass={params.carcass}
                                        selectedView={selectedView}
                                        isLeft={false}
                                    />
                                    {roles.includes('view_well_placed') &&
                                        <BodyText bold center>
                                            {isTagImage(selectedView) ? 'Lado B' : 'Parte Interna'}
                                            <br />
                                            {selectedView === 'identifier' ?
                                                (
                                                    params.carcass.labelB && params.carcass.labelB !== '0' ?
                                                        `Leitura OCR: ${params.carcass.labelB}` : ''
                                                )
                                                :
                                                selectedView === 'identifier_hash_1' ?
                                                    (
                                                        params.carcass.ocrValueHash1B && params.carcass.ocrValueHash1B !== '0' ?
                                                            `Leitura OCR: ${params.carcass.ocrValueHash1B}` : ''
                                                    )
                                                    :
                                                    (
                                                        params.carcass.bestPlacedB !== undefined ?
                                                            `${params.carcass.bestPlacedB ? 'Bem' : 'Mal'} posicionado` : ''
                                                    )
                                            }
                                        </BodyText>
                                    }
                                </Container>
                            </>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        {openPhoto && selectedPhoto &&
            <Lightbox
                mainSrc={selectedPhoto}
                onCloseRequest={() => setOpenPhoto(false)}
                zoomInLabel="Mais zoom"
                zoomOutLabel="Menos zoom" />
        }
    </>);
}

export default CarcassPopsImages;
