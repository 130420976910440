import React, { FC } from "react";

import { ActiveAlertsSocketProvider } from "../sockets/activeAlerts";
import { AlertConstantsProvider } from "./alertConstants";
import { AlertGroupSchedulesProvider } from "./alertGroupSchedules";
import { AlertGroupsProvider } from "./alertGroup";
import { AlertProvidersProvider } from "./alertProviders";
import { AlertsProvider } from "./alert";
import { AnalysisAnnotationsProvider } from "./analysisAnnotations";
import { AnnotationsAnomaliesProvider } from "./annotationsAnomalies";
import { AnnotationsProvider } from "./annotations";
import { AnnotatorHasAccesssProvider } from "./annotatorsHasAccess";
import { AnnotatorsProvider } from "./annotators";
import { AnomalyProvider } from "./anomalies";
import { BranchesHasUserProvider } from "./branchesHasUser";
import { BranchesProvider } from "./branches";
import { CarcassesLocalClassificationsProvider } from "./carcassesLocalClassifications";
import { CarcassesLocalConformationsProvider } from "./carcassesLocalConformations";
import { CarcassesPopsProvider } from "./carcassesPops";
import { CarcassProvider } from "./carcasses";
import { CompaniesProvider } from "./companies";
import { ConfirmationProvider } from "./confirmation";
import { DashboardProvider } from "./dashboard";
import { DownloadPageProvider } from "./downloadPage";
import { DrawerProvider } from "./drawer";
import { HashCountProvider } from "./hashCount";
import { InformationProvider } from "./information";
import { LoadingProvider } from "./loading";
import { LotProvider } from "./lots";
import { MonitoringEmailsProvider } from "./monitoringEmails";
import { MonitoringRecipientsProvider } from "./monitoringRecipient";
import { MonitoringTypesProvider } from "./monitoringTypes";
import { PopDashboardProvider } from "./popDashboard";
import { PopVisualizationBoxesProvider } from "./popVisualizationBoxes";
import { PopVisualizationDownloadProvider } from "./popVisualizationDownload";
import { PrivilegesProvider } from "./privileges";
import { ResponsiveProvider } from "./responsive";
import { RevisionsProvider } from "./revisions";
import { RoutesProvider } from "./routes";
import { SendEmailsProvider } from "./sendEmails";
import { ShiftsProvider } from "./shifts";
import { SlaughterDelayReasonLocationsProvider } from "./slaughterDelayReasonLocations";
import { SlaughterDelaysFilterProvider } from "./slaughterDelaysFilter";
import { SlaughterDelaysProvider } from "./slaughterDelays";
import { SlaughterDelayReasonsProvider } from "./slaughterDelayReasons";
import { TypificationsProvider } from "./typifications";
import { UsersProvider } from "./users";
import { VerdictsProvider } from "./verdicts";


const AnnotateProvider: FC<any> = ({ children }) => {
    return (
        <AnnotationsProvider>
            <AnomalyProvider>
                <AnnotationsAnomaliesProvider>
                    <RevisionsProvider>
                        <VerdictsProvider>{children}</VerdictsProvider>
                    </RevisionsProvider>
                </AnnotationsAnomaliesProvider>
            </AnomalyProvider>
        </AnnotationsProvider>
    );
};

const ControlPanelProvider: FC<any> = ({ children }) => {
    return (
        <HashCountProvider>
            {children}
        </HashCountProvider>
    );
};

const RegistrationProvider: FC<any> = ({ children }) => {
    return (
        <AnnotatorsProvider>
            <AnalysisAnnotationsProvider>
                <AnnotatorHasAccesssProvider>
                    <BranchesProvider>
                        <CarcassProvider>
                            <CarcassesLocalClassificationsProvider>
                                <CarcassesLocalConformationsProvider>
                                    <CompaniesProvider>
                                        <LotProvider>
                                            <BranchesHasUserProvider>
                                                <PrivilegesProvider>
                                                    <RoutesProvider>
                                                        <ShiftsProvider>
                                                            <TypificationsProvider>
                                                                <CarcassesPopsProvider>
                                                                    <UsersProvider>
                                                                        <DownloadPageProvider>
                                                                            {children}
                                                                        </DownloadPageProvider>
                                                                    </UsersProvider>
                                                                </CarcassesPopsProvider>
                                                            </TypificationsProvider>
                                                        </ShiftsProvider>
                                                    </RoutesProvider>
                                                </PrivilegesProvider>
                                            </BranchesHasUserProvider>
                                        </LotProvider>
                                    </CompaniesProvider>
                                </CarcassesLocalConformationsProvider>
                            </CarcassesLocalClassificationsProvider>
                        </CarcassProvider>
                    </BranchesProvider>
                </AnnotatorHasAccesssProvider>
            </AnalysisAnnotationsProvider>
        </AnnotatorsProvider>
    );
};

export const MonitoringProvider: FC<any> = ({ children }) => {
    return (
        <MonitoringEmailsProvider>
            <MonitoringRecipientsProvider>
                <MonitoringTypesProvider>
                    {children}
                </MonitoringTypesProvider>
            </MonitoringRecipientsProvider>
        </MonitoringEmailsProvider>
    )
};

export const AlertSystemProvider: FC<any> = ({ children }) => {
    return (
        <AlertConstantsProvider>
            <AlertGroupsProvider>
                <AlertGroupSchedulesProvider>
                    <AlertsProvider>
                        <AlertProvidersProvider>
                            <ActiveAlertsSocketProvider>
                                {children}
                            </ActiveAlertsSocketProvider>
                        </AlertProvidersProvider>
                    </AlertsProvider>
                </AlertGroupSchedulesProvider>
            </AlertGroupsProvider>
        </AlertConstantsProvider>
    );
};


export const PopEngineProvider: FC<any> = ({ children }) => {
    return (
        <PopDashboardProvider>
            <PopVisualizationDownloadProvider>
                <PopVisualizationBoxesProvider>
                    {children}
                </PopVisualizationBoxesProvider>
            </PopVisualizationDownloadProvider>
        </PopDashboardProvider>
    );
};


export const SlaughterDelaysHooksProvider: FC<any> = ({ children }) => {
    return (
        <SlaughterDelaysFilterProvider>
            <SlaughterDelayReasonLocationsProvider>
                <SlaughterDelayReasonsProvider>
                    <SlaughterDelaysProvider>
                        {children}
                    </SlaughterDelaysProvider>
                </SlaughterDelayReasonsProvider>
            </SlaughterDelayReasonLocationsProvider>
        </SlaughterDelaysFilterProvider>
    )
}


const HooksProvider: FC<any> = ({ children }) => {
    return (
        <DrawerProvider>
            <SendEmailsProvider>
                <DashboardProvider>
                    <RegistrationProvider>
                        <AnnotateProvider>
                            <MonitoringProvider>
                                <ControlPanelProvider>
                                    <AlertSystemProvider>
                                        <PopEngineProvider>
                                            <SlaughterDelaysHooksProvider>
                                                {children}
                                            </SlaughterDelaysHooksProvider>
                                        </PopEngineProvider>
                                    </AlertSystemProvider>
                                </ControlPanelProvider>
                            </MonitoringProvider>
                        </AnnotateProvider>
                    </RegistrationProvider>
                </DashboardProvider>
            </SendEmailsProvider>
        </DrawerProvider>
    );
};

export const FeedbackProvider: FC<any> = ({ children }) => {
    return (
        <ResponsiveProvider>
            <LoadingProvider>
                <InformationProvider>
                    <ConfirmationProvider>{children}</ConfirmationProvider>
                </InformationProvider>
            </LoadingProvider>
        </ResponsiveProvider>
    );
};

export default HooksProvider;
