import * as XLSX from 'xlsx';

const COMMA_TEXT = '::this-is-a-comma-do-not-use+this*string::';

function createWorksheet(workbook: XLSX.WorkBook, csvData: string, sheetName: string) {
  csvData = csvData.replace(/\r/g, '').replace(/"/g, '').replace(/\\,/g, COMMA_TEXT);
  const data = csvData.split('\n').map((row: any) => row.split(',').map((col: string) => col.replaceAll(COMMA_TEXT, ',')));
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
};


export default function csvToXlsx(
  csvData: string | {[key: string]: string},
  filename: string,
  sheetName: string = "Sheet1"
) {
  const workbook = XLSX.utils.book_new();

  if (typeof csvData === 'string')
    createWorksheet(workbook, csvData, sheetName);
  else {
    for (const key in csvData)
      createWorksheet(workbook, csvData[key], key);
  };

  XLSX.writeFile(workbook, `${filename}.xlsx`);
};
