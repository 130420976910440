import React, { FC, useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material';

import BranchSelect from '../../branches/select';
import Container from '../../../atoms/container';
import DatePicker from "../../../atoms/datePicker";
import { useBranches } from '../../../../hooks/branches';
import { IHashCountFilterProps, IHashCountFilter } from '../../../../types';


const HashCountFilter: FC<IHashCountFilterProps> = (params: IHashCountFilterProps) => {
    const { branches, fetchBranches } = useBranches();

    const [dateValue, setDateValue] = useState<Date>(params.date);
    const [branchId, setBranchId] = useState<string>(params.branchId || "");
    const [branchName, setBranchName] = useState<string>(
      branches ? branches.find(b => b.id === branchId)?.name || "" : "");

    useEffect(() => {
        fetchBranches();
    }, []);

    useEffect(() => {
      if (branches && branches.length > 0) {
        if (branchId) {
          setBranchName(branches.find(b => b.id === branchId)?.name || "");
        } else {
          setBranchName('');
        }
      }
    }, [branches, branchId])

    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName((event as any).target.value || '');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id || '');
            }
            if ((event as any).label) {
                setBranchName((event as any).label || '');
            }
        }
    }

    const onApply = () => {
        const filterParams: IHashCountFilter = {
            date: dateValue,
            branchId: branchId,
        };

        setTimeout(() => {
            params.onApply(filterParams);
        }, 300);
    }

    return (
        <Grid container columnSpacing={1} padding={1} alignItems="center">
            <Grid item xs={4} lg={2}>
                <DatePicker
                    value={dateValue}
                    label='Data'
                    placeholder={"Escolha a data"}
                    setValue={(date) => setDateValue(date!)}
                    disabled={params.loading}
                    variant='outlined'
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <Container width="100%">
                    <BranchSelect
                        value={branchId}
                        valueName={branchName}
                        handleChangeValue={handleChangeBranch}
                        disabled={!!params.loading}
                    />
                </Container>
            </Grid>
            <Grid item xs={2} lg={7}>
                <Button variant="contained" color="primary"
                    onClick={() => onApply()}
                    disabled={params.loading}
                  >
                    Filtrar
                </Button>
            </Grid>
        </Grid>)
}

export default HashCountFilter