
import React, { FC } from 'react'


import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'

import { theme } from '../../../../components';
import { IAnnotationSelectOption } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';

const AnnotationSelectOption: FC<IAnnotationSelectOption> = (params: IAnnotationSelectOption) => {
	const navigate = useNavigate();

	const navigateToAnnotation = () => {
		// 👇️ navigate to /contacts
		navigate(params.redirectTo);
	};

	return (
		<Card sx={{
			minWidth: 370, 
			maxWidth: 400, 
			borderRadius: BORDER_RADIUS*2.5, 
			boxShadow: '3px 6px 30px -7px rgba(0,0,0,0.75)',
			transition: 'transform 0.15s ease-in-out',
			'&:hover': {
				transform: 'scale(1.03)'
			}
		}}
			
		>	
			<CardActionArea onClick={navigateToAnnotation}>
				<CardContent sx={{ textAlign: 'center', padding: 0 }}>
					<Box >
						{params.icon}
					</Box>
				</CardContent>
				<CardContent sx={{ 
					backgroundColor: theme.palette.primary.main, 
					borderTopLeftRadius: BORDER_RADIUS*2.5, 
					borderTopRightRadius: BORDER_RADIUS*2.5
				}}>
					<Grid container display='flex'> 
						<Grid item lg={12} xs={12} >
							<Typography gutterBottom component="div" sx={
							{ 
								fontWeight: 'bold', 
								color: 'white', 
								fontSize: 20, 
								textAlign: {
									xs: 'center',
									lg: 'left'
								} 
							}}>
								{params.title}
							</Typography>
							{
								useMediaQuery(theme.breakpoints.up('lg')) &&
								<Typography variant="body2" color="text.secondary" sx={{color: 'white'}}>
									{params.description}
								</Typography>
							}
							
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	)
}


export default AnnotationSelectOption;