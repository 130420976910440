import React, { FC, useState } from 'react';
import {
    CartesianGrid,
    Area,
    AreaChart,
    Label,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import theme from '../../../../theme';


export interface IPopDashboardTimeSeriesChart {
    id: string;
    data: Array<{
        label: string;
        value: number;
    }>;
    average?: string;
    lastWeekAdequation?: string;
}


const PopDashboardTimeSeriesChart: FC<IPopDashboardTimeSeriesChart> = ({
    id,
    data,
    average,
    lastWeekAdequation,
}: IPopDashboardTimeSeriesChart) => {
    const [isReferenceLineHidden, setIsReferenceLineHidden] = useState<boolean>(false);

    const ID = `focus-element-${id}`;
    const isTimeSeriesByCarcassCount = data?.length && data[0]['label'].length < 8;

    const chartData = data.map((item) => ({
        ...item,
        "% Conforme": (100 * item.value).toFixed(2),
        label: isTimeSeriesByCarcassCount ? item.label : `[${item.label}]`
    }));

    const renderCustomizedLabel = (offset?: number) => {
        /* Renders a box with the text over the line on the right.
         * When the value is too close to the top, render the box under the
         * line.
         * 
         * The offset is implemented for rendering a second label, so it will
         * be "offseted" to the left if it is too close to the main box
         * (i.e. will compare to the params.average value)
         */

        // Constants are configurable
        const BOX_WIDTH_PER_CHAR = 6;
        const BOX_PADDING = BOX_WIDTH_PER_CHAR;
        const BOX_HEIGHT = 22;
        const BOX_RADIUS = 4;

        const calculateWidth = (length?: number) => {
            if (length)
                return length * BOX_WIDTH_PER_CHAR + 2 * BOX_PADDING;
            return 0;
        };

        const BOX_MIN_VALUE_DIFF = 15;
        const calculateOffset = (_offset: number, _average: number, _value: number) => {
            if (!_offset ||
                Math.abs(_value - _average) > BOX_MIN_VALUE_DIFF)
                return 0
            return calculateWidth(_offset) + 3;
        }

        // We must return a function to recharts' Label component
        const innerFunction = (props: any) => {
            const { viewBox, value, color, y } = props;
            if (!value)
                // nothing to be written
                return <></>;

            const boxWidth = calculateWidth(value.length);

            // move box to the right
            const boxX = viewBox.width + 80 - boxWidth - calculateOffset(
                offset || 0, average ? parseInt(average) : 0, y
            );

            // position box over or under the line accordingly
            const boxY = viewBox.y < BOX_HEIGHT ? viewBox.y : viewBox.y - BOX_HEIGHT;

            // remove radius on the bottom by drawing a secondary box
            // it also adapts to be rendered under or over the line
            const secondaryBoxY = (
                viewBox.y < BOX_HEIGHT ? boxY : boxY + BOX_HEIGHT / 2
            );

            return (
                <g id={offset ? ID : `${value}-${id}`}>
                    {/* ID prevents overlapping the secondary label */}
                    <rect
                        width={boxWidth}
                        height={BOX_HEIGHT}
                        x={boxX}
                        y={boxY}
                        fill={color}
                        rx={BOX_RADIUS}
                        ry={BOX_RADIUS}
                    />
                    <rect   // remove bottom border radius
                        width={boxWidth}
                        height={BOX_HEIGHT / 2}
                        x={boxX}
                        y={secondaryBoxY}
                        fill={color}
                    />
                    <text
                        x={boxX + boxWidth / 2}
                        y={boxY + BOX_HEIGHT / 2}
                        fill="#fff"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize=".85em"
                        fontFamily='monospace'
                    >
                        {value}
                    </text>
                </g>
            );
        };
        return innerFunction;
    }

    return (
        <ResponsiveContainer
            key={id}
            width="100%"
            minWidth={Math.min(400, 100 * data.length)}
            height="100%"
            minHeight={200}
        >
            <AreaChart
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
                onClick={() => setIsReferenceLineHidden(!isReferenceLineHidden)}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="label"
                    label={{
                        value: isTimeSeriesByCarcassCount ?
                            'N° de animais' : 'Data de Abate',
                        position: 'bottom',
                    }}
                />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Area
                    type="monotone"
                    dot={true}
                    dataKey="% Conforme"
                    strokeWidth={1.5}
                    stroke={theme.palette.primary.main}
                    fill={theme.palette.primary.main}
                />
                {lastWeekAdequation && !isReferenceLineHidden &&
                    <ReferenceLine
                        y={parseInt(lastWeekAdequation)}
                        stroke='#E58A00'
                    >
                        <Label
                            value={`última semana: ${lastWeekAdequation}%`}
                            content={renderCustomizedLabel(`média: ${average}%`.length)}
                            color='#E58A00'
                            y={parseInt(lastWeekAdequation)}
                        />
                    </ReferenceLine>
                }
                {average && !isReferenceLineHidden &&
                    <ReferenceLine
                        y={parseInt(average)}
                        stroke={theme.palette.primary.main}
                    >
                        <Label
                            value={`média: ${average}%`}
                            content={renderCustomizedLabel()}
                            color={theme.palette.primary.main}
                        />
                    </ReferenceLine>
                }

                {/* Prevent overlapping the secondary label */}
                <use xlinkHref={`#${ID}`} />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default PopDashboardTimeSeriesChart;
