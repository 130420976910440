import React, { FC, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BodyText, Title2 } from "../../../atoms/text";
import Modal from "../../../atoms/modal";
import { useAuth } from "../../../../hooks/auth";
import { usePopVisualizationDownload } from '../../../../hooks/popVisualizationDownload';
import useCarcassFilter from "./carcassFilterHook";
import { ICarcassListFilter } from "../../../../types";
import { theme } from '../../../../components';


const CarcassesListFilter: FC<ICarcassListFilter> = (params: ICarcassListFilter) => {
    const { data: exportData } = usePopVisualizationDownload();

    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const {
        farms,
        setFarms,
        classification,
        setClassification,
        conformation,
        setConformation,
        docNums,
        setDocumentNumbers,
        lotNums,
        setLotNumbers,
        sequences,
        setSequences,
        genders,
        setGenders,
        typifications,
        setTypifications,
        localClassificationsRedsoft,
        setLocalClassificationsRedsoft,
        bestPlaced,
        setBestPlaced,
        handleSubmit,
        clearFilters,
        revertFilters,
    } = useCarcassFilter({
        farmList: params.farmList,
        classificationList: params.classificationList,
        conformationList: params.conformationList,
        docList: params.docList,
        lotList: params.lotList,
        sequenceList: params.sequenceList,
        typificationList: params.typificationList,
        genderList: params.genderList,
        localClassificationsRedsoftList: params.localClassificationsRedsoftList,
        setFarms: params.setFarms,
        setClassification: params.setClassification,
        setConformation: params.setConformation,
        setDocumentNumbers: params.setDocumentNumbers,
        setLotNumbers: params.setLotNumbers,
        setSequences: params.setSequences,
        setGenders: params.setGenders,
        setLocalClassificationsRedsoft: params.setLocalClassificationsRedsoft,
        setTypifications: params.setTypifications,
        setBestPlaced: params.setBestPlaced,
    });
    const { roles } = useAuth();

    function onCheckBestPlaced(value: boolean) {
        // Removes "value" if it is in bestPlaced, otherwise includes it
        let includes = false;
        let res = [];
        for (let val of bestPlaced) {
            if (val === value)
                includes = true
            else
                res.push(val);
        }

        if (!includes)
            res.push(value);
        setBestPlaced(res);
    }

    const onSubmit = () => {
        handleSubmit();
        setModalOpen(false);
    }

    const onClear = () => {
        clearFilters();
    }

    const onCancel = () => {
        revertFilters();
        setModalOpen(false);
    }

    return (<>
    <IconButton color='primary' size='medium'
        onClick={() => setModalOpen(!modalOpen)}
        disabled={params.loading}
    >
        <FilterAltOutlined/>
    </IconButton>
    <Modal isOpened={modalOpen} onClose={() => setModalOpen(false)} style={{ overflowY: 'auto' }}>
        <Paper
            sx={{
                borderRadius: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
                margin: isXs? '300px auto 50px' : 'auto',
            }}
            elevation={4}
        >
            <Grid container spacing={2} sx={{ width: { xs: '90vw', sm: '80vw', md: '750px'} }}>
                <Grid item xs={12}>
                    <Title2>Filtro</Title2>
                    <BodyText muted>Os filtros abaixo serão aplicados à lista de carcaças</BodyText>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-farms-multiselect"
                        options={params.farmList || farms}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Fazendas"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setFarms(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={farms}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-local-classification-multiselect"
                        options={params.localClassificationsRedsoftList || localClassificationsRedsoft}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Acabamento Local (RedSoft)"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setLocalClassificationsRedsoft(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={localClassificationsRedsoft}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-classification-multiselect"
                        options={params.classificationList || classification}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Acabamento RedSoft"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setClassification(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={classification}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-conformation-multiselect"
                        options={params.conformationList || conformation}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Conformação RedSoft"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setConformation(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={conformation}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-typifications-multiselect"
                        options={params.typificationList || typifications}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Tipificação RedSoft"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setTypifications(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={typifications}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-docs-multiselect"
                        options={params.docList || docNums}
                        getOptionLabel={(item) => String(item.value)}
                        renderInput={(params) => (
                            <TextField
                                label="Contratos"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setDocumentNumbers(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={docNums}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-lots-multiselect"
                        options={params.lotList || lotNums}
                        getOptionLabel={(item) => String(item.value)}
                        renderInput={(params) => (
                            <TextField
                                label="Lotes"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setLotNumbers(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={lotNums}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-sequence-multiselect"
                        options={params.sequenceList || sequences}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Sequenciais ERP"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setSequences(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={sequences}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-genders-multiselect"
                        options={params.genderList || genders}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Sexos"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setGenders(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={genders}
                    />
                </Grid>
                {roles.includes('pop_can_export_images') && params.setOnlySelectedExport &&
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Checkbox
                            disabled={!exportData || exportData.length === 0}
                            size="small"
                            checked={
                                params.onlySelectedExport &&
                                exportData !== undefined &&
                                exportData.length > 0
                            }
                            onClick={() => {
                                params.setOnlySelectedExport!(
                                    !params.onlySelectedExport
                                );
                            }}
                        />
                        <BodyText>Apenas selecionados para exportação</BodyText>
                    </Grid>
                }
                {roles.includes('view_well_placed') &&
                    <Grid item container xs={12} sx={{ justifyContent: 'space-around' }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                size="small"
                                checked={bestPlaced.includes(true)} onClick={() => onCheckBestPlaced(true)}
                            />
                            <BodyText>Bem Posicionadas</BodyText>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                size="small"
                                checked={bestPlaced.includes(false)} onClick={() => onCheckBestPlaced(false)}
                            />
                            <BodyText>Mal Posicionadas</BodyText>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        gap: '12px'
                    }}
                >
                    <Button variant='contained' onClick={() => onSubmit()}>Filtrar</Button>
                    <Button variant='outlined' onClick={() => onCancel()}>Cancelar</Button>
                    <Button variant='text' onClick={() => onClear()}>Limpar Filtros</Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
    </>);
};

export default CarcassesListFilter;