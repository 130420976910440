import React, { FC, useRef, useState, useEffect } from "react";
import { Button, ButtonGroup, Checkbox, ClickAwayListener, Grid, Grow, Paper, Popper, MenuList, MenuItem, IconButton, FormControlLabel } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon, ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { convertNumberToFixedAndLocale } from "../../../../utils/stringFormatters";

import CarcassPhoto from "../../carcasses/carcassPhoto";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useAnnotations } from "../../../../hooks/annotations";
import { ICarcass, IVerdictCard } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import { useCarcass, getCarcassClassification } from "../../../../hooks/carcasses";
import { convertAItoJBS, getCarcassConformationName, defaultConformations } from "../../../../hooks/carcasses";


const VerdictCard: FC<IVerdictCard> = (params: IVerdictCard) => {
    const { handleMagnifierSize } = useAnnotations();

    const [openButton, setOpenButton] = useState(false);
    const [openButtonConformation, setOpenButtonConformation] = useState(false);
    const anchorButton = useRef<HTMLDivElement>(null);
    const anchorButtonConformation = useRef<HTMLDivElement>(null);
    const [selectedClassification, setSelectedClassification] = useState<number>(
        convertAItoJBS(params.carcass.classificationId));
    const [selectedConformation, setSelectedConformation] = useState<number>(
        params.carcass.conformationId);
    const [badPlaced, setBadPlaced] = useState(false);
    const [badPlacedB, setBadPlacedB] = useState(false);
    const [isPhotoOpen, setIsPhotoOpen] = useState<boolean>(false);
    const [openPhoto, setOpenPhoto] = useState<string>("");

    const { classificationsJBS } = useCarcass();

    useEffect(() => {
        if (!params.isSelected) {
            // Clear badPlaced so there are no issues when selecting all carcasses
            setBadPlaced(false);
            setBadPlacedB(false);
        }
    }, [params.isSelected])

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedClassification(index);
        setOpenButton(false);
        setOpenButtonConformation(false);

        const auxCarcass: ICarcass = params.carcass;
        params.onSelectCarcass({
            ...auxCarcass,
            classificationId: index,
            conformationId: selectedConformation,
            bestPlaced: !badPlaced,
            bestPlacedB: !badPlaced,
        });
    };

    const handleMenuItemClickConformation = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedConformation(index);
        setOpenButton(false);
        setOpenButtonConformation(false);

        const auxCarcass: ICarcass = params.carcass;
        params.onSelectCarcass({
            ...auxCarcass,
            conformationId: index,
            classificationId: selectedClassification,
            bestPlaced: !badPlaced,
            bestPlacedB: !badPlacedB,
        });
    };

    const bestPlaceHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const auxCarcass: ICarcass = {
            ...params.carcass,
            classificationId: selectedClassification,
            conformationId: selectedConformation,
            bestPlaced: badPlaced,
            bestPlacedB: badPlacedB,
        }
        params.onSelectCarcass(auxCarcass);
        setBadPlaced(!badPlaced);
    };

    const bestPlaceBHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const auxCarcass: ICarcass = {
            ...params.carcass,
            classificationId: selectedClassification,
            conformationId: selectedConformation,
            bestPlaced: badPlaced,
            bestPlacedB: badPlacedB,
        }
        params.onSelectCarcass(auxCarcass);
        setBadPlacedB(!badPlacedB);
    };

    const handleToggle = () => {
        setOpenButton((prevOpenButton) => !prevOpenButton);
    };

    const handleToggleConformation = () => {
        setOpenButtonConformation((prevOpenButton) => !prevOpenButton);
    };

    const handleCorrect = () => {
        params.onSelectCarcass({
            ...params.carcass,
            conformationId: selectedConformation,
            classificationId: selectedClassification,
            bestPlaced: !badPlaced,
            bestPlacedB: !badPlacedB,
        });
    };

    const handleClose = (event: Event) => {
        if (
            anchorButton.current &&
            anchorButton.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenButton(false);
    };

    const handleCloseConformation = (event: Event) => {
        if (
            anchorButtonConformation.current &&
            anchorButtonConformation.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenButtonConformation(false);
    };

    const handleDetailClick = (photo: string | undefined) => {
        if(photo !== undefined){
            setIsPhotoOpen(true);
            setOpenPhoto(photo);
        }else{
            setIsPhotoOpen(false);
            setOpenPhoto("");
        }
    };

    if (!params.carcass) {
        return (
            <Container>
                <Text>Carcaça não foi encontrada.</Text>
            </Container>
        );
    }

    return (
        <Container fluid centered hasShadow padded width="600px" height="auto">
            <Container centered width="108%" height="auto" color={theme.palette.primary.dark}>
                <Grid container>
                    <Grid item xs={3} display='flex'>
                        <Checkbox checked={params.isSelected} style={{ color: theme.palette.background.paper }} onClick={handleCorrect} />
                    </Grid>
                    <Grid item xs={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Text size={14} color={theme.palette.background.paper} bold>
                            Sequencial {params.carcass.sequence || params.carcass.partnerId}
                        </Text>
                    </Grid>
                    <Grid item xs={3} display='flex' justifyContent="end">
                        <Container inline centered>
                            <IconButton size="small" onClick={() => handleMagnifierSize(true)} style={{ fontSize: 12, color: theme.palette.background.paper }} >
                                <ZoomInIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleMagnifierSize(false)} style={{ fontSize: 12, color: theme.palette.background.paper }} >
                                <ZoomOutIcon />
                            </IconButton>
                        </Container>
                    </Grid>
                </Grid>
                    <Container fluid style={{ paddingLeft: '12px', paddingRight: '12px', marginBottom: '4px', flexDirection: "row" }}>
                    {params.property !== 'conformation' &&
                        <Container fluid width="50%">
                            <Text size={14} color={theme.palette.background.paper} bold>
                                Acabamento
                            </Text>
                            <Text size={14} color={theme.palette.background.paper}>
                                IA: {params.carcass.classification}
                                {
                                    params.carcass.classificationAccuracy ?
                                        <> ({convertNumberToFixedAndLocale(params.carcass.classificationAccuracy, 1)}%)</>
                                    : <></>
                                }
                            </Text>
                            <Text size={14} color={theme.palette.background.paper}>
                                <Text size={14} color={theme.palette.background.paper} style={{ fontStyle: 'italic' }}>
                                    In loco
                                </Text>: {
                                    params.carcass.localClassificationId ?
                                    getCarcassClassification(params.carcass.localClassificationId) :
                                    params.carcass.localClassification
                                }
                            </Text>
                        </Container>
                    }
                    {params.property !== 'classification' &&
                        <Container fluid width="50%" style={{ marginBottom: '8px', alignItems: "end" }}>
                            <Text size={14} color={theme.palette.background.paper} bold>
                                Conformação
                            </Text>
                            <Text size={14} color={theme.palette.background.paper}>
                                IA: {getCarcassConformationName(params.carcass.conformationId)}
                                {
                                    params.carcass.conformationAccuracy ?
                                        <> ({convertNumberToFixedAndLocale(params.carcass.conformationAccuracy, 1)}%)</>
                                        : <></>
                                }
                            </Text>
                            <Text size={14} color={theme.palette.background.paper}>
                                <Text size={14} color={theme.palette.background.paper} style={{ fontStyle: 'italic' }}>
                                    In loco
                                </Text>: {params.carcass.localConformation}
                            </Text>
                        </Container>
                    }
                    </Container>
            </Container>
            <Container centered width="108%" spacedBetween>
                <Grid container>
                    <Grid item xs={5.75}>
                        <CarcassPhoto hideZoomButtons photoUri={params.carcass.photoUri || ""} />
                    </Grid>
                    <Grid item xs={0.5}></Grid>
                    <Grid item xs={5.75}>
                        <CarcassPhoto hideZoomButtons photoUri={params.carcass.photoUriB || ""} />
                    </Grid>
                </Grid>
            </Container>
            <Container inline centered width="108%" height="auto" color={theme.palette.primary.dark}>
                <Grid container style={{ paddingBottom: '12px', paddingTop: '12px' }}>
                    <Grid item display="flex" justifyContent="center" xs={6}>
                        <Button variant="contained" onClick={() => handleDetailClick(params.carcass.photoUri)} sx={{ backgroundColor: theme.palette.info.dark, width: '85px' }}>
                            <Text color={theme.palette.background.paper} size={13}>Detalhar</Text>
                        </Button>
                        <Spacing left={PADDING} />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={bestPlaceHandleChange}
                                    checked={badPlaced}
                                    style={{ color: theme.palette.background.paper }}
                                />
                            }
                            style={{ color: theme.palette.background.paper }}
                            label="Mal Posicionada"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item display="flex" justifyContent="center" xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={bestPlaceBHandleChange}
                                    checked={badPlacedB}
                                    style={{ color: theme.palette.background.paper }}
                                />
                            }
                            style={{ color: theme.palette.background.paper }}
                            label="Mal Posicionada"
                        />
                        <Spacing left={PADDING} />
                        <Button variant="contained" onClick={() => handleDetailClick(params.carcass.photoUriB)} sx={{ backgroundColor: theme.palette.info.dark, width: '85px' }}>
                            <Text color={theme.palette.background.paper} size={13}>Detalhar</Text>
                        </Button>
                    </Grid>
                    <Grid container paddingTop={2}>
                        {params.property !== 'conformation' &&
                            <Grid item xs={6} display="flex" justifyContent="center" style={{ paddingLeft: '4px', paddingRight: '4px', }}>
                                <ButtonGroup variant="contained" ref={anchorButton} aria-label="split button" sx={{ boxShadow: 'none' }}>
                                    <Button onClick={handleToggle} color={params.isSelected ? 'success' : 'primary'}>
                                        <Text color={theme.palette.background.paper}
                                            size={12}>
                                            {
                                                selectedClassification >= 0 ?
                                                    classificationsJBS.find(item => item.id === selectedClassification)?.label
                                                    : 'Selecione um acabamento'
                                            }
                                        </Text>
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                            </Grid>}
                        {params.property !== 'classification' &&
                            <Grid item xs={6} display="flex" justifyContent="center" style={{ paddingLeft: '4px', paddingRight: '4px' }}>
                                <ButtonGroup variant="contained" ref={anchorButtonConformation} aria-label="split button" sx={{ boxShadow: 'none' }}>
                                    <Button onClick={handleToggleConformation} color={params.isSelected ? 'success' : 'primary'}>
                                        <Text color={theme.palette.background.paper}
                                            size={12}>
                                            {
                                                selectedConformation >= 0 ?
                                                    defaultConformations.find(item => item.id === selectedConformation)?.label
                                                    : 'Selecione uma conformação'
                                            }
                                        </Text>
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Container>
            <Popper
                open={openButton}
                anchorEl={anchorButton.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 100 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {classificationsJBS.map((item, index) => (
                                        <MenuItem
                                            key={item.id}
                                            selected={item.id === selectedClassification}
                                            onClick={(event) => handleMenuItemClick(event, item.id)}>
                                            <Text size={13} color={theme.palette.primary.main}>
                                                {item.label}
                                            </Text>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Popper
                open={openButtonConformation}
                anchorEl={anchorButtonConformation.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 100 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseConformation}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {defaultConformations.map((item, index) => (
                                        <MenuItem
                                            key={item.id}
                                            selected={item.id === selectedConformation}
                                            onClick={(event) => handleMenuItemClickConformation(event, item.id)}>
                                            <Text size={13} color={theme.palette.primary.main}>
                                                {item.label}
                                            </Text>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {isPhotoOpen && params.carcass.photoUri &&
                <Lightbox
                    mainSrc={openPhoto}
                    onCloseRequest={() => setIsPhotoOpen(false)}
                    zoomInLabel="Mais zoom"
                    zoomOutLabel="Menos zoom" />
            }
        
        </Container>
    );
}

export default VerdictCard;
