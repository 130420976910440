import { IAlert } from '../types'
import { baseURL } from "../services/alerts";
import CRUDHook from "./crud";

export const { crudProvider: AlertsProvider, crudHook: useAlerts } = CRUDHook<IAlert>(
    {
        baseURL,
        name: 'alerta',
    }
)
