import React, { FC, useState, useEffect, useRef } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useDownloadPage } from '../../hooks/downloadPage';
import { useInformation } from '../../hooks/information';
import { API_SOCKET_URL, API_DOWNLOAD_FILE_URL } from '../../utils/config';


const DownloadPageActions: FC<any> = () => {
    const { data, loading, filterProperty, undefinedThreshold } = useDownloadPage();
    const { showInformation } = useInformation();

    const refDownload = useRef<HTMLAnchorElement | null>(null);
    const [groupBy, setGroupBy] = useState<'pop' | 'classification' | 'conformation'>(filterProperty);

    const [urlDownload, setUrlDownload] = useState<string>('');
    const [fileNameDownload, setFileNameDownload] = useState<string>('');
    const [currentIndexDownload, setCurrentIndexDownload] = useState<number>(0);
    const [totalDownload, setTotalDownload] = useState<number>(0);
    const [downloading, setDownloading] = useState<boolean>(false);

    useEffect(() => {
        setGroupBy(filterProperty);
    }, [data])

    const startDownload = async (_fileName: string) => {
        setFileNameDownload(_fileName);
        setUrlDownload(`${API_DOWNLOAD_FILE_URL}/download_page/${_fileName}`);
        try {
            setTimeout(() => {
                refDownload.current?.click();
                setTimeout(() => {
                    setUrlDownload('');
                    setFileNameDownload('');
                    setCurrentIndexDownload(0);
                    setTotalDownload(0);
                    setDownloading(false);
                }, 1000);
            }, 1000);
        } catch (err: any) {
            setDownloading(false);
        }
    }

    const onDownload = async () => {
        if (!data || data.length == 0) return;

        setDownloading(true);
        try {
            const socket = new WebSocket(`${API_SOCKET_URL}socket/download_page`)
            socket.onmessage = (ev: MessageEvent<any>) => {
                let data = JSON.parse(ev.data);
                if (data) {
                    if (data.current && data.total && data.status && data.status == 'IN_PROGRESS') {
                        setCurrentIndexDownload(parseInt(data.current))
                        setTotalDownload(parseInt(data.total))
                    } else if (data.path && data.status && data.status == 'COMPLETED') {
                        setCurrentIndexDownload(0);
                        setTotalDownload(0);

                        startDownload(data.path);
                        socket.close();
                    } else if (data.message && data.status == 'ERROR') {
                        if (data.message == "NOT_FOUND")
                            showInformation("Não foi encontrada nenhuma carcaça na data selecionada", 'Aviso');
                        else if (data.message == "MISSING_ARGS")
                            showInformation("Erro de conexão com o servidor. Entre em contato com nossa equipe de desenvolvimento", 'Aviso');
                        socket.close();
                        setDownloading(false);
                    }
                }
            };
            socket.onopen = (ev: Event) => {
                let message: any = {
                    data: data,
                    groupBy: groupBy,
                };
                if (filterProperty === 'pop' && undefinedThreshold)
                    message.undefinedThreshold = undefinedThreshold;
                socket.send(JSON.stringify(message));
            }
        } catch (err) {
            showInformation((err as any).message, 'Aviso');
            setDownloading(false);
        }
    }

    if (!data || data.length === 0)
        return <></>

    return <>
        <a href={urlDownload} download={fileNameDownload} className="hidden" ref={refDownload} target='_blank' />
        <Button
            disabled={loading || downloading}
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => onDownload()}
            sx={{ borderRadius: 2, marginTop: '20px', marginLeft: '20px' }}
        >
            {!downloading && 'Baixar Fotos'}
            {downloading && <>
                {currentIndexDownload < totalDownload ?
                    `Baixando (${currentIndexDownload} de ${totalDownload})`
                    : 'Preparando arquivo para download'
                }
                <CircularProgress size={25} color='inherit' style={{ marginLeft: '8px' }} />
            </>}
        </Button>
    </>;
};

export default DownloadPageActions;
