import React, { FC, createContext, useContext, useState } from 'react'
import { GlassMagnifier } from "react-image-magnifiers";

import { createAnnotation, updateAnnotation, getAnnotations, destroyAnnotation, getAnnotation, getAnnotationCount, getAnnotationForRevisions } from '../services/annotations';
import { IAnnotation, IAnnotationContext, IRevisionFilter } from '../types'
import classeJBS1 from '../assets/carcasses/examplesJBS/Classe 1.png'
import classeJBS2Minor from '../assets/carcasses/examplesJBS/Classe 2 (-).png'
import classeJBS2Equal from '../assets/carcasses/examplesJBS/Classe 2 (=).png'
import classeJBS2Major from '../assets/carcasses/examplesJBS/Classe 2 (+).png'
import classeJBS3Minor from '../assets/carcasses/examplesJBS/Classe 3 (-).png'
import classeJBS3Equal from '../assets/carcasses/examplesJBS/Classe 3 (=).png'
import classeJBS3Major from '../assets/carcasses/examplesJBS/Classe 3 (+).png'
import classeJBS4 from '../assets/carcasses/examplesJBS/Classe 4.png'
import classeJBS5 from '../assets/carcasses/examplesJBS/Classe 5.png'
import classeRedsoft1 from '../assets/carcasses/examplesRedsoft/Classe 1.png'
import classeRedsoft2Minor from '../assets/carcasses/examplesRedsoft/Classe 2 (-).png'
import classeRedsoft2Equal from '../assets/carcasses/examplesRedsoft/Classe 2 (=).png'
import classeRedsoft2Major from '../assets/carcasses/examplesRedsoft/Classe 2 (+).png'
import classeRedsoft3Minor from '../assets/carcasses/examplesRedsoft/Classe 3 (-).png'
import classeRedsoft3Equal from '../assets/carcasses/examplesRedsoft/Classe 3 (=).png'
import classeRedsoft3Major from '../assets/carcasses/examplesRedsoft/Classe 3 (+).png'
import classeRedsoft4 from '../assets/carcasses/examplesRedsoft/Classe 4.png'
import classeRedsoft5 from '../assets/carcasses/examplesRedsoft/Classe 5.png'
import { IAnnotationCount, IAnnotationFilter } from '../types/hooks/annotations';
import moment from 'moment';
import { CarcassPhoto } from '../components';

const AnnotationsContext = createContext<IAnnotationContext>({} as IAnnotationContext);

export const AnnotationsProvider: FC<any> = ({ children }) => {
    const [annotations, setAnnotations] = useState<IAnnotation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchAnnotation, setSearchAnnotation] = useState<string>('');
    const [annotationsPerRow, setAnnotationsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const [filter, setFilter] = useState<IAnnotationFilter>({
        initialDate: new Date(),
        finalDate: new Date(),
        branches: [],
        classifications: [],
        iaClassifications: [],
        onlyBestPlaced: false,
        onlyWithPartnerId: false,
        offset: 0,
    });

    const [annotationCount, setAnnotationCount] = useState<IAnnotationCount>({
        countAnnotate: 0,
        countUnannotate: 0
    });
    const [loadingAnnotationCount, setLoadingAnnotationCount] = useState<boolean>(false);

    const [annotationsForRevisions, setAnnotationsForRevisions] = useState<IAnnotation[]>([]);
    const [loadingAnnotationForRevisions, setLoadingAnnotationForRevisions] = useState<boolean>(false);

    const [magnifierSize, setMagnifierSize] = useState<number>(190);

    const fetchAnnotations = async () => {
        setLoading(true);
        try {
            const annotations: IAnnotation[] = await getAnnotations();
            setAnnotations([...annotations]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchAnnotation = async (_id: string) => {
        const annotation: IAnnotation = await getAnnotation(_id);
        return annotation;
    }

    const createNewAnnotation = async (_annotation: IAnnotation, _reload: boolean = true) => {
        const annotation = await createAnnotation(_annotation);
        if (_reload)
            fetchAnnotations();
        return annotation;
    }

    const editAnnotation = async (_id: string, _payload: IAnnotation) => {
        try {
            const annotation = await updateAnnotation(_id, _payload);

            fetchAnnotations();
            return annotation;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteAnnotation = async (_id: string) => {
        const annotation = await destroyAnnotation(_id);
        fetchAnnotations();
        return annotation;
    }

    const fetchAnnotationCount = async (_filter: IAnnotationFilter) => {
        setLoadingAnnotationCount(true);
        try {
            let today = new Date();
            let initial_date = _filter.initialDate ? moment(_filter.initialDate) : moment(today); 
            let final_date = _filter.finalDate ? moment(_filter.finalDate) : moment(today);
            let query: string = `?initial_date=${initial_date.format('YYYY-MM-DD')}`;
            query += `&final_date=${final_date.format('YYYY-MM-DD')}`;
            if (_filter.branches.length > 0)
                query += `&branches_id=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
            if (_filter.classifications.length > 0)
                query += `&local_classifications_id=${_filter.classifications.map(item => `'${item.localClassification}'`).join(',')}`;
            if (_filter.iaClassifications.length > 0)
                query += `&classifications=${_filter.iaClassifications.map(item => `${item.id!}`).join(',')}`;
            if (_filter.onlyBestPlaced)
                query += `&best_placed=1`;
            if (_filter.onlyWithPartnerId)
                query += `&with_partner_id=1`;

            const auxAnnotationCount: IAnnotationCount = await getAnnotationCount(query);
            setAnnotationCount(auxAnnotationCount);

            setTimeout(() => {
                setLoadingAnnotationCount(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoadingAnnotationCount(false);
        }
        setLoadingAnnotationCount(true)
    };

    const fetchAnnotationsForRevisions = async (_filter: IRevisionFilter) => {
        setLoadingAnnotationForRevisions(true);
        try {
            let today = new Date();
            let initial_date = _filter.initialDate ? moment(_filter.initialDate) : moment(today); 
            let final_date = _filter.finalDate ? moment(_filter.finalDate) : moment(today);
            let query: string = `?initial_date=${initial_date.format('YYYY-MM-DD')}`;
            query += `&final_date=${final_date.format('YYYY-MM-DD')}`;
            if (_filter.annotators.length > 0)
                query += `&annotators_id=${_filter.annotators.map(item => `'${item.id}'`).join(',')}`;
            if (_filter.branches.length > 0)
                query += `&branches_id=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
            if (_filter.classificationsId.length > 0)
                query += `&classifications=${_filter.classificationsId.map(item => `'${item.id}'`).join(',')}`;
            if (_filter.iaClassificationsId.length > 0)
                query += `&ia_classifications=${_filter.iaClassificationsId.map(item => `${item.id}`).join(',')}`;
            if (_filter.offset && _filter.offset > 0)
                query += `&offset=${_filter.offset}`;
            query += `&limit=${_filter.limit}`;

            setAnnotationsForRevisions([]);
            const annotationsForRevisions: IAnnotation[] = await getAnnotationForRevisions(query);
            setAnnotationsForRevisions([...annotationsForRevisions]);

            setTimeout(() => {
                setLoadingAnnotationForRevisions(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoadingAnnotationForRevisions(false);
        }
    }

    function getExampleJBS(_class: number) {
        switch (_class) {
            case 1:
                return <CarcassPhoto photoUri={classeJBS1} />;
            case 2:
                return <CarcassPhoto photoUri={classeJBS2Minor} />;
            case 3:
                return <CarcassPhoto photoUri={classeJBS2Equal} />;
            case 4:
                return <CarcassPhoto photoUri={classeJBS2Major} />;
            case 5:
                return <CarcassPhoto photoUri={classeJBS3Minor} />;
            case 6:
                return <CarcassPhoto photoUri={classeJBS3Equal} />;
            case 7:
                return <CarcassPhoto photoUri={classeJBS3Major} />;
            case 8:
                return <CarcassPhoto photoUri={classeJBS4} />;
            case 9:
                return <CarcassPhoto photoUri={classeJBS5} />;
            default:
                return null;
        }
    }

    function getExampleRedsoft(_class: number) {
        switch (_class) {
            case 1:
                return <CarcassPhoto photoUri={classeRedsoft1} />;
            case 2:
                return <CarcassPhoto photoUri={classeRedsoft2Minor} />;
            case 3:
                return <CarcassPhoto photoUri={classeRedsoft2Equal} />;
            case 4:
                return <CarcassPhoto photoUri={classeRedsoft2Major} />;
            case 5:
                return <CarcassPhoto photoUri={classeRedsoft3Minor} />;
            case 6:
                return <CarcassPhoto photoUri={classeRedsoft3Equal} />;
            case 7:
                return <CarcassPhoto photoUri={classeRedsoft3Major} />;
            case 8:
                return <CarcassPhoto photoUri={classeRedsoft4} />;
            case 9:
                return <CarcassPhoto photoUri={classeRedsoft5} />;
            default:
                return null;
        }
    }

    const handleMagnifierSize = (_add: boolean) => {
        if (_add) {
            if (magnifierSize > 250) return;

            let newValue = magnifierSize + 25;
            if (newValue > 250)
                newValue = 250;
            setMagnifierSize(newValue);
        } else {
            if (magnifierSize < 100) return;

            let newValue = magnifierSize - 100;
            if (newValue < 100)
                newValue = 100;
            setMagnifierSize(newValue);
        }
    }

    return (
        <AnnotationsContext.Provider value={{
            annotations, loading, fetchAnnotations, fetchAnnotation,
            createNewAnnotation, editAnnotation, deleteAnnotation,
            searchAnnotation, setSearchAnnotation, annotationsPerRow, setAnnotationsPerRow, paginate, setPaginate,

            filter, setFilter,

            annotationCount, loadingAnnotationCount, fetchAnnotationCount,
            annotationsForRevisions, loadingAnnotationForRevisions, fetchAnnotationsForRevisions,

            magnifierSize, handleMagnifierSize,
            getExampleJBS, getExampleRedsoft
        }}>{children}</AnnotationsContext.Provider>
    )
}

export function useAnnotations() {
    const context = useContext(AnnotationsContext);

    if (!context) {
        throw new Error('useAnnotations must be used within an AnnotationsProvider')
    }

    return context;
}
