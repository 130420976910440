import React, { FC } from 'react';
import {
    ICRUDTableByBranch,
    IShift,
    ICRUDContext,
} from '../../../../types';

import { getShiftsByBranch } from "../../../../services/shifts";
import { useShifts } from '../../../../hooks/shifts';
import generateCRUDTable from '../../crudTable';


const ShiftsCRUDTable: FC<ICRUDTableByBranch> = (params: ICRUDTableByBranch) => {
    const crudTable = generateCRUDTable<IShift, ICRUDTableByBranch>(
        {
            columns: [
                {
                    name: 'shiftType',
                    title: 'Turno',
                    type: 'integer',
                    required: true,
                    max: 3,
                    min: 1,
                },
                {
                    name: 'startTime',
                    title: 'Início',
                    required: true,
                    min: 8,
                    max: 15,
                },
                {
                    name: 'endTime',
                    title: 'Fim',
                    required: true,
                    min: 8,
                    max: 15,
                },
            ],
            name: 'turno',
            defaultSort: 'startTime',
            entryDescriptor: 'shiftType',
            defaultNewEntry: {
                branchId: params.branchId,
                shiftType: 1,
                startTime: '00:00:00',
                endTime: '23:59:59.999999'
            },
            getHook: useShifts,
            fetchAllEntries: async (hook?: ICRUDContext<IShift>, _params?: ICRUDTableByBranch) => {
                return await getShiftsByBranch(params.branchId);
            }
        }
    )

    return crudTable(params);
};

export default React.memo(ShiftsCRUDTable);