import React, { FC, useEffect } from 'react';
import { IAlertGroup } from '../../../types';

import { useAlertGroups } from '../../../hooks/alertGroup';
import { useAlertConstants } from '../../../hooks/alertConstants';
import { generateCRUDTable } from '../../../components';
import { useBranches } from '../../../hooks/branches';
import { useUsers } from '../../../hooks/users';


const AlertGroupsCRUDTable: FC = () => {
    const { branches, fetchBranches } = useBranches();
    const { users, fetchUsers } = useUsers();
    const { levels, fetchAlertConstants } = useAlertConstants()

    useEffect(() => {
        if (!users || users.length === 0)
            fetchUsers();
        if (!levels || levels.length === 0)
            fetchAlertConstants();
        if (!branches || branches.length === 0)
            fetchBranches();
    }, []);

    const crudTable = generateCRUDTable<IAlertGroup, {}>(
        {
            columns: [
                {
                    name: 'name',
                    title: 'Nome',
                    required: true,
                },
                {
                    name: 'level',
                    title: 'Nível',
                    required: true,
                    type: 'integer',
                    options: levels.map((item) => ({ id: item.value!, label: item.description })),
                },
                {
                    name: 'branchId',
                    title: 'Filial',
                    options: branches.map((item) => ({ id: item.id!, label: item.name })),
                    required: true,
                },
                {
                    name: 'users',
                    title: 'Membros',
                    options: users.map((item) => ({ id: item.id!, label: item.firstName })),
                    multiselect: true,
                    hideColumn: true,
                },
            ],
            excludeColumns: ['schedules'],
            name: 'grupos de alerta',
            defaultSort: 'name',
            entryDescriptor: 'name',
            defaultNewEntry: {
                branchId: '',
                name: '',
                level: levels && levels.length > 0 ? levels[0].value : 10,
                users: [],
            },
            getHook: useAlertGroups
        }
    )

    return crudTable({});
};

export default React.memo(AlertGroupsCRUDTable);
