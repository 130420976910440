import axios, { AxiosResponse } from "axios";
import { REDSOFT_ACCESS_TOKEN, REDSOFT_REFRESH_TOKEN, REDSOFT_TOKEN_EXPIRES_IN, REDSOFT_USER } from "../plugins/localStorage.consts";

let baseURL: string = process.env.REACT_APP_API_URL || (window as any)?._env_?.REACT_APP_API_URL || "http://localhost:5000";
if (process.env.NODE_ENV == "production") {
  baseURL = "/api/";
}

export const API_URL = baseURL;

const api = axios.create();
api.defaults.baseURL = API_URL;

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    let statusCode = error?.response?.status || 401;

    if (statusCode === 401) {
      localStorage.removeItem(REDSOFT_ACCESS_TOKEN);
      localStorage.removeItem(REDSOFT_REFRESH_TOKEN);
      localStorage.removeItem(REDSOFT_TOKEN_EXPIRES_IN);
      localStorage.removeItem(REDSOFT_USER);
      window.location.href = "/";
    }
    return Promise.resolve(error);
  }
);

export default api;
