import { AxiosResponse } from "axios";

import api from ".";
import { ITypification } from "../types";
import CRUDService from "./crud";

export const baseURL = '/api_redsoft/v1_0/typifications';

const defaultService = CRUDService({
  baseURL,
  name: 'tipificação',
});

export const getTypificationsByBranch: (branch?: string) => Promise<ITypification[]> = async (branch?: string) => {
  const url = !!branch ? `${baseURL}/by_branch_id/${branch}` : baseURL;
  const response: AxiosResponse = await api.get(url);
  return defaultService.crudCheckResponse(response, 'Falha ao buscar tipificações');
};
