import React, { FC, useEffect } from 'react';
import { IAlertSchedule } from '../../../types';

import { useAlertGroupSchedules } from '../../../hooks/alertGroupSchedules';
import { generateCRUDTable } from '../../../components';
import { useShifts } from '../../../hooks/shifts';
import { useAlertConstants } from '../../../hooks/alertConstants';
import { useBranches } from '../../../hooks/branches';
import { useAlertGroups } from '../../../hooks/alertGroup';

const BASE_ERROR = 'Por favor, especifique ou o turno ou o horário';


const AlertGroupsSchedulesCRUDTable: FC = () => {
    const { entries: shifts, fetchAllEntries: fetchShifts } = useShifts();
    const { entries: alertGroups, fetchAllEntries: fetchAlertGroups } = useAlertGroups();
    const { weekDays, fetchAlertConstants } = useAlertConstants();
    const { branches, fetchBranches } = useBranches();

    useEffect(() => {
        if (!shifts || shifts.length === 0)
            fetchShifts();
        if (!weekDays || weekDays.length === 0)
            fetchAlertConstants();
        if (!branches || branches.length === 0)
            fetchBranches();
        if (!alertGroups || alertGroups.length === 0)
            fetchAlertGroups();
    }, []);

    const crudTable = generateCRUDTable<IAlertSchedule, {}>(
        {
            columns: [
                {
                    name: 'alertGroupId',
                    title: 'Alert Group',
                    options: alertGroups.map((item) => ({ id: item.id!, label: item.name })),
                    required: true,
                },
                {
                    name: 'shiftId',
                    title: 'Turno',
                    validate: (value: any, entry: any) => {
                        if (!value && (!entry.startTime || !entry.endTime))
                            return { error: BASE_ERROR, value}
                        if (value && (entry.startTime || entry.endTime))
                            return { error: BASE_ERROR, value}
                        if (value && entry['alertGroupId']) {
                            const groupBranchId = alertGroups.find((g) => g.id === entry['alertGroupId'])?.branchId;
                            if (groupBranchId && shifts.find((s) => s.id === value)?.branchId !== groupBranchId)
                                return { error: 'Turno selecionado não pertence à filial do grupo', value }
                        }
                        return { error: '', value }
                    },
                    options: shifts.map((item) => ({
                        id: item.id!,
                        label: `${branches?.find((b) => b.id === item.branchId)?.name || item.branchId} - ${item.shiftType}° Turno`
                    }))
                },
                {
                    name: 'startTime',
                    title: 'Horário Inicial',
                    type: 'time',
                    validate: (value: any, entry: any) => {
                        if ((!value && !entry.shiftId) || (value && entry.shiftId))
                            return { error: BASE_ERROR, value}
                        else if (value && !entry.endTime)
                            return { error: 'Defina também o horário final', value}
                        return { error: '', value }
                    }
                },
                {
                    name: 'endTime',
                    title: 'Horário Final',
                    type: 'time',
                    validate: (value: any, entry: any) => {
                        if ((!value && !entry.shiftId) || (value && entry.shiftId))
                        return { error: BASE_ERROR, value}
                        else if (value && !entry.startTime)
                            return { error: 'Defina também o horário inicial', value}
                        return { error: '', value }
                    }
                },
                {
                    name: 'weekDays',
                    title: 'Dias Trabalhando',
                    type: 'integer',
                    multiselect: true,
                    options: weekDays.map((item) => ({ id: item.value!, label: item.description })),
                },
            ],
            name: 'horário do grupo de alerta',
            defaultSort: 'alertGroupId',
            entryDescriptor: 'alertGroupId',
            defaultNewEntry: {
                alertGroupId: '',
                shiftId: '',
                startTime: '',
                endTime: '',
                weekDays: [],
            },
            getHook: useAlertGroupSchedules
        }
    )

    return crudTable({});
};

export default React.memo(AlertGroupsSchedulesCRUDTable);
