import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app';
import AuthRoutes from './auth';
import { Confirmation, Information, Loading } from '../components';
import { useAuth } from '../hooks/auth';
import { useConfirmation } from '../hooks/confirmation';
import { useInformation } from '../hooks/information';
import { Splash } from '../pages';

const Routes: React.FC = () => {
    const { accessToken, loadStorage, loadingStorage } = useAuth();
    const { informationOpen, informationTitle, informationMessage, informationOnConfirmClick, informationOnClose, hideInformation } = useInformation();
    const {
        confirmationOpen, confirmationTitle, confirmationMessage,
        confirmationConfirmLabel, confirmationCancelLabel,
        confirmationOnConfirmClick, confirmationOnCancelClick, hideConfirmation } = useConfirmation();

    const [confirmationLoadingAction, setConfirmationLoadingAction] = useState<boolean>(false);

    useEffect(() => {
        loadStorage();
    }, []);

    if (loadingStorage) return <Splash />

    return (
        <BrowserRouter>
            {accessToken === undefined && <AuthRoutes />}
            {accessToken !== undefined && <AppRoutes />}

            <Confirmation
                open={confirmationOpen}
                title={confirmationTitle}
                message={confirmationMessage}
                onClose={() => {
                    if (confirmationOnCancelClick) confirmationOnCancelClick();
                    else hideConfirmation();
                }}
                cancelLabel={confirmationCancelLabel ? confirmationCancelLabel : "Cancelar"}
                onCancel={() => {
                    if (confirmationOnCancelClick) confirmationOnCancelClick();
                    else hideConfirmation();
                }}
                loading={confirmationLoadingAction}
                onConfirm={async () => {
                    setConfirmationLoadingAction(true);
                    if (confirmationOnConfirmClick != undefined) {
                        await confirmationOnConfirmClick();
                    }
                    else hideConfirmation();
                    setConfirmationLoadingAction(false);
                }}
                confirmLabel={confirmationConfirmLabel ? confirmationConfirmLabel : "Confirmar"} />
            <Information
                open={informationOpen}
                title={informationTitle}
                message={informationMessage}
                onClose={() => {
                    if (informationOnClose) informationOnClose();
                    else hideInformation()
                }}
                onConfirm={() => {
                    if (informationOnConfirmClick) informationOnConfirmClick();
                    else hideInformation();
                }} />
            <Loading
            ></Loading>
        </BrowserRouter>
    )
}

export default Routes