import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Grow
} from '@mui/material';

import Text from '../../atoms/text';

import {
    IAlertSocketHook,
    IAlertSocketHookError,
    ISocketHookStatus
} from '../../../types';
import useActiveAlerts from '../../../sockets/activeAlerts';
import { useAlertConstants } from '../../../hooks/alertConstants';


const AlertActiveStatus: FC<{
    isOpen?: boolean
}> = (params: {
    isOpen?: boolean
}) => {
    const { data: alerts, hookStatus: alertsHookStatus } = useActiveAlerts();
    const { fetchAlertConstants, levels } = useAlertConstants();

    const navigate = useNavigate();

    useEffect(() => {
        if (!levels || levels.length === 0)
            fetchAlertConstants();
    }, [])

    function getAlertColor() {
        const INACTIVE_COLOR = '#AAA';
        const ERROR_COLOR = 'red';
        const WARNING_COLOR = '#FFCE00';
        const OK_COLOR = '#2E7D32';

        switch (alertsHookStatus) {
            case ISocketHookStatus.ERROR:
                return ERROR_COLOR;
            case ISocketHookStatus.IDLE:
                return INACTIVE_COLOR;
            case ISocketHookStatus.CONNECTING:
                return INACTIVE_COLOR;
            case ISocketHookStatus.LOADING:
                return INACTIVE_COLOR;
            case ISocketHookStatus.CLOSE:
                return INACTIVE_COLOR;
        };

        if ((alerts as IAlertSocketHookError).error)
            return ERROR_COLOR;

        if ((alerts as IAlertSocketHook).total === null)
            return INACTIVE_COLOR;

        if ((alerts as IAlertSocketHook).total === 0)
            return OK_COLOR;


        const level = levels.find(
            (l: any) => l.value === (alerts as IAlertSocketHook).level
        );

        if (!level)
            return INACTIVE_COLOR;

        if (level.name === 'WARNING')
            return WARNING_COLOR;

        return ERROR_COLOR;
    };

    return <Box
        display='flex'
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        gap='8px'
        marginBottom={1}
        onClick={() => navigate('alert/active-alerts')}
        sx={{
            cursor: 'pointer'
        }}
    >
        <Box width='10px' height='10px' sx={{
            borderRadius: '50%',
            background: getAlertColor()
        }}></Box>
        <Grow in={params.isOpen}>
            <Box>
                <Text size={15} medium>
                    {(alerts as IAlertSocketHook).total || 0} alerta(s) ativo(s)
                </Text>
            </Box>
        </Grow>
    </Box>;
}

export default AlertActiveStatus;
