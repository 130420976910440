import { AxiosResponse } from "axios";
import moment from "moment";

import api from ".";
import { ICompany } from "../types";

const baseUrl: string = "companies";

export const createCompany = async (_company: ICompany) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    name: _company.name,
    tradeName: _company.tradeName,
    token: _company.token,
    prefix: _company.prefix,
    fridge: _company.fridge,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToICompany(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateCompany = async (_id: string, _company: ICompany) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    name: _company.name,
    tradeName: _company.tradeName,
    token: _company.token,
    prefix: _company.prefix,
    fridge: _company.fridge,
    email: _company.email,
    cnpj: _company.cnpj,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToICompany(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyCompany = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error(
    response && (response as any).reponse && (response as any).reponse.data ?
    (response as any).response.data : "Não foi possível criar um dado"
  );
};

export const getCompanys = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const companies: ICompany[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const company: ICompany = convertApiResponseToICompany(data);

        companies.push(company);
      }

      return companies;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getCompany = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const company: ICompany = convertApiResponseToICompany(response.data);
      return company;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToICompany = (_company: any) => {
  const company: ICompany = {
    id: _company.id,
    name: _company.name,
    tradeName: _company.tradeName,
    email: _company.email,
    cnpj: _company.cnpj,
    token: _company.token,
    prefix: _company.prefix,
    fridge: _company.fridge,
    isDeleted: _company.isDeleted,
    createdAt: moment(_company.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    updatedAt: moment(_company.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
  };

  return company;
};
