import React, { FC, createContext, useState, useContext } from "react";

import {
    IAlertProvider,
    IAlertProvidersContext,
} from "../types";
import { getAlertProviders } from "../services/alertProviders";


const AlertProvidersContext = createContext<IAlertProvidersContext>(
    {} as IAlertProvidersContext
);


export const AlertProvidersProvider: FC<any> = ({ children }) => {
    const [providers, setProviders] = useState<IAlertProvider[]>(
        [] as IAlertProvider[]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchAlertProviders = async () => {
        setProviders([]);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 6000);

        const response: IAlertProvider[] = await getAlertProviders();
        setProviders(response);
        return response;
    };

    return (
        <AlertProvidersContext.Provider
            value={{
                loading,
                providers,
                fetchAlertProviders,
            }}
        >
            {children}
        </AlertProvidersContext.Provider>
    );
};

export function useAlertProviders() {
    const context = useContext(AlertProvidersContext);

    if (!context) {
        throw new Error(
            "useAlertProviders must be used within an AlertProvidersContextProvider",
        );
    }
    return context;
}
