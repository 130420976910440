import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

import theme from '../../theme';
import { BodyText } from '../../atoms/text';
import BranchesMultiSelect from '../branches/multiSelect';
import DateRangePicker from '../../atoms/dateRangePicker';
import SlaughterDelayTypeSelect from './SlaughterDelayTypeSelect';
import SlaughterDelayReasonSelect from './SlaughterDelayReasonSelect';
import SlaughterDelayReasonLocationSelect from './SlaughterDelayReasonLocationSelect';

import { reasonToAutocompleteOption } from '../../../services/slaughterDelays';
import { ISlaughterDelaysFilterErrors, ISlaughterDelaysReason } from '../../../types/hooks/slaughterDelays';
import { useSlaughterDelaysFilter } from '../../../hooks/slaughterDelaysFilter';
import { useSlaughterDelays } from '../../../hooks/slaughterDelays';
import { IAutocompleteOption } from '../../../types';
import { useSlaughterDelayReasons } from '../../../hooks/slaughterDelayReasons';
import { useSlaughterDelayReasonLocations } from '../../../hooks/slaughterDelayReasonLocations';


const SlaughterDelaysFilter: FC<any> = () => {
    const {
        initialDate,
        setInitialDate,
        finalDate,
        setFinalDate,
        delayType,
        setDelayType,
        branchesExpanded,
        setBranchesExpanded,
        branch,
        setBranch,
        errors,
        setErrors,
        reasons,
        setReasons,
        locations,
        setLocations,
        clear,
    } = useSlaughterDelaysFilter();

    const reasonsHook = useSlaughterDelayReasons();
    const locationsHook = useSlaughterDelayReasonLocations();

    const {
        loading,
        fetchDashboard,
    } = useSlaughterDelays();

    const clearError = (field: keyof ISlaughterDelaysFilterErrors) => {
        const newErrors = {...errors};
        if (newErrors[field]) delete newErrors[field];
        setErrors(newErrors);
    };

    const handleSetReasons = (val: IAutocompleteOption[] | null) => {
        let newValue = [] as ISlaughterDelaysReason[];
        if (reasons && val && val.length > 0) {
            newValue = val.reduce((res, option) => {
                const entry = reasonsHook.entries?.find(e => option.id && e.id === option.id);
                if (!entry && !errors.reasons)
                    setErrors({
                        ...errors,
                        reasons: 'Não foi possível filtrar pelos motivos especificados'
                    });
                else if (entry) res.push(entry);
                return res;
            }, [] as ISlaughterDelaysReason[]);
        };
        setReasons(newValue);
    };

    const handleSetLocations = (val: IAutocompleteOption[] | null) => {
        let newValue = [] as ISlaughterDelaysReason[];
        if (reasons && val && val.length > 0) {
            newValue = val.reduce((res, option) => {
                const entry = locationsHook.entries?.find(e => option.id && e.id === option.id);
                if (!entry && !errors.locations)
                    setErrors({
                        ...errors,
                        locations: 'Não foi possível filtrar pelos locais especificados'
                    });
                else if (entry) res.push(entry);
                return res;
            }, [] as ISlaughterDelaysReason[]);
        };
        setLocations(newValue);
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={3} sx={{marginBottom: 2}}>
            <Grid container item xs={12} sm={6} lg={3}>
                <DateRangePicker
                    disabled={loading}
                    initialDate={initialDate}
                    finalDate={finalDate}
                    placeholder={"Escolha o intervalo de data"}
                    setInitialDate={(val: Date | null) => {
                        setInitialDate(val);
                        clearError('finalDate');
                    }}
                    setFinalDate={(val: Date | null) => {
                        setFinalDate(val);
                        clearError('finalDate');
                    }}
                    maxDaysSelect={0}
                    useDefaultPredefinedRanges
                    sx={{
                        width: '100%',
                        backgroundColor: '#FFF',
                        borderRadius: '0px',
                        height: '37px',
                    }}
                />
                <BodyText color={theme.palette.error.main}>
                    {errors.finalDate}
                </BodyText>
            </Grid>
            <Grid container item xs={12} sm={6} lg={3}>
                <BranchesMultiSelect
                    disabled={loading}
                    singleSelect
                    minWidth="100%"
                    selectedBranches={branch ? [branch] : []}
                    setSelectedBranches={(value) => {
                        setBranch(value ? value[0] : null);
                        clearError('branch');
                    }}
                    expanded={branchesExpanded}
                    setExpanded={setBranchesExpanded}
                    height='38px'
                    sx={{
                        backgroundColor: '#FFF',
                        border: '1px solid #c0c0c0',
                    }}
                    placeholderProps={{
                        style: {
                            opacity: '75%',
                        },
                    }}
                />
                <BodyText color={theme.palette.error.main}>
                    {errors.branch}
                </BodyText>
            </Grid>
            <Hidden lgDown>
                <Grid item lg={6}/>
            </Hidden>
            <Grid container item xs={12} sm={6} lg={3}>
                <SlaughterDelayTypeSelect
                    value={delayType}
                    setValue={setDelayType}
                />
            </Grid>
            <Grid container item xs={12} sm={6} lg={3}>
                <SlaughterDelayReasonSelect
                    multiple
                    value={reasons.map(reasonToAutocompleteOption)}
                    setValue={handleSetReasons}
                />
                <BodyText color={theme.palette.error.main}>
                    {errors.reasons}
                </BodyText>
            </Grid>
            <Grid container item xs={12} sm={6} lg={3}>
                <SlaughterDelayReasonLocationSelect
                    multiple
                    value={locations.map(reasonToAutocompleteOption)}
                    setValue={handleSetLocations}
                />
                <BodyText color={theme.palette.error.main}>
                    {errors.locations}
                </BodyText>
            </Grid>
            <Grid container item xs={12}
                sx={{
                    justifyContent: {xs: 'end', sm: 'start'},
                    flexDirection: {xs: 'row-reverse', sm: 'row'},
                    alignItems: 'center',
                    marginTop: 2,
                }}
            >
                <Button
                    disabled={loading}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => fetchDashboard()}
                >
                    Aplicar
                </Button>
                <Button
                    disabled={loading}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    onClick={clear}
                    sx={{ marginLeft: 2, marginRight: 2 }}
                >
                    Limpar
                </Button>
            </Grid>
        </Grid>
    );
}

export default SlaughterDelaysFilter;
