import { FC } from 'react';

import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Tooltip,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import { theme } from '../../..'

import { ISlaughterDelaysMainCausesEntry } from '../../../../types/hooks/slaughterDelays';
import { useSlaughterDelays } from '../../../../hooks/slaughterDelays';
import { reasonToAutocompleteOption } from '../../../../services/slaughterDelays';


export interface ISlaughterDelayMainCausesChart {
    onClick?: (value: ISlaughterDelaysMainCausesEntry) => void;
    data: ISlaughterDelaysMainCausesEntry[];
};


const SlaughterDelayMainCausesChart: FC<ISlaughterDelayMainCausesChart> = (
    params: ISlaughterDelayMainCausesChart) =>
{
    const { groupByDuration } = useSlaughterDelays();

    if (!params.data || params.data.length === 0)
        return <></>;

    const data = groupByDuration ? params.data.map(entry => ({
        ...entry,
        value: parseFloat((entry.value / 60).toFixed(1))
    })) : params.data;

    return (
        <ResponsiveContainer height={220}>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="code" />
                <YAxis />
                <Tooltip labelFormatter={(label, payload) => {
                    if (!payload || !payload[0]?.payload) return '';
                    return reasonToAutocompleteOption(payload[0]?.payload).label;
                }}/>
                <Legend />
                <Bar
                    name={groupByDuration ?
                        'Tempo total de atraso/parada [min]'
                        : 'Número de ocorrências'
                    }
                    dataKey="value"
                    fill={theme.palette.primary.main}
                    isAnimationActive={true}
                    onClick={(data, index) => {
                        if (params.onClick) params.onClick(data);
                    }}
                    style={{ cursor: 'pointer' }}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default SlaughterDelayMainCausesChart;
