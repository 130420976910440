
import React, { FC, createContext, useContext, useState } from "react";

import {
    getPopDashboard,
} from "../services/carcassesPops";
import {
    IBranch,
    IPopDashboardContext,
    IPopDashboardData,
} from "../types";

const PopDashboardContext = createContext<IPopDashboardContext>({} as IPopDashboardContext);

export const PopDashboardProvider: FC<any> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IPopDashboardData | null>(null);
    
    const [initialDate, setInitialDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>([]);
    const [selectedPops, setSelectedPops] = useState<string[]>([]);

    const [barChartIsPercentage, setBarChartIsPercentage] = useState<boolean>(true);

    function clearFilters() {
        setSelectedPops([]);
        setInitialDate(null);
        setFinalDate(null);
        setSelectedBranches([]);
    }

    const fetchDashboardData = async () => {
        setLoading(true);
        setData(null);
        const interval = setTimeout(() => {
            setLoading(false);
        }, 5000);

        try {
            const result = await getPopDashboard({
                selectedPops,
                initialDate,
                finalDate,
                selectedBranches,
            });
            setData(result);
        } catch (_err) {
            console.log(_err);
        }
        if (interval)
            clearInterval(interval);
        setLoading(false);
    };

    return (
        <PopDashboardContext.Provider
            value={{
                data,
                setData,
                fetchDashboardData,
                clearFilters,
                loading,
                selectedPops,
                initialDate,
                finalDate,
                selectedBranches,
                setSelectedPops,
                setInitialDate,
                setFinalDate,
                setSelectedBranches,
                barChartIsPercentage,
                setBarChartIsPercentage,
            }}
        >
            {children}
        </PopDashboardContext.Provider>
    );
};

export function usePopDashboard() {
    const context = useContext(PopDashboardContext);

    if (!context) {
        throw new Error(
            "usePopDashboard must be used within an PopDashboardProvider",
        );
    }

    return context;
}
