import { AxiosResponse } from "axios";
import api from ".";

const baseURL: string = "/api_alert_system/v1_0/providers";

export const getAlertProviders = async () => {
  const response: AxiosResponse = await api.get(baseURL);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};
