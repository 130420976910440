import React, {
  FC,
  createContext,
  useContext
} from "react";
import { ALERT_SYSTEM_SOCKET_URL } from "../utils/config";
import { IAlertSocketHookData } from "../types/sockets/activeAlerts";
import createSocketHook from "./createSocketHook";
import {
  ISocketHookContext,
  ISocketHookOnOpenCallback,
  ISocketHookOnMessageCallback,
  ISocketHookOnCloseCallback,
  ISocketHookStatus
} from "../types/sockets/base";
import { useAuth } from "../hooks/auth";
import { IHookProvider } from "../types";


const MAX_RECONNECT_RETRIES = 5;
const SOCKET_POOLING_INTERVAL = 5000;

const ActiveAlertsContext = createContext<ISocketHookContext<IAlertSocketHookData>>(
  {} as ISocketHookContext<IAlertSocketHookData>,
);

export const ActiveAlertsSocketProvider: FC<IHookProvider> = ({ children }) => {
  const { user } = useAuth();

  function onOpenCallback(params: ISocketHookOnOpenCallback<IAlertSocketHookData>) {
    if (params.socket && params.socket.readyState === 1) {
      params.send({
        type: "OPEN",
        user: user!.id
      })
    }
  };

  function onMessageCallback(params: ISocketHookOnMessageCallback<IAlertSocketHookData>) {
    if (params.eventData.error)
      return params.reconnect();
    if (params.socket && params.hookStatus !== ISocketHookStatus.CLOSE && params.socket.readyState === 1) {
      setTimeout(() => {
        params.send({
          type: 'FETCH',
          user: user!.id
        });
      }, SOCKET_POOLING_INTERVAL);
    };
  };

  function onCloseCallback(params: ISocketHookOnCloseCallback<IAlertSocketHookData>) {
    if (params.socket && params.socket.readyState === 1) {
      params.send({
        type: "CLOSE",
        user: user!.id
      })
    }
  };

  const socketHook = createSocketHook<IAlertSocketHookData>({
    url: `${ALERT_SYSTEM_SOCKET_URL}/socket/alerts/active`,
    defaultData: {
      total: null,
      level: 0,
      alerts: []
    },
    onCloseCallback,
    onOpenCallback,
    onMessageCallback,
    maxReconnectRetries: MAX_RECONNECT_RETRIES,
  });

  return <ActiveAlertsContext.Provider value={socketHook}>
    {children}
  </ActiveAlertsContext.Provider>;
};


export default function useActiveAlerts() {
  const context = useContext(ActiveAlertsContext);

  if (!context) {
      throw new Error(
          "useActiveAlerts must be used within an ActiveAlertsSocketProvider",
      );
  }

  return context;
}