import React, { FC, createContext, useState, useContext } from "react";
import moment from "moment";

import { theme } from "../components";
import {
    IAnomalyFilter,
    IAnomaly,
    IAnomalyCount,
    IAnomalyContext,
} from "../types";

import {
    fetchAnomalies,
    getAnomaliesForAnnotation,
    getAnomaliesAnnotationCount,
} from '../services/anomalies';

const AnomalyContext = createContext<IAnomalyContext>({} as IAnomalyContext);

export const AnomalyProvider: FC<any> = ({ children }) => {
    const [anomalies, setAnomalies] = useState<IAnomaly[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [anomaliesPerRow, setAnomaliesPerRow] = useState<string>("25");
    const [paginate, setPaginate] = useState<number>(0);

    const [anomaliesForAnnotation, setAnomaliesForAnnotation] = useState<IAnomaly[]>([]);
    const [loadingAnomaliesForAnnotation, setLoadingAnomaliesForAnnotation] = useState<boolean>(false);
    
    const [anomaliesCount, setAnomaliesCount] = useState<IAnomalyCount>({countAnnotated: 0, countNonAnnotated: 0, countFalse: 0, countTrue: 0, countReinserted: 0});
    const [loadingAnomaliesCount, setLoadingAnomaliesCount] = useState<boolean>(false);

    const [filter, setFilter] = useState<IAnomalyFilter>({
        initialDate: moment().toDate(),
        finalDate: moment().toDate(),
        branches: [],
        limit: 10,
    });

    const fetchAnomaliesList = async (_filter: IAnomalyFilter) => {
        setLoading(true);
        setAnomalies([]);

        const anomalies: IAnomaly[] = await fetchAnomalies(_filter);
        setAnomalies(anomalies);
        setFilter(_filter);

        setTimeout(() => {
            setAnomalies([...anomalies]);

            setPaginate(0);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 1000);

        return anomalies;
    };

    const fetchAnomaliesForAnnotation = async (_filter: IAnomalyFilter) => {
        setLoadingAnomaliesForAnnotation(true);

        const anomalies: IAnomaly[] | null = await getAnomaliesForAnnotation(_filter);
        
        setAnomaliesForAnnotation(anomalies);
        setFilter(_filter);

        setTimeout(() => {
            setAnomaliesForAnnotation([...anomalies]);

            setPaginate(0);
        }, 300);
        setTimeout(() => {
            setLoadingAnomaliesForAnnotation(false);
        }, 1000);
        return anomalies;
    };

    const fetchAnomaliesCount = async (_filter: IAnomalyFilter) => {
        setLoadingAnomaliesCount(true);
        let query: string = `?initial_date=${moment(_filter.initialDate).format("YYYY-MM-DD")}`;
        query += `&final_date=${moment(_filter.finalDate).format("YYYY-MM-DD")}`;
        if (_filter.branches && _filter.branches.length > 0)
            query += `&branches_id=${_filter.branches.map((item) => `'${item.id}'`).join(",")}`;

        const anomalyCount: IAnomalyCount | null = await getAnomaliesAnnotationCount(query);
       
        setFilter(_filter);

        setTimeout(() => {
            setAnomaliesCount(anomalyCount);
        }, 300);
        setTimeout(() => {
            setLoadingAnomaliesCount(false);
        }, 1000);

        return anomalyCount;
    };

    return (
        <AnomalyContext.Provider
            value={{
                anomalies,
                loading,
                paginate,
                setPaginate,
                anomaliesPerRow,
                setAnomaliesPerRow,
                fetchAnomaliesList,
                filter,
                setFilter,
                
                anomaliesForAnnotation,
                loadingAnomaliesForAnnotation,
                fetchAnomaliesForAnnotation,

                anomaliesCount,
                fetchAnomaliesCount,
                loadingAnomaliesCount,
            }}
        >
            {children}
        </AnomalyContext.Provider>
    );
};

export function useAnomalies() {
    const context = useContext(AnomalyContext);

    if (!context) {
        throw new Error("useAnomalies must be used within an AnomalyProvider");
    }

    return context;
}

