import { FC } from 'react';

import Grid from '@mui/material/Grid';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import PercentIcon from '@mui/icons-material/Percent';

import { DashboardChartCard, DashboardNumberCard } from '../../..';
import SlaughterDelayOcurrencesChart from './occurrencesChart';
import SlaughterDelayMainReasonsChart from './mainReasonsChart';
import SlaughterDelayMainLocationsChart from './mainLocationsChart';

import { useSlaughterDelays } from '../../../../hooks/slaughterDelays';
import { LargeText } from '../../../atoms/text';


const SlaughterDelaysDashboard: FC = () => {
    const {
        dashboardStats,
        loading,
        occurrencesData,
        mainReasons,
        mainLocations,
    } = useSlaughterDelays();

    return (
        <Grid container spacing={2} alignItems="center" minHeight='100%'>
            {!dashboardStats?.total ? 
            <Grid item xs={12}>
                <LargeText>
                    Nenhum dado disponível. Por favor, utilize o filtro acima para selecionar os dados desejados.
                </LargeText>
            </Grid>
            : <>
                <Grid item xs={12} md={4}>
                    <DashboardNumberCard
                        label={'Tempo Total de Atraso/Parada'}
                        value={(dashboardStats?.duration || 0) / 60}
                        size='medium'
                        icon={<HourglassBottomRoundedIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                        valueUnit='min'
                        loading={loading}
                        decimals={0}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardNumberCard
                        label={'Número de Ocorrências'}
                        value={dashboardStats?.total || 0}
                        size='medium'
                        icon={<FormatListNumberedIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardNumberCard
                        label={'Percentual de Motivos Registrados'}
                        value={dashboardStats?.reasonPercentage || 0}
                        size='medium'
                        icon={<PercentIcon style={{ width: '30px', height: '30px', color: 'white'}} />}
                        valueUnit='%'
                        loading={loading}
                        decimals={1}
                    />
                </Grid>
            </>}
            {occurrencesData && occurrencesData.length > 1 &&
                <Grid item xs={12}>
                    <DashboardChartCard
                        label='Visão Geral das Ocorrências'
                        size='medium'
                        help={'Gráfico da evolução das ocorrências ao longo do'
                            + ' tempo. Utilize o seletor na parte superior do'
                            + ' dashboard para alternar entre o número de'
                            + ' ocorrências e o tempo total de atraso/parada.'
                        }
                        chart={<SlaughterDelayOcurrencesChart />}
                        loading={loading}
                    />
                </Grid>
            }
            {mainReasons && mainReasons.occurrences.length > 0 &&
                <Grid item xs={12}>
                    <DashboardChartCard
                        label='Principais Motivos'
                        size='medium'
                        help={'Gráfico com os principais motivos registrados.'
                            + ' Ao clicar em uma das barras do gráfico, o'
                            + ' dashboard carregará as informações relativas'
                            + ' ao motivo em questão.'
                        }
                        chart={<SlaughterDelayMainReasonsChart />}
                        loading={loading}
                    />
                </Grid>
            }
            {mainLocations && mainLocations.occurrences.length > 0 &&
                <Grid item xs={12}>
                    <DashboardChartCard
                        label='Principais Locais'
                        size='medium'
                        help={'Gráfico com os principais locais registrados.'
                            + ' Ao clicar em uma das barras do gráfico, o'
                            + ' dashboard carregará as informações relativas'
                            + ' ao local em questão.'
                        }
                        chart={<SlaughterDelayMainLocationsChart />}
                        loading={loading}
                    />
                </Grid>
            }
        </Grid>
    );
}

export default SlaughterDelaysDashboard;