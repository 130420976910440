/* CRUDService
This is intended to be a facility for creating base CRUD methods, which
basically consist of fetching cloud server endpoints.

- baseURL: base URL to use for fetch purposes. Usually will start as
'/api_redsoft/v1_0/';
- name: used for error messages. Right now, the messages are in Portuguese;
- convertResponseToModel: method to convert API response;
*/

import { AxiosResponse } from "axios";
import api from ".";

const CRUDService = (
  {
    baseURL='/api_redsoft/v1_0/',
    name,
    convertResponseToModel,
  }: {
    baseURL: string;
    name?: string,
    convertResponseToModel?: (data: any) => any;
  }
) => {
  const crudCheckResponse = async (response: AxiosResponse, errorMessage: string) => {
    if (response && response.request) {
      const status = response.request.status;
      if (status >= 200 && status < 300) {
        if (!Array.isArray(response.data) && convertResponseToModel)
          return convertResponseToModel(response.data);
        return response.data;
      }
    };
    throw new Error(errorMessage);
  };

  const crudCreate = async (_payload: any) => {
    const response: AxiosResponse = await api.post(baseURL, _payload);
    const errorMessage = name ? `Falha ao criar novo(a) ${name}`
      : 'Falha ao criar dado';
    return crudCheckResponse(response, errorMessage);
  };

  const crudFetch = async (_id: string) => {
    const response: AxiosResponse = await api.get(`${baseURL}/${_id}`);
    const errorMessage = name ? `Falha ao buscar ${name}`
      : 'Falha ao buscar dado';
    return crudCheckResponse(response, errorMessage);
  };

  const crudFetchAll = async () => {
    const response: AxiosResponse = await api.get(baseURL);
    const errorMessage = name ? `Falha ao buscar ${name}(s)`
      : 'Falha ao buscar dados';
    return crudCheckResponse(response, errorMessage);
  };

  const crudUpdate = async (_id: string, _payload: any) => {
    const response: AxiosResponse = await api.put(`${baseURL}/${_id}`, _payload);
    const errorMessage = name ? `Falha ao atualizar ${name}`
      : 'Falha ao atualizar dado';
    return crudCheckResponse(response, errorMessage);
  };

  const crudDelete = async (_id: string) => {
    const response: AxiosResponse = await api.delete(`${baseURL}/${_id}`);
    const errorMessage = name ? `Falha ao excluir ${name}`
      : 'Falha ao excluir dado';
    return crudCheckResponse(response, errorMessage);
  };

  return {
    crudCreate,
    crudFetch,
    crudFetchAll,
    crudUpdate,
    crudDelete,
    crudCheckResponse
  };
};

export default CRUDService;
