import React, { FC, useEffect, useState } from "react";
import { Button, Grid, Input, Tooltip, Typography, useMediaQuery, Zoom } from "@mui/material";
import { Clear as ClearIcon } from '@mui/icons-material';

import { LoadingButton } from "@mui/lab";

import Container from "../../../atoms/container";
import Modal from "../../../atoms/modal";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { ILot } from "../../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../../../utils/consts";

let MAX_PHOTOS: number;
const maxLotPhotosString = process.env.MAX_LOT_PHOTOS || "7";
const parsedMaxPhotos = parseInt(maxLotPhotosString, 10);

if (!isNaN(parsedMaxPhotos)) {
    MAX_PHOTOS = parsedMaxPhotos;
} else {
    MAX_PHOTOS = 7;
}

interface ILotPhotoUploadModal {
    lot: ILot;
    setLot: any;
    open: boolean;
    onClose: () => void;
    uploadLotImage: (_lot: ILot, image: File) => Promise<string[]>;
}
const LotPhotoUploadModal: FC<ILotPhotoUploadModal> = (params: ILotPhotoUploadModal) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedImages, setSelectedImages] = useState<File[] | null>(null);
    const [uploadFinished, setUploadFinished] = useState<boolean>(false);

    useEffect(() => {
        setUploadFinished(false);
        setLoading(false);
        setSelectedImages(null);
    }, []);

    useEffect(() => {
        if(getNumberOfPhotos() >= MAX_PHOTOS){
            setError(`Esse lote atingiu o máximo de ${MAX_PHOTOS} fotos. Para inserir uma nova, exclua uma das existentes.`)
        }else {
            setError("");
        }
    },[params.lot.photos])

    useEffect(() => {
        if(selectedImages){
            if (selectedImages.length + getNumberOfPhotos() > MAX_PHOTOS) {
                setError(`Um lote não pode ter mais de ${MAX_PHOTOS} imagens. Este lote tem atualmente ${MAX_PHOTOS - getNumberOfPhotos()} espaços disponíveis.`)
            } else {
                setError("");
                setUploadFinished(false);
            }
        }
     },[selectedImages])

    const close = () => {
        setLoading(false);
        params.onClose();
        setSelectedImages(null);
        setUploadFinished(false);
    };

    const validate = () => {
        let isValid = true;
        if (!selectedImages) {
            setError("Por favor, selecione uma imagem");
            isValid = false;
        }

        if(isValid){
            setError("");
        }
        return isValid;
    };

    const onUpload = async () => {
        if (!validate())
            setLoading(true);

        if(selectedImages && selectedImages.length > 0){
            let images_urls: string[] = []
            for(let i = 0; i < selectedImages.length; i++){
                images_urls = await params.uploadLotImage(params.lot, selectedImages[i]); 
            }
            const new_lot:ILot = {...params.lot, photos: images_urls};
            params.setLot(new_lot);
        }

        setLoading(false);
        setUploadFinished(true);
        setSelectedImages(null);
    };

    const getNumberOfPhotos = () => {
        if(params.lot.photos){
            return params.lot.photos.length
        }
        return 0
    }

    const handleImageSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event.target.files;
        if (files) {
            const filesArray: File[] = Array.from(files)
            let imageFiles: File[] = filesArray.filter(file => file.type.startsWith('image/'));
            setSelectedImages(imageFiles);
        }
    };

    const truncateFileName = (fileName: string, maxLength: number): string => {
        if (fileName.length <= maxLength) {
            return fileName;
        } else {
            const ellipsisLength = 3;
            const leftPartLength = Math.floor((maxLength - ellipsisLength) / 2);
            const rightPartLength = Math.ceil((maxLength - ellipsisLength) / 2);
            const truncatedName = fileName.substring(0, leftPartLength) + '...' + fileName.substring(fileName.length - rightPartLength);

            return truncatedName;
        }
    };
    
    const removeSelectedImage = (index: number) => {
        setSelectedImages(prevImages => {
            if (prevImages){
                const newImages = prevImages.filter((_, i) => i !== index);
                return newImages;
            }
            return []
        });
    }

    return (
    <Modal isOpened={params.open} onClose={close}>
        <Container
            fluid
            color={theme.palette.background.default}
            hasShadow
            centered
            borderRadius={BORDER_RADIUS}
            maxWidth={540}
            minWidth={"25%"}
        >
            <Container
                inline
                color={theme.palette.grayLight.main}
                centered
                width="100%"
                borderTopRadius={BORDER_RADIUS}
            >
                <Container padded>
                <Text size={16} bold color={theme.palette.primary.light}>
                    Adicionar fotos do lote
                </Text>
                </Container>
            </Container>

            <Container fluid centered spacedAround veryPadded width="90%" >
                <Text>Insira suas imagens para upload abaixo</Text>
                <Spacing top={PADDING} />
                <Spacing top={VERY_PADDING} />

                <Grid container rowSpacing={2} alignItems="center">
                    <Grid item xs={6} alignItems="center">
                       <Text light> Selecione suas imagens: </Text> 
                    </Grid>
                    <Grid item xs={6} alignItems="center">
                        <Input id="image-input" type="file" sx={{ display: 'none' }} inputProps={{ "accept": "image/*", multiple: true }} onChange={handleImageSelection} value={''}/>
                        <label htmlFor="image-input" style={{ cursor: 'pointer', textAlign: "center" }}>
                            <Button variant="outlined" component="span" >
                                Selecionar imagens
                            </Button>
                        </label>
                    </Grid>
                    <Grid item xs={12}>
                        {selectedImages && selectedImages.length > 0 && (
                            <div>
                                <Typography variant="body2" color={theme.palette.primary.main}>Arquivos selecionados ({selectedImages.length}):</Typography>
                                <ul style={{paddingLeft: 0}}>
                                    {selectedImages.map((file, index) => (
                                        <Typography key={index} variant="body2" color="black" paddingTop={2} display="flex" alignItems="center" justifyContent="space-around">
                                            {truncateFileName(file.name, isSmallScreen ? 30 : 50)}
                                            <Tooltip title={`Remover imagem (${index + 1})`} placement="right" TransitionComponent={Zoom} TransitionProps={{timeout: 300}} arrow>
                                                <ClearIcon color="primary" onClick={() => removeSelectedImage(index)}
                                                    sx={{
                                                        cursor: "pointer",
                                                        borderRadius: "30px",
                                                        transition: "background-color 0.3s ease",
                                                        '&:hover': {
                                                            backgroundColor: theme.palette.grey[300]
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </Typography>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Spacing top={PADDING} />
                {error && (
                    <Grid item xs={12}>
                        <Text size={13} color={theme.palette.error.main} center>
                            {error}
                        </Text>
                    </Grid>
                )}
            </Container>

            <Container inline spacedAround veryPadded>
                <LoadingButton
                variant="outlined"
                color="primary"
                size="small"
                style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
                loading={loading}
                disabled={loading}
                onClick={close}
                >
                <Text
                    bold
                    size={12}
                    color={
                    loading ? theme.palette.grey[400] : theme.palette.primary.light
                    }
                >
                    Fechar
                </Text>
                </LoadingButton>
                <Spacing left={PADDING} />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                        opacity: loading ? 0.5 : 1,
                        borderColor: theme.palette.primary.light,
                        width: 150,
                        height: 35,
                    }}
                    loading={loading}
                    disabled={loading || error !== ""}
                    onClick={() => onUpload()}
                >
                    <Text bold size={12} color={theme.palette.background.paper}>
                        Confirmar
                    </Text>
                </LoadingButton>
            </Container>
            {loading &&
                <Container fluid flex centered padded>
                    <Text medium size={12} color={theme.palette.grey[400]}>Fazendo upload da imagem.</Text>
                </Container>
            }
            {uploadFinished &&
                <>
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.success.main}>Upload bem sucedido!</Text>
                    </Container>
                    <Spacing bottom={PADDING} />

                </>
            }
        </Container>
        
            
    </Modal>
    );
};

export default LotPhotoUploadModal;
