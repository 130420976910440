
import React, { FC, createContext, useContext, useState } from "react";

import {
    getCarcassesPops,
} from "../services/carcassesPops";
import { IPop, ICarcassesPopContext } from "../types";

const CarcassesPopsContext = createContext<ICarcassesPopContext>({} as ICarcassesPopContext);

export const CarcassesPopsProvider: FC<any> = ({ children }) => {
    const [pops, setPops] = useState<IPop[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchPops = async (branches: string[]) => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 5000);

        try {
            let pops: IPop[] = []
            if (branches.length > 0) {
                pops = await getCarcassesPops(branches);
            } else {
                pops = await getCarcassesPops([]);
            }
            setPops([...pops]);
        } catch (_err) {
            console.log(_err);
        }
        setLoading(false);
    };
    return (
        <CarcassesPopsContext.Provider
            value={{
                pops,
                fetchPops,
                loading,
            }}
        >
            {children}
        </CarcassesPopsContext.Provider>
    );
};

export function useCarcassesPops() {
    const context = useContext(CarcassesPopsContext);

    if (!context) {
        throw new Error(
            "useCarcassesPops must be used within an CarcassesPopsProvider",
        );
    }

    return context;
}
