import React, { FC, useEffect } from 'react';
import {
    IAlert,
    ICRUDContext,
} from '../../../types';

import { useAlerts } from '../../../hooks/alert';
import { useAlertConstants } from '../../../hooks/alertConstants';
import { generateCRUDTable } from '../../../components';
import { useBranches } from '../../../hooks/branches';
import { useAlertProviders } from '../../../hooks/alertProviders';
import { getAllAlertsWithProvidersIds } from '../../../services/alerts';


const AlertsCRUDTable: FC = () => {
    const { branches, fetchBranches } = useBranches();
    const { levels, sources, comparisons, fetchAlertConstants } = useAlertConstants()
    const { providers, fetchAlertProviders } = useAlertProviders();

    useEffect(() => {
        fetchBranches();
        fetchAlertProviders();
        fetchAlertConstants();
    }, []);

    const crudTable = generateCRUDTable<IAlert, {}>(
        {
            columns: [
                {
                    name: 'branchId',
                    title: 'Filial',
                    options: branches.map((item) => ({ id: item.id!, label: item.name })),
                    required: true,
                },
                {
                    name: 'title',
                    title: 'Título',
                    required: true,
                    max: 50,
                },
                {
                    name: 'description',
                    title: 'Descrição',
                    required: false,
                    hideColumn: true,
                    max: 400,
                },
                {
                    name: 'level',
                    title: 'Nível',
                    required: true,
                    type: 'integer',
                    options: levels.map((item) => ({ id: item.value!, label: item.description })),
                },
                {
                    name: 'source',
                    title: 'Parâmetro',
                    required: true,
                    type: 'integer',
                    options: sources.map((item) => ({ id: item.value!, label: item.description })),
                },
                {
                    name: 'comparison',
                    title: 'Comparador',
                    required: true,
                    type: 'integer',
                    options: comparisons.map((item) => ({ id: item.value!, label: item.description })),
                    hideColumn: true,
                },
                {
                    name: 'value',
                    title: 'Valor',
                    required: true,
                    type: 'float',
                },
                {
                    name: 'activationCarcassesCount',
                    title: '# Meias-Carcaças Ativação',
                    required: true,
                    type: 'integer',
                },
                {
                    name: 'deactivationCarcassesCount',
                    title: '# Meias-Carcaças Desativação',
                    required: true,
                    type: 'integer',
                    hideColumn: true,
                },
                {
                    name: 'priority',
                    title: 'Prioridade',
                    required: true,
                    type: 'integer',
                },
                {
                    name: 'providers',
                    title: 'Provedores',
                    options: providers.map((item) => ({ id: item.id!, label: item.name })),
                    multiselect: true,
                    hideColumn: true,
                },
            ],
            name: 'alerta',
            defaultSort: 'title',
            entryDescriptor: 'title',
            defaultNewEntry: {
                branchId: '',
                title: '',
                description: '',
                level: levels && levels.length > 0 ? levels[0].value : 10,
                activationCarcassesCount: 0,
                deactivationCarcassesCount: 0,
                source: sources && sources.length > 0 ? sources[0].value : 1,
                comparison: comparisons && comparisons.length > 0 ? comparisons[0].value : 1,
                value: 90,
                priority: 1,
                providers: [],
            },
            getHook: useAlerts,
            fetchAllEntries: async (hook?: ICRUDContext<IAlert>, params?: {}) => {
                return await getAllAlertsWithProvidersIds();
            }
        }
    );

    return crudTable({});
};

export default React.memo(AlertsCRUDTable);
