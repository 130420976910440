import { FC, useEffect, useRef } from 'react';
import theme from '../../../../theme';

import { ICarcass } from '../../../../../types';
import { ICarcassesPopBoundingBox } from '../../../../../types/hooks/carcassesPops';
import { PopRelatedPhoto } from '../../../../../utils/pops';
import {
    usePopVisualizationBoxes
} from '../../../../../hooks/popVisualizationBoxes';


interface Props {
    photos: any;
    carcass: ICarcass;
    selectedView: string;
    isLeft: boolean;
}


/* Helper to get photo type from view and side */
export function getPhotoType(selectedView: string, isLeft: boolean) {
    if (selectedView === 'identifier') // Etiqueta (hash 2) = 2
        return PopRelatedPhoto.OCR;
    else if (selectedView === 'identifier_hash_1') // Etiqueta Hash 1 = 3
        return PopRelatedPhoto.OCR_HASH_1;
    else if (isLeft) // External = 1
        return PopRelatedPhoto.EXTERNAL;
    return PopRelatedPhoto.INTERNAL; // Internal = 1
};

/* Displays carcass images and has a selector to toggle between Side A,
    Side B or identifier pictures.
*/
export const CarcassPopsSingleImage: FC<Props> = ({
    photos,
    carcass,
    selectedView,
    isLeft,
}: Props) => {
    /*
     * photos:                  photos uri
     * carcass:                 carcass object related to the photo
     * selectedView:            says which tab is selected right now
     * isLeft:                    says wether its the left or right image
     * disabledBoundingBoxes:   array that contains the ids of pops which had their bouding boxes disabled
     * */

    // Initialize state to keep track of bounding box visibility
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const photoType = getPhotoType(selectedView, isLeft);
    const {
        isPopDisabled,
        disabledBoundingBoxes,
        isLabelActive,
    } = usePopVisualizationBoxes();

    let src = photos.internal;
    let carcassPops = carcass.popsA;
    let desiredWidth = 340;
    // Checks for Side A and Side B tabs
    if (selectedView === 'identifier' || selectedView === 'identifier_hash_1') {
        src = isLeft ? photos.external : photos.internal
        carcassPops = isLeft ? carcass.popsA : carcass.popsB

        desiredWidth = 400;
    } else {
        // It controls which bounding box will render the sideA or sideB ones
        if (selectedView === 'sideB') {
            carcassPops = carcass.popsB;
        }

        // It controls wether is a internal picture or not
        if (isLeft) {
            src = photos.external;
        }
    }


    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        if (!!canvas && !!ctx) {
            const image = new Image();
            image.src = src;
            image.onload = () => {
                const imageRatio = image.width/image.height;
                if (imageRatio < 2/5){
                    desiredWidth = 300
                }else if (Math.abs(imageRatio - 1) < 0.1){
                    desiredWidth = 450
                }

                // Ratio = currentWidth / desiredWidth
                const ratio = image.width/desiredWidth;

                canvas.width = image.width / ratio;
                canvas.height = image.height / ratio;
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                if (carcassPops) {
                    carcassPops.forEach((pop) => {
                        if (pop.boundingBoxes && pop.relatedPhoto === photoType
                            && pop.id && !isPopDisabled(pop.popType!)) {
                            pop.boundingBoxes.forEach((box: ICarcassesPopBoundingBox) => {
                                const { x1, y1, x2, y2 } = box;
                                const width = x2 - x1;
                                const height = y2 - y1;

                                let strokeColor = theme.palette.success.dark;
                                // Inadequate or POP Count
                                if (pop.classification !== 0 || (pop.count && pop.count > 0)) {
                                    strokeColor = theme.palette.error.dark;
                                }
                                ctx.strokeStyle = strokeColor;

                                // Drawing Bounding Box
                                ctx.lineWidth = 2;
                                ctx.strokeRect(x1 * canvas.width, y1 * canvas.height, width * canvas.width, height * canvas.height);

                                // Drawing label
                                if (isLabelActive) {
                                    // calculate label box position and size
                                    let labelBoxWidth = ctx.measureText(pop.description).width + 10;
                                    const labelBoxHeight = 20;
                                    let labelBoxX = (x1 * canvas.width) - 2;
                                    let labelBoxY = (y1 * canvas.height) - 20;

                                    // Calculate label text position
                                    let labelText = pop.description;
                                    let labelTextX = labelBoxX + 2;
                                    let labelTextY = labelBoxY + 16;

                                    // Check if the label box fits within the bouding box and canvas
                                    // if (labelBoxX + labelBoxWidth > x2 * canvas.width || labelBoxY + labelBoxHeight > y2 * canvas.height) {
                                    if (labelBoxX + labelBoxWidth > canvas.width) {
                                        // Adjust position
                                        ctx.font = '10px Arial';
                                        labelBoxWidth = -labelBoxWidth;
                                        labelBoxX = x2 * canvas.width + 1;
                                        labelTextX = labelBoxX + labelBoxWidth + 4;
                                    }
                                    // Label box
                                    ctx.fillStyle = strokeColor;
                                    ctx.fillRect(labelBoxX, labelBoxY, labelBoxWidth, labelBoxHeight);

                                    // Label text
                                    ctx.fillStyle = '#fff';
                                    ctx.font = '12px Arial';
                                    ctx.fillText(labelText, labelTextX, labelTextY);
                                }
                            });
                        }
                    });
                }
            };
        };
    }, [src, carcassPops, disabledBoundingBoxes, isLabelActive]);

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <canvas ref={canvasRef} data-src={src}></canvas>
        </div>
    );
}
