import { IEntity } from "./entities";
import { IBranch } from "./branches";
import { IMultiselect } from "../components/atoms/autocomplete";


interface ISlaughterDelaysSettings {
  maxDelay: number;
};

interface ISlaughterDelaysSettingsContext extends ISlaughterDelaysSettings {
  fetchData: () => void;
  updateData: (data: ISlaughterDelaysSettings) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setMaxDelay: (val: number) => void;
};

interface ISlaughterDelaysReason extends IEntity {
  branchId: string;
  code: string;
  description: string;
};

interface ISlaughterDelaysReasonSelect extends IMultiselect {
  branches?: string[];
};
type ISlaughterDelaysReasonLocationSelect = ISlaughterDelaysReasonSelect;

export const SlaughterDelayType = {
  delay: {
      value: 1,
      label: 'Atraso'
  },
  stop: {
      value: 2,
      label: 'Parada'
  },
};

interface ISlaughterDelay extends IEntity {
  delayType?: number;
  startTime: string;
  duration?: number;
  reasonId?: string;
  locationId?: string;
};

interface ISlaughterDelaysFilterErrors {
  fetch?: string;
  branch?: string;
  finalDate?: string;
  reasons?: string;
  locations?: string;
};

interface ISlaughterDelaysFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  delayType: number | null;
  branch: IBranch | null;
  reasons: ISlaughterDelaysReason[];
  locations: ISlaughterDelaysReason[];
};

interface ISlaughterDelaysFilterContext extends ISlaughterDelaysFilter {
  branchesExpanded: boolean;
  errors: ISlaughterDelaysFilterErrors;
  setInitialDate: (val: Date | null) => void;
  setFinalDate: (val: Date | null) => void;
  setDelayType: (val: number | null) => void;
  setBranchesExpanded: (val: boolean) => void;
  setBranch: (val: IBranch | null) => void;
  setErrors: (val: ISlaughterDelaysFilterErrors) => void;
  setReasons: (val: ISlaughterDelaysReason[]) => void;
  setLocations: (val: ISlaughterDelaysReason[]) => void;
  clear: () => void;
  validate: () => boolean;
  buildQuery: (filterParams?: Partial<ISlaughterDelaysFilter>) => string;
};

interface ISlaughterDelaysOccurrencesData {
  label: string;
  occurrences: number;
  duration: number;
};

interface ISlaughterDelaysMainCausesEntry {
  id: string;
  code: string;
  description: string;
  value: number;
};

interface ISlaughterDelaysMainCausesData {
  duration: ISlaughterDelaysMainCausesEntry[];
  occurrences: ISlaughterDelaysMainCausesEntry[];
};

interface ISlaughterDelaysContext {
  dashboardStats: ISlaughterDelaysDashboardStats | null;
  loading: boolean;
  groupByDuration: boolean;
  occurrencesData: ISlaughterDelaysOccurrencesData[];
  mainLocations: ISlaughterDelaysMainCausesData;
  mainReasons: ISlaughterDelaysMainCausesData;
  setLoading: (val: boolean) => void;
  setGroupByDuration: (val: boolean) => void;
  downloadSpreadsheet: () => void;
  fetchDashboard: (filterParams?: Partial<ISlaughterDelaysFilter>) => void;
};

interface ISlaughterDelaysPopupContextErrors {
  fetch?: string,
  location?: string,
  reason?: string,
};

interface ISlaughterDelaysPopupContext {
  entry?: ISlaughterDelay;
  setEntry: (val: ISlaughterDelay | undefined) => void;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  errors: ISlaughterDelaysPopupContextErrors;
  setErrors: (val: ISlaughterDelaysPopupContextErrors) => void;
  validate: (val?: ISlaughterDelay) => boolean;
  apply: () => void;
  loading: boolean;
};

interface ISlaughterDelaysDashboardStats {
  total: number;
  duration: number;
  reasonPercentage: number;
};

export type {
  ISlaughterDelay,
  ISlaughterDelaysContext,
  ISlaughterDelaysDashboardStats,
  ISlaughterDelaysFilter,
  ISlaughterDelaysFilterContext,
  ISlaughterDelaysFilterErrors,
  ISlaughterDelaysMainCausesData,
  ISlaughterDelaysMainCausesEntry,
  ISlaughterDelaysOccurrencesData,
  ISlaughterDelaysPopupContext,
  ISlaughterDelaysPopupContextErrors,
  ISlaughterDelaysReason,
  ISlaughterDelaysReasonLocationSelect,
  ISlaughterDelaysReasonSelect,
  ISlaughterDelaysSettings,
  ISlaughterDelaysSettingsContext,
};
