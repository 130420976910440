import { FC } from 'react';

import { ISlaughterDelaysMainCausesEntry } from '../../../../types/hooks/slaughterDelays';
import { useSlaughterDelays } from '../../../../hooks/slaughterDelays';
import { useSlaughterDelaysFilter } from '../../../../hooks/slaughterDelaysFilter';
import SlaughterDelayMainCausesChart from './mainCausesChart';


const SlaughterDelayMainReasonsChart: FC = () => {
    const {
        groupByDuration,
        mainReasons,
        fetchDashboard,
    } = useSlaughterDelays();
    const { setReasons, branch } = useSlaughterDelaysFilter();

    const data = (groupByDuration ?
        mainReasons.duration : mainReasons.occurrences
    );

    function handleOnClick(value: ISlaughterDelaysMainCausesEntry) {
        const newReasons = [{
            branchId: branch?.id || '',
            id: value.id,
            code: value.code,
            description: value.description,
        }];
        setReasons(newReasons);
        fetchDashboard({reasons: newReasons});
    };

    return <SlaughterDelayMainCausesChart
        data={data}
        onClick={handleOnClick}
    />;
}

export default SlaughterDelayMainReasonsChart;
