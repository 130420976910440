import React, { FC, createContext, useContext, useState } from "react";

import { getHashCount } from "../services/hashCount";
import {
  IHashCount,
  IHashCountContext,
  IHashCountFilter,
} from "../types";

const HashCountContext = createContext<IHashCountContext>(
  {} as IHashCountContext,
);

export const HashCountProvider: FC<any> = ({ children }) => {
  const [hashCount, setHashCount] = useState<IHashCount | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [filter, setFilter] = useState<IHashCountFilter>({
    date: new Date(),
    branchId: '',
  });

  const fetchHashCount = async () => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoading(false);
      }, 20000);
      const hashCount: IHashCount = await getHashCount(filter.branchId, filter.date);
      setHashCount(hashCount);
    } catch (_err) {
      console.log(_err);
    }
    setLoading(false);
  };

  return (
    <HashCountContext.Provider
      value={{
        hashCount,
        setHashCount,
        loading,
        setLoading,
        fetchHashCount,
        filter,
        setFilter,
      }}
    >
      {children}
    </HashCountContext.Provider>
  );
};

export function useHashCount() {
  const context = useContext(HashCountContext);

  if (!context) {
    throw new Error("useHashCount must be used within an HashCountProvider");
  }

  return context;
}
