import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { CarcassDeleteModal, CarcassDownloadModal, CarcassDownloadSheetModal, CarcassesFilter, Container, Page, Spacing, theme } from '../../components';
import { useAuth } from '../../hooks/auth';
import { useInformation } from '../../hooks/information';
import { useCarcass } from '../../hooks/carcasses';
import { PADDING } from '../../utils/consts'
import { ICarcassFilterParams, ICarcass } from '../../types';
import CarcassesList from '../../components/molecules/carcasses/list';
import CarcassDetailedItem from '../../components/molecules/carcasses/carcassDetailedItem';
import { Title1, BodyText } from '../../components/atoms/text';
import CarcassContextMenu from '../../components/molecules/carcasses/carcassContextMenu';


const Carcasses: FC = () => {
    const { hasRights } = useAuth()
    const { showInformation } = useInformation();
    const { fetchCarcasses, loading, carcasses } = useCarcass();

    const [currCarcass, setCurrCarcass] = useState<string>('');  // Current carcass on detail
    const [importing, setImporting] = useState<boolean>(false);
    const [downloadIsOpen, setDownloadIsOpen] = useState<boolean>(false);
    const [downloadCarcassSheetIsOpen,  setDownloadCarcassSheetIsOpen] = useState<boolean>(false);
    const [deleteCarcassesIsOpen, setDeleteCarcassesIsOpen] = useState<boolean>(false);
    const [filteredCarcasses, setFilteredCarcasses] = useState<ICarcass[]>([]);

    useEffect(() => {
        setCurrCarcass(carcasses.length > 0 ? carcasses[0].id : '');
    }, [carcasses]);

    const carcassesIds = filteredCarcasses.map((item) => {
        let value: string | number = '';
        if(item.partnerId && !item.partnerId.includes('SV'))
            value = item.partnerId;
        else if(item.sequence)
            value = item.sequence;
        else if (item.partnerId) {
            value = item.partnerId;
        }
        return {key: item.id, value: value}
    });

    if (currCarcass && !filteredCarcasses.map(item => item.id).includes(currCarcass)) {
        setCurrCarcass(filteredCarcasses.length > 0 ? filteredCarcasses[0].id : '');
    }

    const onApplyFilter = async (filter: ICarcassFilterParams) => {
        fetchCarcasses(filter);
    }

    return (
        <Page width='auto'>
            <Container fluid flex color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline style={{
                    flex: '0',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Carcaças</Title1>
                        <BodyText muted>Animais / Carcaças</BodyText>
                    </Container>
                    <Container inline fluid style={{ flexGrow: '0.2' }}>
                        <CarcassesFilter onApply={onApplyFilter} loading={loading}/>
                        <CarcassContextMenu
                            loading={loading}
                            importing={importing}
                            setImporting={setImporting}
                            downloadIsOpen={downloadIsOpen}
                            setDownloadIsOpen={setDownloadIsOpen}
                            downloadCarcassSheetIsOpen={downloadCarcassSheetIsOpen}
                            setDownloadCarcassSheetIsOpen={setDownloadCarcassSheetIsOpen}
                            deleteCarcassesIsOpen={deleteCarcassesIsOpen}
                            setDeleteCarcassesIsOpen={setDeleteCarcassesIsOpen}
                            showInformation={showInformation}
                            hasRights={hasRights!}
                        />
                    </Container>
                </Container>
                <Spacing top={PADDING * 2} />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} xl={3}>
                        <CarcassesList
                            carcasses={carcasses}
                            currCarcass={currCarcass}
                            setCurrCarcass={setCurrCarcass}
                            loading={loading}
                            filteredCarcasses={filteredCarcasses}
                            setFilteredCarcasses={setFilteredCarcasses}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8} xl={9} sx={{ md: {minHeight: '100%'}}}>
                        <CarcassDetailedItem
                            carcass={carcasses.find(item => item.id === currCarcass)}
                            loading={loading}
                            carcassesIds={carcassesIds}
                            setCurrCarcass={setCurrCarcass}
                        />
                    </Grid>
                </Grid>
            </Container>

            <CarcassDownloadModal
                open={downloadIsOpen}
                onClose={() => setDownloadIsOpen(!downloadIsOpen)}
            />

            <CarcassDownloadSheetModal
                open={downloadCarcassSheetIsOpen}
                onClose={() => setDownloadCarcassSheetIsOpen(!downloadCarcassSheetIsOpen)}
            />
            
            <CarcassDeleteModal
                open={deleteCarcassesIsOpen}
                onClose={() => setDeleteCarcassesIsOpen(!deleteCarcassesIsOpen)}
            />

        </Page >
    )
}

export default Carcasses;
