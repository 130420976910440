import React, { FC, useEffect, useRef, useState } from "react";
import { Checkbox, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

import BranchSelect from "../../branches/select";
import CarcassesLocalClassificationsMultiSelect from '../../carcassesLocalClassifications/multiSelect';
import CarcassClassificationsMultiSelect from "../../carcasses/classificationsMultiSelect";
import Container from "../../../atoms/container";
import DateRangePicker from "../../../atoms/dateRangePicker";
import Modal from "../../../atoms/modal";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useBranches } from "../../../../hooks/branches";
import { useInformation } from "../../../../hooks/information";
import {
  ICarcassLocalClassification,
  ILabelId,
  IRevisionAnnotationsFromIA
} from "../../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../../../utils/consts";
import api from '../../../../services';
import { useAuth } from '../../../../hooks/auth';

const RevisionAnnotationsFromIA: FC<IRevisionAnnotationsFromIA> = (
  params: IRevisionAnnotationsFromIA,
) => {
  const { user } = useAuth();
  const { showInformation } = useInformation();
  const { fetchBranches } = useBranches();
  const refDownload = useRef<HTMLAnchorElement | null>(null);

  const [initialDate, setInitialDate] = useState<Date | null>();
  const [finalDate, setFinalDate] = useState<Date | null>();
  const [branchId, setBranchId] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [branchError, setBranchError] = useState<string | undefined>(
    undefined
  );

  const [onlyIAClassification, setOnlyIAClassification] = useState<boolean>(true);
  const [onlyLocalClassification, setOnlyLocalClassification] = useState<boolean>(false);
  const [selectedClassificationsIA, setSelectedClassificationsIA] = useState<ILabelId[]>([]);
  const [selectedClassificationsLocal, setSelectedClassificationsLocal] = useState<ICarcassLocalClassification[]>([]);
  const [classificationsLocalExpanded, setClassificationsLocalExpanded] = useState<boolean>(false);
  const [classificationsIAExpanded, setClassificationsIAExpanded] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const [urlDownload, setUrlDownload] = useState<string>("");
  const [fileNameDownload, setFileNameDownload] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentIndexDownload, setCurrentIndexDownload] = useState<number>(0);
  const [totalDownload, setTotalDownload] = useState<number>(0);
  const [downloading, setDownloading] = useState<boolean>(false);
  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    if (classificationsLocalExpanded) {
        setClassificationsIAExpanded(false);
    }
  }, [classificationsLocalExpanded]);

  useEffect(() => {
    if (classificationsIAExpanded) {
      setClassificationsLocalExpanded(false);
    }
  }, [classificationsIAExpanded]);

  const handleChangeBranch = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    if ((event as any).target) {
      setBranchId((event as any).target.value);
      setBranchName("");
    } else {
      if ((event as any).id) {
        setBranchId((event as any).id);
      }
      if ((event as any).label) {
        setBranchName((event as any).label);
      }
    }
    setBranchError(undefined);
    setError(null);
  };

  const validate = () => {
    let isValid: boolean = true;

    if (selectedClassificationsLocal.length === 0 && selectedClassificationsIA.length === 0) {
      showInformation('Favor informar um acabamento.', 'Atenção');
      isValid = false;
  }

    if (!initialDate || !finalDate) {
      setError("Favor informar a data");
      isValid = false;
    }

    if (branchId == "") {
      setBranchError("Favor informar a empresa");
      isValid = false;
    }

    if (isValid) {
      setError("");
      setBranchError(undefined);
    }
    return isValid;
  };

  const onCreate = async () => {
    if (validate() == false) return;

    setLoading(true);
    try {
      let filter = "";
      if (initialDate)
        filter += `?initial_date=${moment(initialDate).format(
          "YYYY-MM-DD"
        )}`;
      if (finalDate)
        filter +=
          (filter != "" ? "&" : "?") +
          `final_date=${moment(finalDate).format("YYYY-MM-DD")}`;
      if (branchId)
        filter +=
          (filter != "" ? "&" : "?") +
          `branches_id=${branchId}`;

      filter +=
        (filter != "" ? "&" : "?") +
        `ia_filter=${onlyIAClassification}`;
      if(onlyIAClassification)
        filter +=
          (filter != "" ? "&" : "?") +
          `classifications_id=${selectedClassificationsIA.map(item => `'${item.id!}'`).join(',')}`;
      else if(onlyLocalClassification)
          filter +=
            (filter != "" ? "&" : "?") +
            `classifications_id=${selectedClassificationsLocal.map(item => `'${item.localClassification!}'`).join(',')}`;
      
      const response: any = await api.get(`/api_redsoft/v1_0/annotations/create_annotations_from_ia${filter}`);
    
      if (response && response.request) {
        const status = response.request.status;
        if (status == 200) {            
          showInformation(`Anotações criadas com sucesso: ${response.data.count} de ${response.data.count + response.data.non_registered} anotações feitas`, 'Aviso');
        }
        else {
          showInformation(`Erro: ${status}`, 'Aviso');
        }
        setLoading(false);
      }
    } catch (err) {
        showInformation((err as any).message, 'Aviso');
        setLoading(false);
    }
}
  const close = () => {
    cleanData();

    params.onClose();
  };

  const cleanData = () => {
    setInitialDate(new Date());
    setFinalDate(new Date());
    setBranchId("");
    setBranchName("");
    setBranchError(undefined);
    setError(null);
    setUrlDownload("");
    setFileNameDownload("");
    setLoading(false);
  };

  const toggleClassificationCheckbox = () => {
    setOnlyLocalClassification(!onlyLocalClassification); 
    setOnlyIAClassification(!onlyIAClassification);
  };

  return (
    <Modal isOpened={params.open} onClose={close}>
      <a
        href={urlDownload}
        download={fileNameDownload}
        className="hidden"
        ref={refDownload}
      />

      <Container
        fluid
        color={theme.palette.background.default}
        hasShadow
        centered
        borderRadius={BORDER_RADIUS}
        style={{ maxWidth: 540 }}
      >
        <Container
          inline
          color={theme.palette.grayLight.main}
          centered
          width="100%"
          borderTopRadius={BORDER_RADIUS}
        >
          <Container padded>
            <Text size={16} bold color={theme.palette.primary.light}>
              Criar anotações
            </Text>
          </Container>
        </Container>

        <Container fluid centered spacedAround veryPadded width="90%">
          <Text>Preencha o filtro para efetuar a criação.</Text>
          <Spacing top={PADDING} />
          <Text light size={13} center={true}>
            Esse processo pode demorar um pouco dependendo da quantidade de
            carcaças. <br /> <br />
            Lembre-se, muitas vezes não temos os dados de acabamento local, isso pode afetar o funcionamento da funcionalidade.
          </Text>
          <Spacing top={VERY_PADDING} />
          
          <Grid container rowSpacing={2} alignItems="center">

            <Grid item xs={6}>
                <Checkbox size="small" checked={onlyIAClassification} onClick={toggleClassificationCheckbox} />
                <Text size={14}>Acabamento IA</Text>
            </Grid>
            <Grid item xs={6}>
                <Checkbox size="small" checked={onlyLocalClassification} onClick={toggleClassificationCheckbox} />
                <Text size={14}>Acabamento Local</Text>
            
            </Grid> 

            <Grid item xs={4}>
              <Text light>Data:</Text>
            </Grid>
            <Grid item xs={8}>
              <DateRangePicker
                initialDate={initialDate}
                finalDate={finalDate}
                placeholder={"Escolha intervalo de data"}
                setInitialDate={setInitialDate}
                setFinalDate={setFinalDate}
                disabled={loading}
                //maxDaysSelect={7}
              />
            </Grid>
            <Grid item xs={4}>
              <Text light>Frigorífico:</Text>
            </Grid>
            <Grid item xs={8}>
              <BranchSelect
                value={branchId}
                valueName={branchName}
                handleChangeValue={handleChangeBranch}
                variant="outlined"
                placeholder="Escolha o frigorífico"
                error={branchError}
              />
            </Grid>

            <Grid item xs={4}>
              { onlyIAClassification && <Text light>Acabamento IA:</Text>}
              { onlyLocalClassification && <Text light>Acabamento Local:</Text>}
            </Grid>
            <Grid item xs={8}>
              {
                onlyLocalClassification && 
                <CarcassesLocalClassificationsMultiSelect
                  placeholder="Acabamento local"
                  selectedBranchesId={[branchId]}
                  selectedClassifications={selectedClassificationsLocal}
                  setSelectedClassifications={setSelectedClassificationsLocal}
                  singleSelect={false}
                  expanded={classificationsLocalExpanded}
                  setExpanded={setClassificationsLocalExpanded}
                  sx={{
                      backgroundColor: '#FFF',
                      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                      borderRadius: '4px',
                      height: '37px',
                      marginTop: -5
                  }}
                />
              }
              {
                onlyIAClassification &&
                <CarcassClassificationsMultiSelect
                    placeholder="Acabamento IA"
                    selectedItems={selectedClassificationsIA}
                    setSelectedItems={setSelectedClassificationsIA}
                    expanded={classificationsIAExpanded}
                    setExpanded={setClassificationsIAExpanded}
                    singleSelect={false}
                    sx={{
                        backgroundColor: '#FFF',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                        borderRadius: '4px',
                        height: '37px',
                        marginTop: -5
                    }}
                />
              }
            </Grid>
          </Grid>
        </Container>

        {error && (
          <Text size={13} color={theme.palette.error.main}>
            Erro: {error}
          </Text>
        )}
        <Container inline spacedAround veryPadded>
          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
            loading={loading}
            disabled={loading}
            onClick={close}
          >
            <Text
              bold
              size={12}
              color={
                loading ? theme.palette.grey[400] : theme.palette.primary.light
              }
            >
              Fechar
            </Text>
          </LoadingButton>
          <Spacing left={PADDING} />
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            style={{
              opacity: loading ? 0.5 : 1,
              borderColor: theme.palette.primary.light,
              width: 150,
              height: 35,
            }}
            loading={loading}
            disabled={loading}
            onClick={() => onCreate()}
          >
            <Text bold size={12} color={theme.palette.background.paper}>
              Confirmar
            </Text>
          </LoadingButton>
        </Container>
      
        {loading && currentIndexDownload > 0 &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Baixando {currentIndexDownload} de {totalDownload}</Text>
                    </Container>
                }
                {currentIndexDownload > 0 && currentIndexDownload == totalDownload || downloading &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Preparando arquivo para efetuar o download</Text>
                    </Container>
                }
            </Container>
    </Modal>
  );
};

export default RevisionAnnotationsFromIA;
