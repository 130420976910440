import React, { FC, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import RuleIcon from '@mui/icons-material/Rule';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { ReactComponent as CowIcon } from "../../../../assets/icons/animal_cow.svg";

import DashboardNumberCard from '../../dashboard/numberCard';
import { DashboardChartCard } from '../../..';
import PopDashboardBarChart from './charts/barChart';
import PopDashboardTimeSeriesChart from './charts/timeSeriesChart';
import { LargeText } from '../../../atoms/text';

import { usePopDashboard } from '../../../../hooks/popDashboard';
import { useCarcassesPops } from '../../../../hooks/carcassesPops';


const PopDashboardGrid: FC<any> = () => {
    const hook = usePopDashboard();
    const { pops, fetchPops } = useCarcassesPops();

    useEffect(() => {
        if (!hook.data && !hook.loading) {
            hook.fetchDashboardData();
            if (!pops || pops.length === 0)
                fetchPops([]);
        }
    }, []);

    if (!hook.data)
        return <Stack spacing={4} alignItems='center' padding={2}>
            <LargeText muted>
                {hook.loading ?
                    "Por favor, aguarde enquanto carregamos os dados..."
                    : "Utilize o filtro acima para gerar o dashboard de POPs"
                }
            </LargeText>
            {hook.loading && <CircularProgress />}
        </Stack>;

    return (
        <Grid container rowSpacing={2} columnSpacing={3} alignItems="center" >
            <Grid item xs={12} sm={6} md={4}>
                <DashboardNumberCard
                    label={'Quantidade de Processos Avaliados'}
                    value={hook.data?.total}
                    size='medium'
                    icon={<RuleIcon style={{width: '32px', height: '32px', color: 'white'}} />}
                    help={`Quantidade total de processos operacionais avaliados considerando os filtros aplicados`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DashboardNumberCard
                    label={'Taxa Média de Conformidade'}
                    value={hook.data?.total > 0
                        ? parseInt((100 * hook.data.totalAdequate / hook.data.total).toFixed(0))
                        : 0
                    }
                    valueUnit='%'
                    size='medium'
                    icon={<DoneAllIcon style={{width: '32px', height: '32px', color: 'white'}} />}
                    help={`Razão de processos avaliados como adequados em relação ao total de processos`}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DashboardNumberCard
                    label={'Quantidade de Meias-Carcaças Avaliadas'}
                    value={hook.data?.totalHalfAnimals}
                    size='medium'
                    icon={<CowIcon style={{scale: '0.85', color: 'white'}} />}
                    help={`Total de meias-carcaças (2 por animal) avaliadas considerando os filtros aplicados`}
                />
            </Grid>
            {hook.data?.countPops && Object.keys(hook.data.countPops).map((key) => {
                const pop = pops?.find(pop => pop.name === key);
                return <Grid key={key} item xs={12} sm={6} md={4}>
                    <DashboardNumberCard
                        label={pop?.description || key}
                        value={hook.data?.countPops[key]}
                        size='medium'
                        icon={<SearchOffIcon style={{width: '32px', height: '32px', color: 'white'}} />}
                        help={`Total de ocorrências do processo operacional "${pop?.description || key}"`}
                    />
                </Grid>
            })}
            {hook.data?.binaryPops && Object.keys(hook.data?.binaryPops).length > 0 && <>
                <Grid item xs={12}>
                    <DashboardChartCard
                        label={<>
                            <span>Estatísticas de Conformidade</span>
                            <FormGroup
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "left",
                                    marginLeft: '16px',
                                }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size='small'
                                            checked={hook.barChartIsPercentage}
                                            onChange={() => hook.setBarChartIsPercentage(
                                                !hook.barChartIsPercentage
                                            )}
                                        />
                                    }
                                    label={
                                        <span style={{
                                            fontSize: '0.9rem',
                                            color: '#333'
                                        }}>
                                            {hook.barChartIsPercentage ? 'Percentuais' : 'N° Ocorrências'}
                                        </span>
                                    }
                                />
                            </FormGroup>
                        </>
                        }
                        size='medium'
                        help={'Gráfico da distribuição de processos avaliados como'
                            + ' adequados em relação aos inadequados para cada'
                            + ' Processo Operacional (POP), considerando os'
                            + ' filtros aplicados.'
                        }
                        chart={<PopDashboardBarChart showPercentages={hook.barChartIsPercentage}/>}
                    />
                </Grid>
                {Object.keys(hook.data.binaryPops).map((key) => {
                    const binaryPop = hook.data!.binaryPops[key];
                    const pop = pops?.find(pop => pop.name === key);
                    const isTimeSeriesByCarcassCount = (
                        binaryPop.timeSeries?.length
                        && binaryPop.timeSeries[0]['label'].length < 8
                    );
                    const isLargeTimeSeries = (
                        !isTimeSeriesByCarcassCount
                        && binaryPop.timeSeries.length > 10
                    );
                    return <Grid
                        item xs={12}
                        sm={isLargeTimeSeries ? 12 : 6}
                        key={`time-series-chart-${key}`}
                    >
                        <DashboardChartCard
                            label={pop?.description || key}
                            size='medium'
                            help={'Gráfico da taxa de adequação do processo '
                                + `"${pop?.description || key}" ao longo do `
                                + 'tempo.'
                                + '\n\n Clique no gráfico para ativar/desativar as linhas de referência.'
                            }
                            chart={<PopDashboardTimeSeriesChart
                                id={`chart-${key}`}
                                data={binaryPop.timeSeries}
                                lastWeekAdequation={
                                    binaryPop.lastWeekAdequation ?
                                    (
                                        100 * binaryPop.lastWeekAdequation
                                    ).toFixed(0)
                                    : undefined
                                }
                                average={
                                    binaryPop.adequateCount > 0 ?
                                    (
                                        100 * binaryPop.adequateCount /
                                        (binaryPop.adequateCount + binaryPop.inadequateCount)
                                    ).toFixed(0)
                                    : '0'
                                }
                            />}
                        />
                    </Grid>
                })}
            </>}
        </Grid>
    );
}

export default PopDashboardGrid;
