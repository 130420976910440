export function capitalizeTheFirstLetterOfEachWord(words: string | undefined) {
  if (!words) return "";

  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
}

export function normalizeString(word: any) {
  if (word && word.normalize)
    return word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return word;
}

export const convertNumberToFixedAndLocale = (number: number, digits: number = 2) => {
  const convertedNumber = number.toLocaleString('pt-br', {
      style: 'decimal', 
      maximumFractionDigits: digits,
      minimumFractionDigits: digits
  })
  return convertedNumber
}