import React, { FC, useState } from 'react';
import { Button, Grid, SelectChangeEvent } from '@mui/material';

import BranchesMultiSelect from '../../branches/multiSelect';
import DashboardPopMultiSelect from '../../dashboard/filter/popMultiSelect';
import DateRangePicker from '../../../atoms/dateRangePicker';
import { IBranch } from '../../../../types';
import { usePopDashboard } from '../../../../hooks/popDashboard';
import { useBranches } from '../../../../hooks/branches';


const PopDashboardFilter: FC<any> = () => {
    const { branches } = useBranches();
    const hook = usePopDashboard();

    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);

    const onChangePops = (event: SelectChangeEvent<typeof hook.selectedPops>) => {
        const { value } = event.target;
        hook.setSelectedPops((value as string[]).sort());
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={3} alignItems="center" sx={{marginBottom: 4}}>
            <Grid container item xs={12} sm={6} lg={3} width="90%">
                <DateRangePicker
                    disabled={hook.loading}
                    initialDate={hook.initialDate}
                    finalDate={hook.finalDate}
                    placeholder={"Escolha o intervalo de data"}
                    setInitialDate={hook.setInitialDate}
                    setFinalDate={hook.setFinalDate}
                    maxDaysSelect={0}
                    useDefaultPredefinedRanges
                    sx={{
                        width: '100%',
                        backgroundColor: '#FFF',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                        borderRadius: '4px',
                        height: '37px',
                        marginTop: -5
                    }}
                />
            </Grid>
            <Grid container item xs={12} sm={6} lg={3} width="90%">
                <BranchesMultiSelect
                    disabled={hook.loading}
                    minWidth="100%"
                    selectedBranches={hook.selectedBranches}
                    setSelectedBranches={hook.setSelectedBranches}
                    expanded={branchesExpanded}
                    setExpanded={setBranchesExpanded}
                    sx={{
                        backgroundColor: '#FFF',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                        borderRadius: '4px',
                        height: '37px',
                        marginTop: -5
                    }}
                />
            </Grid>
            <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                <DashboardPopMultiSelect
                    disabled={hook.loading}
                    selectedPops={hook.selectedPops}
                    setSelectedPops={hook.setSelectedPops}
                    onChangePops={onChangePops}
                    branches={branches.map((branch: IBranch) => branch.id!)}
                />
            </Grid>
            <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px"
                sx={{
                    justifyContent: {xs: 'end', lg: 'start'},
                    flexDirection: {xs: 'row-reverse', lg: 'row'}
                }}
            >
                <Button
                    disabled={hook.loading}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={hook.fetchDashboardData}
                >
                    Aplicar
                </Button>
                <Button
                    disabled={hook.loading}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    onClick={hook.clearFilters}
                    sx={{ marginLeft: 2, marginRight: 2 }}
                >
                    Limpar
                </Button>
            </Grid>
        </Grid>
    );
}

export default PopDashboardFilter;
