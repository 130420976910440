/* Loads environment variables */
import { API_URL as baseUrl } from "../services";

let appURL: string = process.env.REACT_APP_URL || (window as any)?._env_?.REACT_APP_URL || "http://localhost:3000";
let websocketURL: string = process.env.REACT_APP_WEBSOCKET_URL || (window as any)?._env_?.REACT_APP_WEBSOCKET_URL || "ws://localhost:5000";
let alertWebsocketURL: string = process.env.REACT_APP_ALERT_WEBSOCKET_URL || (window as any)?._env_?.REACT_APP_ALERT_WEBSOCKET_URL || "ws://localhost:5025";
let downloadFileURL: string = process.env.REACT_APP_DOWNLOAD_FILE_URL || (window as any)?._env_?.REACT_APP_DOWNLOAD_FILE_URL || "https://api.redsoft.ai/download";
let refreshTokenInterval: number = parseInt(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL || (window as any)?._env_?.REACT_APP_REFRESH_TOKEN_INTERVAL || '300');

if (process.env.NODE_ENV == "production") {
  websocketURL = `wss://${window.location.hostname.replace("web", "api")}/`
}


export const APP_URL = appURL;
export const API_SOCKET_URL = websocketURL;
export const ALERT_SYSTEM_SOCKET_URL = alertWebsocketURL;
export const API_DOWNLOAD_FILE_URL = downloadFileURL;
export const API_URL = baseUrl;
export const REFRESH_TOKEN_INTERVAL = refreshTokenInterval
