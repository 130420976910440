import React, { FC, useEffect } from 'react';

import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import {
    CarcassConformationsMultiSelect,
    CarcassesLocalConformationsMultiSelect,
    CarcassClassificationsMultiSelect,
    CarcassesLocalClassificationsMultiSelect,
    Container,
    PopTypeMultiselect,
    Spacing,
    Text,
} from '../../components';
import Input from '../../components/atoms/input';

import BranchesMultiSelect from '../../components/molecules/branches/multiSelect';
import DashboardSexMultiSelect from '../../components/molecules/dashboard/filter/sexMultiSelect';
import DashboardPopMultiSelect from '../../components/molecules/dashboard/filter/popMultiSelect';
import DateRangePicker from '../../components/atoms/dateRangePicker';

import { IBranch } from '../../types';
import { PADDING, VERY_PADDING } from '../../utils/consts';
import { useBranches } from '../../hooks/branches';
import { useDownloadPage } from '../../hooks/downloadPage';

const DownloadFilter: FC<any> = () => {
    const hook = useDownloadPage();
    const { branches, fetchBranches } = useBranches();

    useEffect(() => {
        if (!branches || branches.length === 0)
            fetchBranches();
    },  []);

    const onChangeSexes = (event: SelectChangeEvent<typeof hook.sexes>) => {
        const { value } = event.target;
        hook.setSexes((value as string[]).sort());
    };

    const onChangePops = (event: SelectChangeEvent<typeof hook.selectedPops>) => {
        const { value } = event.target;
        hook.setSelectedPops((value as string[]).sort());
    };

    const handleChangeAccuracy = (event: SelectChangeEvent<string | number | null>) => {
        hook.setAccuracyTreshold(parseFloat((event.target.value as string).replace(/\D/g, '')) || 0);
    };

    const handleChangeLimit = (event: SelectChangeEvent<string | number | null>) => {
        const newValue = (event.target.value as string).replace(/\D/g, '');
        hook.setLimit(newValue.length ? parseInt(newValue) || null : null);
    };

    const handleClickPhotoType = (value: number) => {
        if (hook.photoTypes.includes(value))
            hook.setPhotoTypes(hook.photoTypes.filter(t => t !== value));
        else
            hook.setPhotoTypes([...hook.photoTypes, value]);
    };

    const handleChangeAccuracyUndefined = (event: SelectChangeEvent<string | number | null>) => {
        hook.setUndefinedThreshold(parseFloat((event.target.value as string).replace(/\D/g, '')) || 0);
    };

    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING, paddingLeft: PADDING, paddingBottom: VERY_PADDING }}>
            <Grid container rowSpacing={2} columnSpacing={3} alignItems="center" >
                <Grid container item xs={12} sm={6} lg={3} width="90%">
                    <DateRangePicker
                        disabled={hook.loading}
                        initialDate={hook.initialDate}
                        finalDate={hook.finalDate}
                        placeholder={"Escolha o intervalo de data"}
                        setInitialDate={hook.setInitialDate}
                        setFinalDate={hook.setFinalDate}
                        maxDaysSelect={0}
                        useDefaultPredefinedRanges
                        sx={{
                            width: '100%',
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%">
                    <BranchesMultiSelect
                        disabled={hook.loading}
                        minWidth="100%"
                        selectedBranches={hook.selectedBranches}
                        setSelectedBranches={hook.setSelectedBranches}
                        expanded={hook.branchesExpanded}
                        setExpanded={hook.setBranchesExpanded}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <DashboardSexMultiSelect
                        disabled={hook.loading}
                        sexes={hook.sexes}
                        setSexes={hook.setSexes}
                        onChangeSexes={onChangeSexes}
                   />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <DashboardPopMultiSelect
                        disabled={hook.loading}
                        selectedPops={hook.selectedPops}
                        setSelectedPops={hook.setSelectedPops}
                        onChangePops={onChangePops}
                        branches={branches.map((branch: IBranch) => branch.id!)}
                   />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%">
                    <CarcassesLocalClassificationsMultiSelect
                        disabled={hook.loading}
                        placeholder="Acabamento Local"
                        selectedBranchesId={hook.selectedBranches.map(item => item.id || "")}
                        selectedClassifications={hook.selectedLocalClassifications}
                        setSelectedClassifications={hook.setSelectedLocalClassifications}
                        expanded={hook.localClassificationsExpanded}
                        setExpanded={hook.setLocalClassificationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5,
                            width: '100%'
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                    <CarcassClassificationsMultiSelect
                        useJBS
                        disabled={hook.loading}
                        placeholder="Acabamentos IA"
                        selectedItems={hook.selectedClassifications}
                        setSelectedItems={hook.setSelectedClassifications}
                        expanded={hook.classificationsExpanded}
                        setExpanded={hook.setClassificationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5,
                            width: '100%'
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                    <CarcassesLocalConformationsMultiSelect
                        disabled={hook.loading}
                        placeholder="Conformação Local"
                        selectedBranchesId={hook.selectedBranches.map(item => item.id || "")}
                        selectedConformations={hook.selectedLocalConformations}
                        setSelectedConformations={hook.setSelectedLocalConformations}
                        expanded={hook.localConformationsExpanded}
                        setExpanded={hook.setLocalConformationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5,
                            width: '100%'
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                    <CarcassConformationsMultiSelect
                        useJBS
                        disabled={hook.loading}
                        placeholder="Conformação IA"
                        selectedItems={hook.selectedConformations}
                        setSelectedItems={hook.setSelectedConformations}
                        expanded={hook.conformationsExpanded}
                        setExpanded={hook.setConformationsExpanded}
                        singleSelect={false}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5,
                            width: '100%'
                        }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} width="90%" minWidth="200px">
                   <PopTypeMultiselect
                        disabled={hook.loading}
                        value={hook.popClassifications}
                        setValue={hook.setPopClassifications}
                   />
                </Grid>
                <Grid container item xs={12} lg={9} sx={{
                    display: 'flex',
                    justifyContent: {xs: 'flex-end', md: 'flex-start' },
                    flexWrap: 'wrap',
                    gap: 2,
                }}>
                    <Container inline baselineAlignment style={{ marginLeft: '8px' }}>
                        <Text size={14} medium muted={hook.loading}>Limite:&emsp;</Text>
                        <Input
                            disabled={hook.loading}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={hook.limit || ''}
                            onChange={handleChangeLimit}
                            placeholder=''
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '35px',
                                marginTop: 0,
                                minWidth: '5em',
                                maxWidth: '7em',
                            }}
                        />
                    </Container>
                    <Container inline baselineAlignment style={{ marginLeft: '8px' }}>
                        <Text size={14} medium muted={hook.loading}>
                            Confiança Mín:&emsp;
                        </Text>
                        <Input
                            disabled={hook.loading}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={hook.accuracyTreshold ? String(hook.accuracyTreshold) : ''}
                            onChange={handleChangeAccuracy}
                            placeholder=''
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '35px',
                                marginTop: 0,
                                minWidth: '5em',
                                maxWidth: '7em',
                            }}
                        />
                    </Container>
                    <Container inline baselineAlignment style={{ marginLeft: '8px' }}>
                        <Text size={14} medium muted={hook.loading || hook.filterProperty !== 'pop'}>
                            Confiança Mín. para Indefinidos:&emsp;
                        </Text>
                        <Input
                            disabled={hook.loading || hook.filterProperty !== 'pop'}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={hook.undefinedThreshold ? String(hook.undefinedThreshold) : ''}
                            onChange={handleChangeAccuracyUndefined}
                            placeholder=''
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '35px',
                                marginTop: 0,
                                minWidth: '5em',
                                maxWidth: '7em',
                            }}
                        />
                    </Container>
                </Grid>
                <Grid container item xs={12} lg={3}>
                    <RadioGroup value={hook.filterProperty || 'pop'}
                        onChange={(e) => hook.setFilterProperty(
                            (e.target as HTMLInputElement).value as
                            ('classification' | 'conformation' | 'pop')
                        )}
                    >
                        <FormControlLabel
                            disabled={hook.loading}
                            key='pop' value='pop'
                            control={<Radio size='small' />}
                            label='Agrupar por POP' />
                        <FormControlLabel
                            disabled={hook.loading}
                            key='classification' value='classification'
                            control={<Radio size='small' />}
                            label='Agrupar por acabamento' />
                        <FormControlLabel
                            disabled={hook.loading}
                            key='conformation' value='conformation'
                            control={<Radio size='small' />}
                            label='Agrupar por conformação' />
                    </RadioGroup>
                </Grid>
                <Grid container item xs={12} lg={3}>
                    <Stack>
                        <Container inline centered>
                            <Checkbox
                                disabled={hook.loading}
                                size="small"
                                checked={hook.photoTypes.includes(0)}
                                onClick={() => handleClickPhotoType(0)}
                            />
                            <Container fluid flexStart flex>
                                <Text>Selecionar imagens externas</Text>
                            </Container>
                        </Container>
                        <Container inline centered>
                            <Checkbox
                                disabled={hook.loading}
                                size="small"
                                checked={hook.photoTypes.includes(1)}
                                onClick={() => handleClickPhotoType(1)}
                            />
                            <Container fluid flexStart flex>
                                <Text>Selecionar imagens internas</Text>
                            </Container>
                        </Container>
                        <Container inline centered>
                            <Checkbox
                                disabled={hook.loading}
                                size="small"
                                checked={hook.photoTypes.includes(3)}
                                onClick={() => handleClickPhotoType(3)}
                            />
                            <Container fluid flexStart flex>
                                <Text>Selecionar imagens tag #1</Text>
                            </Container>
                        </Container>
                        <Container inline centered>
                            <Checkbox
                                disabled={hook.loading}
                                size="small"
                                checked={hook.photoTypes.includes(2)}
                                onClick={() => handleClickPhotoType(2)}
                            />
                            <Container fluid flexStart flex>
                                <Text>Selecionar imagens tag #2</Text>
                            </Container>
                        </Container>
                    </Stack>
                </Grid>
                <Grid container item xs={12} lg={3} sx={{
                    flexDirection: 'column',
                }}>
                    <Container inline centered>
                        <Checkbox
                            disabled={hook.loading}
                            size="small"
                            checked={hook.onlyBestPlaced}
                            onClick={() => hook.setOnlyBestPlaced(!hook.onlyBestPlaced)}
                        />
                        <Container fluid flexStart flex>
                            <Text>Somente bem posicionadas</Text>
                        </Container>
                    </Container>
                    <Container inline centered>
                        <Checkbox
                            disabled={hook.loading}
                            size="small"
                            checked={hook.randomize}
                            onClick={() => hook.setRandomize(!hook.randomize)}
                        />
                        <Container fluid flexStart flex>
                            <Text>Imagens Aleatórias</Text>
                        </Container>
                    </Container>
                </Grid>
                <Grid container item xs={12} lg={3} width="90%" minWidth="200px" sx={{justifyContent: 'end'}}>
                    <Button
                        disabled={hook.loading}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={hook.onApply}
                        sx={{ borderRadius: 2 }}
                    >
                        Filtrar
                    </Button>
                    <Spacing left={20}/>
                    <Button
                        disabled={hook.loading}
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={hook.clear}
                        sx={{ borderRadius: 2 }}
                    >
                        Limpar
                    </Button>
                </Grid> 
            </Grid>
        </Container>
    );
}

export default DownloadFilter;
