import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    ButtonBase,
    Collapse,
    Grow,
    IconButton,
    Paper,
    Hidden,
} from "@mui/material";

import {
    Analytics as AnalyticsIcon,
    AppRegistration as AnnotationIcon,
    Apps as AppsIcon,
    ArticleRounded as ArticleRoundedIcon,
    AssignmentInd as AnnotatorIcon,
    SavedSearch as PopsIcon,
    Business as BusinessIcon,
    BarChart as BarChartIcon,
    Dashboard as DashboardIcon,
    Download as DownloadIcon,
    EditNotifications as EditNotificationsIcon,
    Phishing as PhishingIcon,
    Gavel as GavelIcon,
    Group as GroupIcon,
    Help as HelpIcon,
    History as HistoryIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    Logout as LogoutIcon,
    MailOutline as MailIcon,
    Menu as MenuIcon,
    ModelTraining as ModelTrainingIcon,
    NotificationImportant as NotificationImportantIcon,
    Notifications as NotificationsIcon,
    RateReview as RevisionIcon,
    Tune as TuneIcon,
    WhatsApp as WhatsAppIcon,
    Wallpaper as WallpaperIcon,
} from "@mui/icons-material";

import { Drawer, MobileDrawer } from "./drawer";
import Container from "../../atoms/container";
import Spacing from "../../atoms/spacing";
import SendEmailModal from "./sendEmailModal";
import Text from "../../atoms/text";
import theme from "../../theme";
import logoBrand from "../../../assets/logo_brand.png";
import { ReactComponent as CarcassesIcon } from "../../../assets/icons/carcassesIcon.svg";
import { useDrawer } from "../../../hooks/drawer";
import { useAuth } from "../../../hooks/auth";
import {
    IDrawerMolecule,
} from "../../../types";
import DrawerButton from "./button";
import { PADDING } from "../../../utils/consts";
import Image from "../../atoms/image";
import AlertActiveStatus from "../alertActive/status";


const AppDrawer: FC<IDrawerMolecule> = (params: IDrawerMolecule) => {
    var version = require('../../../../package.json').version;
    const location = useLocation();

    const navigate = useNavigate();

    const navigateToHomePage = () => {
        navigate('/');
    };

    const { hasRights, roles, user, logout } = useAuth();
    const { isOpen, setIsOpen } = useDrawer();

    const [showAnimals, setShowAnimals] = useState<boolean>(false);
    const [showAnnotations, setShowAnnotations] = useState<boolean>(false);
    const [showAlertSystem, setShowAlertSystem] = useState<boolean>(false);
    const [showPops, setShowPops] = useState<boolean>(false);

    const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState<boolean>(false);

    const getColor = (pathname: string) => {
        if (location.pathname == pathname) return theme.palette.primary.main;
        return "#000";
    };

    const checkRole = (routes: string[]) => {
        for (let i = 0; i < routes.length; i++)
            if (roles.indexOf(routes[i]) >= 0)
                return true;
        return false;
    };

    const supportPhoneNumber = "+5566996707000";
    const supportEmailAddress = "suporte@redsoft.ai";


    function formatPhoneNumber(inputNumber: string) {
        // FROM: +5566996707000 -> +55 66 99670-7000
        const numericOnly = inputNumber.replace(/[^\d]/g, '');

        const countryCode = numericOnly.slice(0, 2);
        const areaCode = numericOnly.slice(2, 4);
        const firstPart = numericOnly.slice(4, 9);
        const secondPart = numericOnly.slice(9,);

        const formattedNumber = `+${countryCode} ${areaCode} ${firstPart}-${secondPart}`;

        return formattedNumber;
    }

    const onEmailClick = () => {
        setIsSendEmailModalOpen(true);
    }

    const drawerElements = (isPermanent: boolean) => <Container fluid flex flexGrow spacedBetween>
        <Container fluid>
            <IconButton size="large" color="primary"
                sx={{
                    backgroundColor: 'transparent',
                    alignSelf: { xs: 'flex-start', md: 'center' }
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <MenuIcon style={{ fontSize: 25 }} />
            </IconButton>
            <Grow in={isOpen}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Image
                        source={logoBrand}
                        height={35}
                        width={35 * 4.16}
                        onClick={navigateToHomePage}
                        style={{ cursor: 'pointer' }}
                    />
                    <Spacing top={PADDING} />
                    <Text medium size={15}>
                        Bem vindo(a)
                    </Text>
                    <Text
                        bold
                        color={theme.palette.primary.main}
                        size={15}
                    >
                        {user && user.firstName}
                    </Text>
                </Box>
            </Grow>

            {hasRights!("view_alert_system") &&
                <AlertActiveStatus isOpen={isOpen} />
            }

            <Container fluid flex flexStart>
                <DrawerButton
                    name="Dashboard"
                    color={getColor("/")}
                    route="/"
                    icon={
                        <DashboardIcon
                            fontSize="large"
                            style={{ color: getColor("/") }}
                        />
                    }
                    onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                />
                {hasRights!("view_companies") &&
                    <DrawerButton
                        name="Empresas" color={getColor("/companies")}
                        route="/companies"
                        icon={
                            <BusinessIcon fontSize="large"
                                style={{ color: getColor("/companies") }}
                            />
                        }
                        onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                    />
                }
                {checkRole(['view_carcasses', 'view_lots']) &&
                    <Container inline width="90%" flexStart>
                        <Container inline width="90%" flexStart>
                            <ButtonBase onClick={() => setShowAnimals(!showAnimals)}>
                                <Box
                                    sx={{
                                        backgroundColor: "transparent",
                                        padding: "12px",
                                        alignItems: "center",
                                        display: "flex"
                                    }}
                                >
                                    <>
                                        <AppsIcon
                                            fontSize="large"
                                            style={{ color: getColor("") }}
                                        />
                                        {!isOpen && (!showAnimals ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />)}
                                    </>
                                </Box>
                                <Container fluid width='150px'>
                                    <Grow in={isOpen}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                height: "100%",
                                                cursor: "pointer",
                                                marginRight: PADDING,
                                            }}
                                        >
                                            <Text bold size={16} color={getColor("")}>
                                                Animais
                                            </Text>
                                            {!showAnimals ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />}
                                        </Box>
                                    </Grow>
                                </Container>
                            </ButtonBase>
                        </Container>
                    </Container>
                }
                <Collapse in={showAnimals}>
                    <Container fluid flex flexStart sx={{ paddingLeft: PADDING }}>
                        <DrawerButton
                            name="Carcaças"
                            color={getColor("/carcasses")}
                            route="/carcasses"
                            icon={
                                <CarcassesIcon
                                    fill={getColor("/carcasses")}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />
                        {
                            checkRole(['view_lotes']) &&
                            <DrawerButton
                                name="Lotes"
                                color={getColor("/lotes")}
                                route="/lotes"
                                icon={
                                    <ArticleRoundedIcon
                                        fontSize="large"
                                        style={{
                                            color: getColor(
                                                "/lotes",
                                            ),
                                        }}
                                    />
                                }
                                onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                            />
                        }
                    </Container>
                </Collapse>


                {checkRole(['view_analysis_annotations', 'view_annotators', 'view_annotations', 'view_revisions', 'view_verdicts']) &&
                    <Container inline width="90%" flexStart>
                        <ButtonBase onClick={() => setShowAnnotations(!showAnnotations)}>
                            <Box
                                sx={{
                                    backgroundColor: "transparent",
                                    padding: "12px",
                                    alignItems: "center",
                                    display: "flex"
                                }}
                            >
                                <>
                                    <ModelTrainingIcon
                                        fontSize="large"
                                        style={{ color: getColor("") }}
                                    />
                                    {!isOpen && (!showAnnotations ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />)}
                                </>
                            </Box>
                            <Container fluid width='150px'>
                                <Grow in={isOpen}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            height: "100%",
                                            cursor: "pointer",
                                            marginRight: PADDING,
                                        }}
                                    >
                                        <Text bold size={16} color={getColor("")}>
                                            Treinamento
                                        </Text>
                                        {!showAnnotations ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />}
                                    </Box>
                                </Grow>
                            </Container>
                        </ButtonBase>
                    </Container>
                }

                <Collapse in={showAnnotations}>
                    <Container fluid flex flexStart sx={{ paddingLeft: PADDING }}>
                        {hasRights!("view_annotators") && <DrawerButton
                            name="Anotadores"
                            color={getColor("/annotators")}
                            route="/annotators"
                            icon={
                                <AnnotatorIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor("/annotators"),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {hasRights!("view_analysis_annotations") && <DrawerButton
                            name="Análise"
                            color={getColor("/analysis-annotations")}
                            route="/analysis-annotations"
                            icon={
                                <AnalyticsIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor(
                                            "/analysis-annotations",
                                        ),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {hasRights!("view_annotations") && <DrawerButton
                            name="Anotações"
                            color={getColor("/annotations_select")}
                            route="/annotations_select"
                            icon={
                                <AnnotationIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor("/annotations_select"),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {hasRights!("view_revisions") && <DrawerButton
                            name="Revisões"
                            color={getColor("/revisions")}
                            route="/revisions"
                            icon={
                                <RevisionIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor("/revisions"),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {hasRights!("view_verdicts") && <DrawerButton
                            name="Arbitragem"
                            color={getColor("/verdicts")}
                            route="/verdicts"
                            icon={
                                <GavelIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor("/verdicts"),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {hasRights!("view_download_page") && <DrawerButton
                            name="Download"
                            color={getColor("/download-page")}
                            route="/download-page"
                            icon={
                                <DownloadIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor("/download-page"),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                    </Container>
                </Collapse>

                {(checkRole(['view_pop_dashboard', 'view_pop_visualization']) || checkRole(['view_alert_system'])) &&
                    <Container inline width="90%" flexStart>
                        <ButtonBase onClick={() => setShowPops(!showPops)}>
                            <Box
                                sx={{
                                    backgroundColor: "transparent",
                                    padding: "12px",
                                    alignItems: "center",
                                    display: "flex"
                                }}
                            >
                                <>
                                    <PopsIcon
                                        fontSize="large"
                                        style={{ color: getColor("") }}
                                    />
                                    {!isOpen && (
                                        !showPops ?
                                            <KeyboardArrowDownIcon sx={{ color: '#000' }} /> :
                                            <KeyboardArrowUpIcon sx={{ color: '#000' }} />)
                                    }
                                </>
                            </Box>
                            <Container fluid width='150px'>
                                <Grow in={isOpen}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            height: "100%",
                                            cursor: "pointer",
                                            marginRight: PADDING,
                                        }}
                                    >
                                        <Text bold size={16} color={getColor("")}>
                                            POP-Engine
                                        </Text>
                                        {!showPops ?
                                            <KeyboardArrowDownIcon sx={{ color: '#000' }} /> :
                                            <KeyboardArrowUpIcon sx={{ color: '#000' }} />
                                        }
                                    </Box>
                                </Grow>
                            </Container>
                        </ButtonBase>
                    </Container>
                }

                <Collapse in={showPops}>
                    <Container fluid flex flexStart sx={{ paddingLeft: PADDING }}>
                        {hasRights!("view_pop_dashboard") && <DrawerButton
                            name="Dashboard"
                            color={getColor("/pops/dashboard")}
                            route="/pops/dashboard"
                            icon={
                                <BarChartIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor("/pops/dashboard"),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {hasRights!("view_pop_visualization") && <DrawerButton
                            name="Visualização"
                            color={getColor("/pops/visualization")}
                            route="/pops/visualization"
                            icon={
                                <WallpaperIcon
                                    fontSize="large"
                                    style={{
                                        color: getColor(
                                            "/pops/visualization",
                                        ),
                                    }}
                                />
                            }
                            onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                        />}
                        {checkRole(['view_alert_system']) &&
                            <Container inline width="90%" flexStart>
                                <ButtonBase onClick={() => setShowAlertSystem(!showAlertSystem)}>
                                    <Box
                                        sx={{
                                            backgroundColor: "transparent",
                                            padding: "12px",
                                            alignItems: "center",
                                            display: "flex"
                                        }}
                                    >
                                        <>
                                            <NotificationsIcon
                                                fontSize="large"
                                                style={{ color: getColor("") }}
                                            />
                                            {!isOpen && (
                                                !showAlertSystem ?
                                                    <KeyboardArrowDownIcon sx={{ color: '#000' }} /> :
                                                    <KeyboardArrowUpIcon sx={{ color: '#000' }} />)
                                            }
                                        </>
                                    </Box>
                                    <Container fluid width='150px'>
                                        <Grow in={isOpen}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    cursor: "pointer",
                                                    marginRight: PADDING,
                                                }}
                                            >
                                                <Text bold size={16} color={getColor("")}>
                                                    Alertas
                                                </Text>
                                                {!showAlertSystem ?
                                                    <KeyboardArrowDownIcon sx={{ color: '#000' }} /> :
                                                    <KeyboardArrowUpIcon sx={{ color: '#000' }} />
                                                }
                                            </Box>
                                        </Grow>
                                    </Container>
                                </ButtonBase>
                            </Container>
                        }

                        <Collapse in={showAlertSystem}>
                            <Container fluid flex flexStart sx={{ paddingLeft: PADDING }}>
                                {hasRights!("view_alert_system_groups") && <DrawerButton
                                    name="Grupos"
                                    color={getColor("/alert/groups")}
                                    route="/alert/groups"
                                    icon={
                                        <GroupIcon
                                            fontSize="large"
                                            style={{
                                                color: getColor("/alert/groups"),
                                            }}
                                        />
                                    }
                                    onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                                />}
                                {hasRights!("view_alert_system_alerts") && <DrawerButton
                                    name="Configuração"
                                    color={getColor("/alert/setup")}
                                    route="/alert/setup"
                                    icon={
                                        <EditNotificationsIcon
                                            fontSize="large"
                                            style={{
                                                color: getColor(
                                                    "/alert/setup",
                                                ),
                                            }}
                                        />
                                    }
                                    onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                                />}
                                {hasRights!("view_alert_system_active") && <DrawerButton
                                    name="Alertas Ativos"
                                    color={getColor("/alert/active-alerts")}
                                    route="/alert/active-alerts"
                                    icon={
                                        <NotificationImportantIcon
                                            fontSize="large"
                                            style={{
                                                color: getColor("/alert/active-alerts"),
                                            }}
                                        />
                                    }
                                    onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                                />}
                                {hasRights!("view_alert_system_history") && <DrawerButton
                                    name="Histórico"
                                    color={getColor("/alert/history")}
                                    route="/alert/history"
                                    icon={
                                        <HistoryIcon
                                            fontSize="large"
                                            style={{
                                                color: getColor("/alert/history"),
                                            }}
                                        />
                                    }
                                    onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                                />}
                            </Container>
                        </Collapse>


                    </Container>

                </Collapse>

                {hasRights!("view_slaughter_delays") &&
                    <DrawerButton
                        name="Telenória"
                        color={getColor("/telenoria")}
                        route="/telenoria"
                        icon={
                            <PhishingIcon
                                fontSize="large"
                                style={{ color: getColor("/telenoria") }}
                            />
                        }
                        onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                    />
                }

                {hasRights!("view_tutorial") &&
                    <DrawerButton
                        name="Ajuda"
                        color={getColor("/help")}
                        route="/help"
                        icon={
                            <HelpIcon
                                fontSize="large"
                                style={{ color: getColor("/help") }}
                            />
                        }
                        onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                    />
                }

                {hasRights!("view_control_panel") &&
                    <DrawerButton
                        name="Painel de Controle"
                        color={getColor("/control_panel")}
                        route="/control_panel"
                        icon={
                            <TuneIcon
                                fontSize="large"
                                style={{ color: getColor("/control_panel") }}
                            />
                        }
                        onClick={isPermanent ? (e) => null : (e) => setIsOpen(false)}
                    />
                }
            </Container>
        </Container>

        <Container fluid padded centered>
            <Box width="75%" paddingBottom={2}>
                <Box display="flex" justifyContent="center" alignItems="center" paddingBottom={0.5}>
                    <a href={`https://wa.me/${supportPhoneNumber}`} target="_blank" rel="noopener noreferrer" style={{ display: "flex", textDecoration: "none" }}>
                        <WhatsAppIcon sx={{ color: theme.palette.primary.main, marginRight: "5px" }} />
                        {isOpen &&
                            <Text
                                size={12}
                                bold
                                color={theme.palette.primary.main}
                                style={{ opacity: 0.9, alignItems: 'center' }}
                            >
                                {formatPhoneNumber(supportPhoneNumber)}
                            </Text>
                        }
                    </a>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ cursor: 'pointer' }} onClick={onEmailClick}>
                    <MailIcon sx={{ color: theme.palette.primary.main, marginRight: "5px" }} />
                    {isOpen &&
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9, alignItems: 'center' }}
                        >
                            {supportEmailAddress}
                        </Text>
                    }
                </Box>
            </Box>
            {
                isOpen ?
                    <Text
                        size={12}
                        bold
                        color={theme.palette.primary.main}
                        style={{ opacity: 0.9 }}
                    >
                        RedSoft© &nbsp;&nbsp; 2023 &nbsp;&nbsp; v{version}
                    </Text>
                    :
                    <>
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9 }}
                        >
                            RedSoft©
                        </Text>
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9 }}
                        >
                            v{version}
                        </Text>
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9 }}
                        >
                            2023
                        </Text>
                    </>
            }
            <Box sx={{ mt: 2, mb: 2 }}>
                {isOpen ?
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={logout}
                    >
                        Sair
                    </Button>
                    : <IconButton color="primary" onClick={logout}>
                        <LogoutIcon />
                    </IconButton>
                }
            </Box>

        </Container>
        <SendEmailModal open={isSendEmailModalOpen} onClose={() => setIsSendEmailModalOpen(false)}></SendEmailModal>
    </Container>;

    return (<>
        <Hidden mdUp>
            <Paper
                sx={{
                    width: '100%',
                    display: "block",
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
                elevation={2}
            >
                <IconButton size="large" color="primary" style={{ backgroundColor: 'transparent' }}
                    onClick={() => setIsOpen(!isOpen)}>
                    <MenuIcon style={{ fontSize: 25 }} />
                </IconButton>
            </Paper>
            <Box sx={{ height: '50px', padding: 0 }} />
        </Hidden>
        <Box sx={{ height: '100%', display: "flex" }}>
            <Hidden mdDown>
                <Drawer
                    variant="permanent"
                    open={isOpen}
                    style={{ zIndex: 0 }}
                    elevation={10}
                    PaperProps={{
                        sx: {
                            borderRight: "0px",
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                >
                    {drawerElements(true)}
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <MobileDrawer
                    anchor='left'
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    style={{ zIndex: 0 }}
                    elevation={10}
                    PaperProps={{
                        sx: {
                            borderRight: "0px",
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                >
                    {drawerElements(false)}
                </MobileDrawer>
            </Hidden>
            <Paper component="main" sx={{ flexGrow: 1, width: "100%", margin: 0 }} square elevation={0}>
                {params.children}
            </Paper>
        </Box>
    </>);
};

export default AppDrawer;
