import React, { FC, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

import BranchSelect from "../../branches/select";
import Container from "../../../atoms/container";
import DateRangePicker from "../../../atoms/dateRangePicker";
import Modal from "../../../atoms/modal";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useBranches } from "../../../../hooks/branches";
import { useInformation } from "../../../../hooks/information";
import {
  IAnnotationsAnomaliesDownloadModal,
} from "../../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../../../utils/consts";
import { API_SOCKET_URL, API_DOWNLOAD_FILE_URL } from '../../../../utils/config';
import { useAuth } from '../../../../hooks/auth';

const AnnotationsAnomaliesDownloadModal: FC<IAnnotationsAnomaliesDownloadModal> = (
  params: IAnnotationsAnomaliesDownloadModal,
) => {
  const { user } = useAuth();
  const { showInformation } = useInformation();
  const { fetchBranches } = useBranches();

  const refDownload = useRef<HTMLAnchorElement | null>(null);

  const [initialDate, setInitialDate] = useState<Date | null>();
  const [finalDate, setFinalDate] = useState<Date | null>();
  const [branchId, setBranchId] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [branchError, setBranchError] = useState<string | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | null>(null);

  const [urlDownload, setUrlDownload] = useState<string>("");
  const [fileNameDownload, setFileNameDownload] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentIndexDownload, setCurrentIndexDownload] = useState<number>(0);
  const [totalDownload, setTotalDownload] = useState<number>(0);
  const [downloading, setDownloading] = useState<boolean>(false);
  useEffect(() => {
    fetchBranches();
  }, []);

  const handleChangeBranch = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    if ((event as any).target) {
      setBranchId((event as any).target.value);
      setBranchName("");
    } else {
      if ((event as any).id) {
        setBranchId((event as any).id);
      }
      if ((event as any).label) {
        setBranchName((event as any).label);
      }
    }
    setBranchError(undefined);
    setError(null);
  };

  const validate = () => {
    let isValid: boolean = true;

    if (!initialDate || !finalDate) {
      setError("Favor informar a data");
      isValid = false;
    }

    if (branchId == "") {
      setBranchError("Favor informar a empresa");
      isValid = false;
    }

    if (isValid) {
      setError("");
      setBranchError(undefined);
    }
    return isValid;
  };
  const startDownload = async (_filePath: string) => {
    try {
        setDownloading(true);
        setUrlDownload(`${API_DOWNLOAD_FILE_URL}/anomalies_annotations/${_filePath}`);
        setFileNameDownload(_filePath);
        setTimeout(() => {
            refDownload.current?.click();
            setTimeout(() => {
                setUrlDownload('');
                setFileNameDownload('');
            }, 1000);
        }, 1000);

        setDownloading(false);
        setLoading(false);
    } catch (err: any) {
        setDownloading(false);
        setLoading(false);
    }
}
  const onDownload = async () => {
    if (validate() == false) return;

    setLoading(true);
    try {
        const socket = new WebSocket(`${API_SOCKET_URL}socket/annotations_anomalies/download_photos`)
        socket.onmessage = (ev: MessageEvent<any>) => {
            let data = JSON.parse(ev.data);
            if (data) {
                if (data.current && data.total && data.status && data.status == 'IN_PROGRESS') {
                    setCurrentIndexDownload(parseInt(data.current))
                    setTotalDownload(parseInt(data.total))
                } else if (data.path && data.status && data.status == 'COMPLETED') {
                    setCurrentIndexDownload(0);
                    setTotalDownload(0);

                    startDownload(data.path);
                    socket.close();
                } else if (data.message && data.status == 'ERROR') {
                    if (data.message == "NOT_FOUND")
                        showInformation("Não foi encontrada nenhuma anomalia na data selecionada", 'Aviso');
                    else if (data.message == "MISSING_ARGS")
                        showInformation("Erro de conexão com o servidor. Entre em contato com nossa equipe de desenvolvimento", 'Aviso');
                    socket.close();
                    setLoading(false);
                }
            }
        };
        socket.onopen = (ev: Event) => {
            let query: any = {};
            query.initialDate = moment(initialDate).format("YYYY-MM-DD");
            query.finalDate = moment(finalDate).format("YYYY-MM-DD");
            query.branchId = branchId;

            let message: any = {
                query: query,
                userId: user ? user.id : ''
            }

            socket.send(JSON.stringify(message));
        }
    } catch (err) {
        showInformation((err as any).message, 'Aviso');
        setLoading(false);
    }
}
  const close = () => {
    cleanData();

    params.onClose();
  };

  const cleanData = () => {
    setInitialDate(new Date());
    setFinalDate(new Date());
    setBranchId("");
    setBranchName("");
    setBranchError(undefined);
    setError(null);
    setUrlDownload("");
    setFileNameDownload("");
    setLoading(false);
  };

  return (
    <Modal isOpened={params.open} onClose={close}>
      <a
        href={urlDownload}
        download={fileNameDownload}
        className="hidden"
        ref={refDownload}
      />

      <Container
        fluid
        color={theme.palette.background.default}
        hasShadow
        centered
        borderRadius={BORDER_RADIUS}
        style={{ maxWidth: 540 }}
      >
        <Container
          inline
          color={theme.palette.grayLight.main}
          centered
          width="100%"
          borderTopRadius={BORDER_RADIUS}
        >
          <Container padded>
            <Text size={16} bold color={theme.palette.primary.light}>
              Download de anomalias
            </Text>
          </Container>
        </Container>

        <Container fluid centered spacedAround veryPadded width="90%">
          <Text>Preencha o filtro para efetuar o download.</Text>
          <Spacing top={PADDING} />
          <Text light size={13}>
            Esse processo pode demorar um pouco dependendo da quantidade de
            anomalias.
          </Text>
          <Spacing top={VERY_PADDING} />

          <Grid container rowSpacing={2} alignItems="center">
            <Grid item xs={4}>
              <Text light>Data:</Text>
            </Grid>
            <Grid item xs={8}>
              <DateRangePicker
                initialDate={initialDate}
                finalDate={finalDate}
                placeholder={"Escolha intervalo de data"}
                setInitialDate={setInitialDate}
                setFinalDate={setFinalDate}
                disabled={loading}
                maxDaysSelect={7}
              />
            </Grid>
            <Grid item xs={4}>
              <Text light>Frigorífico:</Text>
            </Grid>
            <Grid item xs={8}>
              <BranchSelect
                value={branchId}
                valueName={branchName}
                handleChangeValue={handleChangeBranch}
                variant="outlined"
                placeholder="Escolha o frigorífico"
                error={branchError}
              />
            </Grid>
          </Grid>
        </Container>

        {error && (
          <Text size={13} color={theme.palette.error.main}>
            Erro: {error}
          </Text>
        )}
        <Container inline spacedAround veryPadded>
          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
            loading={loading}
            disabled={loading}
            onClick={close}
          >
            <Text
              bold
              size={12}
              color={
                loading ? theme.palette.grey[400] : theme.palette.primary.light
              }
            >
              Fechar
            </Text>
          </LoadingButton>
          <Spacing left={PADDING} />
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            style={{
              opacity: loading ? 0.5 : 1,
              borderColor: theme.palette.primary.light,
              width: 150,
              height: 35,
            }}
            loading={loading}
            disabled={loading}
            onClick={() => onDownload()}
          >
            <Text bold size={12} color={theme.palette.background.paper}>
              Confirmar
            </Text>
          </LoadingButton>
        </Container>
      
        {loading && currentIndexDownload > 0 &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Baixando {currentIndexDownload} de {totalDownload}</Text>
                    </Container>
                }
                {currentIndexDownload > 0 && currentIndexDownload == totalDownload || downloading &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Preparando arquivo para efetuar o download</Text>
                    </Container>
                }
            </Container>
    </Modal>
  );
};

export default AnnotationsAnomaliesDownloadModal;
