import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import { API_SOCKET_URL } from "../utils/config";
import {
  ISocketHook,
  ISocketHookStatus,
} from "../types";


/* createSocketHook() is a method that returns the provider and a hook (with
 * context) to create a websocket with auto-reconnect capabilities. It's usage
 * is pretty straight-forward, check the example:
 *
 * ```
 *   interface IMyData {
 *     someData: string[];
 *     ...
 *   }
 *
 *   const MyActualHook = (...) => {
 *     ...
 *
 *     function myOnMessageCallback = (...) => {
 *       // do something particular
 *     }
 *     const socketHook = createSocketHook<IMyData>({
 *       url: 'socket/my_data_url',
 *       defaultData: {someData: []} as IMyData,
 *       onMessageCallback: myOnMessageCallback
 *     });
 *
 *     return socketHook;
 *   }
 * ```
 *
 * You can customize some callbacks for each event, see interface declarations
 * for more information.
 * 
 * Also, you can use it inside a context. Bare in mind that it will open the
 * socket connection once the application starts (and not only when actually
 * accessing the hook). See the example below for a socket that implements
 * pooling:
 *
 * ```
 * const SOCKET_POOLING_INTERVAL = 5000;
 *
 * const ActiveAlertsContext = createContext<ISocketHookContext<IAlertSocketHookData>>(
 *   {} as ISocketHookContext<IAlertSocketHookData>,
 * );
 *
 * export const ActiveAlertsSocketProvider: FC<IHookProvider> = ({ children }) => {
 *   const { user } = useAuth();
 *
 *   function onOpenCallback(params: ISocketHookOnOpenCallback<IAlertSocketHookData>) {
 *     if (params.socket && params.socket.readyState === 1) {
 *       params.send({
 *         type: "OPEN",
 *         user: user!.id
 *       })
 *     }
 *   };
 *
 *   function onMessageCallback(params: ISocketHookOnMessageCallback<IAlertSocketHookData>) {
 *     if (params.eventData.error)
 *       return params.reconnect();
 *     if (params.socket && params.hookStatus !== ISocketHookStatus.CLOSE && params.socket.readyState === 1) {
 *       params.setData(params.eventData);
 *       setTimeout(() => {
 *         params.send({
 *           type: 'FETCH',
 *           user: user!.id
 *         });
 *       }, SOCKET_POOLING_INTERVAL);
 *     };
 *   };
 *
 *   function onCloseCallback(params: ISocketHookOnCloseCallback<IAlertSocketHookData>) {
 *     if (params.socket && params.socket.readyState === 1) {
 *       params.send({
 *         type: "CLOSE",
 *         user: user!.id
 *       })
 *     }
 *   };
 *
 *   const socketHook = createSocketHook<IAlertSocketHookData>({
 *     url: `${ALERT_SYSTEM_SOCKET_URL}/socket/alerts/active`,
 *     defaultData: {
 *       total: 0,
 *       level: 0,
 *       alerts: []
 *     },
 *     onCloseCallback,
 *     onOpenCallback,
 *     onMessageCallback,
 *   });
 *
 *   return <ActiveAlertsContext.Provider value={socketHook}>
 *     {children}
 *   </ActiveAlertsContext.Provider>;
 * };
 *
 *
 * export default function useActiveAlerts() {
 *   const context = useContext(ActiveAlertsContext);
 *
 *   if (!context) {
 *       throw new Error(
 *           "useActiveAlerts must be used within an ActiveAlertsSocketProvider",
 *       );
 *   }
 *
 *   return context;
 * }
 * ```
 */

const DEFAULT_RECONNECT_ATTEMPT_INTERVAL = 4000;

export default function createSocketHook<T>(params: ISocketHook<T>) {
  const socket = useRef<WebSocket>();
  const [data, setData] = useState<T>(params.defaultData);
  const [hookStatus, setHookStatus] = useState<ISocketHookStatus>(
    ISocketHookStatus.IDLE);
  const [reconnectRetries, setReconnectRetries] = useState<number>(0);

  useEffect(() => {
    if (!socket.current)
      reconnect(true);
    return () => {
      if (socket.current?.readyState && socket.current?.readyState < 2)
        socket.current?.close();
    };
  }, []);

  useEffect(() => {
    /* when accessing any state value inside socket event handlers, the value
     * will be the one at the moment of assignment of the handler. Therefore,
     * we need to reassign the handlers so it reflects the new state.
     */
    setOnMessageHandler(true);
    setOnOpenHandler(false, true);
    setOnErrorHandler(true);
    setOnCloseHandler(false, true);
  }, [hookStatus, data, socket.current]);

  const onMessage = (_message: string) => {
    // onmessage handler that simply parses the message and sets data

    // convert message to object
    const fixedMessage: string = _message.replaceAll("'", '"')
      .replaceAll("None", "null").replaceAll("False", "false")
      .replaceAll("True", "true");

    const parsedMessage: any = JSON.parse(fixedMessage);
    setData(parsedMessage);

    // recreate callback
    setOnMessageHandler();
    return parsedMessage;
  };

  const setOnOpenHandler = (isFirstConnection?: boolean, forceReset?: boolean) => {
    // set onopen handler if none defined
    if (socket && socket.current && (forceReset || !(socket.current as WebSocket).onopen)) {
      socket.current.onopen = () => {
        setHookStatus(ISocketHookStatus.OPEN);
        setReconnectRetries(0);
        if (!isFirstConnection && params.onReconnectCallback)
          params.onReconnectCallback({
            socket: socket.current,
            send,
            data,
            setData,
            hookStatus,
            setHookStatus,
            reconnect,
            closeForcibly,
          });
        else if (params.onOpenCallback)
          params.onOpenCallback({
            socket: socket.current,
            send,
            data,
            setData,
            hookStatus,
            setHookStatus,
            reconnect,
            closeForcibly,
          });
      };
    };
  };

  const setOnMessageHandler = (forceReset?: boolean) => {
    // set onmessage handler if none defined
    if (socket && socket.current && (forceReset || !(socket.current as WebSocket).onmessage)) {
      (socket.current as WebSocket).onmessage = (ev: MessageEvent<any>) => {
        setHookStatus(ISocketHookStatus.LOADING);

        const newData = onMessage(ev.data);
        if (params.onMessageCallback)
          params.onMessageCallback({
            event: ev,
            eventData: newData,
            socket: socket.current,
            send,
            data: newData,
            setData,
            hookStatus,
            setHookStatus,
            reconnect,
            closeForcibly,
          });

        setHookStatus(ISocketHookStatus.OPEN);
      };
    };
  };

  const setOnErrorHandler = (forceReset?: boolean) => {
    // set onerror handler if none defined
    if (socket && socket.current && (forceReset || !(socket.current as WebSocket).onerror)) {
      (socket.current as WebSocket).onerror = (error) => {
        setHookStatus(ISocketHookStatus.ERROR);
        setData(params.defaultData);
        console.error('WebSocket reconnection error:', error);
        if (params.onErrorCallback)
          params.onErrorCallback({
            error,
            socket: socket.current,
            send,
            data,
            setData,
            hookStatus,
            setHookStatus,
            reconnect,
            closeForcibly,
          });

        if (!params.noReconnect && socket.current!.readyState < 2)
          setTimeout(
            reconnect,
            params.reconnectAttemptInterval || DEFAULT_RECONNECT_ATTEMPT_INTERVAL
          );
      };
    };
  };

  const setOnCloseHandler = (preventReconnect?: boolean, forceReset?: boolean) => {
    // set onclose handler if none defined
    if (socket && socket.current && (forceReset || !(socket.current as WebSocket).onclose)) {
      socket.current.onclose = (ev: CloseEvent) => {
        setHookStatus(ISocketHookStatus.CLOSE);
        setData(params.defaultData);
        if (params.onCloseCallback)
          params.onCloseCallback({
            event: ev,
            socket: socket.current,
            send,
            data,
            setData,
            hookStatus,
            setHookStatus,
            reconnect,
            closeForcibly,
          });

        if (!params.noReconnect && !preventReconnect)
          setTimeout(
            reconnect,
            params.reconnectAttemptInterval || DEFAULT_RECONNECT_ATTEMPT_INTERVAL
          );
      };
    };
  };

  function send(payload: any) {
    // helper to send payloads htrough socket by stringifying them
    if (!socket.current)
      console.warn('Unable to send data: socket not found');
    else if (socket.current.readyState >= 2)
      console.warn('Unable to send data: socket is closed or closing');
    else
      socket.current.send(JSON.stringify(payload));
  };

  function reconnect(isFirstConnection?: boolean) {
    // create a new WebSocket and set event handlers
    setHookStatus(ISocketHookStatus.CONNECTING);

    setReconnectRetries(reconnectRetries + 1);
    if (params.maxReconnectRetries &&
        params.maxReconnectRetries < reconnectRetries
    ) {
      setHookStatus(ISocketHookStatus.CLOSE);
      console.error(
        `Max reconnects achieved to socket (${params.maxReconnectRetries} attempts)`
      );
      return;
    };

    const url = params.url.includes('://') ? params.url
      : `${API_SOCKET_URL}${params.url}`;

    socket.current = new WebSocket(url);
    setOnOpenHandler(isFirstConnection);
    setOnErrorHandler();
    setOnMessageHandler();
    setOnCloseHandler();
  };

  function closeForcibly() {
    // close socket and prevent auto-reconnection
    setOnCloseHandler(true);
    socket.current?.close();
  };

  return {
    data,
    setData,
    socket: socket.current,
    hookStatus,
    setHookStatus,
    send,
    reconnect,
    closeForcibly,
  }
};

