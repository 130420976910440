import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import {
    Box,
    Button,
    Card,
    Collapse,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    IAlert,
    IAlertConstantEntry,
} from '../../../types';
import { Title2, Title3, BodyText } from '../../atoms/text';
import { BORDER_RADIUS } from '../../../utils/consts';
import theme from '../../theme';
import { colors } from '../../theme/styles';
import { silenceAlert } from '../../../services/alerts';

const MAX_DESCRIPTION_LENGTH = 50;


const AlertActiveCard: FC<{
    alert: IAlert,
    comparisons: IAlertConstantEntry[],
    levels: IAlertConstantEntry[],
    sources: IAlertConstantEntry[],
    statuses: IAlertConstantEntry[],
}> = (params: {
    alert: IAlert,
    comparisons: IAlertConstantEntry[],
    levels: IAlertConstantEntry[],
    sources: IAlertConstantEntry[],
    statuses: IAlertConstantEntry[],
}) => {
    if (!params.alert || !params.alert.status)
        return <></>;

    const [isSilent, setIsSilent] = useState<boolean>('SILENT' === params.statuses.find(
        s => s.value === params.alert.status?.status
    )?.name);
    const [eventAt, setEventAt] = useState<string>(
        moment(
            params.alert.status.eventAt,
            'YYYY-MM-DD HH:mm:ss.S'
        ).format('DD/MM/YYYY HH:mm:ss.S')
    );
    const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);

    useEffect(() => {
        setEventAt(params.alert.status ? moment(
            params.alert.status.eventAt,
            'YYYY-MM-DD HH:mm:ss.S'
        ).format('DD/MM/YYYY HH:mm:ss.S')
        : eventAt)
    }, [params.alert])

    async function handleButtonClick(alertId: string, activate: boolean) {
        const res = await silenceAlert(alertId, activate);
        if (res) {
            setIsSilent(!activate);
            setEventAt(moment().format('DD/MM/YYYY HH:mm:ss.S'))
        }
    }

    const isCritical = 'CRITICAL' === params.levels.find(
            s => s.value === params.alert.level
        )?.name;

    return <Card elevation={3} sx={{
        opacity: isSilent ? '70%' : '1',
        borderLeft: `7px solid ${isCritical ? theme.palette.primary.main : '#FFAE00'}`,
        background: '#FFF',
        borderRadius: BORDER_RADIUS * 4,
        padding: '8px 24px 18px 16px',
    }}>
        <Title2 bold style={{ fontStyle: isSilent ? 'italic' : 'none', marginBottom: '4px' }}>{params.alert.title}</Title2>
        <Box sx={{
            display: 'flex', justifyContent: 'space-between', width: '100%',
            fontStyle: isSilent ? 'italic' : 'none',
            color: isSilent ? colors.textColor : colors.primary
        }}>
            <Title3 color={colors.primary} bold={false}>
                {params.levels.find((l) => l.value === params.alert.level)?.description}
            </Title3>
            <Title3 color={colors.primary} bold={false}>
                {isSilent && `Silenciado em `}{eventAt.split('.')[0]}
            </Title3>
        </Box>
        <BodyText>
            "{
                params.sources.find(
                    s => s.value === params.alert.source
                )?.description
            }" {
                params.comparisons.find(
                    c => c.value === params.alert.comparison
                )?.description.toLowerCase()
            } {params.alert.value}% de adequação nas últimas {
                params.alert.activationCarcassesCount
            } carcaças
        </BodyText>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '8px',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    paddingRight: '8px',
                    alignItems: 'center',
                    width: '100%'
                }}
                onClick={() => setDescriptionExpanded(!descriptionExpanded)}
            >
                {params.alert.description && <>
                    {params.alert.description.length > MAX_DESCRIPTION_LENGTH && <>
                        {descriptionExpanded ?
                            <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </>}
                    {!descriptionExpanded &&
                        <BodyText style={{ fontStyle: 'italic' }}>
                            {params.alert.description.length > MAX_DESCRIPTION_LENGTH ?
                                params.alert.description.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...'
                                : params.alert.description
                            }
                        </BodyText>
                    }
                </>}
            </Box>
            <Button
                variant='outlined'
                onClick={() => handleButtonClick(params.alert.id!, isSilent)}
                sx={{ padding: '6px 18px' }}
            >
                {isSilent ? 'Reativar' : 'Silenciar'}
            </Button>
        </Box>
        {params.alert.description &&
            <Collapse in={descriptionExpanded &&
                    params.alert.description.length > MAX_DESCRIPTION_LENGTH
                }
                onClick={() => setDescriptionExpanded(false)}
            >
                <BodyText style={{ fontStyle: 'italic', paddingTop: '12px' }}>
                    {params.alert.description}
                </BodyText>
            </Collapse>
        }
    </Card>;
}

export default AlertActiveCard;
