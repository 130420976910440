import React, { FC } from 'react';
import { Grid } from '@mui/material';

import {
    Container,
    Page,
    Text,
    theme
  } from '../../components';
import { VERY_PADDING } from '../../utils/consts';
import DownloadFilter from './filter';
import DownloadPageCounts from './counts';
import DownloadPageActions from './actions';


const DownloadPage: FC = () => {
    return <Page width='100%' height='auto' style={{ minHeight: '95vh', padding: "0 30px 40px" }}>
        <Container fluid flex color={theme.palette.background.default} width="100%" >
            <Container fluid sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING, paddingTop: VERY_PADDING}}>
                <Grid container rowSpacing={4} columnSpacing={4} sx={{alignItems: "center"}}>
                    <Grid item lg={2} xs={12}>
                        <Text semiBold color={theme.palette.primary.main} size={25}>Download</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <DownloadFilter />
                        <DownloadPageCounts />
                        <DownloadPageActions />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    </Page >
}

export default DownloadPage;
