import React, { FC } from 'react';
import Grid from '@mui/material/Grid';

import { Container, Page, theme } from '../../../components';
import { Title1, Title2, BodyText } from '../../../components/atoms/text';
import CRUDTable from './table';
import AlertGroupsSchedulesCRUDTable from './schedulesTable'


const AlertGroups: FC = () => {
    return (
        <Page width='auto'>
            <Container fluid flex color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline style={{
                    flex: '0',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Grupos de Alerta</Title1>
                        <BodyText muted>Alertas / Grupos de Alerta</BodyText>
                    </Container>
                </Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Title2 style={{ marginBottom: '-1.5em', marginTop: '50px' }}>Cadastro de Grupos</Title2>
                        <CRUDTable />
                    </Grid>
                    <Grid item xs={12}>
                        <Title2 style={{ marginBottom: '-1.5em', marginTop: '50px' }}>Cadastro de Horários</Title2>
                        <AlertGroupsSchedulesCRUDTable />
                    </Grid>
                </Grid>
            </Container>
        </Page >
    )
}

export default AlertGroups;
