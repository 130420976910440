import React, { FC, useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import { MonitorHeart as MonitorHeartIcon } from "@mui/icons-material";
import moment from 'moment';

import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { useBranches } from '../../../../hooks/branches';
import { IBranchesSystemsHealth, IBranchSystemHealth } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';

const BranchesSystemsHealth: FC<IBranchesSystemsHealth> = (params: IBranchesSystemsHealth) => {
    const { healths, reloadHealthStatus } = useBranches();

    const [auxHealths, setAuxHealths] = useState<IBranchSystemHealth[]>([]);

    useEffect(() => {
        setAuxHealths([...healths.filter(item => item.branchId == params.branch.id)]);
    }, [healths]);

    const minutesDiff = (date: Date) => {
        const duration = moment.duration(moment().diff(date))
        return duration.asMinutes();
    }

    return (
        <Container fluid flex padded>
            <Container fluid flex padded centered>
                <Container fluid flex>
                    <Text semiBold size={16} color={theme.palette.grey[600]}>Saúde do(s) sistema(s)</Text>
                    <Spacing top={PADDING} />
                </Container>

                {(auxHealths == undefined || auxHealths.length == 0) && <Text size={14} color={theme.palette.grey[600]}>Nenhuma informação de saúde dos sistemas foi encontrada.</Text>}
                {auxHealths?.map((item, index) => (
                        <Container key={index} fluid hasShadow padded borderRadius={BORDER_RADIUS} color={theme.palette.grey[100]} width="95%">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Text bold size={14} color={theme.palette.grey[600]}>Sistema:
                                        <Text bold size={14} color={theme.palette.grey[500]}>
                                            {` ${item.name}`}
                                        </Text>
                                    </Text>
                                </Grid>
                                <Grid item xs={4}>
                                    <Text bold size={14} color={theme.palette.grey[600]}>Última checagem:
                                        <Text bold size={14} color={theme.palette.grey[500]}>
                                            {` ${item.checkedAt}`}
                                        </Text>
                                    </Text>
                                </Grid>
                                <Grid item xs={2}>
                                    {minutesDiff(moment(item.checkedAt, 'DD/MM/YYYY HH:mm:ss').toDate()) < 1 &&
                                        <Container inline horizontalCentered>
                                            <Text semiBold size={14} color={theme.palette.success.main}>Saudável</Text>
                                            <Spacing left={PADDING} />
                                            <MonitorHeartIcon color="success" />
                                        </Container>
                                    }
                                    {minutesDiff(moment(item.checkedAt, 'DD/MM/YYYY HH:mm:ss').toDate()) >= 1 &&
                                        minutesDiff(moment(item.checkedAt, 'DD/MM/YYYY HH:mm:ss').toDate()) < 5 &&
                                        <Container inline horizontalCentered>
                                            <Text semiBold size={14} color={theme.palette.warning.main}>Verificar sistema</Text>
                                            <Spacing left={PADDING} />
                                            <MonitorHeartIcon color="warning" />
                                        </Container>
                                    }
                                    {minutesDiff(moment(item.checkedAt, 'DD/MM/YYYY HH:mm:ss').toDate()) >= 5 &&
                                        <Container inline horizontalCentered>
                                            <Text semiBold size={14} color={theme.palette.error.main}>Crítico</Text>
                                            <Spacing left={PADDING} />
                                            <MonitorHeartIcon color="error" />
                                        </Container>
                                    }

                                </Grid>
                            </Grid>
                        </Container>
                ))}
            </Container>
        </Container>
    );
}

export default BranchesSystemsHealth;
