import React, { FC, useEffect, useState } from "react";
import { Button, Divider, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { Close as CloseIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';

import { IDashboardPopMultiSelect } from "../../../../types";
import Text from '../../../atoms/text';
import { theme } from '../../../../components'
import { useCarcassesPops } from "../../../../hooks/carcassesPops";


const DashboardPopMultiSelect: FC<IDashboardPopMultiSelect> = (params: IDashboardPopMultiSelect) => {
    const { pops, fetchPops } = useCarcassesPops();
    useEffect(() => {
      fetchPops(params.branches || []);
    },[])

    let selectAllPops = () => {
        params.setSelectedPops(pops.map((pop) => pop.name))
    }
    return (
        <Select
            multiple
            displayEmpty
            disabled={params.disabled}
            value={params.selectedPops}
            onChange={params.onChangePops}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <p style={{color: '#C8C8C8'}}>POPs</p>;
                }
                const descriptions = selected.map(name => {
                    const foundPop = pops.find(pop => pop.name === name);
                    return foundPop ? foundPop.description : name;
                });

                return descriptions.join(', ');
            }}
            sx={{
                minWidth:"100%",
                height: '37px',
                backgroundColor: '#FFF',
                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                borderRadius: '4px',
                marginTop: '0px'
            }}
        >
            <MenuItem disabled value="">
                <em>Pops</em>
            </MenuItem>
            {pops.map((pop) => (
                <MenuItem key={pop.description} value={pop.name}>
                    <Checkbox checked={params.selectedPops.indexOf(pop.name) > -1} />
                    <ListItemText primary={pop.description} />
                </MenuItem>
            ))}
            <div>
                <Divider sx={{ width: "100%" }} />
                <MenuItem value="" sx={{'&:hover': {backgroundColor: 'transparent', color: 'inherit', boxShadow: 'none', cursor: 'default'}}}>
                    <Button startIcon={<DoneAllIcon style={{ color: theme.palette.text.primary }} />} 
                        onClick={() => selectAllPops()} 
                        sx={{width: '50%', justifyContent: "end"}}
                    >
                        <Text size={15}>Selecionar todos</Text>
                    </Button>
                    {params.selectedPops.length > 0 && (
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSelectedPops([])}
                            sx={{width: '50%', justifyContent: 'end'}}
                        >
                            <Text size={15}>Limpar</Text>
                        </Button>
                    )}
                </MenuItem>
            </div>
        </Select>
    )
}

export default DashboardPopMultiSelect;
