import React, { FC, useEffect, useState } from "react";
import { Button, Divider, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { IDashboardSexMultiSelect } from "../../../../types";
import Text from '../../../atoms/text';
import { theme } from '../../../../components'


interface ISex {
    label: string;
    value: string;
}

const DashboardSexMultiSelect: FC<IDashboardSexMultiSelect> = (params: IDashboardSexMultiSelect) => {
    
    const sexOptions: ISex[] = [
        {
            label: 'Macho',
            value: 'M',
        },
        {
            label: 'Femea',
            value: 'F',
        },
    ]

    return (
        <Select
            multiple
            displayEmpty
            disabled={params.disabled}
            value={params.sexes}
            onChange={params.onChangeSexes}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <p style={{color: '#C8C8C8'}}>Sexo</p>;
                }
                return selected.join(', ');
            }}
            sx={{
                minWidth:"100%",
                height: '37px',
                backgroundColor: '#FFF',
                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                borderRadius: '4px',
                marginTop: '0px'
            }}
        >   
            <MenuItem disabled value="">
                <em>Sexo</em>
            </MenuItem>
            {sexOptions.map((sex) => (
                <MenuItem key={sex.label} value={sex.value}>
                    <Checkbox checked={params.sexes.indexOf(sex.value) > -1} />
                    <ListItemText primary={sex.label} />
                </MenuItem>
            ))}
            {params.sexes.length > 0 && (
                <div>
                    <Divider sx={{ width: "100%" }} />
                    <MenuItem value="">
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSexes([])}
                            sx={{width: '100%', justifyContent: 'end'}}
                        >
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </MenuItem>
                </div>
            )}
        </Select>
    )
}

export default DashboardSexMultiSelect;