import React, { FC } from 'react';

import { Container, Page, Spacing, theme } from '../../components';
import { PADDING } from '../../utils/consts'
import { Title1 } from '../../components/atoms/text';
import HashCount from '../../components/molecules/controlPanel/hashCount';


const ControlPanel: FC = () => {
    return (
        <Page width='auto' height='auto' color={theme.palette.background.default} style={{minHeight: "100%"}}>
            <Container fluid flex horizontalCentered height='auto' width="100%" veryPadded>
                <Container inline width="100%" spacedBetween> 
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Painel de Controle</Title1>
                    </Container>
                </Container>
                <Spacing top={PADDING} />
                <HashCount />
            </Container>
        </Page>
    )
}

export default ControlPanel;