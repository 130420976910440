import { Grid, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';

import 'react-image-lightbox/style.css';

import logoIcon from '../../../../assets/logo.png';
import { useAuth } from '../../../../hooks/auth';
import { getCarcassClassification, getCarcassClassificationColor } from '../../../../hooks/carcasses';
import { ICarcass } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';
import { convertNumberToFixedAndLocale } from '../../../../utils/stringFormatters';
import Image from '../../../atoms/image';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';

interface LotCarcasseCardProps {
    carcass: ICarcass;
}

const LotCarcasseCard: FC<LotCarcasseCardProps> = ({ carcass }) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { roles } = useAuth();

    // Data to be shown
    const carcassData = [
        {
            include: !!carcass?.typification,
            title: 'Tipificação',
            value: <>{carcass?.typification}</>,
        },
        {
            include: roles.includes('view_well_placed') && carcass?.bestPlaced !== undefined,
            title: 'Bem pos',
            value: <>{carcass?.bestPlaced ? "Sim": "Não"}</>,
        },
    ]

    // Data to be shown under 'Partner data'
    const partnerData = [
        {
            include: !!carcass?.sex,
            title: 'Sexo',
            value: <>{carcass?.sex}</>,
        },
        {
            include: !!carcass?.weight,
            title: 'Peso',
            value: !!carcass?.weight ? <>
                {
                    convertNumberToFixedAndLocale(carcass?.weight!)
                } kg ({
                    convertNumberToFixedAndLocale(carcass?.weight! / 15)
                } @)
            </> : <></>,
        },
        {
            include: carcass?.dentition !== undefined,
            title: 'Dentição',
            value: <>{carcass?.dentition}</>,
        },
        {
            include: !!carcass?.cnpj,
            title: 'CNPJ',
            value: <>{carcass?.cnpj}</>,
        },
        {
            include: !!carcass?.farm,
            title: 'Fazenda',
            value: <>{carcass?.farm}</>,
        },
        {
            include: !!carcass?.uf && !!carcass?.city,
            title: 'Cidade',
            value: <>{carcass?.city} - {carcass?.uf}</>,
        },
        {
            include: !!carcass?.docNum,
            title: 'Contrato',
            value: <>{carcass?.docNum}</>,
        },
    ]

    const renderClassificationText = () => {
        if(carcass?.classificationAccuracy && carcass?.classificationAccuracyB){
            if(carcass?.classificationAccuracy > carcass.classificationAccuracyB){
                return getCarcassClassification(carcass?.classificationId)
            }
            return getCarcassClassification(carcass.classificationIdB || carcass?.classificationId)
        }
        return getCarcassClassification(carcass?.classificationId)
    };

    const renderDataEntry = (
            item: {include: boolean, title: string, value: React.ReactElement}, index: number
    ) => {
        if (!item || !item.include) return <></>;
        return <Grid container borderBottom={2.75} sx={{borderColor: "white"}} key={item.title + String(index)}>
            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                <Text size={13}>{item.title}: </Text>
            </Grid>
            <Grid item xs={6} textAlign="left">
                <Text semiBold size={12}> {item.value} </Text>
            </Grid>
        </Grid>;
    }

    return (
        <Grid container direction="row" 
            borderRadius={BORDER_RADIUS/2} 
            boxShadow={3} 
            sx={{ backgroundColor: theme.palette.grey[100] }}
            item
            xs={12}
            >
            <Grid item xs={6} sm={3}>
                <div style={{ borderRadius: `${BORDER_RADIUS*2}px 0 0 ${BORDER_RADIUS*2}px`, overflow: 'hidden' }}>
                    <Image height={isSmallScreen ? 380 : 400} source={carcass?.photoUri ? carcass?.photoUri : ""} ></Image>
                </div>
            </Grid>
            <Grid item xs={6} sm={3.5}>
                <div style={{ borderRadius: `0 ${BORDER_RADIUS*2}px ${BORDER_RADIUS*2}px 0`, overflow: 'hidden', textAlign: isSmallScreen ? 'end' : 'unset'}}>
                    <Image height={isSmallScreen ? 380 : 400} source={carcass.photoUriB || ""} ></Image>
                </div>
            </Grid>
            <Grid item xs={5.5} display={{ xs: 'none', sm: 'grid'}}>
                <Grid item paddingLeft={1} paddingRight={2}>
                    <Spacing top={PADDING}/>
                    <Grid container item xs={12} justifyContent="center" alignItems="center" textAlign="center" >
                        <Grid item xs={10}>
                            <Text size={15} semiBold color={getCarcassClassificationColor(carcass?.classificationId)}>
                                {renderClassificationText()}
                            </Text>
                        </Grid>
                        <Grid item xs={2}>
                            <Image source={logoIcon} height={30} width={30 * 1.666} />
                        </Grid>

                    </Grid>
                    <Spacing top={PADDING}/>
                    <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                        <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                            <Text size={12} bold>Sequência </Text>
                        </Grid>
                        <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                            <Text semiBold size={12} bold>{carcass?.partnerId}</Text>
                        </Grid>
                        
                    </Grid>
                    {carcassData.map((item, index) => renderDataEntry(item, index))}
                    <Spacing top={PADDING}/>
                    <Grid item xs={12} textAlign="left">
                        <Text size={13} semiBold style={{color: theme.palette.primary.main}}>
                            Dados do parceiro:
                        </Text>
                    </Grid>
                    <Spacing top={PADDING}/>
                    {partnerData.map((item, index) => renderDataEntry(item, index))}
                </Grid>
            </Grid>
        </Grid>   
    );
}

export default LotCarcasseCard;


