import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { BodyText } from '../../atoms/text';
import { useSlaughterDelays } from '../../../hooks/slaughterDelays';
import { colors } from '../../theme/styles';


const SlaughterDelaysDurationOccurrencesSwitch: FC<any> = () => {
    const {
        loading,
        groupByDuration,
        setGroupByDuration
    } = useSlaughterDelays();


    return (
        <Stack direction='row' alignItems='center'>
            <BodyText color={groupByDuration ? colors.textColor : colors.primary }>
                Ocorrências
            </BodyText>
            <Switch
                checked={groupByDuration}
                onChange={() => setGroupByDuration(!groupByDuration)}
                disabled={loading}
            />
            <BodyText color={groupByDuration ? colors.primary : colors.textColor}>
                Duração
            </BodyText>
        </Stack>
    );
}

export default SlaughterDelaysDurationOccurrencesSwitch;
