import React, { FC, createContext, useContext, useState } from 'react'
import moment from 'moment';
import * as XLSX from 'xlsx';

import {
    createVerdict,
    batchCreateVerdict,
    updateVerdict,
    getVerdicts,
    destroyVerdict,
    getVerdict,
    getVerdictsSheet,
    getVerdictsDownloadFilePath,
} from '../services/verdicts';
import { IVerdict, IVerdictContext, IVerdictFilter } from '../types'

const VerdictsContext = createContext<IVerdictContext>({} as IVerdictContext);

export const VerdictsProvider: FC<any> = ({ children }) => {
    const [verdicts, setVerdicts] = useState<IVerdict[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchVerdict, setSearchVerdict] = useState<string>('');
    const [verdictsPerRow, setVerdictsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const [filter, setFilter] = useState<IVerdictFilter>({
        initialDate: new Date(),
        finalDate: new Date(),
        annotators: [],
        branches: [],
        classificationsId: [],
        conformationsId: [],
        limit: 10,
        accuracy: 0,
        localClassifications: [],
        localConformations: [],
        option: 'all',
        property: 'all',
        offset: 0,
    });

    const fetchVerdicts = async () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 5000);
        try {
            const verdicts: IVerdict[] = await getVerdicts();
            setVerdicts([...verdicts]);
        } catch (_err) {
            console.log(_err);
        }
        setLoading(false);
    }

    const fetchVerdict = async (_id: string) => {
        const verdict: IVerdict = await getVerdict(_id);
        return verdict;
    }

    const createNewVerdict = async (_verdict: IVerdict, _reload: boolean = true) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 6000);
        const verdict = await createVerdict(_verdict);
        if (_reload)
            fetchVerdicts();
        setLoading(false);
        return verdict;
    }

    const batchCreateNewVerdicts = async (
        _verdicts: IVerdict[], _reload: boolean = true
    ) => {
        setLoading(true);
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 10000);
        const res = await batchCreateVerdict(_verdicts);
        clearTimeout(timeout);
        if (_reload)
            fetchVerdicts();
        setLoading(false);
        return res;
    }

    const editVerdict = async (_id: string, _payload: IVerdict) => {
        try {
            const verdict = await updateVerdict(_id, _payload);

            fetchVerdicts();
            return verdict;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteVerdict = async (_id: string) => {
        const verdict = await destroyVerdict(_id);
        fetchVerdicts();
        return verdict;
    }

    const downloadVerdictsSheet = async (
        _initialDate : Date,
        _finalDate: Date,
        _branches?: string,
        _classifications?: string,
        _localClassifications?: string,
        _conformations?: string,
        _localConformations?: string,
        _includeClassification?: boolean,
        _includeConformation?: boolean,
    ) => {
        try {
            let query: string = `?initial_date=${moment(_initialDate).format('YYYY-MM-DD')}`;
            query += `&final_date=${moment(_finalDate).format('YYYY-MM-DD')}`;
            if (_branches)
                query += `&branches_id=${_branches}`;
            if (_classifications)
                query += `&classifications=${_classifications}`;
            if (_localClassifications)
                query += `&local_classifications=${_localClassifications}`;
            if (_conformations)
                query += `&conformations=${_conformations}`;
            if (_localConformations)
                query += `&local_conformations=${_localConformations}`;
            if (_includeClassification)
                query += `&include_classification=1`;
            if (_includeConformation)
                query += `&include_conformation=1`;

            let csvData = await getVerdictsSheet(query);
            csvData = csvData.replace(/\r/g, '').replace(/"/g, '');
            const data = csvData.split('\n').map((row: any) => row.split(','));
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, `planilha_arbitragem_${_initialDate.toLocaleDateString('pt-BR')}_ate_${_finalDate.toLocaleDateString('pt-BR')}.xlsx`);
        } catch (_err) {
            console.error(_err);
            return _err;
        }
        return false;
    }

    const downloadVerdictsFilePath = async (_filePath: string) => {
        try {
            const responseDownload: any = await getVerdictsDownloadFilePath(_filePath);
            return responseDownload;
        } catch (err) {
            throw err;
        }
    };

    return (
        <VerdictsContext.Provider value={{
            verdicts, loading, fetchVerdicts, fetchVerdict,
            createNewVerdict, editVerdict, deleteVerdict,
            downloadVerdictsSheet, downloadVerdictsFilePath,
            searchVerdict, setSearchVerdict, verdictsPerRow,
            setVerdictsPerRow, paginate, setPaginate,
            filter, setFilter, batchCreateNewVerdicts
        }}>{children}</VerdictsContext.Provider>
    )
}

export function useVerdicts() {
    const context = useContext(VerdictsContext);

    if (!context) {
        throw new Error('useVerdicts must be used within an VerdictsProvider')
    }

    return context;
}
