import React, { FC } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

import { ILabelId } from "../../../types";
import { SlaughterDelayType } from "../../../types/hooks/slaughterDelays";


interface ISlaughterDelayTypeSelect {
    value: number | null;
    setValue: (val: number | null) => void;
    placeholder?: string;
    sx?: any;
};


const SlaughterDelayTypeSelect: FC<ISlaughterDelayTypeSelect> = (params: ISlaughterDelayTypeSelect) => {
    const options: ILabelId[] = Object.values(
        SlaughterDelayType).map(item => ({
            id: item.value,
            label: item.label
        }));

    const handleChange = (event: SelectChangeEvent) => {
        params.setValue(
            event.target.value ? parseInt(event.target.value) : null
        );
    };

    return (
        <FormControl fullWidth
            sx={{
                marginTop: 0,
                ...params.sx
            }}
        >
            <InputLabel>{params.placeholder || 'Seleção de nível'}</InputLabel>
            <Select
                value={params.value?.toString() || ''}
                onChange={handleChange}
                label={params.placeholder || 'Seleção de nível'}
                IconComponent={params.value ? CloseIcon : ArrowDropDownIcon}
                onOpen={() => params.setValue(null)}
            >
                {options.map((opt) =>
                    <MenuItem value={opt.id} key={opt.id}>
                        {opt.label}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default SlaughterDelayTypeSelect;
