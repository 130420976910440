import { Box, Divider, Grid, Stack } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import 'react-image-lightbox/style.css';

import Container from '../../../atoms/container';
import theme from '../../../theme';
import CarcassImages from './CarcassImages';

import logoIcon from '../../../../assets/logo.png';
import { Image } from '../../../../components';
import { ICarcass, ICarcassDetailedItem } from '../../../../types';
import { capitalizeTheFirstLetterOfEachWord, convertNumberToFixedAndLocale } from '../../../../utils/stringFormatters';
import { BodyText, LargeText, Title1, Title2, Title3, Title4 } from '../../../atoms/text';
import { colors } from '../../../theme/styles';


/* Card displaying detailed information about a carcass (i.e. data and pictures) */
const CarcassDetailedItem: FC<ICarcassDetailedItem> = (params: ICarcassDetailedItem) => {
    const renderSequenceItemTitle = (carcass: ICarcass) => {
        if(!carcass.partnerId && carcass.sequence)
            return `Ordinal ${carcass.sequence}`;
        return `Sequencial Local ${carcass.partnerId}`;
    }

    // Defines what will be shown under "RedSoft data"
    const redsoftData = params.carcass ? [
        {
            include: params.carcass.sequence,
            title: 'Ordinal Redsoft',
            value: params.carcass.sequence,
            titleJSX: Title3,
        },
        {
            include: params.carcass.iaTypification,
            title: 'Acabamento Redsoft',
            value: params.carcass.iaTypification + (params.carcass.localClassificationRedsoft ? ` (${params.carcass.localClassificationRedsoft})`: ''),
        },
        {
            include: ((params.carcass.conformationId !== 0 || params.carcass.conformationIdB !== 0) && params.carcass.conformationName),
            title: 'Conformação Redsoft',
            value: params.carcass.conformationName,
        },
        {
            include: params.carcass.typification,
            title: 'Tipificação Redsoft',
            value: params.carcass.typification,
        },
    ] : [];

    // Defines what will be shown under "ERP data"
    const data = params.carcass ? [
        {
            include: params.carcass.partnerId,
            title: 'Sequencial',
            value: <>{params.carcass.partnerId}</>,
        },
        {
            include: (params.carcass.date || params.carcass.classificationDate),
            title: 'Data de abate',
            value: <>{params.carcass.date ?
                moment(params.carcass.date).add(1, 'h').format('DD/MM/YYYY HH:mm:ss') //Essa vem no horario do mato-grosso
                : (moment(params.carcass.classificationDate).format('HH:mm:ss') === '00:00:00' ?
                    moment(params.carcass.classificationDate).format('DD/MM/YYYY') :
                    moment(params.carcass.classificationDate).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') //Essa vem no UTC
            )
                
                }
            </>,
        },
        {
            include: params.carcass.metaData?.typification,
            title: 'Tipificação',
            value: <>{params.carcass.metaData?.typification}</>,
        },
        {
            include: params.carcass.localClassification && params.carcass.localClassification !== "Indefinido",
            title: 'Acabamento Local',
            value: <>{params.carcass.localClassification}</>,
        },
        {
            include: params.carcass.breed,
            title: 'Raça',
            value: <>{
                params.carcass.breed &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.breed)
            }</>,
        },
        {
            include: (params.carcass.verdicTypification && params.carcass.verdicTypification !== "Indefinido"),
            title: 'Arbitragem',
            value: <>{params.carcass.verdicTypification}</>,
        },
        {
            include: params.carcass.complement,
            title: 'Acabamento',
            value: <>{
                params.carcass.complement &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.complement)
            }</>,
        },
        {
            include: params.carcass.localConformation,
            title: 'Conformação Local',
            value: <>{params.carcass.localConformation}</>,
        },
        {
            include: params.carcass.dif,
            title: 'Registro DIF',
            value: <>{params.carcass.dif !== ' ' ? params.carcass.dif : 'Não'}</>,
        },
        {
            include: params.carcass.destination,
            title: 'Destino de abate',
            value: <>{params.carcass.destination !== ' ' ? params.carcass.destination : 'Não'}</>,
        },
        {
            include: params.carcass.city,
            title: 'Cidade',
            value: <>{
                params.carcass.city &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.city)
            }</>,
        },
        {
            include: params.carcass.uf,
            title: 'UF',
            value: <>{params.carcass.uf}</>,
        },
        {
            include: typeof params.carcass.dentition === 'number',
            title: 'Dentição',
            value: <>{params.carcass.dentition} dentes</>,
        },
        {
            include: params.carcass.lotNum,
            title: 'Sequencial de Lote',
            value: <>{params.carcass.lotNum}</>,
        },
        {
            include: params.carcass.lotNum,
            title: 'Contrato (CCA)',
            value: <>{params.carcass.docNum}</>,
        },
        {
            include: params.carcass.farm,
            title: 'Fazenda',
            value: <>{
                params.carcass.farm &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.farm)
            }</>,
        },
        {
            include: params.carcass.farm,
            title: 'Proprietário',
            value: <>{
                params.carcass.rancherName ?
                capitalizeTheFirstLetterOfEachWord(params.carcass.rancherName) : '-'
            }</>,
        },
        {
            include: params.carcass.producerName,
            title: 'Produtor',
            value: <>{
                params.carcass.producerName &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.producerName)
            }</>,
        },
        {
            include: params.carcass.sex,
            title: 'Sexo',
            value: <>{params.carcass.sex === 'M' ? 'Macho' : params.carcass.sex == 'F' ? 'Fêmea' : 'Não informado'}</>,
        },
        {
            include: params.carcass.troop,
            title: 'Tropa',
            value: <>{params.carcass.troop}</>,
        },
        {
            include: params.carcass.maturityId,
            title: 'Maturidade',
            value: <>{params.carcass.maturityId}</>,
        },
        {
            include: params.carcass.weight,
            title: 'Peso',
            value: <>
                {
                    params.carcass.weight ?
                    `${convertNumberToFixedAndLocale(params.carcass.weight)} kg (${convertNumberToFixedAndLocale(params.carcass.weight / 15)} @)`
                    : '-'
                }
            </>,
        },
    ] : [];

    return (<>
        {params.carcass && <>
            <Container inline>
                <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} md={8} sx={{ md: { height: '100%' } }}>
                        <CarcassImages
                            carcass={params.carcass}
                            loading={params.loading}
                            carcassesIds={params.carcassesIds}
                            setCurrCarcass={params.setCurrCarcass}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} height='100%' justifyContent='center' sx={{ padding: 2 }}>
                        <div style={{ marginBottom: 16 }}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Title1 style={{ marginBottom: 24 }}>{renderSequenceItemTitle(params.carcass)}</Title1>
                                </Grid>
                                <Grid item xs={2}>
                                    <Image source={logoIcon} height={40} width={40 * 1.666} />
                                </Grid>
                            </Grid>
                            {redsoftData.map((item, index) => {
                                if (!item.include) return <></>;
                                if (item.titleJSX)
                                    return <React.Fragment key={`${index}-${item.title}`}>
                                        {item.titleJSX({children:
                                            <div>
                                                <span style={{ color: colors.textColor }}>
                                                    {item.title}:&nbsp;
                                                </span>
                                                {item.value}
                                            </div>,
                                        })}
                                    </React.Fragment>

                                return <Title4 key={`${index}-${item.title}`}
                                            semiBold color={colors.primary}>
                                    <div>
                                        <span style={{ color: colors.textColor }}>
                                            {item.title}:&nbsp;
                                        </span>
                                        {item.value}
                                    </div>
                                </Title4>
                            })}
                        </div>
                        <Divider sx={{ margin: '8px auto 16px', borderColor: theme.palette.primary.light, opacity: 0.7 }} />
                        <Title3 bold style={{ paddingTop: '4px', marginBottom: '16px' }} >Dados do ERP</Title3>
                        <Stack spacing={1} divider={<Divider />}>
                            {data.map((item, index) => (
                                item.include &&
                                <Container fluid inline key={index} style={{ justifyContent: 'space-between' }}>
                                    <LargeText>{item.title}:&nbsp;</LargeText>
                                    <LargeText bold right>{item.value}</LargeText>
                                </Container>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            </>}
            {params.carcass === undefined &&
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Title4 center style={{ paddingTop: '40px', paddingBottom: '32px' }}>
                        Nenhuma carcaça selecionada, utilize a listagem à esquerda e os filtros para visualizar os detalhes de uma carcaça.
                    </Title4>
                </Box>
            }
    </>);
}

export default CarcassDetailedItem;
