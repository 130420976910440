import { FC, useState, useEffect } from "react";
import {
    Button, Divider, Modal, Paper, TextField, Typography
} from '@mui/material'
import { PADDING } from "../../../../utils/consts";
import theme from "../../../theme";
import { invalid } from "moment";
import { capitalizeTheFirstLetterOfEachWord } from "../../../../utils/stringFormatters";
import { useSendEmails } from "../../../../hooks/sendEmails";
import { ISupportEmail } from "../../../../types";

interface ISendEmailModal {
    open: boolean;
    onClose: () => void;
}

const SendEmailModal: FC<ISendEmailModal> = (params: ISendEmailModal) => {
    const defaultInvalidMessage = 'Preencha os campos obrigatórios.'

    const { sendEmailToSupport } = useSendEmails();

    const [subject, setSubject] = useState<string>('');
    const [senderName, setSenderName] = useState<string>('');
    const [senderEmail, setSenderEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [invalidMessage, setInvalidMessage] = useState<string>(defaultInvalidMessage);

    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const resetAllFields = () => {
        setSubject('');
        setSenderName('');
        setSenderEmail('');
        setMessage('');
    }

    const isEmailValid = (email: string): boolean => {
        // Regular expression for a simple email validation
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleSendEmail = () => {
        const email: ISupportEmail = {
            'senderName': sanitizeFields(senderName),
            'senderEmail': senderEmail,
            'subject': sanitizeFields(subject),
            'message': message,
        }
        sendEmailToSupport(email);
        setIsSuccess(true);
        resetAllFields();

        setTimeout(() => {
            setIsSuccess(false);
        }, 3000);
    }

    const sanitizeFields = (value: string): string => {
        // Regex to that only allows letters (with and without accents), numbers and spaces
        // To avoid html/xml/script injection
        const fieldsRegex = /[^A-Za-zÀ-ÖØ-öø-ÿ0-9 ]/g;
        const newValue = value.replace(fieldsRegex, "")
        return newValue
    }

    useEffect(() => {
        var isFormValid: boolean = subject !== '' && senderName !== '' && senderName !== '' && message !== '';
        if (!isFormValid) {
            setIsFormValid(isFormValid);
            setInvalidMessage(defaultInvalidMessage);
        } else if (!isEmailValid(senderEmail)) {
            setIsFormValid(false);
            setInvalidMessage('Email inválido, por favor digite um email válido.')
        } else {
            setIsFormValid(true);
            setSubject(capitalizeTheFirstLetterOfEachWord(subject));
            setSenderName(capitalizeTheFirstLetterOfEachWord(senderName));
        }
    }, [subject, senderName, senderEmail, message]);


    return (
        <Modal
            sx={{ display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}
            open={params.open}
            onClose={params.onClose}
            aria-labelledby="send-email-modal"
            aria-describedby="send-email-modal-description"
        >
            <Paper sx={{ width: '50em', padding: PADDING * 0.8, borderRadius: '8px', boxShadow: '5px 6px 15px -4px rgba(0,0,0,0.52)' }}>
                <Typography variant="h5" gutterBottom color={theme.palette.primary.main}>
                    Envie sua dúvida
                </Typography>
                <TextField
                    label="Seu nome"
                    placeholder="Digite seu nome"
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    value={senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    label="Seu email"
                    placeholder="Digite seu email"
                    fullWidth
                    inputProps={{ maxLength: 254 }}
                    value={senderEmail}
                    onChange={(e) => setSenderEmail(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    label="Assunto"
                    placeholder="Digite o assunto do seu email"
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    required
                    multiline
                    rows={8}
                    fullWidth
                    inputProps={{ maxLength: 800 }}
                    label="Mensagem"
                    placeholder="Digite sua mensagem, responderemos assim que possível. Caso ache necessário, coloque um telefone de contato junto da mensagem."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    margin="normal"
                />
                {!isFormValid && (
                    <Typography variant="body2" color={theme.palette.error.main}>
                        * {invalidMessage}
                    </Typography>
                )}
                <Typography variant="body2" color={theme.palette.success.main} sx={{ opacity: isSuccess ? 1 : 0, transition: "opacity 0.8s linear" }}>
                    Sua mensagem foi enviada ao suporte, retornaremos no seu email :)
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={params.onClose}
                    style={{ marginTop: '16px' }}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!isFormValid}
                    onClick={handleSendEmail}
                    style={{ marginTop: '16px', float: 'inline-end', marginLeft: '10px' }}
                >
                    Enviar
                </Button>
            </Paper>
        </Modal>
    );
};

export default SendEmailModal;
