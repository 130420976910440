import { AxiosResponse } from "axios";

import api from ".";
import { IAnnotationAnomaly, IAnnotator } from "../types";
import { convertApiResponseToIAnnotator } from "./annotators";

const baseUrl: string = "annotations_anomalies";

export const createAnnotation = async (_annotation: IAnnotationAnomaly) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    anomalyId: _annotation.anomalyId,
    annotatorId: _annotation.annotatorId,
    isAnomaly: _annotation.isAnomaly,
    isExternal: _annotation.isExternal,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 201 && response.data) {
      return convertApiResponseToIAnnotationAnomaly(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateAnnotation = async (
  _id: string,
  _annotation: IAnnotationAnomaly
) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    annotatorId: _annotation.annotatorId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotationAnomaly(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyAnnotation = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getAnnotations = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotations: IAnnotationAnomaly[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotation: IAnnotationAnomaly = convertApiResponseToIAnnotationAnomaly(data);

        annotations.push(annotation);
      }

      return annotations;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotation = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotation: IAnnotationAnomaly = convertApiResponseToIAnnotationAnomaly(
        response.data
      );
      return annotation;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotationsAnomaliesDownloadFilePath = async (_filePath: string) => {
  const response: AxiosResponse = await api.post(
    `/api_redsoft/v1_0/${baseUrl}/download_photos`,
    { filePath: _filePath },
    { responseType: "blob" }
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response;
    } else if (status == 204) {
      throw new Error("Não foi encontrada nenhuma anomalia na data selecionada");
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const setReinsertFalseAnnotationsAnomalies = async (initialDate: Date, finalDate: Date, branchId: string) => {
  const response: AxiosResponse = await api.post(
    `/api_redsoft/v1_0/${baseUrl}/reinsert`,
    { initialDate: initialDate, finalDate: finalDate, branchId: branchId },
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response;
    } else if (status == 204) {
      throw new Error("Não foi encontrada nenhuma falsa anomalia na data selecionada");
    }
  }

  throw new Error("Não foi possível buscar os dados");
}

export const convertApiResponseToIAnnotationAnomaly = (_annotation: any) => {
  let annotator: IAnnotator | undefined = undefined;
  if (_annotation.annotator)
    annotator = convertApiResponseToIAnnotator(_annotation.annotator);

  const annotation: IAnnotationAnomaly = {
    id: _annotation.id,
    annotatorId: _annotation.annotatorId,
    anomalyId: _annotation.anomalyId,
    annotator,
    isAnomaly: _annotation.isAnomaly,
    isExternal: _annotation.isExternal,

    createdAt: _annotation.createdAt,
    updatedAt: _annotation.updatedAt,
  };

  return annotation;
};
