import { AxiosResponse } from "axios";

import api from ".";
import {
  ISlaughterDelaysReason,
  ISlaughterDelaysDashboardStats,
  ISlaughterDelaysOccurrencesData,
  ISlaughterDelaysMainCausesData
} from "../types/hooks/slaughterDelays";


export const BASE_URL: string = "/api_redsoft/v1_0/slaughter_delays";

export const reasonToAutocompleteOption = (entry: ISlaughterDelaysReason) => ({
  id: entry.id!,
  label: `${entry.code} - ${entry.description}`
});


const baseGet = async (uri: string) => {
  if (!uri.startsWith('/')) uri = '/' + uri;

  const response: AxiosResponse = await api.get(`${BASE_URL}${uri}`);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data !== undefined) {
      return response.data;
    };
  };

  throw new Error("Não foi possível buscar os dados");
};


export const getDashboardStats = async (branch_id: string, query: string) => {
  const uri = `/${branch_id}/dashboard${query}`;
  return await baseGet(uri) as ISlaughterDelaysDashboardStats;
};


export const getSpreadsheetCSV = async (branch_id: string, query: string) => {
  const uri = `/${branch_id}/download${query}`;
  return await baseGet(uri);
};


export const getOccurrencesData = async (branch_id: string, query: string) => {
  const uri = `/${branch_id}/occurrences${query}`;
  const res = await baseGet(uri) as ISlaughterDelaysOccurrencesData[];
  return res.map(entry => ({...entry, duration: entry.duration / 60}));
};


export const getMainCauses = async (branch_id: string, query: string) => {
  const uri = `/${branch_id}/main_causes${query}`;
  return await baseGet(uri) as {
    reasons: ISlaughterDelaysMainCausesData,
    locations: ISlaughterDelaysMainCausesData,
  };
};
