import React, { FC, createContext, useContext, useState } from 'react'

import { createAnnotation, 
    updateAnnotation, 
    getAnnotations, 
    destroyAnnotation, 
    getAnnotation,
    getAnnotationsAnomaliesDownloadFilePath,
    setReinsertFalseAnnotationsAnomalies
 } from '../services/annotationsAnomalies';
import { IAnnotationAnomaly, IAnnotationAnomaliesContext, IBranch } from '../types'
import { IAnnotationsAnomalyFilter } from '../types/hooks/annotationsAnomalies';

const AnnotationsAnomaliesContext = createContext<IAnnotationAnomaliesContext>({} as IAnnotationAnomaliesContext);

export const AnnotationsAnomaliesProvider: FC<any> = ({ children }) => {
    const [annotations, setAnnotations] = useState<IAnnotationAnomaly[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchAnnotation, setSearchAnnotation] = useState<string>('');
    const [annotationsPerRow, setAnnotationsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const [filter, setFilter] = useState<IAnnotationsAnomalyFilter>({ initialDate: new Date(), finalDate: new Date(), branches: [] });

    const [loadingAnnotationCount, setLoadingAnnotationCount] = useState<boolean>(false);

    const fetchAnnotations = async () => {
        setLoading(true);
        try {
            const annotations: IAnnotationAnomaly[] = await getAnnotations();
            setAnnotations([...annotations]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchAnnotation = async (_id: string) => {
        const annotation: IAnnotationAnomaly = await getAnnotation(_id);
        return annotation;
    }

    const createNewAnnotation = async (_annotation: IAnnotationAnomaly, _reload: boolean = true) => {
        const annotation = await createAnnotation(_annotation);
        if (_reload)
            fetchAnnotations();
        return annotation;
    }

    const editAnnotation = async (_id: string, _payload: IAnnotationAnomaly) => {
        try {
            const annotation = await updateAnnotation(_id, _payload);

            fetchAnnotations();
            return annotation;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteAnnotation = async (_id: string) => {
        const annotation = await destroyAnnotation(_id);
        fetchAnnotations();
        return annotation;
    }

    const downloadAnnotationsAnomaliesFilePath = async (_filePath: string) => {
        try {
            const responseDownload: any = await getAnnotationsAnomaliesDownloadFilePath(_filePath);
            return responseDownload;
        } catch (err) {
            throw err;
        }
    };

    const reinsertFalseAnnotationsAnomalies = async (initialDate: Date, finalDate: Date, branchId: string) => {
        try {
            const responseReinsert: any = await setReinsertFalseAnnotationsAnomalies(initialDate, finalDate, branchId);
            return responseReinsert;
        } catch (err) {
            throw err;
        }
    };
    return (
        <AnnotationsAnomaliesContext.Provider value={{
            annotations, 
            loading, 

            fetchAnnotations, 
            searchAnnotation,  setSearchAnnotation, 
            annotationsPerRow, setAnnotationsPerRow, 
            paginate, setPaginate,
            filter, setFilter,

            fetchAnnotation,
            createNewAnnotation, editAnnotation, deleteAnnotation,

            downloadAnnotationsAnomaliesFilePath,

            reinsertFalseAnnotationsAnomalies,
            
        }}>{children}</AnnotationsAnomaliesContext.Provider>
    )
}

export function useAnnotationsAnomalies() {
    const context = useContext(AnnotationsAnomaliesContext);
    if (!context) {
        throw new Error('useAnnotationsAnomalies must be used within an AnnotationsProvider')
    }

    return context;
}
