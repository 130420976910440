import React, { FC, useRef, useState } from 'react';
import {
    FormGroup,
    FormControlLabel,
    Grid,
    Switch,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import BranchSelect from '../../branches/select';
import CarcassClassificationsMultiSelect from '../../carcasses/classificationsMultiSelect';
import CarcassConformationsMultiSelect from '../../carcasses/conformationsMultiSelect';
import CarcassesLocalClassificationsMultiSelect from '../../carcassesLocalClassifications/multiSelect';
import CarcassesLocalConformationsMultiSelect from '../../carcassesLocalConformations/multiSelect';
import Container from '../../../atoms/container';
import DateRangePicker from '../../../atoms/dateRangePicker';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { useInformation } from '../../../../hooks/information';
import {
    ILabelId,
    IVerdictDownloadModal,
    ICarcassLocalClassification,
    ICarcassLocalConformation,
} from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import { API_SOCKET_URL, API_DOWNLOAD_FILE_URL} from '../../../../utils/config';
import { useAuth } from '../../../../hooks/auth';

const VerdictDownloadModal: FC<IVerdictDownloadModal> = (params: IVerdictDownloadModal) => {
    const { user } = useAuth();
    const { showInformation } = useInformation();

    const refDownload = useRef<HTMLAnchorElement | null>(null);

    const [initialDate, setInitialDate] = useState<Date | null>(new Date());
    const [finalDate, setFinalDate] = useState<Date | null>(new Date());
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);
    const [selectedClassifications, setSelectedClassifications] = useState<ILabelId[]>([]);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const [selectedConformations, setSelectedConformations] = useState<ILabelId[]>([]);
    const [conformationsExpanded, setConformationsExpanded] = useState<boolean>(false);
    const [selectedLocalClassifications, setSelectedLocalClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [localClassificationsExpanded, setLocalClassificationsExpanded] = useState<boolean>(false);
    const [selectedLocalConformations, setSelectedLocalConformations] = useState<ICarcassLocalConformation[]>([]);
    const [localConformationsExpanded, setLocalConformationsExpanded] = useState<boolean>(false);
    const [groupByConformation, setGroupByConformation] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [urlDownload, setUrlDownload] = useState<string>('');
    const [fileNameDownload, setFileNameDownload] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [currentIndexDownload, setCurrentIndexDownload] = useState<number>(0);
    const [totalDownload, setTotalDownload] = useState<number>(0);
    const [downloading, setDownloading] = useState<boolean>(false);

    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setBranchError(undefined);
        setError(null);
    };

    const validate = () => {
        let isValid: boolean = true;

        if (!initialDate || !finalDate) {
            setError('Favor informar a data');
            isValid = false;
        }
        if (branchId == '') {
            setBranchError('Favor informar o frigorífico')
            isValid = false;
        }

        if (isValid) {
            setError('');
            setBranchError(undefined);
        }
        return isValid;
    };

    const startDownload = async (_filePath: string) => {
        try {
            setDownloading(true);
            setUrlDownload(`${API_DOWNLOAD_FILE_URL}/verdicts/${_filePath}`);
            setFileNameDownload(_filePath);
            setTimeout(() => {
                refDownload.current?.click();
                setTimeout(() => {
                    setUrlDownload('');
                    setFileNameDownload('');
                }, 1000);
            }, 1000);

            setDownloading(false);
            setLoading(false);
        } catch (err: any) {
            setDownloading(false);
            setLoading(false);
        }
    };

    const onDownload = async () => {
        if (validate() == false) return;
        if (!initialDate || !finalDate) return;

        setLoading(true);
        try {
            const socket = new WebSocket(`${API_SOCKET_URL}socket/verdicts/download_photos`)
            socket.onmessage = (ev: MessageEvent<any>) => {
                let data = JSON.parse(ev.data);
                if (data) {
                    if (data.current && data.total && data.status && data.status == 'IN_PROGRESS') {
                        setCurrentIndexDownload(parseInt(data.current))
                        setTotalDownload(parseInt(data.total))
                    } else if (data.path && data.status && data.status == 'COMPLETED') {
                        setCurrentIndexDownload(0);
                        setTotalDownload(0);

                        startDownload(data.path);
                        socket.close();
                    } else if (data.message && data.status == 'ERROR') {
                        if (data.message == "NOT_FOUND")
                            showInformation("Não foi encontrada nenhuma carcaça na data selecionada", 'Aviso');
                        else if (data.message == "MISSING_ARGS")
                            showInformation("Erro de conexão com o servidor. Entre em contato com nossa equipe de desenvolvimento", 'Aviso');
                        socket.close();
                        setLoading(false);
                    }
                }
            };
            socket.onopen = (ev: Event) => {
                let query: any = {};
                query.initialDate = moment(initialDate).format("YYYY-MM-DD");
                query.finalDate = moment(finalDate).format("YYYY-MM-DD");
                query.branchId = branchId;
                if (groupByConformation) {
                    query.groupBy = "conformation";
                    if (selectedConformations.length > 0)
                        query.conformations = selectedConformations
                            .map((item) => item.id)
                            .join(",");
                    if (selectedLocalConformations.length > 0)
                        query.localConformations = selectedLocalConformations
                            .map((item) => item.localConformation)
                            .join(",");
                } else {
                    if (selectedClassifications.length > 0)
                        query.classifications = selectedClassifications
                            .map((item) => item.id)
                            .join(",");
                    if (selectedLocalClassifications.length > 0)
                        query.localClassifications = selectedLocalClassifications
                            .map((item) => item.localClassification)
                            .join(",");
                }

                let message: any = {
                    query: query,
                    userId: user ? user.id : ''
                }
                socket.send(JSON.stringify(message));
            }
        } catch (err) {
            showInformation((err as any).message, 'Aviso');
            setLoading(false);
        }
    };

    const close = () => {
        cleanData();
        params.onClose();
    }

    const cleanData = () => {
        setInitialDate(new Date());
        setFinalDate(new Date());
        setBranchId('');
        setBranchName('');
        setBranchError(undefined);
        setSelectedClassifications([]);
        setClassificationsExpanded(false);
        setSelectedConformations([]);
        setConformationsExpanded(false);
        setSelectedLocalClassifications([]);
        setLocalClassificationsExpanded(false);
        setSelectedLocalConformations([]);
        setLocalConformationsExpanded(false);
        setError(null);
        setUrlDownload('');
        setFileNameDownload('');
        setGroupByConformation(false);
        setLoading(false);
    }

    return (
        <Modal isOpened={params.open} onClose={close}>
            <a href={urlDownload} download={fileNameDownload} className="hidden" ref={refDownload} />

            <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={BORDER_RADIUS}
                style={{ maxWidth: 540 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Download de fotos de carcaças</Text>
                    </Container>
                </Container>

                <Container fluid centered spacedAround veryPadded width="90%">
                    <Text>Preencha o filtro para efetuar o download.</Text>
                    <Spacing top={PADDING} />
                    <Text light size={13}>Esse processo pode demorar um pouco dependendo da quantidade de carcaças.</Text>
                    <Spacing top={VERY_PADDING} />

                    <Grid container rowSpacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    label={`Agrupar por ${groupByConformation ? 'Conformação' : 'Acabamento'}`}
                                    control={
                                        <Switch value={groupByConformation}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setGroupByConformation(event.target.checked)}
                                        />
                                    }
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <Text light>Data:</Text>
                        </Grid>
                        <Grid item xs={8}>
                            <DateRangePicker
                                initialDate={initialDate}
                                finalDate={finalDate}
                                placeholder={"Escolha intervalo de data"}
                                setInitialDate={setInitialDate}
                                setFinalDate={setFinalDate}
                                disabled={loading}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Text light>Frigorífico:</Text>
                        </Grid>
                        <Grid item xs={8}>
                            <BranchSelect
                                value={branchId}
                                valueName={branchName}
                                handleChangeValue={handleChangeBranch}
                                variant="outlined"
                                placeholder='Escolha o frigorífico'
                                error={branchError} />
                        </Grid>
                        {groupByConformation ? <>
                            <Grid item xs={4}>
                                <Text light>Conformações Arbitragem:</Text>
                            </Grid>
                            <Grid item xs={8}>
                                <CarcassConformationsMultiSelect
                                    selectedItems={selectedConformations}
                                    setSelectedItems={setSelectedConformations}
                                    expanded={conformationsExpanded}
                                    setExpanded={setConformationsExpanded}
                                    placeholder="Selecionar"
                                    sx={{
                                        border: `solid 1px ${theme.palette.grey[400]}`,
                                        borderRadius: '4px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Text light>Conformações Locais:</Text>
                            </Grid>
                            <Grid item xs={8}>
                                <CarcassesLocalConformationsMultiSelect
                                    placeholder="Conformação Local"
                                    selectedBranchesId={branchId ? [branchId] : []}
                                    selectedConformations={selectedLocalConformations}
                                    setSelectedConformations={setSelectedLocalConformations}
                                    expanded={localConformationsExpanded}
                                    setExpanded={setLocalConformationsExpanded}
                                    singleSelect={false}
                                    sx={{
                                        backgroundColor: '#FFF',
                                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                        borderRadius: '4px',
                                        height: '37px',
                                    }}
                                />
                            </Grid>
                        </> : <>
                            <Grid item xs={4}>
                                <Text light>Acabamentos Arbitragem:</Text>
                            </Grid>
                            <Grid item xs={8}>
                                <CarcassClassificationsMultiSelect
                                    useJBS
                                    selectedItems={selectedClassifications}
                                    setSelectedItems={setSelectedClassifications}
                                    expanded={classificationsExpanded}
                                    setExpanded={setClassificationsExpanded}
                                    placeholder="Selecionar"
                                    sx={{
                                        border: `solid 1px ${theme.palette.grey[400]}`,
                                        borderRadius: '4px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Text light>Acabamentos Locais:</Text>
                            </Grid>
                            <Grid item xs={8}>
                                <CarcassesLocalClassificationsMultiSelect
                                    placeholder="Acabamento Local"
                                    selectedBranchesId={branchId ? [branchId] : []}
                                    selectedClassifications={selectedLocalClassifications}
                                    setSelectedClassifications={setSelectedLocalClassifications}
                                    expanded={localClassificationsExpanded}
                                    setExpanded={setLocalClassificationsExpanded}
                                    singleSelect={false}
                                    sx={{
                                        backgroundColor: '#FFF',
                                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                        borderRadius: '4px',
                                        height: '37px',
                                    }}
                                />
                            </Grid>
                        </>}
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={close}>
                        <Text bold size={12} color={loading ? theme.palette.grey[400] : theme.palette.primary.light}>Fechar</Text>
                    </LoadingButton>
                    <Spacing left={PADDING} />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, borderColor: theme.palette.primary.light, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={() => onDownload()}>
                        <Text bold size={12} color={theme.palette.background.paper}>Confirmar</Text>
                    </LoadingButton>
                </Container>

                {loading && currentIndexDownload > 0 &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Baixando {currentIndexDownload} de {totalDownload}</Text>
                    </Container>
                }
                {currentIndexDownload > 0 && currentIndexDownload == totalDownload || downloading &&
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.grey[400]}>Preparando arquivo para efetuar o download</Text>
                    </Container>
                }
            </Container>
        </Modal >
    )
}

export default VerdictDownloadModal;
