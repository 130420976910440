

import React, { FC, useEffect, useState } from 'react';

import { Legend, PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { IDashboardCharts } from '../../../../types';
import { theme } from '../../../../components';
import useMediaQuery from '@mui/material/useMediaQuery';

const DashboardWeightPieChart: FC<IDashboardCharts> = (params: IDashboardCharts) => {
    const [data, setData] = useState<any[]>([]);
    const [colors, setColors] = useState<string[]>([theme.palette.primary.main, '#875352', '#FFAE00', '#FF8042', '#E58A00', '#4D5A80']);

    const [dataArroba, setDataArroba] = useState<any[]>([]);
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const dataArrobaAux = data.map(obj => {
            const { name, name2, ...rest } = obj;
            return {
                name: name2,
                ...rest
            };
        });
        setDataArroba(dataArrobaAux);
    }, [data])

    useEffect(() => {
        if(params.data !== undefined){
            setData(params.data)
        }
    }, [params.data])

    useEffect(() => {
        if(params.colors !== undefined){
            setColors(params.colors)
        }
    }, [params.colors])
    
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        percent: number;
        index: number;
        }) => {

        if (percent === 0){
            return null;
        }

        const color = colors[index % colors.length];
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

        var x = cx + (outerRadius + 25) * Math.cos(-midAngle * (Math.PI / 180));
        var y = cy + (outerRadius + 20) * Math.sin(-midAngle * (Math.PI / 180));
       

        return (
            <text x={x} y={y} fill={color} textAnchor="middle" dominantBaseline="middle" fontSize={12}>
                {(percent * 100).toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%
            </text>
        );
    };

    return (
        <ResponsiveContainer height={(params.height ? params.height : 220) + Number(isXs) * 100}>
            <PieChart>
                <Pie
                    data={params.isKgChecked ? data : dataArroba}
                    startAngle={180}
                    endAngle={-180}
                    fill="#8884d8"
                    paddingAngle={0.2}
                    dataKey="value"
                    legendType='circle'
                    isAnimationActive={true}
                    label={renderCustomizedLabel}
                    labelLine={false}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Legend
                    iconSize={15}
                    layout='vertical'
                    align={isXs ? 'center' : 'right'}
                    verticalAlign={isXs ? 'bottom' : 'middle'}
                />
                <Tooltip/>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default DashboardWeightPieChart;

