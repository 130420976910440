import React, { FC, createContext, useState, useContext } from 'react';
import moment from 'moment';

import {
    ISlaughterDelaysFilter,
    ISlaughterDelaysFilterContext,
    ISlaughterDelaysFilterErrors,
    ISlaughterDelaysReason,
} from '../types/hooks/slaughterDelays';
import { IBranch } from '../types';
import { getTimezoneData } from '../utils/dates';


const SlaughterDelaysFilterContext = createContext<ISlaughterDelaysFilterContext>(
    {} as ISlaughterDelaysFilterContext);


export const SlaughterDelaysFilterProvider: FC<any> = ({ children }) => {
    const [initialDate, setInitialDate] = useState<Date | null>(moment().toDate());
    const [finalDate, setFinalDate] = useState<Date | null>(moment().toDate());
    const [delayType, setDelayType] = useState<number | null>(null);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [branch, setBranch] = useState<IBranch | null>(null);
    const [errors, setErrors] = useState<ISlaughterDelaysFilterErrors>({});
    const [reasons, setReasons] = useState<ISlaughterDelaysReason[]>([]);
    const [locations, setLocations] = useState<ISlaughterDelaysReason[]>([]);


    const clear = () => {
        setDelayType(null);
        setBranchesExpanded(false);
        setErrors({});
        setReasons([]);
        setLocations([]);
    };

    const validate = () => {
        const newErrors: ISlaughterDelaysFilterErrors = {};
        if (!branch) newErrors.branch = 'Selecione uma filial';
        const helperInitialDate = initialDate || moment().format('YYYY-MM-DD');
        if (finalDate && finalDate < helperInitialDate)
            newErrors.finalDate = 'Data final deve ser maior ou igual à inicial';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const buildQuery = (filterParams?: Partial<ISlaughterDelaysFilter>) => {
        let query = '?';

        const filter = {
            branch,
            delayType,
            finalDate,
            initialDate,
            locations,
            reasons,
            ...filterParams,
        };

        if (filter.initialDate)
            query += `initialDate=${filter.initialDate.toISOString().slice(0,10)}&`;
        if (filter.finalDate)
            query += `finalDate=${filter.finalDate.toISOString().slice(0,10)}&`;
        if (filter.delayType) query += `delayType=${filter.delayType}&`;
        const timezoneData = getTimezoneData();
        if (timezoneData?.offset)
            query += `timezone=${timezoneData.offset}&`;
        if (timezoneData?.name)
            query += `timezoneName=${timezoneData.name}&`;
        if (filter.reasons && filter.reasons.length > 0) {
            let reasonsSerialized = filter.reasons.reduce((res, val) => {
                res += `${val.id!},`
                return res
            }, '');
            reasonsSerialized = reasonsSerialized.slice(0, reasonsSerialized.length - 1);
            query += `reasons=${reasonsSerialized}&`;

        };
        if (filter.locations && filter.locations.length > 0) {
            let locationsSerialized = filter.locations.reduce((res, val) => {
                res += `${val.id!},`
                return res
            }, '');
            locationsSerialized = locationsSerialized.slice(
                0, locationsSerialized.length - 1);
            query += `locations=${locationsSerialized}&`;

        };
        return query;
    };

    return (
        <SlaughterDelaysFilterContext.Provider value={{
            initialDate,
            setInitialDate,
            finalDate,
            setFinalDate,
            delayType,
            setDelayType,
            branchesExpanded,
            setBranchesExpanded,
            branch,
            setBranch,
            errors,
            setErrors,
            reasons,
            setReasons,
            locations,
            setLocations,
            clear,
            validate,
            buildQuery,
        }}>
            {children}
        </SlaughterDelaysFilterContext.Provider>
    );
};

export function useSlaughterDelaysFilter() {
    const context = useContext(SlaughterDelaysFilterContext);

    if (!context) {
        throw new Error(
            'useSlaughterDelaysFilter must be used within an SlaughterDelaysFilterProvider');
    }

    return context;
};
