import React, { FC } from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'


import { NoteAltOutlined, ImageSearchOutlined } from '@mui/icons-material';

import { AnnotationSelectOption, Page, theme } from '../../components'
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts';

const AnnotationsSelect: FC = () => {
    const upLgBreakpoint =  useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Page width='100%' height={upLgBreakpoint ? '80%' : '100%'} veryPadded style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Grid container 
                rowSpacing={
                    upLgBreakpoint ? 15 : 2
                } 
                width={
                    upLgBreakpoint ? '80%' : '100%'
                }
            >
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main} fontWeight='bold' mb={2}>
                        BEM-VINDO(A) ÀS ANOTAÇÕES  
                    </Typography>
                    <Typography variant="h5" color='black' fontWeight='bold'>
                        Selecione uma das opções 
                    </Typography>
                   
                    <Typography variant="h6" color='black' fontWeight='medium'>
                        para iniciar 
                    </Typography>
                    
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <AnnotationSelectOption 
                        icon={<NoteAltOutlined style={{ fontSize: 190, color: theme.palette.primary.main }} />}
                        title='Acabamento de Gordura'
                        description='Anote imagens de carcaças no que diz respeito ao seu acabamento de gordura.'
                        redirectTo='/annotations'
                    />
                    
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <AnnotationSelectOption 
                        icon={<ImageSearchOutlined style={{ fontSize: 190, color: theme.palette.primary.main }} />}
                        title='Anomalias'
                        description='Classifique imagens como anomalias ou não, indicando a presença ou ausência de carcaça na imagem.'
                        redirectTo='/annotations_anomalies'
                    />
                </Grid>
            </Grid>
        </Page>
    )
}

export default AnnotationsSelect;