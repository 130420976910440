import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { LoadingButton } from '@mui/lab';

import BranchesMultiSelect from '../../branches/multiSelect';
import CarcassesLocalClassificationsMultiSelect from '../../carcassesLocalClassifications/multiSelect';
import CarcassClassificationsMultiSelect from '../../carcasses/classificationsMultiSelect';
import CarcassesLocalConformationsMultiSelect from '../../carcassesLocalConformations/multiSelect';
import CarcassConformationsMultiSelect from '../../carcasses/conformationsMultiSelect';
import DateRangePicker from "../../../atoms/dateRangePicker";

import Container from '../../../atoms/container';
import Modal from '../../../atoms/modal';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { useVerdicts } from '../../../../hooks/verdicts';
import {
    IBranch,
    ILabelId,
    ICarcassLocalClassification,
    ICarcassLocalConformation,
    IVerdictDownloadSheetModal,
} from '../../../../types';
import { BORDER_RADIUS } from '../../../../utils/consts';


const VerdictDownloadSheetModal: FC<IVerdictDownloadSheetModal> = (params: IVerdictDownloadSheetModal) => {
    const { downloadVerdictsSheet } = useVerdicts();

    const [initialDate, setInitialDate] = useState<Date | null>(params.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(params.finalDate);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(params.branches);
    const [selectedClassifications, setSelectedClassifications] = useState<ILabelId[]>(params.classificationsId);
    const [selectedLocalClassifications, setSelectedLocalClassifications] = useState<ICarcassLocalClassification[]>(params.localClassifications);
    const [selectedConformations, setSelectedConformations] = useState<ILabelId[]>(params.conformationsId);
    const [selectedLocalConformations, setSelectedLocalConformations] = useState<ICarcassLocalConformation[]>(params.localConformations);
    const [includeClassification, setIncludeClassification] = useState<boolean>(params.includeClassification);
    const [includeConformation, setIncludeConformation] = useState<boolean>(params.includeConformation);

    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const [localClassificationsExpanded, setLocalClassificationsExpanded] = useState<boolean>(false);
    const [conformationsExpanded, setConformationsExpanded] = useState<boolean>(false);
    const [localConformationsExpanded, setLocalConformationsExpanded] = useState<boolean>(false);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setInitialDate(params.initialDate);
        setFinalDate(params.finalDate);
        setSelectedBranches(params.branches);
        setSelectedClassifications(params.classificationsId);
        setSelectedLocalClassifications(params.localClassifications);
        setSelectedConformations(params.conformationsId);
        setSelectedLocalConformations(params.localConformations);
        setIncludeClassification(params.includeClassification);
        setIncludeConformation(params.includeConformation);
    },
    [
        params.initialDate,
        params.finalDate,
        params.branches,
        params.classificationsId,
        params.localClassifications,
        params.conformationsId,
        params.localConformations,
        params.includeClassification,
        params.includeConformation,
    ])

    useEffect(() => {
        if (branchesExpanded) {
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [branchesExpanded]);

    useEffect(() => {
        if (classificationsExpanded) {
            setBranchesExpanded(false);
            setLocalClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [classificationsExpanded]);

    useEffect(() => {
        if (localClassificationsExpanded) {
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setConformationsExpanded(false);
            setLocalConformationsExpanded(false);
        }
    }, [localClassificationsExpanded]);

    useEffect(() => {
        if (conformationsExpanded) {
            setBranchesExpanded(false);
            setLocalConformationsExpanded(false);
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
        }
    }, [conformationsExpanded]);

    useEffect(() => {
        if (localConformationsExpanded) {
            setBranchesExpanded(false);
            setConformationsExpanded(false);
            setClassificationsExpanded(false);
            setLocalClassificationsExpanded(false);
        }
    }, [localConformationsExpanded]);

    const onDownload = async () => {
        setBranchesExpanded(false);
        setClassificationsExpanded(false);
        setLocalClassificationsExpanded(false);
        setConformationsExpanded(false);
        setLocalConformationsExpanded(false);
        const valid = validate();

        if(valid){
            setLoading(true);

            let branches = selectedBranches ? selectedBranches.reduce(
                (res: string, entry: IBranch) => {
                    return res + entry.id! + ','
                }, "")
                : undefined;
            let classifications = (includeClassification && selectedClassifications) ?
                selectedClassifications.reduce(
                    (res: string, entry: ILabelId) => {
                        return res + entry.id + ','
                    },
                "")
                : undefined;
            let localClassifications = (includeClassification && selectedLocalClassifications) ?
                selectedLocalClassifications.reduce(
                    (res: string, entry: ICarcassLocalClassification) => {
                        return res + entry.localClassification + ','
                    },
                "")
                : undefined;

            let conformations = (includeConformation && selectedConformations) ?
                selectedConformations.reduce(
                    (res: string, entry: ILabelId) => {
                        return res + entry.id + ','
                    },
                "")
                : undefined;
            let localConformations = (includeConformation && selectedLocalConformations) ?
                selectedLocalConformations.reduce(
                    (res: string, entry: ICarcassLocalConformation) => {
                        return res + entry.localConformation + ','
                    },
                "")
                : undefined;
    
            if (branches)
                branches = branches.slice(0, branches.length - 1);
            if (classifications)
                classifications = classifications.slice(0, classifications.length - 1);
            if (localClassifications)
                localClassifications = localClassifications.slice(0, localClassifications.length - 1);
            if (conformations)
                conformations = conformations.slice(0, conformations.length - 1);
            if (localConformations)
                localConformations = localConformations.slice(0, localConformations.length - 1);

            try {
                const _error = await downloadVerdictsSheet(
                    initialDate!, finalDate!, branches, classifications,
                    localClassifications, conformations, localConformations,
                    includeClassification, includeConformation
                );
                if (_error)
                    throw _error
                setError('');
            } catch (e) {
                setError(`falha ao baixar planilha.`);
            }
            setLoading(false);
        }
        return
    }
    const validate = () => {
        let isValid: boolean = true;

        if (!initialDate || !finalDate) {
            setError('Favor informar a data');
            isValid = false;
        }

        if (isValid) {
            setError('');
        }
        return isValid;
    }
    const close = () => {
        setError('');
        params.onClose();
    }

    const cleanData = () => {
        setInitialDate(new Date());
        setFinalDate(new Date());
        setSelectedBranches([]);
        setSelectedClassifications([]);
        setSelectedLocalClassifications([]);
        setError(null);
        setLoading(false);
        setBranchesExpanded(false);
        setClassificationsExpanded(false);
        setLocalClassificationsExpanded(false);
    }

    return (
        <Modal isOpened={params.open} onClose={close}>
            <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={BORDER_RADIUS}
                style={{ minWidth: 500, maxWidth: '95% !important' }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Download de planilha de arbitragem</Text>
                    </Container>
                </Container>
                <Stack spacing={2} sx={{ marginTop: 2, marginBottom: 4, width: '100%', paddingRight: 4, paddingLeft: 4 }}>
                    {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                    <Box>
                        <Text light style={{ marginBottom: '4px' }}>Data:</Text>
                        <DateRangePicker
                            initialDate={initialDate}
                            finalDate={finalDate}
                            placeholder={"Escolha o intervalo de data"}
                            setInitialDate={setInitialDate}
                            setFinalDate={setFinalDate}
                            disabled={false}
                            maxDaysSelect={0}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                            }}
                        />
                    </Box>
                    <Box>
                        <Text light style={{ marginBottom: '8px' }}>Filiais:</Text>
                        <BranchesMultiSelect
                            selectedBranches={selectedBranches}
                            setSelectedBranches={setSelectedBranches}
                            expanded={branchesExpanded}
                            setExpanded={setBranchesExpanded}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                                marginTop: -5
                            }} />
                    </Box>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                size="small"
                                checked={includeClassification}
                                onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => setIncludeClassification(event.target.checked)
                                }
                            />}
                            label="Incluir acabamento"
                        />
                        <FormControlLabel control={
                            <Checkbox
                                size="small"
                                checked={includeConformation}
                                onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => setIncludeConformation(event.target.checked)
                                }
                            />}
                            label="Incluir conformação"
                        />
                    </FormGroup>
                    {includeClassification && <>
                        <Box>
                            <Text light style={{ marginBottom: '8px' }}>Acabamento Local:</Text>
                            <CarcassesLocalClassificationsMultiSelect
                                placeholder="Acabamento Local"
                                selectedBranchesId={selectedBranches.map(item => item.id || "")}
                                selectedClassifications={selectedLocalClassifications}
                                setSelectedClassifications={setSelectedLocalClassifications}
                                expanded={localClassificationsExpanded}
                                setExpanded={setLocalClassificationsExpanded}
                                singleSelect={false}
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                    marginTop: -5
                                }}
                            />
                        </Box>
                        <Box>
                            <Text light style={{ marginBottom: '8px' }}>Acabamento IA:</Text>
                            <CarcassClassificationsMultiSelect
                                useJBS
                                placeholder="Acabamentos IA"
                                selectedItems={selectedClassifications}
                                setSelectedItems={setSelectedClassifications}
                                expanded={classificationsExpanded}
                                setExpanded={setClassificationsExpanded}
                                singleSelect={false}
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                    marginTop: -5
                                }}
                            />
                        </Box>
                    </>}
                    {includeConformation && <>
                        <Box>
                            <Text light style={{ marginBottom: '8px' }}>Conformação Local:</Text>
                            <CarcassesLocalConformationsMultiSelect
                                placeholder="Conformação Local"
                                selectedBranchesId={selectedBranches.map(item => item.id || "")}
                                selectedConformations={selectedLocalConformations}
                                setSelectedConformations={setSelectedLocalConformations}
                                expanded={localConformationsExpanded}
                                setExpanded={setLocalConformationsExpanded}
                                singleSelect={false}
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                    marginTop: -5
                                }}
                            />
                        </Box>
                        <Box>
                            <Text light style={{ marginBottom: '8px' }}>Conformação IA:</Text>
                            <CarcassConformationsMultiSelect
                                useJBS
                                placeholder="Conformação IA"
                                selectedItems={selectedConformations}
                                setSelectedItems={setSelectedConformations}
                                expanded={conformationsExpanded}
                                setExpanded={setConformationsExpanded}
                                singleSelect={false}
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                    marginTop: -5
                                }}
                            />
                        </Box>
                    </>}
                    <Stack spacing={1} sx={{ paddingTop: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            style={{
                                width: '100%',
                            }}
                            loading={loading}
                            disabled={loading}
                            onClick={() => onDownload()}
                        >
                            Baixar Planilha
                        </LoadingButton>
                        <LoadingButton
                            variant="text"
                            color="primary"
                            style={{ width: '100%' }}
                            loading={loading}
                            disabled={loading}
                            onClick={cleanData}
                        >
                            Limpar Filtros
                        </LoadingButton>
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            style={{ width: '100%' }}
                            loading={loading}
                            disabled={loading}
                            onClick={close}
                        >
                            Fechar
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Container>
        </Modal >
    )
}

export default VerdictDownloadSheetModal;