import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { IAlertActiveFilterParams, IAlert } from '../../../types';
import { Container, Page, theme } from '../../../components';
import { Title1, BodyText } from '../../../components/atoms/text';
import AlertActiveFilter from '../../../components/molecules/alertActive/filter';
import { getActiveAlerts } from '../../../services/alerts';
import AlertActiveCard from '../../../components/molecules/alertActive/card';
import { useAlertConstants } from '../../../hooks/alertConstants';
import { useBranches } from '../../../hooks/branches';


const ActiveAlerts: FC = () => {
    const { branches } = useBranches();

    const {
        levels: levelsConstants,
        statuses: statusesConstants,
        sources: sourcesConstants,
        comparisons: comparisonsConstants,
        fetchAlertConstants
    } = useAlertConstants();

    const [activeAlerts, setActiveAlerts] = useState<IAlert[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (
            !levelsConstants || levelsConstants.length === 0 ||
            !sourcesConstants || sourcesConstants.length === 0 ||
            !statusesConstants || statusesConstants.length === 0 ||
            !comparisonsConstants || comparisonsConstants.length === 0
        )
            fetchAlertConstants()
    }, [])

    async function fetchActiveAlerts(params: IAlertActiveFilterParams) {
        setLoading(true);
        const timeout = setTimeout(() => setLoading(false), 15000);
        if (!params.branches || params.branches.length === 0)
            params.branches = branches;
        await getActiveAlerts(params).then((res) => setActiveAlerts(res || []));
        setLoading(false);
        clearInterval(timeout);
    }

    return (
        <Page width='auto'>
            <Container fluid flex color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline style={{
                    flex: '0',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Alertas Ativos</Title1>
                        <BodyText muted>Alertas / Alertas Ativos</BodyText>
                    </Container>
                </Container>
                <Grid container>
                    <AlertActiveFilter
                        levels={levelsConstants}
                        sources={sourcesConstants}
                        statuses={statusesConstants}
                        onApply={fetchActiveAlerts}
                        loading={loading}
                    />
                </Grid>
                <Grid container spacing={3}>
                    {!loading && activeAlerts.map((i) =>
                        <Grid item xs={12} md={6} lg={4} key={i.id}>
                            <AlertActiveCard
                                alert={i}
                                levels={levelsConstants}
                                comparisons={comparisonsConstants}
                                sources={sourcesConstants}
                                statuses={statusesConstants}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Page >
    )
}

export default ActiveAlerts;
