
import { FC, createContext, useContext, useState } from "react";

import {
    IPopVisualizationBoxesContext,
} from "../types/hooks/carcassesPops";
import { PopGroups } from "../utils/pops";

const PopVisualizationBoxesContext = createContext<IPopVisualizationBoxesContext>({} as IPopVisualizationBoxesContext);

export const PopVisualizationBoxesProvider: FC<any> = ({ children }) => {
    const [disabledBoundingBoxes, setDisabledBoundingBoxes] = useState<number[]>([]);
    const [isLabelActive, setIsLabelActive] = useState<boolean>(true);

    function clear() {
        setDisabledBoundingBoxes([]);
    };

    function isPopDisabled(popType: number) {
        return disabledBoundingBoxes.includes(popType);
    };

    function togglePopBoxes(popType: number) {
        let newDisabledBoundingBoxes: number[] = [];
        if (disabledBoundingBoxes.includes(popType))
            newDisabledBoundingBoxes = disabledBoundingBoxes.filter(p => p !== popType);
        else
            newDisabledBoundingBoxes = disabledBoundingBoxes.concat(popType);
        setDisabledBoundingBoxes(newDisabledBoundingBoxes);
    };


    function togglePopBoxesByGroup(popTypes: number[]) {
        let newDisabledBoundingBoxes: number[] = [];

        const currentlyDisabledPops = disabledBoundingBoxes.filter((p) => popTypes.includes(p));

        if (currentlyDisabledPops.length > 0) {
            newDisabledBoundingBoxes = disabledBoundingBoxes.filter((p) => !popTypes.includes(p));
        } else {
            newDisabledBoundingBoxes = [...disabledBoundingBoxes, ...popTypes.filter((p) => !currentlyDisabledPops.includes(p))];
        }

        setDisabledBoundingBoxes(newDisabledBoundingBoxes);
    }
    function isPopGroupDisabled(popGroupId: number) {
        return PopGroups[popGroupId].some(pop => disabledBoundingBoxes.includes(pop));
    }

    return (
        <PopVisualizationBoxesContext.Provider
            value={{
                disabledBoundingBoxes,
                setDisabledBoundingBoxes,
                isLabelActive,
                setIsLabelActive,

                clear,
                isPopDisabled,
                isPopGroupDisabled,
                togglePopBoxes,
                togglePopBoxesByGroup,
            }}
        >
            {children}
        </PopVisualizationBoxesContext.Provider>
    );
};

export function usePopVisualizationBoxes() {
    const context = useContext(PopVisualizationBoxesContext);

    if (!context) {
        throw new Error(
            "usePopVisualizationBoxes must be used within an PopVisualizationBoxesProvider",
        );
    }

    return context;
}
