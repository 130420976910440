import React, { FC, useState } from 'react';
import Grid from '@mui/material/Grid';

import { IAlertHistoryFilterParams, IAlertStatus } from '../../../types';

import { Container, Page, theme } from '../../../components';
import { Title1, BodyText } from '../../../components/atoms/text';
import AlertHistoryFilter from '../../../components/molecules/alertHistory/filter';
import AlertHistoryTable from '../../../components/molecules/alertHistory/table';
import { getAlertHistory } from '../../../services/alertStatuses';
import { useBranches } from '../../../hooks/branches';


const AlertHistory: FC = () => {
    const { branches } = useBranches();

    const [statusHistory, setStatusHistory] = useState<IAlertStatus[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    async function fetchStatusHistory(params: IAlertHistoryFilterParams) {
        setLoading(true);
        const timeout = setTimeout(() => setLoading(false), 15000);
        if (!params.branches || params.branches.length === 0)
            params.branches = branches;
        await getAlertHistory(params).then((res) => setStatusHistory(res || []));
        setLoading(false);
        clearInterval(timeout);
    }

    return (
        <Page width='auto'>
            <Container fluid flex color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline style={{
                    flex: '0',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <Container style={{ flexGrow: '1' }}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>Histórico de Alertas</Title1>
                        <BodyText muted>Alertas / Histórico de Alertas</BodyText>
                    </Container>
                </Container>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <AlertHistoryFilter
                            onApply={fetchStatusHistory}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AlertHistoryTable
                            data={statusHistory}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page >
    )
}

export default AlertHistory;
