import React, { FC, useState } from 'react'
import moment from 'moment'
import { ptBR } from "date-fns/locale";
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { ClockPickerView } from '@mui/x-date-pickers';

import { ITimePicker } from '../../../types';


const TimePicker: FC<ITimePicker> = (params: ITimePicker) => {
    const [open, setOpen] = useState<boolean>(false)
    const inputFormat = params.inputFormat || "HH:mm";
    let views: ClockPickerView[] = [];
    if (inputFormat.includes('HH'))
        views.push('hours');
    if (inputFormat.includes('mm'))
        views.push('minutes');
    if (inputFormat.includes('ss'))
        views.push('seconds');

    return (
        <LocalizationProvider
            locale={ptBR} dateAdapter={AdapterDateFns} >
            <MuiTimePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                label={params.label}
                views={views}
                value={params.value || null}
                mask={params.mask}
                inputFormat={inputFormat}
                onChange={(newValue: any) => {
                    if (moment(newValue, inputFormat).isValid())
                        params.setValue(newValue);
                    else
                        params.setValue(null);
                }}
                disabled={params.disabled}
                renderInput={(params) => <TextField
                    {...params}
                    placeholder={params.placeholder || 'Selecione data e hora...'}
                    sx={{
                        marginTop: 0,
                        ...params.sx
                    }}
                />}
            />
        </LocalizationProvider>
    )
}

export default TimePicker