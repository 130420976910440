import { AxiosResponse } from "axios";

import api from ".";
import { IVerdict } from "../types";

const baseUrl: string = "/api_redsoft/v1_0/verdicts";

export const createVerdict = async (_verdict: IVerdict) => {
  const response: AxiosResponse = await api.post(`${baseUrl}`, {
    userId: _verdict.userId,
    carcassId: _verdict.carcassId,
    classification: _verdict.classification,
    conformation: _verdict.conformation,
    bestPlaced: _verdict.bestPlaced
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIVerdict(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const batchCreateVerdict = async (_verdicts: IVerdict[]) => {
  const payload = _verdicts.map((verdict) => ({
    carcassId: verdict.carcassId,
    classification: verdict.classification,
    conformation: verdict.conformation,
    bestPlaced: verdict.bestPlaced
  }));
  const response: AxiosResponse = await api.post(
    `${baseUrl}/batch`, {data: payload}
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data?.total;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateVerdict = async (_id: string, _verdict: IVerdict) => {
  const response: AxiosResponse = await api.put(`${baseUrl}/${_id}`, {
    userId: _verdict.userId,
    carcass: _verdict.carcassId,
    classification: _verdict.classification || null,
    conformation: _verdict.conformation || null,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIVerdict(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyVerdict = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getVerdicts = async () => {
  const response: AxiosResponse = await api.get(`${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const verdicts: IVerdict[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const verdict: IVerdict = convertApiResponseToIVerdict(data);

        verdicts.push(verdict);
      }

      return verdicts;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getVerdict = async (_id: string) => {
  const response: AxiosResponse = await api.get(`${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const verdict: IVerdict = convertApiResponseToIVerdict(response.data);
      return verdict;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getVerdictsSheet = async (_query: string) => {
  const response: AxiosResponse = await api.get(`${baseUrl}/download_sheet${_query}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {

      return response.data;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getVerdictsDownloadFilePath = async (_filePath: string) => {
  const response: AxiosResponse = await api.post(
    `${baseUrl}/download_photos`,
    { filePath: _filePath },
    { responseType: "blob" }
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response;
    } else if (status == 204) {
      throw new Error("Não foi encontrada nenhuma arbitragem na data selecionada");
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIVerdict = (_verdict: any) => {
  const verdict: IVerdict = {
    userId: _verdict.userId,
    carcassId: _verdict.carcassId,
    classification: _verdict.classification,
    originalClassification: _verdict.originalClassification,
    conformation: _verdict.conformation,
    originalConformation: _verdict.originalConformation,
    isDeleted: _verdict.isDeleted,
    createdAt: _verdict.createdAt,
    updatedAt: _verdict.updatedAt
  }

  return verdict;
};

