import { FC, useEffect, useState } from 'react';

import DashboardBase from './dashboardBase';

import { IDashboardFilterData } from '../../types';

import { useBranches } from '../../hooks/branches';
import { useDashboard } from '../../hooks/dashboard';


const Dashboard: FC = () => {
    const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
    const [isKgChecked, setIsKgChecked] = useState<boolean>(true);
    const [weightUnitLabel, setWeightUnitLabel] = useState<string>("kg");
    
    const { branches, fetchBranches } = useBranches();
    const { filter, setFilter, fetchDashboardData } = useDashboard();
   
    useEffect(() => {
        fetchDashboardData(filter);
        fetchBranches();
    }, [])
    
     // Se tiver apenas 1 opção de branch no filtro, deixar selecionada.
     useEffect(() => {
        if(branches && branches.length === 1) {
            let newFilter: IDashboardFilterData = {
                ...filter,
                branches: branches
            }
            if(branches != filter.branches){
                setFilter(newFilter);
            }
        }
    }, [branches])

    return <DashboardBase filterExpanded={filterExpanded}
        setFilterExpanded={setFilterExpanded}
        isKgChecked={isKgChecked}
        setIsKgChecked={setIsKgChecked}
        weightUnitLabel={weightUnitLabel}
        setWeightUnitLabel={setWeightUnitLabel}
    />
}

export default Dashboard;
