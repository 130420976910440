import { AxiosResponse } from "axios";

import api from "./";
import { IUser } from "../types";
import { error } from "console";

const baseUrl: string = "/api_manager/users";

export const createUser = async (_payload: IUser): Promise<IUser> => {
  const response: AxiosResponse = await api.post(`${baseUrl}`, _payload);

  if (response.data && response.status === 200) {
    const user: IUser = convertApiResponseToIUser(response.data);
    const rs_response: AxiosResponse = await api.post(`/api_redsoft/v1_0/users`, {
      id: user.id,
      name: (user.firstName + ' ' + user.lastName).trim(),
      password: '',
      status: 1,
      email: user.email,
    });
    if (rs_response.data && rs_response.status === 200)
      return user;
  } else {
    const errorResponse = (response as any).response;
    if (errorResponse) throw new Error(errorResponse.data.errorMessage as string);
  }
  throw new Error("Não foi possível criar um Usuário.");
};

export const updateUser = async (_id: string, _payload: IUser): Promise<IUser> => {
  const response: AxiosResponse = await api.put(`${baseUrl}/${_id}`, _payload);
  if (response.data && response.status === 200) {
    const user: IUser = convertApiResponseToIUser(response.data);
    return user;
  }
  throw new Error("Não foi possível editar um usuário");
};

export const destroyUser = async (_id: string): Promise<IUser | any> => {
  const response: AxiosResponse = await api.delete(`${baseUrl}/${_id}`);
  if (response.data && response.status === 200) {
    return convertApiResponseToIUser(response.data);
  }
  if (response.status === 204) {
    return true;
  } else if ((response as any)?.response?.status === 400) {
    const data = (response as any)?.response?.data;
    if (data.error) {
      throw new Error(data.error);
    }
  }
  throw new Error(`Não foi possível remover um usuário.`);
};

export const getUser = async (_id: string): Promise<IUser> => {
  const response: AxiosResponse = await api.get(`${baseUrl}/${_id}`);

  if (response.data && response.status === 200) {
    return convertApiResponseToIUser(response.data);
  }
  throw new Error(`Não foi possível buscar o usuário`);
};

export const getUserByUserId = async (_userId: string): Promise<IUser> => {
  const response: AxiosResponse = await api.get(`${baseUrl}/by_user_id/${_userId}`);

  if (response.data && response.status === 200) {
    return convertApiResponseToIUser(response.data);
  }
  throw new Error(`Não foi possível buscar o usuário`);
};

export const getUsers = async (): Promise<IUser[]> => {
  const response: AxiosResponse = await api.get(`${baseUrl}`);
  if (response.data && response.status === 200) {
    const users: IUser[] = [];
    for (let i = 0; i < response.data.length; i++) {
      const data = response.data[i];
      users.push(convertApiResponseToIUser(data));
    }
    return users;
  }
  throw new Error(`Não foi possível buscar os usuárioƒs`);
};

export const getUserGroups = async (_id: string): Promise<any> => {
  const response: AxiosResponse = await api.get(`${baseUrl}/${_id}/groups`);

  if (response.data && response.status === 200) {
    const groups: any[] = [];

    for (let i = 0; i < response.data.length; i++) {
      const data = response.data[i];
      groups.push(data);
    }
    return groups;
  }
  throw new Error(`Não foi possível os grupos`);
};

export const addGroup = async (_userId: string, _groupId: string): Promise<boolean> => {
  const response: AxiosResponse = await api.put(`${baseUrl}/${_userId}/group_membership/${_groupId}`);

  if (response.data && (response.status === 200 || response.status === 204)) {
    return true;
  }
  return false;
};

export const removeGroup = async (_userId: string, _groupId: string): Promise<boolean> => {
  const response: AxiosResponse = await api.delete(`${baseUrl}/${_userId}/group_membership/${_groupId}`);

  if (response.data && (response.status === 200 || response.status === 204)) {
    return true;
  }
  return false;
};

export const convertApiResponseToIUser = (_data: any): IUser => {
  return {
    id: _data.id,
    createdTimestamp: _data.createdTimestamp,
    username: _data.username,
    enabled: _data.enabled,
    emailVerified: _data.emailVerified,
    firstName: _data.firstName,
    lastName: _data.lastName,
    email: _data.email,
    attributes: _data.attributes,
    requiredActions: _data.requiredActions,
  };
};
