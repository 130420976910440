import React, { FC } from "react";
import { ICheckboxMultiselect, ILabelId } from "../../../types";
import CheckboxMultiselect from "../../atoms/checkboxMultiselect";
import { PopClassificationsType } from "../../../services/carcassesPops";


const PopTypeMultiselect: FC<ICheckboxMultiselect> = (params: ICheckboxMultiselect) => {
    const options: ILabelId[] = params.options || Object.values(
        PopClassificationsType).map(item => ({
            id: item.value,
            label: item.label
        }))
    return <CheckboxMultiselect
        value={params.value}
        setValue={params.setValue}
        options={options}
        placeholder={params.placeholder || 'Classificações de POP'}
        disabled={params.disabled}
        sx={params.sx}
    />
}

export default PopTypeMultiselect;