import React from 'react';

import TextStyled from './styled';
import { IText } from '../../../types';
import { colors } from '../../theme/styles';

const Text: React.FC<IText> = (params: IText) => {
    return (
        <TextStyled
            id={params.id}
            ref={params.ref}
            className={params.className}
            center={params.center}
            right={params.right}
            size={.75 * (params.size || 16)}
            thin={params.thin}
            extraLight={params.extraLight}
            light={params.light}
            medium={params.medium}
            semiBold={params.semiBold}
            bold={params.bold}
            extraBold={params.extraBold}
            black={params.black}
            color={params.color}
            muted={params.muted}
            style={{ ...params.style }}
            onClick={params.onClick}
            key={params.key}
            >
            {params.children}
        </TextStyled>
    );
};

export default Text;

export const BodyText: React.FC<IText> = (params: IText) => {
    return (
        <Text size={14} color={colors.textColor} {...params}/>
    );
};

export const LargeText: React.FC<IText> = (params: IText) => {
    return (
        <Text size={16} color={colors.textColor} {...params}/>
    );
};

export const Title1: React.FC<IText> = (params: IText) => {
    return (
        <Text
            size={28}
            bold
            color={colors.primary}
            style={{ marginBottom: '16px', ...params.style }}
            {...params}
        />
    );
};

export const Title2: React.FC<IText> = (params: IText) => {
    return (
        <Text
            size={24}
            color={colors.primary}
            style={{ marginBottom: '12px', ...params.style }}
            {...params}
        />
    );
};

export const Title3: React.FC<IText> = (params: IText) => {
    return (
        <Text
            size={18}
            bold
            color={colors.textColor}
            style={{ marginBottom: '9px', ...params.style }}
            {...params}
        />
    );
};

export const Title4: React.FC<IText> = (params: IText) => {
    return (
        <Text
            size={18}
            color={colors.textColor}
            style={{ marginBottom: '9px', ...params.style }}
            {...params}
        />
    );
};
