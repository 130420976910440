import React, { FC } from 'react';
import {
    ICRUDTableByBranch,
    ICarcassLocalConformation,
    ICRUDContext,
} from '../../../../types';

import { getCarcassesLocalConformationsByBranch } from "../../../../services/carcassesLocalConformations";
import { useCarcassesLocalConformations } from '../../../../hooks/carcassesLocalConformations';
import generateCRUDTable from '../../crudTable';


const CarcassesLocalConformationsCRUDTable: FC<ICRUDTableByBranch> = (params: ICRUDTableByBranch) => {
    const crudTable = generateCRUDTable<ICarcassLocalConformation, ICRUDTableByBranch>(
        {
            columns: [
                {
                    name: 'name',
                    title: 'Nome',
                    required: true,
                },
                {
                    name: 'localConformation',
                    title: 'Local',
                    required: true,
                },
                {
                    name: 'conformationValue',
                    title: 'RedSoft',
                    type: 'integer',
                    required: true,
                    max: 5,
                    min: 0,
                },
            ],
            name: 'conformação local',
            defaultSort: 'conformationValue',
            entryDescriptor: 'name',
            defaultNewEntry: {
                branchId: params.branchId,
                localConformation: null,
                name: '',
            },
            getHook: useCarcassesLocalConformations,
            fetchAllEntries: async (hook?: ICRUDContext<ICarcassLocalConformation>, _params?: ICRUDTableByBranch) => {
                return await getCarcassesLocalConformationsByBranch(params.branchId);
            }
        }
    )

    return crudTable(params);
};

export default React.memo(CarcassesLocalConformationsCRUDTable);