import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Divider, IconButton } from "@mui/material";
import {
    Close as CloseIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    DoneAll as DoneAllIcon,
    Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { defaultConformations } from "../../../../hooks/carcasses";
import { ILabelId, ICarcassMultiSelect } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import { normalizeString } from "../../../../utils/stringFormatters";

const CarcassConformationsMultiSelect: FC<ICarcassMultiSelect> = (params: ICarcassMultiSelect) => {
    const conformations = defaultConformations;
    const [search, setSearch] = useState<string>('')
    const [filteredConformations, setFilteredConformations] = useState<ILabelId[]>([]);

    useEffect(() => {
        filterConformations(search);
    }, [search]);

    const filterConformations = (_search: string) => {
        setFilteredConformations([])
        let auxConformations = [...conformations]

        setTimeout(() => {
            if (_search != '') {
                auxConformations = auxConformations.filter((item: ILabelId) =>
                    (item.label && normalizeString(item.label).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredConformations([...auxConformations])
        }, 0)
    }

    const handleSelectConformation = (_conformation: ILabelId, _isChecked: boolean) => {
        if (params.singleSelect) {
            params.setSelectedItems([_conformation]);
            return;
        }

        const auxConformations: ILabelId[] = [];
        for (let i = 0; i < params.selectedItems.length; i++) {
            const auxConformation: ILabelId = params.selectedItems[i];
            if (auxConformation.id == _conformation.id && !_isChecked) continue;
            auxConformations.push(auxConformation);
        }
        if (_isChecked) auxConformations.push(_conformation);

        params.setSelectedItems([...auxConformations]);
    }

    const renderConformationItem = (_conformation: ILabelId, key: number) => {
        const isChecked: boolean = params.selectedItems.find(item => item.id == _conformation.id) != undefined;

        return (
            <Container key={key} className="container-hover" inline spacedBetween horizontalCentered width="100%"
                onClick={() => {
                    if (!params.disabled)
                        handleSelectConformation(_conformation, !isChecked)
                }}
                sx={{ cursor: 'pointer' }}
            >
                <Checkbox size="small" checked={isChecked} disabled={params.disabled} />
                <Container fluid flexStart flex>
                    <Text muted={params.disabled}>{_conformation.label}</Text>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth="130px" height="30px" width='100%'>
            <Container inline horizontalCentered spacedBetween minWidth="130px" height="30px" onClick={() => params.setExpanded(!params.expanded)}
                style={{ cursor: 'pointer', paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedItems.length == 0 &&
                    <Text size={15} muted={params.disabled}>
                        {params.placeholder || 'Conformações'}
                    </Text>}
                {params.selectedItems.length > 0 &&
                    <Text bold size={14} muted={params.disabled}>
                        {`${params.selectedItems[0].label} ${!params.singleSelect && params.selectedItems.length > 1 ? ' +' + (params.selectedItems.length - 1) : ''}`}
                    </Text>
                }
                <IconButton style={{ backgroundColor: 'transparent' }} disabled={params.disabled}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Collapse in={params.expanded} timeout="auto" unmountOnExit>
                <Container fluid absolute hasShadow padded borderRadius={BORDER_RADIUS} color={theme.palette.background.paper} sx={{ zIndex: 100, marginTop: 10 }}>
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder='Pesquisar acabamento'
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color='primary' />}
                        disabled={params.disabled}
                        sx={{ height: '35px' }}
                    />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: 'auto' }}>
                        {filteredConformations.map((item, index) => renderConformationItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button
                            disabled={params.disabled}
                            startIcon={<DoneAllIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => {
                                if (!params.disabled)
                                    params.setSelectedItems(conformations)
                            }}
                        >
                            <Text size={15} muted={params.disabled}>Marcar todos</Text>
                        </Button>
                        <Button
                            disabled={params.disabled}
                            startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => {
                                if (!params.disabled)
                                    params.setSelectedItems([])
                            }}
                        >
                            <Text size={15} muted={params.disabled}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Collapse>
        </Container>
    );
}

export default CarcassConformationsMultiSelect