import React, { FC, useEffect, useState } from 'react'
import moment from 'moment';
import {
    Backdrop,
    Button,
    CircularProgress,
    FormGroup,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
} from '@mui/material';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';

import { AnnotationsFilter, CarcassPhoto, Container, Page, Spacing, Text, theme } from '../../components'
import { useAnnotations } from '../../hooks/annotations';
import { useAnnotators } from '../../hooks/annotators';
import { useAnnotatorHasAccesss } from '../../hooks/annotatorsHasAccess';
import { useAuth } from '../../hooks/auth';
import { useCarcass } from '../../hooks/carcasses';
import { useInformation } from '../../hooks/information';
import {
    IAnnotation,
    IAnnotationFilter,
    IAnnotator,
    IAnnotatorHasAccess,
    ICarcass,
    ICarcassLocalClassification,
    IBranch,
    ILabelId,
} from '../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts';
import { Title1, Title2, BodyText } from '../../components/atoms/text';


const AnnotationsFlow: FC = () => {
    const {
        filter, setFilter,
        getExampleJBS, getExampleRedsoft,
        annotationCount, fetchAnnotationCount,
        createNewAnnotation } = useAnnotations();
    const { fetchAnnotatorByUserId, fetchAnnotatorAnnotates } = useAnnotators();
    const { fetchAnnotatorHasAccessByAnnotatorAndBranch } = useAnnotatorHasAccesss();
    const { user } = useAuth();
    const { classificationsJBS, fetchCarcassForAnnotation } = useCarcass();
    const { showInformation } = useInformation();

    const [annotator, setAnnotator] = useState<IAnnotator | undefined>(undefined);
    const [carcass, setCarcass] = useState<ICarcass | null>(null);
    const [selectedClassification, setSelectedClassification] = useState<number>(-1);
    const [showExamples, setShowExamples] = useState<boolean>(true);
    const [loadingAccess, setLoadingAccess] = useState<boolean>(false);
    const [loadingCarcass, setLoadingCarcass] = useState<boolean>(false);
    const [userHasAccess, setUserHasAccess] = useState<boolean | undefined>(undefined);
    const [sending, setSending] = useState<boolean>(false);
    const [inputOffset, setInputOffset] = useState<string>(String((filter.offset || 0 ) + 1));

    useEffect(() => {
        fetchAnnotatorData();
        fetchAnnotationCount(filter);
    }, []);


    useEffect(() => {
        if (userHasAccess)
            loadCarcass(filter);
        else setCarcass(null);
    }, [userHasAccess]);

    const fetchAnnotatorData = async () => {
        if (user) {
            const userId: string = user.id;
            const auxAnnotator: IAnnotator | undefined = await fetchAnnotatorByUserId(userId);
            if (auxAnnotator) setAnnotator(auxAnnotator);
        }
    }

    const loadCarcass = async (_filter: IAnnotationFilter, fetchCount: boolean = true) => {
        if (_filter.initialDate == null || _filter.finalDate == null) return;

        try {
            setLoadingCarcass(true);
            setTimeout(() => {
                setLoadingCarcass(false);
            }, 5000);
            if (fetchCount) fetchAnnotationCount(_filter);

            const auxCarcass: ICarcass | null = await fetchCarcassForAnnotation(_filter);
            setCarcass(auxCarcass);
            setLoadingCarcass(false);
        } catch (err) {
            setLoadingCarcass(false);
            showInformation(
                'Falha ao buscar carcaça para anotação', 'Erro',
                undefined, () => {
                    applyFilter(
                        _filter.initialDate,
                        _filter.finalDate,
                        _filter.branches,
                        _filter.classifications,
                        _filter.iaClassifications,
                        _filter.onlyBestPlaced,
                        _filter.onlyWithPartnerId,
                        0
                    )
                }
            );
        }
    }

    const checkAccess = async (_newFilter: IAnnotationFilter | undefined) => {
        setLoadingAccess(true);
        try {
            let auxFilter: IAnnotationFilter = filter;
            if (_newFilter)
                auxFilter = _newFilter;
            await fetchAnnotationCount(auxFilter);

            if (userHasAccess)
                setUserHasAccess(false);
            let auxHasAccess: boolean = false;
            if (annotator && annotator.id && auxFilter.branches[0] && auxFilter.branches[0].id) {
                const selectedBranchId: string = auxFilter.branches[0].id;

                const annotatorId: string = annotator.id;
                const initialDate: string = moment(auxFilter.initialDate).format('YYYY-MM-DD');
                const finalDate: string = moment(auxFilter.finalDate).format('YYYY-MM-DD');
                const countAnnotations: number = await fetchAnnotatorAnnotates(annotatorId, initialDate, finalDate, selectedBranchId, filter.onlyBestPlaced, filter.onlyWithPartnerId);
                if (annotator.fullAccess)
                    auxHasAccess = true;
                else {
                    const annotatorsHasAccesses: IAnnotatorHasAccess[] = await fetchAnnotatorHasAccessByAnnotatorAndBranch(annotatorId, selectedBranchId);
                    if (annotatorsHasAccesses.length > 0) {
                        const annotatorHasAccess: IAnnotatorHasAccess | undefined =
                            annotatorsHasAccesses.find(item => (
                                moment(item.releasedDate, 'YYYY-MM-DD').format('DD/MM/YYYY') >= moment(auxFilter.initialDate).format('DD/MM/YYYY') &&
                                moment(item.releasedDate, 'YYYY-MM-DD').format('DD/MM/YYYY') <= moment(auxFilter.finalDate).format('DD/MM/YYYY')
                            ));
                        if (annotatorHasAccess) {
                            if ((countAnnotations || 0) < annotatorHasAccess.releasedQuantity)
                                auxHasAccess = true;
                        }
                    }
                }
            }
            setUserHasAccess(auxHasAccess);
            setLoadingAccess(false);
        } catch (err) {
            setLoadingAccess(false);
        }
    }

    const applyFilter = async (
        _initialDate: Date | null,
        _finalDate: Date | null,
        _branches: IBranch[],
        _classifications: ICarcassLocalClassification[],
        _iaClassifications: ILabelId[],
        onlyBestPlaced: boolean,
        onlyWithPartnerId: boolean,
        offset: number = 0,
        fetchCount: boolean = true
    ) => {
        if (_branches.length == 0) {
            showInformation('Favor informar uma empresa', 'Aviso');
            return;
        }

        const newFilter: IAnnotationFilter = {
            initialDate: _initialDate,
            finalDate: _finalDate,
            branches: _branches,
            classifications: _classifications,
            iaClassifications: _iaClassifications,
            onlyBestPlaced,
            onlyWithPartnerId,
            offset
        }
        setFilter(newFilter);
        setInputOffset(String(offset + 1));
        loadCarcass(newFilter, fetchCount);
    }

    const sendAnnotation = async () => {
        if (annotator && annotator.id) {
            const newAnnotation: IAnnotation = {
                annotatorId: annotator.id,
                carcassId: carcass?.id,
                classification: selectedClassification
            }
            try {
                setSending(true);
                await createNewAnnotation(newAnnotation, false);
                await applyFilter(
                    filter.initialDate,
                    filter.finalDate,
                    filter.branches,
                    filter.classifications,
                    filter.iaClassifications,
                    filter.onlyBestPlaced,
                    filter.onlyWithPartnerId,
                    filter.offset,
                    true
                );

                setSending(false);
                setSelectedClassification(-1);
                checkAccess(undefined);
            } catch (e) {
                setSending(false);
                console.log(e);
            }
        }
    }

    const renderAnnotationStats = () => {
        return (
            <Container fluid padded maxWidth="300px" color={theme.palette.primary.main} borderRadius={BORDER_RADIUS / 2} sx={{ zIndex: 10 }}>
                <Grid container width="100%">
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold color={theme.palette.background.default}>Não anotadas:</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text bold color={theme.palette.background.default}>{annotationCount.countUnannotate}</Text>
                    </Grid>
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold color={theme.palette.background.default}>Anotadas:</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text bold color={theme.palette.background.default}>{annotationCount.countAnnotate}</Text>
                    </Grid>
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold color={theme.palette.background.default}>Total:</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text bold color={theme.palette.background.default}>
                            {annotationCount.countAnnotate + annotationCount.countUnannotate}
                        </Text>
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const handleChangeAnnotationOffset = (event: React.FocusEvent<HTMLInputElement>) => {
        const currentOffset = filter.offset || 0;
        try {
            let newOffset = parseInt((event.target.value as string).replace(/\D/g, '')) - 1;
            if (Number.isNaN(newOffset)) throw new Error('NaN');
            if (newOffset < 0) newOffset = 0;
            if (
                newOffset === currentOffset ||
                newOffset >= annotationCount.countAnnotate + annotationCount.countUnannotate
            ) {
                setInputOffset(String(currentOffset + 1));
                return;
            };

            applyFilter(
                filter.initialDate,
                filter.finalDate,
                filter.branches,
                filter.classifications,
                filter.iaClassifications,
                filter.onlyBestPlaced,
                filter.onlyWithPartnerId,
                newOffset,
                false
            );
        } catch (e) {
            setInputOffset(String(currentOffset + 1));
            return;
        };
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter')
            event.currentTarget.blur();
    };

    return (
        <Page width='100%' height='87%' veryPadded>
            <Container fluid color={theme.palette.background.default} width="100%" minHeight="95vh">
                <Container sx={{ marginBottom: VERY_PADDING }}>
                    <Title1 style={{ whiteSpace: 'nowrap' }}>Anotações - Acabamento de Gordura</Title1>
                    <BodyText muted>Treinamento / Anotações / Acabamento de Gordura</BodyText>
                </Container>
                <Container inline width="100%">
                    <AnnotationsFilter
                        initialDate={filter.initialDate}
                        finalDate={filter.finalDate}
                        branches={filter.branches}
                        classifications={[]}
                        iaClassifications={[]}
                        onApplyFilter={applyFilter}
                        offset={filter.offset || 0}
                    />
                    <Container fluid width="250px" sx={{ paddingTop: VERY_PADDING * 1.7 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked={showExamples} value={showExamples} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowExamples(event.target.checked)} />} label="Mostrar exemplos" />
                        </FormGroup>
                    </Container>
                </Container>

                {!loadingAccess && userHasAccess == false && userHasAccess != undefined &&
                    <Container fluid centered padded color={theme.palette.info.main}>
                        <Text bold size={15} color={theme.palette.background.paper}>Você está sem acesso para efetuar anotações nesta data.</Text>
                    </Container>
                }

                {!loadingAccess && !loadingCarcass && !carcass &&
                    <Container fluid centered height="450px">
                        <Container fluid centered width="500px" height="450px">
                            {userHasAccess == undefined && <Text center bold size={20}>Preencha a data e selecione a empresa para buscar uma nova carcaça para iniciar a anotação.</Text>}
                            {userHasAccess != undefined && annotationCount.countUnannotate > 0 && <Text center bold size={20}>Verifique com o seu gestor sobre a sua permissão de acesso à plataforma de anotação.</Text>}
                            {annotationCount.countUnannotate == 0 && <Text center bold size={20}>Nenhuma carcaça foi encontrada com o filtro selecionado.</Text>}
                            <Spacing top={VERY_PADDING} />
                        </Container>
                    </Container>
                }

                {carcass && <Grid container justifyContent='right'>
                    <Grid item>
                        <Title2 style={{ margin: '0' }}>
                            <IconButton
                                color='primary'
                                onClick={() => applyFilter(
                                    filter.initialDate,
                                    filter.finalDate,
                                    filter.branches,
                                    filter.classifications,
                                    filter.iaClassifications,
                                    filter.onlyBestPlaced,
                                    filter.onlyWithPartnerId,
                                    (filter.offset || 1) - 1,
                                    false
                                )}
                                disabled={!filter.offset || filter.offset <= 0}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                            <input
                                value={inputOffset}
                                onChange={(e) => setInputOffset(e.target.value)}
                                onKeyDown={handleKeyDown}
                                onBlur={handleChangeAnnotationOffset}
                                style={{ width: 'auto', background: 'none', border: 'none', textAlign: 'center' }}
                                size={5}
                            />
                            <IconButton
                                color='primary'
                                onClick={() => applyFilter(
                                    filter.initialDate,
                                    filter.finalDate,
                                    filter.branches,
                                    filter.classifications,
                                    filter.iaClassifications,
                                    filter.onlyBestPlaced,
                                    filter.onlyWithPartnerId,
                                    (filter.offset || 0) + 1,
                                    false
                                )}
                                disabled={
                                    filter.offset !== undefined &&
                                    filter.offset >= annotationCount.countAnnotate + annotationCount.countUnannotate - 1
                                }
                            >
                                <ChevronRightIcon />
                            </IconButton>
                        </Title2>
                    </Grid>
                </Grid>}

                {carcass && <Container fluid flex flexGrow horizontalCentered color='#000' height="100%" sx={{ justifyContent: 'flexStart' }}>
                    <Grid container>
                        <Grid item xs={9} padding={2}>
                            {carcass.photoUri &&
                                <Grid container columnSpacing={2}>
                                    {showExamples &&
                                        <Grid item xs={4}>
                                            <Container fluid minWidth="30%">
                                                <Container fluid centered width="100%" height="30px" color={theme.palette.primary.main}>
                                                    <Text medium color={theme.palette.background.paper}>Exemplo JBS</Text>
                                                </Container>
                                                {selectedClassification > 0 &&
                                                    <Container fluid horizontalCentered width="100%">
                                                        {getExampleJBS(selectedClassification)}
                                                    </Container>}
                                            </Container>
                                        </Grid>
                                    }
                                    <Grid item xs={showExamples ? 4 : 12}>
                                        <Container fluid centered width="100%" height="30px" color={theme.palette.primary.main}>
                                            <Text medium color={theme.palette.background.paper}>Carcaça {carcass.sequence || carcass.partnerId}</Text>
                                        </Container>
                                        <Container fluid width="60%" style={{ margin: 'auto', justifyContent: 'center' }}>
                                            <CarcassPhoto photoUri={carcass.photoUri} minWidth='inherit' minHeight='inherit' />
                                        </Container>
                                    </Grid>
                                    {showExamples &&
                                        <Grid item xs={4}>
                                            <Container fluid minWidth="30%">
                                                <Container fluid centered width="100%" height="30px" color={theme.palette.primary.main}>
                                                    <Text medium color={theme.palette.background.paper}>Exemplo Redsoft</Text>
                                                </Container>
                                                {selectedClassification > 0 &&
                                                    <Container fluid horizontalCentered width="100%">
                                                        {getExampleRedsoft(selectedClassification)}
                                                    </Container>}
                                            </Container>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Container fluid veryPadded>
                                {classificationsJBS.map((item, index) => (
                                    <Container key={index} fluid sx={{ padding: 3 }}>
                                        <Button variant="contained" color={selectedClassification == item.id ? 'success' : 'primary'} onClick={() => {
                                            setSelectedClassification(item.id)
                                        }} disabled={sending}>
                                            <Text size={13} color={theme.palette.background.paper}>
                                                {item.label.toLocaleUpperCase()}
                                            </Text>
                                        </Button>
                                    </Container>
                                ))}
                                <Spacing top={VERY_PADDING} />
                                <Button
                                    variant="contained"
                                    onClick={() => sendAnnotation()}
                                    disabled={selectedClassification == -1 || sending}
                                    sx={{
                                        backgroundColor: theme.palette.primary.dark,
                                        opacity: selectedClassification == -1 || sending ? 0.6 : 1
                                    }}
                                >
                                    <Text size={13} color={theme.palette.background.paper}>
                                        Enviar
                                    </Text>
                                </Button>
                                <Spacing top={VERY_PADDING} />
                                {renderAnnotationStats()}
                            </Container>
                        </Grid>
                    </Grid>
                </Container>}
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingAccess || loadingCarcass}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    )
}

export default AnnotationsFlow;