import React, { FC } from 'react';
import { Grid } from '@mui/material';

import AnomalyAnnotationCard from '../annotationsAnomaliesCard';
import { IAnnotationAnomalyContinuousGrid, IAnomaly } from '../../../../types';
import { PADDING } from '../../../../utils/consts';

const AnnotationsAnomaliesContinuousGrid: FC<IAnnotationAnomalyContinuousGrid> = (params: IAnnotationAnomalyContinuousGrid) => {
    return (
    // #color={params.anomalies.length == 0 ? 'transparent' : '#000'}
        <Grid container item padding={PADDING/2} >
            {params.anomalies.length > 0 &&
                <Grid container item rowSpacing={6}>
                    {params.anomalies.map((item, index) => {
                        if (index >= params.limit) return null;
                        if (!item.photoUri) return null;
                        let isSelected: boolean = false;
                        if (params.selectedAnomalies.find(anomaly => anomaly.id == item.id))
                            isSelected = true;

                        return (
                           
                            <Grid key={index} item xs={6} md={6} lg={4} xl={3}>
                                <AnomalyAnnotationCard
                                    anomaly={item}
                                    isSelected={isSelected}
                                    onSelectAnomaly={(auxAnomaly: IAnomaly) => params.onSelectAnomaly(auxAnomaly)} />
                            </Grid>   
                        )
                    })}
                </Grid>
            }
        </Grid>
    );
}

export default AnnotationsAnomaliesContinuousGrid;