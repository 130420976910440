import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { GlassMagnifier } from 'react-image-magnifiers';

import './classes.css'
import Container from '../../../atoms/container';
import { useAnnotations } from '../../../../hooks/annotations';
import { ICarcassPhoto } from '../../../../types';

const CarcassPhoto: FC<ICarcassPhoto> = (params: ICarcassPhoto) => {
    const { magnifierSize, handleMagnifierSize } = useAnnotations();

    return (
        <Container fluid width="100%">
            {!params.hideZoomButtons &&
                <Container inline>
                    <IconButton size="medium" color="primary" onClick={() => handleMagnifierSize(true)}>
                        <ZoomInIcon />
                    </IconButton>
                    <IconButton size="medium" color="primary" onClick={() => handleMagnifierSize(false)}>
                        <ZoomOutIcon />
                    </IconButton>
                </Container>
            }
            {!params.hideMagnifier &&
                <GlassMagnifier
                    imageSrc={params.photoUri}
                    magnifierSize={magnifierSize}
                    style={{
                        width: '-webkit-fill-available',
                        minWidth: params.minWidth || '300px',
                        minHeight: params.minHeight || '800px',
                    }}
                />}
        </Container>
    );
}

export default CarcassPhoto;