import { AxiosResponse } from "axios";
import moment from "moment";
import { IAlertStatus, IAlertHistoryFilterParams } from "../types";
import api from ".";


export const baseURL: string = "/api_alert_system/v1_0/statuses";


export const getAlertHistory = async (params?: IAlertHistoryFilterParams) => {
  let filter = "?";
  if (params) {
    if (params.initialTime)
      filter += `initialTime=${moment(params.initialTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      )}&`;
    if (params.finalTime)
      filter += `finalTime=${moment(params.finalTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      )}&`;
    if (params.branches && params.branches.length > 0)
      filter += `branchesId=${params.branches.map((item) => item.id).join(",")}&`;
    if (params.alerts && params.alerts.length > 0)
      filter += `alertsId=${params.alerts.map((item) => item.id).join(",")}&`;
    if (params.statuses && params.statuses.length > 0)
      filter += `statuses=${params.statuses.join(",")}&`;
    if (params.levels && params.levels.length > 0)
      filter += `levels=${params.levels.join(",")}&`;
    if (params.sources && params.sources.length > 0)
      filter += `sources=${params.sources.join(",")}&`;
  }

  if (filter.endsWith('&'))
    filter = filter.substring(0, filter.length - 1);
  else if (filter.length === 1)
      filter = '';

  const response: AxiosResponse = await api.get(`${baseURL}/history${filter}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data as IAlertStatus[];
    }
  }

  throw new Error("Não foi possível buscar os dados");
};
