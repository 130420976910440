import { AxiosResponse } from "axios";
import moment from "moment";
import api from ".";
import { IDownloadPageFilter } from "../types";

const baseUrl: string = "carcasses/download_page";

export const getDownloadPage = async (query: IDownloadPageFilter) => {
  const url = `/api_redsoft/v1_0/${baseUrl}`;
  let filters = '?';
  if (query.initialDate)
    filters += `initialDate=${moment(query.initialDate).format("YYYY-MM-DD")}&`;
  if (query.finalDate)
    filters += `finalDate=${moment(query.finalDate).format("YYYY-MM-DD")}&`;
  if (query.selectedBranches.length > 0)
    filters += `branchesId=${query.selectedBranches.map(item => item.id).join(',')}&`;
  if (query.sexes.length > 0)
    filters += `sexes=${query.sexes.join(',')}&`;
  if (query.selectedPops.length > 0)
    filters += `pops=${query.selectedPops.join(',')}&`;
  if (query.popClassifications.length > 0)
    filters += `popClassifications=${query.popClassifications.join(',')}&`;
  if (query.selectedClassifications.length > 0)
    filters += `classifications=${query.selectedClassifications.map(item => item.id).join(',')}&`;
  if (query.selectedLocalClassifications.length > 0)
    filters += `localClassifications=${query.selectedLocalClassifications.map(item => item.id).join(',')}&`;
  if (query.selectedConformations.length > 0)
    filters += `conformations=${query.selectedConformations.map(item => item.id).join(',')}&`;
  if (query.selectedLocalConformations.length > 0)
    filters += `localConformations=${query.selectedLocalConformations.map(item => item.localConformation).join(',')}&`;
  if (query.photoTypes.length > 0)
    filters += `photoTypes=${query.photoTypes.join(',')}&`;
  if (query.accuracyTreshold)
    filters += `accuracy=${query.accuracyTreshold}&`;
  if (query.undefinedThreshold)
    filters += `undefinedThreshold=${query.undefinedThreshold}&`;
  if (query.filterProperty)
    filters += `groupBy=${query.filterProperty}&`;
  if (query.onlyBestPlaced)
    filters += `bestPlaced=1&`;
  if (query.limit)
    filters += `limit=${query.limit}&`;
  if (query.randomize)
    filters += `randomize=1&`;

  const response: AxiosResponse = await api.get(url + filters);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível buscar carcaças");
};
