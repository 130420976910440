import React, { FC } from 'react';
import Grid from '@mui/material/Grid';

import {
  Container,
  Page,
  theme
} from '../../components';
import { BodyText, Title1 } from '../../components/atoms/text';
import { VERY_PADDING } from '../../utils/consts';
import PopDashboardFilter from '../../components/molecules/carcassesPops/dashboard/filter';
import PopDashboardGrid from '../../components/molecules/carcassesPops/dashboard/grid';


const PopDashboard: FC<any>  = () => {
    return <Page width='100%' height='auto' style={{ minHeight: '95vh', padding: "0 30px 40px" }}>
        <Container fluid flex color={theme.palette.background.default} width="100%" >
            <Container fluid sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING, paddingTop: VERY_PADDING}}>
                <Grid container rowSpacing={4} columnSpacing={4} sx={{alignItems: "center"}}>
                    <Grid item lg={2} xs={12}>
                        <Title1 style={{ whiteSpace: 'nowrap' }}>POP Dashboard</Title1>
                        <BodyText muted>POP-Engine / POP Dashboard</BodyText>
                    </Grid>
                    <Grid item xs={12}>
                        <PopDashboardFilter />
                        <PopDashboardGrid />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    </Page >
}

export default PopDashboard;
