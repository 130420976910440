import React, { FC } from 'react';
import { Grid } from '@mui/material';

import VerdictCard from '../verdictCard';
import Container from '../../../atoms/container';
import { ICarcass, IVerdictContinuosGrid } from '../../../../types';

const VerdictContinuosGrid: FC<IVerdictContinuosGrid> = (params: IVerdictContinuosGrid) => {
    return (
        <Container fluid flex veryPadded color={params.carcasses.length == 0 ? 'transparent' : '#000'}>
            {params.carcasses.length > 0 &&
                <Grid container>
                    {params.carcasses.map((item, index) => {
                        // console.log(item)
                        if (!item.photoUri) return null;
                        let isSelected: boolean = false;
                        if (params.selectedCarcasses.find(annotation => annotation.id == item.id))
                            isSelected = true;

                        return (
                            <Grid item key={index} xs={6} md={6} lg={6} xl={6} display="flex" justifyContent="center">
                                <VerdictCard
                                    carcass={item}
                                    isSelected={isSelected}
                                    property={params.property}
                                    onSelectCarcass={(auxCarcass: ICarcass) => params.onSelectCarcass(auxCarcass)} />
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </Container>
    );
}

export default VerdictContinuosGrid;
