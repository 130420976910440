import React, { FC, useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import SyncIcon from '@mui/icons-material/Sync';
import moment from 'moment';
import 'moment/locale/pt-br';

import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

import theme from '../../../theme';
import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';

import LotClassificationPieChartModal from '../lotClassificationPieChartModal';
import LotPhotoUploadModal from '../lotPhotoUploadModal';

import { PADDING } from '../../../../utils/consts';
import { PIE_COLORS } from '../lotClassificationPieChartModal';
import {
    capitalizeTheFirstLetterOfEachWord,
    convertNumberToFixedAndLocale
} from '../../../../utils/stringFormatters';

import {  ILot, ILotClassificationData, ILotsFilterParams } from '../../../../types';
import { useAuth } from '../../../../hooks/auth';
import { useLots } from '../../../../hooks/lots';
import { getCarcassClassification } from '../../../../hooks/carcasses';
import { fetchDownloadReport } from '../../../../services/lots';


const FONTSIZE_DATA = 15;
const FONTSIZE_DATA_LABEL = 13;
interface ILotHeader extends ILot{
    onExpandClick: () => void; 
    expanded: boolean;
    uploadLotImage:  (_lot: ILot, image: File) => Promise<string[]>;
    removeLotImage: (_lot: ILot, photoUri: string) => Promise<string[]>;
}


const LotHeader: FC<ILotHeader> = (params) => {
    
    const { onExpandClick, expanded, ...extracted_lot} = params;
    const { hasRights } = useAuth();

    const [uploadPhotoIsOpen, setUploadPhotoIsOpen] = useState<boolean>(false);
    const [lot, setLot] = useState<ILot>({ ...extracted_lot});
    const [photoIsExpanded, setPhotoIsExpanded] = useState<boolean>(false);
    const [selectedPhoto, setSelectedPhoto] = useState<string | undefined>();
    const [previousPhoto, setPreviousPhoto] = useState<string | undefined>();
    const [nextPhoto, setNextPhoto] = useState<string | undefined>();
    const [LotClassificationDataPieChart, setLotClassificationDataPieChart] = useState<ILotClassificationData[]>([]);
    const [LotClassificationDataPieChartFrigobom, setLotClassificationDataPieChartFrigobom] = useState<ILotClassificationData[]>([]);
    const [LotTypificationDataPieChart, setLotTypificationDataPieChart] = useState<ILotClassificationData[]>([]);
    const [pieChartModalIsOpen, setPieChartModalIsOpen] = useState<boolean>(false);
    const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState<boolean>(false);
    const [downloadReportLoading, setDownloadReportLoading] = useState<boolean>(false);

    const { filter } = useLots();
    useEffect(() => {
        let data: ILotClassificationData[] = [];
        let typificationData: ILotClassificationData[] = [];
        if(params.carcassCountPerClass){
            params.carcassCountPerClass.map((obj: any, index: any) => (
                data.push({name: getCarcassClassification(obj.classification), value: Math.floor(obj.count/2)})
            )
            )
            const sum = data.reduce((total, item) => total + item.value, 0);
            const desiredSum = params.carcassCount ? Math.floor(params.carcassCount/2) : 0;
            data = adjustDifference(data, sum, desiredSum)
            setLotClassificationDataPieChart(data);
        }
        if(params.carcassCountPerTypification){
            params.carcassCountPerTypification.map(
                (obj: any, index: any) => typificationData.push({name: obj.name, value: Math.floor(obj.count/2)})
            );
            const sum = data.reduce((total, item) => total + item.value, 0);
            const desiredSum = params.carcassCount ? Math.floor(params.carcassCount/2) : 0;
            data = adjustDifference(data, sum, desiredSum)
            setLotTypificationDataPieChart(typificationData);
        }
    }, [])
    
    useEffect(() => {
        const frigobomData = convertClassificationToFrigobomNomenclature(LotClassificationDataPieChart);
        if(frigobomData !== undefined){
            setLotClassificationDataPieChartFrigobom(frigobomData);
        }else{
            setLotClassificationDataPieChartFrigobom(LotClassificationDataPieChart)
        }
        
    }, [LotClassificationDataPieChart])
    
    const onClosePhotoUploadModal = () => {
        setUploadPhotoIsOpen(!uploadPhotoIsOpen);
    }
    
    const onVisualizeClick = (_photos: string[] | undefined) => {
        if(_photos){
            setSelectedPhoto(_photos[0]);
            setPreviousPhoto(undefined);
            setNextPhoto(_photos.length !== 0 ? _photos[1] : undefined)
            setPhotoIsExpanded(true);
        }
    }
    const togglePieChartModal = () => {
        setPieChartModalIsOpen(!pieChartModalIsOpen);
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, percent, color }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        name: string;
        percent: number;
        color: string;
        }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + (radius + 46) * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + (radius + 46) * Math.sin(-midAngle * (Math.PI / 180));
        
        return (
            <text x={x} y={y} fill={color} textAnchor="middle" dominantBaseline="central" fontSize={12}>
                {
                    percent ? `${(percent * 100).toFixed(2)}%`: ''
                }
            </text>
        );
      };
    

    const convertClassificationToFrigobomNomenclature = (classificationData: ILotClassificationData[] | undefined) => {
        if(!classificationData)
            return

        var convertedData: ILotClassificationData[] = [];

        var padraoValue = 0;
        var desuniformeValue = 0;
        var semCoberturaValue = 0;
        for (let i = 0; i < classificationData.length; i++){
            if(classificationData[i].name == 'Mediana' || classificationData[i].name == 'Uniforme' || classificationData[i].name == 'Excessiva'){
                padraoValue += classificationData[i].value
            }else if(classificationData[i].name == 'Escassa'){
                desuniformeValue += classificationData[i].value
            }else if(classificationData[i].name == 'Ausente'){
                semCoberturaValue += classificationData[i].value
            }
        }
        convertedData.push({
            name: 'Sem Cobertura',
            value: semCoberturaValue
        })
        convertedData.push({
            name: 'Desuniforme',
            value: desuniformeValue
        })
        convertedData.push({
            name: 'Padrão',
            value: padraoValue
        })
        

        const sum = convertedData.reduce((total, item) => total + item.value, 0);
        const desiredSum = params.carcassCount ? Math.floor(params.carcassCount/2) : 0;
        convertedData = adjustDifference(convertedData, sum, desiredSum)
        return convertedData;
    }

    const adjustDifference = (countObject: ILotClassificationData[], sum: number, desiredSum: number) => {
        const adjustedObject = countObject.slice()
        if (sum !== desiredSum) {
            const difference = desiredSum - sum;
            const adjustment = Math.floor(difference / adjustedObject.length);
            var leftover = difference % adjustedObject.length;

            for (let i = 0; i < adjustedObject.length; i++) {
                adjustedObject[i].value += Math.max(0, adjustment);
                if (leftover > 0) {
                    adjustedObject[i].value += 1;
                    leftover--;
                }
                else{
                    break;
                }
            }
        }
        return adjustedObject
    } 

    const isFilterFrigobomOnly = (filter: ILotsFilterParams) => {
        if(filter && filter.branches.length === 1){
            if(filter.branches[0].company && filter.branches[0].company.name.toLocaleLowerCase().includes('frigobom')){
                return true
            }
        }
        return false
    }

    const changeToNextPhoto = () => {
        setPreviousPhoto(selectedPhoto)
        setSelectedPhoto(nextPhoto)
        if(lot.photos && nextPhoto){
            if(lot.photos.indexOf(nextPhoto) + 1 < lot.photos.length){
                setNextPhoto(lot.photos[lot.photos.indexOf(nextPhoto) + 1])
            } else{
                setNextPhoto(undefined);
            }
        }
    }

    const changeToPreviousPhoto = () => {
        setNextPhoto(selectedPhoto)
        setSelectedPhoto(previousPhoto)
        if(lot.photos && previousPhoto){
            if(lot.photos.indexOf(previousPhoto) - 1 >= 0){
                setPreviousPhoto(lot.photos[lot.photos.indexOf(previousPhoto) - 1])
            } else{
                setPreviousPhoto(undefined);
            }
        }
    }

    const deleteCurrentPhoto = () => {
        const currLot: ILot = {...params}
        if(selectedPhoto){
            params.removeLotImage(currLot, selectedPhoto)
            if(lot.photos){
                lot.photos = lot.photos.filter(photo => photo !== selectedPhoto)
            }
            if(nextPhoto){
                setSelectedPhoto(nextPhoto)
                if(lot.photos && nextPhoto){
                    setNextPhoto(undefined);
                    if(lot.photos.indexOf(nextPhoto) + 1 < lot.photos.length){
                        setNextPhoto(lot.photos[lot.photos.indexOf(nextPhoto) + 1])
                    }
                }
            }else if(previousPhoto){
                setSelectedPhoto(previousPhoto)
                if(lot.photos && previousPhoto){
                    setPreviousPhoto(undefined);
                    if(lot.photos.indexOf(previousPhoto) - 1 >= 0){
                        setPreviousPhoto(lot.photos[lot.photos.indexOf(previousPhoto) - 1])
                    }
                }
            }else{
                setPhotoIsExpanded(false);
            }
        }
    }

    const handleDeleteDialog = (option: boolean) => {
        if(option){
            deleteCurrentPhoto()
        }
        setConfirmDeleteIsOpen(false)
        return option
    }

    const downloadReport = async () => {
        setDownloadReportLoading(true);
        await fetchDownloadReport(params);
        setDownloadReportLoading(false);
    }
    
    function formatCpfCnpj(input: string): string{
        const cleanedInput = input.replace(/\D/g, '');
        if(cleanedInput.length === 14){
            return cleanedInput.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        }else if (cleanedInput.length === 11){
            return cleanedInput.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
        }
        return cleanedInput
    }
    return (
        <Container fluid centered width="100%" sx={{borderBottom: `2px solid ${theme.palette.grey[200]}`}}>
            <Container fluid width="100%"  >
                <Container inline maxHeight="auto" padded>
                    <Spacing left={PADDING / 2} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={2} style={{ whiteSpace: "nowrap" }}>
                            <Spacing top={PADDING / 2} />
                            
                            {params.lotNum &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Container inline flex>
                                        <IconButton onClick={params.onExpandClick}>
                                            {params.expanded ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                        <Spacing left={PADDING / 2} />
                                        <Text size={16} bold style={{ alignSelf: "center"}}>SEQ Lote #{params.lotNum}</Text>
                                    </Container>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} style={{ whiteSpace: "nowrap" }}>                            
                            <Spacing top={PADDING / 2} />
                            {params.farm &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}> Fazenda: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{params.farm} </Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {params.city && params.state &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}> Cidade: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{params.city} - {params.state} </Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            <Grid item xs={12} sm={6} md={3}>
                                <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}> Proprietário: </Text>
                                <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{params.producerName ? capitalizeTheFirstLetterOfEachWord(params.producerName) : '-'} </Text>
                            </Grid>
                            <Spacing top={PADDING / 2} />
                            {params.cpfCnpj &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}> CPF/CNPJ: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{formatCpfCnpj(params.cpfCnpj)} </Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {params.carcassCount &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}> Quantidade carcaças: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{Math.floor(params.carcassCount/2)} </Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {(params.averageWeight &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}>Peso médio: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{convertNumberToFixedAndLocale(params.averageWeight)} kg ({convertNumberToFixedAndLocale(params.averageWeight / 15)} @)</Text>
                                </Grid>
                            ) ||
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}>Peso médio: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>Indisponível</Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {(params.totalWeight &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}>Peso total: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>{convertNumberToFixedAndLocale(params.totalWeight)} kg ({convertNumberToFixedAndLocale(params.totalWeight / 15)} @)</Text>
                                </Grid>
                            ) ||
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{display: 'inline'}}>Peso total: </Text>
                                    <Text size={FONTSIZE_DATA} bold style={{display: 'inline'}}>Indisponível</Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {params.uniqueDocNums &&
                                <Grid container item xs={12} sm={6} md={6} >
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{alignSelf: "center", display: 'inline'}}>Contratos: </Text>
                                    <Spacing right={PADDING / 2} />
                                    {params.uniqueDocNums.split(',').map((docNum, index) => (        
                                        <React.Fragment key={index}>
                                            <Chip label={docNum} size="small" variant="outlined" sx={{ fontWeight: "bold", fontSize: `${FONTSIZE_DATA_LABEL}` ,backgroundColor: `${theme.palette.grey[100]}` }}/>
                                            <Spacing right={PADDING / 2} />
                                        </React.Fragment>   
                                    ))}
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                        </Grid>
                        <Grid item container xs={12} md={6} sx={{ justifyContent: 'center', alignItems: 'flex-start', paddingRight: { sm: 2 } }}>
                            <Grid item xs={12} sm={8} md={6} lg={7}
                                sx={{
                                    display: {xs: 'none', sm: 'flex'},
                                    flexWrap: 'wrap',
                                    justifyContent: { xs: 'center', sm: 'flex-start' },
                                    paddingTop: 1
                                }}
                            >
                                <Stack spacing={1} sx={{ alignItems: 'center' }}>
                                    <Text size={14} semiBold>Acabamento</Text>
                                    <PieChart width={185} height={150} onClick={togglePieChartModal} style={{cursor: "pointer"}}>
                                        <Pie
                                        data={isFilterFrigobomOnly(filter) ? LotClassificationDataPieChartFrigobom : LotClassificationDataPieChart}
                                        dataKey="value"
                                        nameKey="name"
                                        startAngle={180}
                                        endAngle={-180}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={50}
                                        fill="#8884d8"
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                        >
                                            {
                                                (isFilterFrigobomOnly(filter) ? LotClassificationDataPieChartFrigobom : LotClassificationDataPieChart).map((entry, index) => (
                                                    <Cell key={`cell-${index}`}  fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                                ))
                                            }
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </Stack>
                                {LotTypificationDataPieChart && LotTypificationDataPieChart.length <= 10 && 
                                    <Stack spacing={1} sx={{ alignItems: 'center', marginLeft: 1 }}>
                                        <Text size={14} semiBold>Tipificação</Text>
                                        <PieChart width={185} height={150} onClick={togglePieChartModal} style={{cursor: "pointer"}}>
                                            <Pie
                                            data={LotTypificationDataPieChart}
                                            dataKey="value"
                                            nameKey="name"
                                            startAngle={180}
                                            endAngle={-180}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={50}
                                            fill="#8884d8"
                                            label={renderCustomizedLabel}
                                            labelLine={false}
                                            >
                                                {
                                                    (LotTypificationDataPieChart).map((entry, index) => (
                                                        <Cell key={`cell-${index}`}  fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                                    ))
                                                }
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </Stack>
                                }

                            </Grid>
                            <Grid item xs={12} sm={4} md={6} lg={5} xl={5}
                                sx={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                                    alignItems: { xs: 'center', sm: 'flex-end' }
                                }}
                            >
                                <Spacing top={PADDING / 2} />
                                {params.classificationDate &&
                                    <Grid item xs={12}>
                                        <Text size={FONTSIZE_DATA_LABEL} semiBold >
                                            {moment(params.classificationDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}
                                        </Text>
                                    </Grid>
                                }  
                                <Grid item xs={12} marginTop={2}>
                                    <Button
                                        size="small"
                                        variant={downloadReportLoading ? "contained": "outlined"}
                                        color="primary"
                                        onClick={() => { if (!downloadReportLoading) downloadReport() }}
                                        sx={{cursor: downloadReportLoading ? 'default' : 'pointer'}}
                                    >
                                        <Text medium size={13}
                                            color={downloadReportLoading ? 'white' : theme.palette.primary.main}
                                            style={{ marginRight: '6px'}}
                                        >
                                            Relatório (RTA)
                                        </Text>
                                        {downloadReportLoading ?
                                            <SyncIcon sx={{
                                                animation: "spin 3s linear infinite",
                                                "@keyframes spin": {
                                                "0%": {
                                                    transform: "rotate(360deg)",
                                                },
                                                "100%": {
                                                    transform: "rotate(0deg)",
                                                },
                                                },
                                            }} />
                                            :  <DescriptionIcon />
                                        }
                                    </Button>
                                </Grid>
                                {hasRights!('can_upload_lot_photo') &&
                                    <Grid item xs={12} marginTop={2}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => setUploadPhotoIsOpen(true)}>
                                            <Text medium size={13} color={theme.palette.primary.main}>Adicionar foto do lote</Text>
                                        </Button>
                                    </Grid>
                                }
                                {
                                    lot.photos && lot.photos.length !== 0 &&
                                    <Grid item xs={12} marginTop={2}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => onVisualizeClick(lot.photos)}>
                                            <Text medium size={13} color={theme.palette.primary.main}>Visualizar fotos</Text>
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item md={0} lg={1} xl={1}></Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Spacing top={PADDING} />
            </Container>
            <LotClassificationPieChartModal
                data={isFilterFrigobomOnly(filter) ? LotClassificationDataPieChartFrigobom : LotClassificationDataPieChart}
                open={pieChartModalIsOpen}
                onClose={togglePieChartModal}
                dimension={500}
                radius={90}
            />

            <LotPhotoUploadModal
                open={uploadPhotoIsOpen}
                onClose={onClosePhotoUploadModal}
                lot={lot}
                setLot={setLot}
                uploadLotImage={params.uploadLotImage}
            />

            
            {lot.photos && lot.photos.length !== 0 && photoIsExpanded && selectedPhoto &&
            <>
                <Lightbox
                        prevSrc={previousPhoto}
                        mainSrc={selectedPhoto}
                        nextSrc={nextPhoto}
                        onMoveNextRequest={changeToNextPhoto}
                        onMovePrevRequest={changeToPreviousPhoto}
                        onCloseRequest={() => setPhotoIsExpanded(false)}
                        zoomInLabel="Mais zoom"
                        zoomOutLabel="Menos zoom" 
                        toolbarButtons={[
                            <IconButton aria-label="delete" color="error" sx={{'&:hover':{color: theme.palette.error.light}}}
                                onClick={() => setConfirmDeleteIsOpen(true)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        ]}
                    />
                <Dialog
                    open={confirmDeleteIsOpen}
                    keepMounted
                    onClose={() => setConfirmDeleteIsOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>Deletar foto</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Essa foto será DELETADA permanentemente, tem certeza?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => handleDeleteDialog(false)}>Não</Button>
                    <Button onClick={() => handleDeleteDialog(true)}>Sim</Button>
                    </DialogActions>
                </Dialog>
            </>
                
            }
        </Container>
    );
}

export default LotHeader;
