import { FC, createContext, useState, useContext } from "react";
import moment from "moment";

import {
    ICarcass,
    ILot,
    ILotCarcasses,
    ILotContext,
    ILotsFilterParams,
} from "../types";
import { fetchLotsInfos, fetchLotsInternal, addLotImage, deleteLotImage } from "../services/lots";

const LotContext = createContext<ILotContext>({} as ILotContext);

export const LotProvider: FC<any> = ({ children }) => {
    const [lots, setLots] = useState<ILot[]>([]);
    const [lotsCarcasses, setLotsCarcasses] = useState<ILotCarcasses[]>([]);
    const [totalNumberOfLots, setTotalNumberOfLots] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<ILotsFilterParams>({
        initialDate: moment().toDate(),
        finalDate: moment().toDate(),
        branches: [],
        docNums: [],
        farms: [],
        lotNums: [],
        dentitions: [],
        weightIntervals: [],
        sexes: [],
    });


    const fetchLots = async (_filter: ILotsFilterParams, currentPage: number, perPage: number) => {
        setLoading(true);
        setLots([]);

        const [lots, totalNumberOfLots]:[ILot[], number] = await fetchLotsInfos(_filter, currentPage, perPage);

        setTotalNumberOfLots(totalNumberOfLots);
        setLots(lots);
        setFilter(_filter);

        setTimeout(() => {
            setLots([...lots]);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const fetchLotCarcasses = async (_lot: ILot, currentPage: number, perPage: number) => {
        setLoading(true);

        let carcasses: ICarcass[] = [];
    
        carcasses = await fetchLotsInternal(_lot, currentPage, perPage);

        let lotCarcasseIndex = lotsCarcasses.findIndex((lotCarcasse) => lotCarcasse.id === _lot.id);
        
        let newLotCarcasses: ILotCarcasses = {
            id: _lot.id,
            carcasses: carcasses,
        }

        if (lotCarcasseIndex !== -1) {
            lotsCarcasses.splice(lotCarcasseIndex, 1, newLotCarcasses);
        }else {
            lotsCarcasses.push(newLotCarcasses);
        }


        setTimeout(() => {
            setLotsCarcasses([...lotsCarcasses]);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 900);
        
    };

    const uploadLotImage = async (_lot: ILot, image: File): Promise<string[]> => {
        setLoading(true);
    
        let images_urls = await addLotImage(_lot, image);

        let lotIndex = lots.findIndex((lot) => lot.id === _lot.id);
        
        if (lotIndex !== -1 && images_urls.length !== 1) {
            let updatedLot: ILot = {
                ...lots[lotIndex],
                photos: images_urls
            };
            lots.splice(lotIndex, 1, updatedLot);
        }
        
        setTimeout(() => {
            setLots([...lots]);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 900);
        
        return images_urls
    };

    const removeLotImage = async (_lot: ILot, photoUri: string): Promise<string[]> => {
        setLoading(true);
    
        let images_urls = await deleteLotImage(_lot, photoUri);

        let lotIndex = lots.findIndex((lot) => lot.id === _lot.id);
        
        if (lotIndex !== -1 && images_urls.length !== 1) {
            let updatedLot: ILot = {
                ...lots[lotIndex],
                photos: images_urls
            };
            lots.splice(lotIndex, 1, updatedLot);
        }
        
        setTimeout(() => {
            setLots([...lots]);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 900);
        
        return images_urls
    };

    return (
        <LotContext.Provider
            value={{
                lots,
                lotsCarcasses,
                totalNumberOfLots,
                loading,
                filter,
                setFilter,
                fetchLots,
                fetchLotCarcasses,
                uploadLotImage,
                removeLotImage,
            }}
        >
            {children}
        </LotContext.Provider>
    );
};

export function useLots() {
    const context = useContext(LotContext);

    if (!context) {
        throw new Error("useLots must be used within an LotsProvider");
    }

    return context;
}







