import React, { FC, useState } from 'react';
import { Stack, Box } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IVerdictCountList } from "../../../../types";
import { LargeText, BodyText } from '../../../atoms/text';
import {
    getCarcassClassificationClassName,
    getCarcassTypification,
    getCarcassConformationName,
} from '../../../../hooks/carcasses';


const VerdictCountList: FC<IVerdictCountList> = (params: IVerdictCountList) => {
    const [isClassVerdictedExpanded, setIsClassVerdictedExpanded] = useState<boolean>(false);
    const [isClassNoVerdictedExpanded, setIsClassNoVerdictedExpanded] = useState<boolean>(false);
    const [isConfVerdictedExpanded, setIsConfVerdictedExpanded] = useState<boolean>(false);
    const [isConfNoVerdictedExpanded, setIsConfNoVerdictedExpanded] = useState<boolean>(false);

    if (!params.countVerdicts.classification)
        return <></>

    const renderNoVerdictedList = (title: string, data: any, expanded: boolean,
                                   setExpanded: (val: boolean) => void,
                                   nameConverter = (x: number) => String(x)) => {
        if (Object.keys(data).length === 0) return <></>;
        return <Box>
            <BodyText
                style={{
                    fontStyle: 'italic',
                    marginBottom: '8px',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={() => setExpanded(!expanded)}
            >
                {title} {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </BodyText>
            {expanded && Object.keys(data).map((key: any, index: number) =>
                <BodyText key={'noVerdicted' + index + key + title} style={{ marginBottom: '4px' }}>
                    &emsp; {nameConverter(parseInt(key))}: {data[key]}
                </BodyText>
            )}
        </Box>
    };

    const renderVerdictedList = (title: string, data: any, expanded: boolean,
                                 setExpanded: (val: boolean) => void,
                                 nameConverter = (x: any) => String(x),
                                 nameConverterInner = (x: any) => String(x)) => {
        if (Object.keys(data).length === 0) return <></>;
        return <Box>
            <BodyText
                style={{
                    fontStyle: 'italic',
                    marginBottom: '-2px',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={() => setExpanded(!expanded)}
            >
                {title} {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </BodyText>
            {expanded && Object.keys(data).map((key: any, index: number) => <>
                <BodyText key={'verdicted' + index + title} style={{ width: '100%', marginTop: '8px' }}>
                    &emsp; {nameConverter(parseInt(key))}
                </BodyText>
                {Object.keys(data[key]).map((innerKey: any, innerIndex: number) =>
                    <BodyText key={'verdicted' + index + title + innerIndex} style={{ width: '100%' }}>
                        &emsp;&emsp;&#8226; {nameConverterInner(parseInt(innerKey))}: {data[key][innerKey]}
                    </BodyText>
                )}
            </>)}
        </Box>
    };

    return <>
        <LargeText bold style={{ paddingLeft: '20px', marginBottom: '4px' }}>
            Total: {
                Math.max(params.totalNoVerdictClass, params.totalNoVerdictConf) +
                Math.max(params.totalVerdictClass, params.totalVerdictConf)
            }
        </LargeText>
        <Stack direction="row" spacing={4} flexWrap="wrap" sx={{ paddingLeft: '20px', marginBottom: 2 }}>
            {(params.countForVerdictClass || params.countForVerdictConf) && <>
            <Stack spacing={1}>
                <LargeText bold>
                    Não-Arbitradas: {Math.max(params.totalNoVerdictClass, params.totalNoVerdictConf)}
                </LargeText>
                {!!params.countForVerdictClass ?
                    renderNoVerdictedList(
                        'Acabamento',
                        params.countForVerdictClass,
                        isClassNoVerdictedExpanded,
                        setIsClassNoVerdictedExpanded,
                        getCarcassTypification
                    ) :
                    renderNoVerdictedList(
                        'Conformação',
                        params.countForVerdictConf,
                        isConfNoVerdictedExpanded,
                        setIsConfNoVerdictedExpanded,
                        getCarcassConformationName
                    )
                }
            </Stack>
            {!!params.countForVerdictClass && !!params.countForVerdictConf &&
                <Stack spacing={1}>
                    <LargeText bold>
                        &emsp;
                    </LargeText>
                    {renderNoVerdictedList(
                        'Conformação',
                        params.countForVerdictConf,
                        isConfNoVerdictedExpanded,
                        setIsConfNoVerdictedExpanded,
                        getCarcassConformationName
                    )}
                </Stack>
            }
            <Stack spacing={1}>
                <LargeText bold>
                    Arbitradas: {Math.max(params.totalVerdictClass, params.totalVerdictConf)}
                </LargeText>
                {!!params.countForVerdictClass ?
                    !!params.countVerdicts.classification && renderVerdictedList(
                        'Acabamento',
                        params.countVerdicts.classification,
                        isClassVerdictedExpanded,
                        setIsClassVerdictedExpanded,
                        getCarcassTypification,
                        getCarcassClassificationClassName
                    ) :
                    !!params.countForVerdictConf && params.countVerdicts.conformation &&
                    renderVerdictedList(
                        'Conformação',
                        params.countVerdicts.conformation,
                        isConfVerdictedExpanded,
                        setIsConfVerdictedExpanded,
                        getCarcassConformationName,
                        getCarcassConformationName
                    )
                }
            </Stack>
            {!!params.countForVerdictConf &&
            !!params.countForVerdictClass &&
            !!params.countVerdicts.classification &&
                <Stack spacing={1}>
                    <LargeText bold>
                        &emsp;
                    </LargeText>
                    {renderVerdictedList(
                        'Conformação',
                        params.countVerdicts.conformation,
                        isConfVerdictedExpanded,
                        setIsConfVerdictedExpanded,
                        getCarcassConformationName,
                        getCarcassConformationName
                    )}
                </Stack>
            }
            </>}
        </Stack>
    </>
};

export default VerdictCountList;
