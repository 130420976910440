import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AddPhotoAlternate as AddPhotoIcon, Visibility, VisibilityOff } from '@mui/icons-material';

import { ReactComponent as CautionIcon } from "../../../assets/svg/cautionIcon.svg";
import { ReactComponent as CellphoneIcon } from "../../../assets/icons/cellphoneIcon.svg";
import { ReactComponent as DashesCurvedLeftIcon } from "../../../assets/svg/dashesCurvedLeft.svg";
import { ReactComponent as DashesCurvedRightIcon } from "../../../assets/svg/dashesCurvedRight.svg";
import { ReactComponent as PeopleGroupIcon } from "../../../assets/svg/peopleGroupIcon.svg";
import { ReactComponent as PlatformIcon } from "../../../assets/svg/platformIcon.svg";
import { Container, Input, Page, PrivilegeSelect, Spacing, Text, theme } from '../../../components';
import { useAuth } from '../../../hooks/auth';
import { useCompanies } from '../../../hooks/companies';
import { useBranchesHasUser } from '../../../hooks/branchesHasUser';
import { usePrivileges } from '../../../hooks/privileges';
import { useUsers } from '../../../hooks/users';
import { IBranch, IBranchHasUser, IPrivilege, IUser } from '../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';
import { Avatar, Button, Divider, IconButton, TextField } from '@mui/material';
import BranchSelect from '../../../components/molecules/branches/select';
import { useInformation } from '../../../hooks/information';
import { convertBase64 } from '../../../utils/converters';
import { API_URL } from '../../../services';
import { REDSOFT_USER } from '../../../plugins/localStorage.consts';
import { useBranches } from '../../../hooks/branches';
import { getUser } from '../../../services/users';
import UserSelect from '../../../components/molecules/users/select';

const UserRegister: FC<any> = () => {
    const { isSuperAdmin } = useAuth();
    const { branches, fetchBranchesByCompanyId } = useBranches();
    const { fetchCompanies } = useCompanies();
    const { branchesHasUserByBranch, createNewBranchHasUser, fetchBranchesHasUserByBranch } = useBranchesHasUser();
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { id, companyId, branchId } = useParams();
    const privilegeHook = usePrivileges();
    const usersHooks = useUsers();

    const [allUsers, setAllUsers] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [selectedUserName, setSelectedUserName] = useState<string>('');
    const [selectedUserError, setSelectedUserError] = useState<string | null>(null);
    const [user, setUser] = useState<IUser | undefined>(undefined);
    const [userPrivilegeId, setUserPrivilegeId] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string | null>(null);
    const [privilege, setPrivilege] = useState<string>('');
    const [privilegeDescription, setPrivilegeDescription] = useState<string>('');
    const [privilegeError, setPrivilegeError] = useState<string | null>(null);
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);


    useEffect(() => {
        fetchCompanies();
        privilegeHook.fetchPrivileges();
        usersHooks.fetchUsers();
        loadUser();
    }, []);

    useEffect(() => {
        if (user) {
            setName(user.firstName);
            setEmail(user.email || '');
            if (user.attributes && user.attributes.phone)
                setPhone(user.attributes.phone || '');
        }
    }, [user]);

    useEffect(() => {
        if (companyId) fetchBranchesByCompanyId(companyId);
    }, [companyId]);

    useEffect(() => {
        if (branchId) fetchBranchesHasUserByBranch(branchId);
    }, [branchId]);

    useEffect(() => {
        const auxBranch: IBranch | undefined = branches.find(item => item.id == branchId);
        if (auxBranch)
            setBranchName(auxBranch.name);
    }, [branches]);

    useEffect(() => {
        if (!user && privilegeHook.privileges.length > 0) {
            let auxPrivilege: IPrivilege | undefined = privilegeHook.privileges.find(item => item.name == "USER");
            if (auxPrivilege) {
                setPrivilege(auxPrivilege.id);
                setPrivilegeDescription(auxPrivilege.name);
            }
        }
    }, [privilegeHook.privileges]);

    useEffect(() => {
        fetchUsersData(branchesHasUserByBranch);
    }, [branchesHasUserByBranch]);

    const fetchUsersData = async (_branchesHasUser: IBranchHasUser[]) => {
        setAllUsers([]);
        const auxUsers: IUser[] = [];
        for (let i = 0; i < _branchesHasUser.length; i++) {
            const branchHasUser: IBranchHasUser = _branchesHasUser[i];
            if (branchHasUser.userId) {
                const userId: string = branchHasUser.userId;
                try {
                    const auxUser: IUser = await getUser(userId);
                    auxUsers.push(auxUser);
                } catch (err) {
                    console.log(err)
                }
            }
        }

        const auxAllUsers: IUser[] = [];
        for (let i = 0; i < usersHooks.users.length; i++) {
            const user: IUser = usersHooks.users[i];
            if (!auxUsers.find(item => item.id == user.id))
                auxAllUsers.push(user);
        }
        setAllUsers(auxAllUsers);
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
        setPhoneError(null);
        setError(null);
    }

    const handleChangeSelectedUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).label == undefined) {
            setSelectedUser('');
            setSelectedUserName('');
        } else if ((event as any).target) {
            setSelectedUser((event as any).target.value);
            setSelectedUserName('');
        } else {
            if ((event as any).id) {
                setSelectedUser((event as any).id);
            }
            if ((event as any).label) {
                setSelectedUserName((event as any).label);
            }
        }
        setSelectedUserError(null);
        setError(null);
    }
    const handleChangePrivilege = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).label == undefined) {
            setPrivilege('');
            setPrivilegeDescription('');
        } else if ((event as any).target) {
            setPrivilege((event as any).target.value);
            setPrivilegeDescription('');
        } else {
            if ((event as any).id) {
                setPrivilege((event as any).id);
            }
            if ((event as any).label) {
                setPrivilegeDescription((event as any).label);
            }
        }
        setPrivilegeError(null);
        setError(null);
    }
    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).label == undefined) {
            setBranchName('');
        } else {
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setError(null);
    }

    const loadUser = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxUser: IUser | undefined = await usersHooks.fetchUser(id);
                if (auxUser) {
                    setUser(auxUser);

                    const privilege: IPrivilege[] = await usersHooks.fetchUserGroups(id);
                    if (privilege.length > 0) {
                        setUserPrivilegeId(privilege[0].id);
                        setPrivilege(privilege[0].id);
                        setPrivilegeDescription(privilege[0].attributes.description[0]);
                    }
                } else {
                    console.log(`Not found user with id ${id}`)
                }
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    function validate() {
        if (selectedUser) return true;

        let isValid = true;
        if (name == '') {
            setNameError('Informe o nome completo do usuário');
            isValid = false;
        }
        if (email == '') {
            setEmailError('Informe o email do usuário');
            isValid = false;
        }
        if (privilege == '') {
            setPrivilegeError('Selecione o tipo de acesso');
            isValid = false;
        }
        if (!id && branchId == '') {
            setBranchError('Selecione a empresa');
            isValid = false;
        }



        if (isValid) {
            setNameError(null);
            setEmailError(null);
            setPrivilegeError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            if (!selectedUser) {
                let names: string[] = name.split(" ");

                let payload: IUser = {
                    firstName: names.shift() || "",
                    lastName: names.join(" "),
                    username: email,
                    email,
                    enabled: true,
                    emailVerified: false
                };
                if (password) {
                    payload.credentials = [
                        {
                            type: "password",
                            value: password,
                            temporary: false
                        }
                    ]
                }

                if (user && user.id) {
                    await usersHooks.editUser(user.id, payload);
                    if (user) {
                        if (userPrivilegeId && userPrivilegeId != privilege)
                            await usersHooks.removeGroup(user.id, userPrivilegeId);
                        await usersHooks.addGroup(user.id, privilege);
                    }
                } else {
                    const auxUser: IUser = await usersHooks.createNewUser(payload, false);
                    if (auxUser.id) {
                        await usersHooks.addGroup(auxUser.id, privilege);

                        const branchHasUser: IBranchHasUser = {
                            branchId: branchId,
                            userId: auxUser.id
                        }
                        await createNewBranchHasUser(branchHasUser, true);
                    }
                }
            } else {
                const branchHasUser: IBranchHasUser = {
                    branchId: branchId,
                    userId: selectedUser
                }
                await createNewBranchHasUser(branchHasUser, true);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!id) informationMessage = 'O novo usuário foi cadastrado com sucesso.';

            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    setLoading(false);
                    hideInformation();
                    cleanData();
                    navigate(`/companies/${companyId}/branches/${branchId}/users`);
                });
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setPhone('');
        setPhoneError(null);
        setPrivilege('');
        setPrivilegeDescription('');
        setPrivilegeError(null);
        setBranchName('');
        setBranchError(undefined);
        setEmail('');
        setEmailError(null);
        setPassword('');
        setShowPassword(false);
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{(user && user.id) ? "Editar" : "Criar"} Usuário</Text>
                </Container>
                <Spacing top={VERY_PADDING} />

                <Container inline flex>
                    <Container fluid veryPadded width="100%" height="auto">
                        <Container inline flexStart width="100%" height="100%">
                            <Container fluid width="50%" height="100%">
                                <Container fluid veryPadded>
                                    {!user &&
                                        <>
                                            <Text semiBold size={15}>Selecione um usuário já cadastrado:</Text>
                                            <UserSelect
                                                value={selectedUser}
                                                valueName={selectedUserName}
                                                disabled={loading}
                                                error={selectedUserError || ''}
                                                handleChangeValue={handleChangeSelectedUser}
                                                options={allUsers.filter(item => item.username != "superadmin").map(item => {
                                                    let auxItem: any = item;
                                                    auxItem.name = `${item.firstName} ${item.lastName}`;
                                                    return auxItem;
                                                })} />
                                            <Spacing top={VERY_PADDING} />
                                            <Container inline horizontalCentered>
                                                <Divider sx={{ display: "flex", flex: 1 }} />
                                                <Spacing left={PADDING} />
                                                <Text size={13}>Ou insira um usuário novo</Text>
                                                <Spacing left={PADDING} />
                                                <Divider sx={{ display: "flex", flex: 1 }} />
                                            </Container>
                                            <Spacing top={VERY_PADDING} />
                                        </>
                                    }
                                    <Text size={35}>Informações pessoais</Text>
                                    <Spacing top={VERY_PADDING * 2} />
                                    <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>Nome:</Text>
                                        <TextField id="name" autoFocus
                                            required
                                            value={name} onChange={handleChangeName}
                                            placeholder='Nome completo do usuário'
                                            error={nameError != null} disabled={loading || selectedUser != ""} />
                                        {nameError && <Text size={13} color={theme.palette.error.main}>{nameError}</Text>}
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                    <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>Telefone de contato (opcional):</Text>
                                        <TextField
                                            id="phone"
                                            value={phone} onChange={handleChangePhone}
                                            placeholder='Telefone de contato do usuário'
                                            error={phoneError != null} disabled={loading || selectedUser != ""} />
                                        {phoneError && <Text size={13} color={theme.palette.error.main}>{phoneError}</Text>}
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                    <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>Tipo de acesso:</Text>
                                        <PrivilegeSelect
                                            value={privilege}
                                            options={privilegeHook.privileges.map(item => {
                                                return {
                                                    id: item.id,
                                                    description: item.attributes.description[0]
                                                }
                                            }) as any[]}
                                            valueName={privilegeDescription}
                                            disabled={loading || selectedUser != ""}
                                            error={privilegeError || ''}
                                            handleChangeValue={handleChangePrivilege}
                                            placeholder='Escolha o tipo de acesso' />
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                    {!id && branchId && <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>Frigorífico de acesso:</Text>
                                        <BranchSelect
                                            disabled
                                            value={branchId}
                                            valueName={branchName}
                                            error={branchError}
                                            handleChangeValue={handleChangeBranch}
                                            placeholder='Escolha o frigorífico' />
                                        <Spacing top={PADDING} />
                                        <Text medium color={theme.palette.info.main} size={11}>É possível vincular mais filiais posteriomente</Text>
                                    </Container>}
                                    <Spacing top={VERY_PADDING} />
                                    <Text size={20} bold>Informações de login</Text>
                                    <Spacing top={VERY_PADDING} />
                                    <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>E-mail:</Text>
                                        <TextField id="email"
                                            value={email} onChange={handleChangeEmail}
                                            placeholder='Informe o e-mail de acesso do usuário'
                                            error={emailError != null} disabled={loading || selectedUser != ""} />
                                        {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                    <Container fluid width="100%">
                                        <TextField id="passw" required
                                            value={password} onChange={handleChangePassword}
                                            placeholder='Insira a senha de acesso do usuário'
                                            InputProps={{
                                                endAdornment:
                                                    <IconButton
                                                        size="small"
                                                        aria-label="passw-togle-visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                            }}
                                            disabled={loading || selectedUser != ""} />
                                        {id != undefined && <Text size={13} color={theme.palette.error.main} style={{ paddingTop: PADDING / 2 }}>Editando a senha, o usuário perderá o acesso.</Text>}
                                        {/* {id == undefined && password == '' && <Text light size={13} color={theme.palette.info.main} style={{ paddingTop: PADDING / 2 }}>Será enviada uma senha aleatória para o e-mail {email == '' ? 'informado' : email}</Text>} */}
                                    </Container>
                                    <Spacing top={VERY_PADDING * 2} />
                                    {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                                    <Container inline spacedAround veryPadded>
                                        <LoadingButton
                                            variant="outlined"
                                            size="small"
                                            loading={loading}
                                            disabled={loading}
                                            style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : 'transparent', width: 150, height: 35, borderColor: theme.palette.primary.dark }}
                                            onClick={() => navigate(`/companies/${companyId}/branches/${branchId}/users`)}>
                                            <Text size={16} color={theme.palette.primary.main}>Cancelar</Text>
                                        </LoadingButton>
                                        <LoadingButton
                                            variant="contained"
                                            size="small"
                                            loading={loading}
                                            disabled={loading}
                                            style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : theme.palette.primary.dark, width: 150, height: 35 }}
                                            onClick={() => confirmRegistration()}>
                                            <Text size={16} color={theme.palette.background.paper}>Confirmar</Text>
                                        </LoadingButton>
                                    </Container>
                                </Container>
                            </Container>
                            <Container fluid centered width="50%" height="100%" color="#F6FBEE">
                                <Container width="80%" >
                                    <Container inline width="100%">
                                        <CellphoneIcon />
                                        <Spacing left={VERY_PADDING} />
                                        <Container fluid>
                                            <Spacing top={PADDING} />
                                            <Text bold size={16}>Senha automática</Text>
                                            <Text medium size={13} color={theme.palette.grey[400]}>Após a confirmação do cadastro, a senha é enviada automaticamente ao email do usuário</Text>
                                        </Container>
                                    </Container>
                                </Container>
                                <Spacing top={PADDING} />
                                <Container inline flexStart width="80%" >
                                    <Spacing left={PADDING * 2} />
                                    <DashesCurvedLeftIcon />
                                </Container>
                                <Spacing top={PADDING} />
                                <Container width="80%" >
                                    <Container inline width="100%">
                                        <PeopleGroupIcon />
                                        <Spacing left={VERY_PADDING} />
                                        <Container fluid>
                                            <Spacing top={PADDING} />
                                            <Text bold size={16}>Nível de acesso</Text>
                                            <Text medium size={13} color={theme.palette.grey[400]}>Defina o nível de acesso dos usuários de acordo com suas funções</Text>
                                        </Container>
                                    </Container>
                                </Container>
                                <Spacing top={PADDING} />
                                <Container inline flexStart width="80%" >
                                    <Spacing left={PADDING * 3} />
                                    <DashesCurvedRightIcon />
                                </Container>
                                <Spacing top={PADDING} />
                                <Container width="80%" >
                                    <Container inline width="100%">
                                        <CautionIcon />
                                        <Spacing left={VERY_PADDING} />
                                        <Container fluid>
                                            <Spacing top={PADDING} />
                                            <Text bold size={16}>Inativação</Text>
                                            <Text medium size={13} color={theme.palette.grey[400]}>Você pode inativar usuários cadastrados a qualquer momento</Text>
                                        </Container>
                                    </Container>
                                </Container>
                                <Spacing top={PADDING} />
                                <Container inline flexStart width="80%" >
                                    <Spacing left={PADDING * 2} />
                                    <DashesCurvedLeftIcon />
                                </Container>
                                <Spacing top={PADDING} />
                                <Container width="80%" >
                                    <Container inline width="100%">
                                        <PlatformIcon />
                                        <Spacing left={VERY_PADDING} />
                                        <Container fluid>
                                            <Spacing top={PADDING} />
                                            <Text bold size={16}>Aproveite a plataforma</Text>
                                            <Text medium size={13} color={theme.palette.grey[400]}>Explore todas as funcionalidades que temos a oferecer!</Text>
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page >
    );
}

export default UserRegister;
