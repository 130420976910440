import React, { FC } from 'react';
import { Grid } from '@mui/material';

import {
    Container,
    Page,
    Text,
    theme
  } from '../../components';
import { VERY_PADDING } from '../../utils/consts';
import SlaughterDelaysFilter from '../../components/molecules/slaughterDelays/filter';
import SlaughterDelaysDownloadSpreadsheetButton from '../../components/molecules/slaughterDelays/downloadSpreadsheetButton';
import SlaughterDelaysDashboard from '../../components/molecules/slaughterDelays/dashboard';
import SlaughterDelaysDurationOccurrencesSwitch from '../../components/molecules/slaughterDelays/durationOccurrencesSwitch';


const SlaughterDelaysPage: FC = () => {
    return <Page width='100%' height='auto' style={{ minHeight: '95vh', padding: "0 30px 40px" }}>
        <Container fluid flex color={theme.palette.background.default} width="100%" >
            <Container fluid sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING, paddingTop: VERY_PADDING}}>
                <Grid container spacing={3} sx={{alignItems: "center"}}>
                    <Grid item lg={2} xs={12}>
                        <Text semiBold color={theme.palette.primary.main} size={25}>Telenória</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <SlaughterDelaysFilter />
                    </Grid>
                    <Grid item container xs={12} flexDirection='row' gap={2}>
                        <SlaughterDelaysDownloadSpreadsheetButton />
                        <SlaughterDelaysDurationOccurrencesSwitch />
                    </Grid>
                    <Grid item xs={12}>
                        <SlaughterDelaysDashboard />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    </Page >
}

export default SlaughterDelaysPage;
