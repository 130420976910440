import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Container, Page, Spacing, Text, theme } from '../../../components';
import { useBranches } from '../../../hooks/branches';
import { IBranch, ICompany } from '../../../types';
import { VERY_PADDING } from '../../../utils/consts';
import { useInformation } from '../../../hooks/information';
import CompanySelect from '../../../components/molecules/companies/select';
import { Checkbox, TextField } from '@mui/material';
import { useCompanies } from '../../../hooks/companies';
import { useConfirmation } from '../../../hooks/confirmation';
import {
    CarcassesLocalClassificationsCRUDTable,
    CarcassesLocalConformationsCRUDTable,
    ShiftsCRUDTable,
    TypificationsCRUDTable,
} from '../../../components';
import moment from 'moment';
import DatePicker from '../../../components/atoms/datePicker';


const BranchRegister: FC<any> = () => {
    const { createNewBranch, editBranch, fetchBranch, fetchBranches } = useBranches();
    const { companies, fetchCompanies } = useCompanies();
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { companyId, id } = useParams();

    const [companyName, setCompanyName] = useState<string>('');
    const [companyError, setCompanyError] = useState<string | undefined>(undefined);
    const [branch, setBranch] = useState<IBranch | undefined>(undefined);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [cnpj, setCnpj] = useState<string>('');
    const [cnpjError, setCnpjError] = useState<string | null>(null);
    const [token, setToken] = useState<string>('');
    const [tokenError, setTokenError] = useState<string | null>(null);
    const [prefix, setPrefix] = useState<string>('');
    const [prefixError, setPrefixError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isLicenseActive, setIsLicenseActive] = useState<boolean | undefined>(true);
    const [licenseExpirationDate, setLicenseExpirationDate] = useState<Date | null>(null);
    const [expirationDateError, setExpirationDateError] = useState<string | null>(null);

    useEffect(() => {
        fetchCompanies();
        loadBranch();
    }, []);

    useEffect(() => {
        const company: ICompany | undefined = companies.find(item => item.id == companyId);
        if (company && company.id) {
            setCompanyName(company.name);
        }
    }, [companies]);

    useEffect(() => {
        if (branch) {
            setName(branch.name);
            setEmail(branch.email || '');
            setCnpj(branch.cnpj || '');
            setToken(branch.token);
            setPrefix(branch.prefix);
            setIsLicenseActive(branch.isLicenseActive);
            setLicenseExpirationDate(new Date(branch.licenseExpirationDate ? branch.licenseExpirationDate : ''));
        }
    }, [branch]);

    const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setCompanyName('');
        } else {
            if ((event as any).label) {
                setCompanyName((event as any).label);
            }
        }
        setError(null);
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }
    const handleChangeCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCnpj(event.target.value);
        setCnpjError(null);
        setError(null);
    }
    const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
        setTokenError(null);
        setError(null);
    }
    const handleChangePrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrefix(event.target.value);
        setPrefixError(null);
        setError(null);
    }
    const handleChangeIsLicenseActive = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLicenseActive(event.target.checked);
    }
    const handleChangeLicenseExpirationDate= (date: Date | null) => {
        if(date !== null){
            setLicenseExpirationDate(date);
        }else{
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 30);
            setLicenseExpirationDate(currentDate);
        }
        setExpirationDateError(null);
        setError(null);
    }

    const loadBranch = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxBranch: IBranch = await fetchBranch(id);
                setBranch(auxBranch);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    function validate() {
        let isValid = true;
        if (companyId == '') {
            setCompanyError('Informe a empresa');
            isValid = false;
        }
        if (name == '') {
            setNameError('Informe o nome completo do frigorífico');
            isValid = false;
        }
        if (prefix == '') {
            setPrefixError('Informe o prefixo do frigorífico');
            isValid = false;
        }
        if (!isLicenseActive && licenseExpirationDate !== null && licenseExpirationDate.getDate() < (new Date()).getDate()){
            setExpirationDateError('A data de expiração deve ser uma data futura.')
            isValid = false;
        }


        if (isValid) {
            setNameError(null);
            setEmailError(null);
        }
        return isValid;
    }

    const confirmForm = async () => {
        setLoading(true);
        const interval = setTimeout(() => {
            setLoading(false);
        }, 5000);
        try {
            let payload: IBranch = {
                name,
                companyId,
                email,
                cnpj,
                token,
                isLicenseActive,
                licenseExpirationDate: moment(licenseExpirationDate).format("YYYY-MM-DD HH:mm:ss"),
                prefix
            };
            if (branch && branch.id) {
                await editBranch(branch.id, payload);
            } else {
                await createNewBranch(payload, false);
            }
            setLoading(false);
            if (interval)
                clearInterval(interval);

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!branch) informationMessage = 'O novo frigorífico foi cadastrado com sucesso.';

            hideConfirmation();
            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    fetchBranches();
                    setLoading(false);
                    hideInformation();
                    cleanData();
                    navigate(`/companies/${companyId}/branches`);
                });
        } catch (error) {
            setLoading(false);
            if (error instanceof Error) {
                setError(error.message);
            }
        }
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        if (!isLicenseActive){
            showConfirmation("ATENÇÃO: A Licença está desativada, leia o aviso logo abaixo do campo de destivação.", 
                "Licença Desativada", 
                "Entendi", 
                "Voltar",
                async () => {
                    confirmForm();
                },
                () => {
                    hideConfirmation();
                }
            );

        }else{
            confirmForm();
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setCnpj('');
        setCnpjError(null);
        setToken('');
        setTokenError(null);
        setPrefix('');
        setPrefixError(null);
        setLicenseExpirationDate(new Date());
        setExpirationDateError(null);
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{(branch && branch.id) ? "Editar" : "Criar"} Frigorífico</Text>
                </Container>
                <Spacing top={VERY_PADDING} />

                <Container fluid veryPadded centered>
                    <Container fluid width="50%" height="100%">
                        <Container fluid veryPadded>
                            <Text size={35}>Informações do frigorífico</Text>
                            <Spacing top={VERY_PADDING} />
                            {companyId &&
                                <>
                                    <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>Empresa:</Text>
                                        <CompanySelect
                                            value={companyId}
                                            valueName={companyName}
                                            error={companyError}
                                            handleChangeValue={handleChangeCompany}
                                            variant="outlined"
                                            disabled />
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                </>
                            }
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Nome:</Text>
                                <TextField id="name" autoFocus required
                                    value={name} onChange={handleChangeName}
                                    placeholder='Informe o nome do frigorífico'
                                    error={nameError != null} disabled={loading} />
                                {nameError && <Text size={13} color={theme.palette.error.main}>{nameError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>CNPJ (opcional):</Text>
                                <TextField id="cnpj"
                                    value={cnpj} onChange={handleChangeCnpj}
                                    placeholder='Informe o CNPJ do frigorífico'
                                    error={cnpjError != null} disabled={loading} />
                                {cnpjError && <Text size={13} color={theme.palette.error.main} >{cnpjError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>E-mail (opcional):</Text>
                                <TextField id="email"
                                    value={email} onChange={handleChangeEmail}
                                    placeholder='Informe o e-mail do frigorífico'
                                    error={emailError != null} disabled={loading} />
                                {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Token (opcional):</Text>
                                <TextField id="token"
                                    value={token} onChange={handleChangeToken}
                                    placeholder='Informe o Token de sincronização com o frigorífico'
                                    error={tokenError != null} disabled={loading} />
                                {tokenError && <Text size={13} color={theme.palette.error.main} >{tokenError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Prefixo:</Text>
                                <TextField id="prefix"
                                    value={prefix} onChange={handleChangePrefix}
                                    required placeholder='Informe o prefixo do frigorífico'
                                    error={prefixError != null} disabled={loading} />
                                {prefixError && <Text size={13} color={theme.palette.error.main} >{prefixError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'} style={{"flexDirection": "row"}}>
                                <Container width={'65%'} style={{"display": "flex", "alignItems": "center", "justifyContent": "space-between"}}>
                                    <Text semiBold size={15}>Data de expiração da licença:</Text>
                                    <DatePicker
                                        value={licenseExpirationDate}
                                        setValue={handleChangeLicenseExpirationDate}
                                        label="" 
                                        placeholder=""
                                        disabled={isLicenseActive}
                                        disablePast={true}
                                    />
                                </Container>
                                <Container width={'35%'} style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                                    <Text semiBold size={15}>Licença Ativa:</Text>
                                    <Checkbox checked={isLicenseActive} onChange={handleChangeIsLicenseActive}></Checkbox>
                                </Container>
                                {expirationDateError && <Text size={13} color={theme.palette.error.main} >{expirationDateError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            {!isLicenseActive && 
                            <Container width={'100%'} style={{"display": "flex", "flexDirection": "row", "alignItems": "center", "justifyContent": "center"}}>
                                <Text size={13} color={theme.palette.primary.dark} >
                                    ATENÇÃO: A data de expiração da licença é quando a licença será invalidada, emails serão agendados para 1, 7 e 15 dias antes da expiração da licença.
                                    Os Emails serão enviados para o email cadastrado acima. Cópias serão enviadas para Michel e Rafael.
                                </Text>
                            </Container>
                            }
                            <Spacing top={VERY_PADDING * 2} />
                            {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                            {branch && branch.id && <>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="Carcasses Local Classifications CRUD Table"
                                        id="CarcassesLocalClassificationsCRUDTableAccordion"
                                    >
                                    <Typography>Acabamentos Locais</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CarcassesLocalClassificationsCRUDTable branchId={branch.id}/>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="Carcasses Local Conformations CRUD Table"
                                        id="CarcassesLocalConformationsCRUDTableAccordion"
                                    >
                                    <Typography>Conformações Locais</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CarcassesLocalConformationsCRUDTable branchId={branch.id}/>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="Shifts CRUD Table"
                                        id="ShiftsCRUDTableAccordion"
                                    >
                                    <Typography>Turnos</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ShiftsCRUDTable branchId={branch.id}/>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="Typifications CRUD Table"
                                        id="TypificationsCRUDTableAccordion"
                                    >
                                    <Typography>Regras de Tipificação</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TypificationsCRUDTable branchId={branch.id}/>
                                    </AccordionDetails>
                                </Accordion>
                            </>}
                            <Container inline spacedAround veryPadded>
                                <LoadingButton
                                    variant="outlined"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : 'transparent', width: 150, height: 35, borderColor: theme.palette.primary.dark }}
                                    onClick={() => navigate(`/companies/${companyId}/branches`)}>
                                    <Text size={16} color={theme.palette.primary.dark}>Cancelar</Text>
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : theme.palette.primary.dark, width: 150, height: 35 }}
                                    onClick={() => confirmRegistration()}>
                                    <Text size={16} color={theme.palette.background.paper}>Confirmar</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page >
    );
}

export default BranchRegister;
