import { AxiosResponse } from "axios";

import api from ".";
import { ICarcassLocalConformation } from "../types";

import CRUDService from "./crud";

export const baseURL = '/api_redsoft/v1_0/carcasses_local_conformations';

export const convertApiResponseToICarcassLocalConformation = (_data: any) => {
  const entry: ICarcassLocalConformation = {
    id: _data.id,
    branchId: _data.branchId,
    localConformation: _data.localConformation,
    name: _data.name,
    conformationValue: _data.conformationValue,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return entry;
};

const defaultService = CRUDService({
  baseURL,
  name: 'conformação local',
  convertResponseToModel: convertApiResponseToICarcassLocalConformation
});

export const getCarcassesLocalConformationsByBranch: (branch?: string) => Promise<ICarcassLocalConformation[]> =
  async (branch?: string) => {
    const url = !!branch ? `${baseURL}/by_branch_id/${branch}` : baseURL;
    const response: AxiosResponse = await api.get(url);
    return defaultService.crudCheckResponse(response, 'Falha ao buscar acabamentos locais');
  };
