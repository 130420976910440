import React, { FC, useEffect } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    LabelList,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import theme from '../../../../theme';

import { usePopDashboard } from '../../../../../hooks/popDashboard';
import { useCarcassesPops } from '../../../../../hooks/carcassesPops';


const PopDashboardBarChart: FC<{showPercentages: boolean}> = ({
    showPercentages,
}) => {
    const hook = usePopDashboard();
    const { pops, fetchPops } = useCarcassesPops();

    useEffect(() => {
        if (!hook.data && !hook.loading)
            hook.fetchDashboardData();

        if (!pops || pops.length === 0)
            fetchPops([]);
    }, []);

    if (!hook.data?.binaryPops)
        return <></>

    const data = Object.keys(hook.data!.binaryPops).map(key => {
        const data = hook.data!.binaryPops[key];
        const total = data.adequateCount + data.inadequateCount;
        const adequatePercent = total > 0 ? parseInt(
            (100 * data.adequateCount / total).toFixed(0)) : 0;
        const inadequatePercent = total > 0 ? 100 - adequatePercent : 0;
        return {
            "% Conforme": adequatePercent,
            "% Não Conforme": inadequatePercent,
            name: pops?.find(pop => pop.name === key)?.description || key,
            "Total Conforme": data.adequateCount,
            "Total Não Conforme": data.inadequateCount,
        }
    });

    const renderCustomizedLabel = (props: any) => {
        const { x, y, width, height, value } = props;
        if (!value || (showPercentages && value < 5) || (!showPercentages && height < 12))
            return <></>;

        return (
            <g>
                <text x={x + width / 2} y={y + height / 2} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer
            width="100%"
            height="100%"
            minHeight={350}
        >
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={false} height={10} />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                <Bar
                    dataKey={showPercentages ? "% Conforme" : "Total Conforme"}
                    barSize={50}
                    fill={theme.palette.success.main}
                    stackId="a"
                >
                    <LabelList
                        dataKey={showPercentages ? "% Conforme" : "Total Conforme"}
                        content={renderCustomizedLabel}
                    />
                </Bar>
                <Bar
                    dataKey={showPercentages ? "% Não Conforme" : "Total Não Conforme"}
                    barSize={50}
                    fill={theme.palette.primary.main}
                    stackId="a"
                >
                    <LabelList
                        dataKey={showPercentages ? "% Não Conforme" : "Total Não Conforme"}
                        content={renderCustomizedLabel}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default PopDashboardBarChart;
