import React, { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

import BranchSelect from "../../branches/select";
import Container from "../../../atoms/container";
import DateRangePicker from "../../../atoms/dateRangePicker";
import Modal from "../../../atoms/modal";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useBranches } from "../../../../hooks/branches";
import { useConfirmation } from "../../../../hooks/confirmation";
import { useInformation } from "../../../../hooks/information";
import { useAnnotationsAnomalies } from "../../../../hooks/annotationsAnomalies";
import {
  IAnnotationsAnomaliesReinsertModal,
} from "../../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../../../utils/consts";
import { useAuth } from '../../../../hooks/auth';

const AnnotationsAnomaliesReinsertModal: FC<IAnnotationsAnomaliesReinsertModal> = (
  params: IAnnotationsAnomaliesReinsertModal,
) => {
  const { user } = useAuth();
  const { reinsertFalseAnnotationsAnomalies } = useAnnotationsAnomalies();
  const { showInformation } = useInformation();
  const { hideConfirmation, showConfirmation } = useConfirmation();

  const { fetchBranches } = useBranches();

  const [initialDate, setInitialDate] = useState<Date>(new Date());
  const [finalDate, setFinalDate] = useState<Date>(new Date());
  const [branchId, setBranchId] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [branchError, setBranchError] = useState<string | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchBranches();
  }, []);

  const handleChangeBranch = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    if ((event as any).target) {
      setBranchId((event as any).target.value);
      setBranchName("");
    } else {
      if ((event as any).id) {
        setBranchId((event as any).id);
      }
      if ((event as any).label) {
        setBranchName((event as any).label);
      }
    }
    setBranchError(undefined);
    setError(null);
  };

  const validate = () => {
    let isValid: boolean = true;

    if (!initialDate || !finalDate) {
      setError("Favor informar a data");
      isValid = false;
    }

    if (branchId == "") {
      setBranchError("Favor informar a empresa");
      isValid = false;
    }

    if (isValid) {
      setError("");
      setBranchError(undefined);
    }
    return isValid;
  };
  
  const onReinsert = async () => {
    if (validate() == false) return;
    setLoading(true);
    showConfirmation(`O abate já terminou em ${branchName}?`, "Reinserir", "Confirma", "Cancelar",
        async () => {
            const responseReinsert: any = await reinsertFalseAnnotationsAnomalies(initialDate, finalDate, branchId);
            hideConfirmation();
            showInformation('Falsas anomalias reinseridas com sucesso', 'Sucesso')
        },
        () => {
            hideConfirmation();
    });
    setLoading(false);
    
}
  const close = () => {
    cleanData();

    params.onClose();
  };

  const cleanData = () => {
    setInitialDate(new Date());
    setFinalDate(new Date());
    setBranchId("");
    setBranchName("");
    setBranchError(undefined);
    setError(null);
    setLoading(false);
  };

  return (
    <Modal isOpened={params.open} onClose={close}>
      <Container
        fluid
        color={theme.palette.background.default}
        hasShadow
        centered
        borderRadius={BORDER_RADIUS}
        style={{ maxWidth: 540 }}
      >
        <Container
          inline
          color={theme.palette.grayLight.main}
          centered
          width="100%"
          borderTopRadius={BORDER_RADIUS}
        >
          <Container padded>
            <Text size={16} bold color={theme.palette.primary.light}>
              Reinserção de falsas anomalias
            </Text>
          </Container>
        </Container>

        <Container fluid centered spacedAround veryPadded width="90%">
          <Text>Preencha o filtro para efetuar a reinserção de anomalias.</Text>
          <Spacing top={PADDING} />
          <Text light size={13}>
            É ideal que esse processo seja feito apenas após o abate ter finalizado, verifique isso antes de confirmar.
          </Text>
          <Spacing top={VERY_PADDING} />

          <Grid container rowSpacing={2} alignItems="center">
            <Grid item xs={4}>
              <Text light>Data:</Text>
            </Grid>
            <Grid item xs={8}>
              <DateRangePicker
                initialDate={initialDate}
                finalDate={finalDate}
                placeholder={"Escolha intervalo de data"}
                setInitialDate={setInitialDate}
                setFinalDate={setFinalDate}
                disabled={loading}
                maxDaysSelect={7}
              />
            </Grid>
            <Grid item xs={4}>
              <Text light>Frigorífico:</Text>
            </Grid>
            <Grid item xs={8}>
              <BranchSelect
                value={branchId}
                valueName={branchName}
                handleChangeValue={handleChangeBranch}
                variant="outlined"
                placeholder="Escolha o frigorífico"
                error={branchError}
              />
            </Grid>
          </Grid>
        </Container>

        {error && (
          <Text size={13} color={theme.palette.error.main}>
            Erro: {error}
          </Text>
        )}
        <Container inline spacedAround veryPadded>
          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
            loading={loading}
            disabled={loading}
            onClick={close}
          >
            <Text
              bold
              size={12}
              color={
                loading ? theme.palette.grey[400] : theme.palette.primary.light
              }
            >
              Fechar
            </Text>
          </LoadingButton>
          <Spacing left={PADDING} />
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            style={{
              opacity: loading ? 0.5 : 1,
              borderColor: theme.palette.primary.light,
              width: 150,
              height: 35,
            }}
            loading={loading}
            disabled={loading}
            onClick={() => onReinsert()}
          >
            <Text bold size={12} color={theme.palette.background.paper}>
              Confirmar
            </Text>
          </LoadingButton>
        </Container>
      
          {loading &&
              <Container fluid flex centered padded>
                  <Text medium size={12} color={theme.palette.grey[400]}>Reinserindo anomalias...</Text>
              </Container>
          }

      </Container>
    </Modal>
  );
};

export default AnnotationsAnomaliesReinsertModal;
