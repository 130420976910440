import React, { FC } from 'react';

import CountUp from 'react-countup';
import Container from '../../../atoms/container';
import { Box, Stack } from '@mui/material';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { IDashboardNumberCard } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';
import HelperIcon from '../../../atoms/helperIcon';


// GENERAL DIMENSIONS
const topPadding = PADDING*2;
const bottomPadding = PADDING*2;
const leftPadding = PADDING*3;
const rightPadding = PADDING*3;

// MEDIUM SIZE CARD DIMENSIONS
const cardHeight = 190;

const IconBackgroundSize = '50px';
const IconBackgroundBorderRadius = '35%';

const labelTextSize = 18;
const valueTextSize = 45;

// SMALL SIZE CARD DIMENSIONS
const smallCardHeight = ((cardHeight + (topPadding + bottomPadding) - 16)/2) - 40; // [(190 + 40 - 16)/2] - 40 = 67  // (cardHeight + (cardTopPadding + cardBotPadding) - rowSpacing)/2 - outerPadding

const labelTextSizeSmallCard = 14;
const valueTextSizeSmallCard = 24;


const DashboardNumberCard: FC<IDashboardNumberCard> = (params: IDashboardNumberCard) => {
    const formattingFn = React.useCallback((value: number) => {
        const formattedValue = value.toLocaleString('pt-br')
        return formattedValue
    }, []);

    const decimals = params.decimals === undefined ? 2 : params.decimals;

    return (
        <Container 
                flex
                hasShadow 
                padded  
                color="white" 
                borderRadius={BORDER_RADIUS*10}
                sx={{
                    paddingRight: rightPadding,
                    paddingLeft: leftPadding,
                    paddingTop: topPadding,
                    paddingBottom: bottomPadding,
                    opacity: params.loading ? 0.75 : 1,
                    ...params.style,
                }}
        >
            {/* IF size="medium" */}
            {
                (
                    (params.size && params.size === "medium") 
                    || !params.size 
                ) &&
                <Stack 
                    height="100%"
                    minHeight={params.minHeight || cardHeight}
                    spacing={1}
                >
                    <Box 
                        sx={{ display: 'flex', paddingBottom: '18px', paddingTop: '4px', width: '100%' }}
                    >
                        <Box
                            width={IconBackgroundSize} 
                            height={IconBackgroundSize}
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                display: 'flex',
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: IconBackgroundBorderRadius,
                                aspectRatio: 1,
                            }}
                        >
                            {params.icon}
                        </Box>
                        {params.help &&
                            <Box sx={{ textAlign: 'right', width: '100%' }}>
                                <HelperIcon text={params.help} sx={{ marginLeft: '8px' }} />
                            </Box>
                        }
                    </Box>
                    <Box sx={{ paddingBottom: '8px', display: 'flex' }}>
                        {(params.value || params.value === 0) ?
                            params.noCountUp ?
                                <Text
                                    semiBold
                                    size={valueTextSize}
                                    color={theme.palette.primary.main}
                                >
                                    {params.value}
                                </Text>
                                :
                                <CountUp
                                    formattingFn={formattingFn}
                                    start={0}
                                    end={params.value ? params.value : 0}
                                    duration={1}
                                    delay={0}
                                    decimals={(params.value !== undefined && params.value%1 === 0) ? 0 : decimals}
                                >
                                    {(countUp: any) => (
                                        <span
                                            ref={countUp.countUpRef}
                                            style={{
                                                fontFamily: 'Open Sans',
                                                fontWeight: 600,
                                                fontSize: valueTextSize,
                                                color: theme.palette.primary.main,
                                            }}
                                        />
                                    )}
                                </CountUp>
                            :
                            <Text semiBold size={valueTextSize} color={theme.palette.primary.main}>
                                -
                            </Text>
                        }
                        {params.valueUnit &&
                            <Text
                                semiBold
                                size={valueTextSize}
                                color={theme.palette.primary.main}
                                style={{ marginLeft: '4px' }}
                            >
                                {params.valueUnit}
                            </Text>
                        }
                    </Box>
                    <Box>
                        <Text semiBold size={labelTextSize} color={theme.palette.primary.main}>{params.label}</Text>
                    </Box>
                </Stack>
            }

            {/* IF size="small" */}
            {
                (
                    (params.size && params.size === "small")
                ) &&
                <Stack
                    direction={params.minHeight ? 'column' : 'row'}
                    alignItems={params.minHeight ? 'flex-start' : 'center'}
                    minHeight={params.minHeight || smallCardHeight}
                    spacing={2}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                            paddingTop: params.minHeight ? '4px' : '0',
                            width: params.minHeight ? '100%' : 'auto',
                        }}
                    >
                        <Box
                            width={IconBackgroundSize} 
                            height={IconBackgroundSize}
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                display: 'flex',
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: IconBackgroundBorderRadius,
                                aspectRatio: 1
                            }}
                        >
                            {params.icon}
                        </Box>
                        {params.help && params.minHeight &&
                            <Box sx={{ textAlign: 'right', width: '100%' }}>
                                <HelperIcon text={params.help} sx={{ marginLeft: '8px' }} />
                            </Box>
                        }
                    </Box>
                    <Stack width='100%'>
                        <Box width='100%' display='flex'>
                            {params.value !== undefined ?
                                <CountUp
                                    formattingFn={formattingFn}
                                    start={0}
                                    end={params.value ? params.value : 0}
                                    duration={1}
                                    delay={0}
                                    decimals={(params.value !== undefined && params.value%1 === 0) ? 0 : decimals}
                                >
                                    {(countUp: any) => (
                                        <span
                                            ref={countUp.countUpRef}
                                            style={{
                                                fontFamily: 'Open Sans',
                                                fontWeight: 600,
                                                fontSize: valueTextSizeSmallCard,
                                                color: theme.palette.primary.main,
                                            }}
                                        />
                                    )}
                                </CountUp>
                                : <Text
                                    semiBold
                                    size={valueTextSizeSmallCard}
                                    color={theme.palette.primary.main}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    --
                                </Text>
                            }
                            {params.valueUnit &&
                                <Text
                                    semiBold
                                    size={valueTextSizeSmallCard}
                                    color={theme.palette.primary.main}
                                    style={{ marginLeft: '4px' }}
                                >
                                    {params.valueUnit}
                                </Text>
                            }
                            {params.help && !params.minHeight &&
                                <Box sx={{ textAlign: 'right', width: '100%' }}>
                                    <HelperIcon text={params.help} sx={{ marginLeft: '8px' }} />
                                </Box>
                            }
                        </Box>
                        <Text semiBold size={labelTextSizeSmallCard} color={theme.palette.primary.main}>
                            {params.label}
                        </Text>
                    </Stack>
                </Stack>
            }
        </Container>
    );
}

export default DashboardNumberCard;
